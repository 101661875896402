import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { Col, Row, FormGroup } from 'reactstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { required, number0 } from 'constants/validations'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'constants/axios'
import { NotificationManager } from 'react-notifications';
import { getShortProducts } from 'actions/buyer'

const ShortBookPopOver = (props) => {
    const [key, setKey] = useState('');
    const [loader, setLoader] = useState(false);
    const [productId, setProductId] = useState('')
    const [productName, setProductName] = useState('')
    const [manufacturer, setManufacturer] = useState('')
    const [chemCombination, setChemCombination] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState({})

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            let data = {
                product: productName.toUpperCase(),
                id: productId,
                quantity: Number(quantity)
            }
            let errP = required(productName);
            let errQ = number0(quantity);
            if (errP || errQ) {
                setError(true)
                setErrorMessage({ product: errP, quantity: errQ })
            } else {
                setLoader(true)
                const result = await axios.post('shortBook/addShortBook', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('buyer_token')
                    }
                })
                if (result.data.error) {
                    NotificationManager.error(result.data.title);
                } else {
                    NotificationManager.success(result.data.title);
                }
                if (props.onAdd) { props.onAdd() }
                onClose()
            }
        } catch (error) {
            NotificationManager.error('Something went wrong, Please try again')
            if (props.onAdd) { props.onAdd() }
            onClose()
        }
    }

    const onClose = async () => {
        setProductId(props.product && props.product._id ? props.product._id : '')
        setProductName(props.product && props.product.name ? props.product.name : '')
        setManufacturer(props.product && props.product.company_id && props.product.company_id.name ? props.product.company_id.name : '')
        setChemCombination(props.product && props.product.chem_combination ? props.product.chem_combination : '')
        setQuantity(0)
        setError(false)
        setErrorMessage({})
        setLoader(false)
        setKey(props.product && props.product.name ? props.product.name : '')
        props.toggle();
        let data = { key: props.product && props.product.name ? props.product.name : '' }
        await props.getShortProducts({ data })
    }

    const handleChange = (e) => {
        setQuantity(e.target.value)
    }

    const handleInputChange = async (value) => {
        if (!props.product || props.product.name !== value) {
            setKey(value ? value : '')
            setProductId('')
            setProductName('')
            setManufacturer('')
            setChemCombination('')
        }
        let data = { key: value && value !== "Select Product" ? value : '' }
        await props.getShortProducts({ data })
    }

    const selectChanged = (product) => {
        if (product) {
            setProductId(product._id)
            setProductName(product.name)
            setManufacturer(product.company_id.name)
            setChemCombination(product.chem_combination)
        }
        else {
            setProductId('')
            setProductName('')
            setManufacturer('')
            setChemCombination('')
        }
    }

    useEffect(() => {
        const getDetails = async () => {
            if (props.product) {
                setKey(props.product.name)
                setProductId(props.product._id)
                setProductName(props.product.name)
                setManufacturer(props.product.company_id.name)
                setChemCombination(props.product.chem_combination)
                let data = { key: props.product.name ? props.product.name : '' }
                await props.getShortProducts({ data })
            }
        }
        getDetails()
    }, [])

    const { open, shortProducts } = props

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>
                    Add To Shortbook
                </DialogTitle>
                {loader ?
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                    :
                    <form onSubmit={onSubmit} autoComplete={false}>
                        <DialogContent>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <Autocomplete
                                            options={shortProducts}
                                            id="auto-complete"
                                            onInputChange={(e, val) => handleInputChange(val)}
                                            getOptionLabel={(option) => { return option ? option.name : 'Select Product' }}
                                            autoComplete
                                            onChange={(e, option) => selectChanged(option)}
                                            value={productName ? shortProducts.find(v => v.name === productName) : ''}
                                            includeInputInList
                                            renderInput={(params) => <TextField {...params}
                                                name="name"
                                                fullWidth={true}
                                                label="Product Name"
                                                value={key}
                                                error={error && errorMessage.product ? error : false}
                                                variant="standard"
                                                helperText={error && errorMessage.product ? errorMessage.product : ''} />}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <TextField name="manufacturer"
                                            fullWidth={true}
                                            type="text"
                                            label="Manufacturer"
                                            autoComplete="off"
                                            value={manufacturer}
                                            disabled={true}
                                            variant="standard"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <TextField name="chemCombination"
                                            fullWidth={true}
                                            type="text"
                                            label="Chemical Combination"
                                            autoComplete="off"
                                            value={chemCombination}
                                            disabled={true}
                                            variant="standard"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <TextField name="quantity"
                                            fullWidth={true}
                                            type="text"
                                            label="Quantity"
                                            autoComplete="off"
                                            error={error && errorMessage.quantity ? error : false}
                                            helperText={error && errorMessage.quantity ? errorMessage.quantity : ''}
                                            inputMode="numeric"
                                            value={quantity}
                                            onChange={(event) => handleChange(event)}
                                            variant="standard"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </DialogContent>
                        <DialogActions>
                            <Button type='button' onClick={onClose} color='secondary' >
                                Cancel
                            </Button>
                            <Button type='submit' color='primary'>
                                Add
                            </Button>
                        </DialogActions>
                    </form>
                }
            </Dialog>
        </React.Fragment>
    );
}
const mapStateToProps = ({ buyer }) => {
    const { shortProducts } = buyer;
    return { shortProducts }
};

export default connect(mapStateToProps, { getShortProducts })(ShortBookPopOver);


