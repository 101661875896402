import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { listGroup, addStaff, listPermissionModules } from 'actions/seller';
import { connect } from 'react-redux';
import ReactStrapTextField from 'components/ReactStrapTextField';
import ReactstrapSelectField from 'components/reactstrapSelectField';
import { Field, Form } from 'react-final-form'
import { required, emailField, validatePhone } from 'constants/validations';
import Axios from 'sagas/axiosRequest';

const EditStaff = (props) => {
    const [permission, setPermission] = useState([])
    const [groups, setGroups] = useState([])
    const [isAllPermission, setIsAllPermission] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState()

    useEffect(() => {
        getInitialData()
    }, [])

    const getInitialData = async () => {
        props.listPermissionModules({})
        const groupListData = await Axios.axiosHelperFunc('get', 'seller/getGroupList', '', {});
        setIsAllPermission(props.selectedData && props.selectedData.Groups && props.selectedData.Groups.permissions ? (props.selectedData.Groups.permissions.length === props.listpermissionModulesdata.length) ? true : false : false)
        setPermission(props.selectedData && props.selectedData.Groups && props.selectedData.Groups.permissions ? props.selectedData.Groups.permissions : [])
        setGroups(groupListData && groupListData.data && groupListData.data.detail ? groupListData.data.detail : [])
    }

    const handleRequestClose = () => {
        props.handleClick('edit');
        setPermission([])
    };

    useEffect(() => {
        if (selectedGroup) {
            let index = groups.findIndex((e) => String(e._id) === String(selectedGroup));
            if (index > -1) {
                let tempPermission = permission;
                tempPermission = groups[index].permissions;
                let isAdminAccess = tempPermission.length === props.listpermissionModulesdata.length ? true : false;
                setPermission(tempPermission)
                setIsAllPermission(isAdminAccess);
            } else {
                setPermission([])
                setIsAllPermission(false);
            }
        } else {
            setPermission([])
        }
    }, [selectedGroup])

    const onSubmit = (values) => {
        let data = {
            first_name: values.name,
            email: values.email,
            phone: values.phone,
            groupId: values.groupId,
            staffId: values.staffId,
            isAllPermission: isAllPermission
        }
        props.handleClick('edit')
        props.addStaff({ data, listStaff: { searchText: '', page: 1, perPage: 10 } })
        setPermission([])
    }

    const { edit, title, listpermissionModulesdata } = props;
    return (
        <React.Fragment>
            <Dialog open={edit} onClose={handleRequestClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        name: props.selectedData ? props.selectedData.first_name : '',
                        email: props.selectedData ? props.selectedData.email : '',
                        phone: props.selectedData ? props.selectedData.phone : '',
                        staffId: props.selectedData ? props.selectedData._id : '',
                        groupId: props.selectedData ? props.selectedData.Groups._id : '',
                    }}
                    render={({ handleSubmit, values, submitting }) => {
                        if (selectedGroup !== values.groupId) setSelectedGroup(values.groupId)
                        return <form onSubmit={handleSubmit} autoComplete="off">
                            <DialogTitle>
                                {title}
                            </DialogTitle>
                            <DialogContent>
                                <Row>
                                    <Col xs={12} md={12} sm={12} xl={2} lg={2} className="addStaffAvatar">
                                        <Avatar className="size-100" alt="Remy Sharp" src={require('assets/images/avatar.png')} />
                                    </Col>
                                    <Col xs={12} md={12} sm={12} xl={10} lg={10}>
                                        <Row>
                                            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                <Field id="name" name="name" type="text"
                                                    component={ReactStrapTextField} label="Name"
                                                    validate={required}
                                                />
                                            </Col>
                                            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                <Field id="email" name="email" type="text"
                                                    component={ReactStrapTextField} label="Email ID"
                                                    validate={emailField}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                <Field id="phone" name="phone" type="text"
                                                    component={ReactStrapTextField} label="Phone"
                                                    validate={validatePhone}
                                                />
                                            </Col>
                                            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                <Row>
                                                    <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                                                        <Field
                                                            name="groupId"
                                                            id="groupId"
                                                            component={ReactstrapSelectField}
                                                            label="Group"
                                                            type="select"
                                                        >
                                                            {
                                                                groups !== undefined && groups.length > 0 && groups.map((value, key) => {
                                                                    return <option key={'group_' + key} value={value._id}>{value.name}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            values.groupId !== '' ? <Row>
                                                {
                                                    listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length ? <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length}
                                                                    value={'admin_access'}
                                                                    color="primary"
                                                                    disabled={true}
                                                                />
                                                            }
                                                            label={'Admin Access'}
                                                        />
                                                    </Col> : ''
                                                }
                                                {
                                                    (listpermissionModulesdata !== undefined && listpermissionModulesdata.length > 0) ? listpermissionModulesdata.map((value, key) => {
                                                        let index;
                                                        if (values.staffId !== '') {
                                                            if (typeof (permission[0]) === 'string') {
                                                                index = permission.findIndex((e) => e === value._id)
                                                            } else {
                                                                index = permission.findIndex((e) => e._id === value._id)
                                                            }
                                                        } else {
                                                            index = permission.findIndex((e) => e._id === value._id)
                                                        }
                                                        return index > -1 ? <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={index > -1 ? true : false}
                                                                        value={value.value}
                                                                        color="primary"
                                                                        disabled={true}
                                                                    />
                                                                }
                                                                label={value.name}
                                                            />
                                                        </Col> : ''
                                                    }) : ''
                                                }
                                            </Row> : ''
                                        }
                                    </Col>
                                </Row>
                            </DialogContent>
                            <DialogActions className="pr-4">
                                <Button onClick={handleRequestClose} color='secondary' >
                                    Cancel
                                </Button>
                                <Button type='submit' color='primary' disabled={submitting}>
                                    Edit
                                </Button>
                            </DialogActions>
                        </form>
                    }}
                />
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = ({ seller }) => {
    const { listGroupData, listpermissionModulesdata } = seller;
    return { listGroupData, listpermissionModulesdata }
};

export default connect(mapStateToProps, { listGroup, listPermissionModules, addStaff })(EditStaff)