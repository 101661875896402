import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Button } from '@mui/material'
import TableComponent from './TableComponent';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import axios from 'constants/axios';
import { getProductRequestList } from 'actions/buyer';
import { NotificationManager } from 'react-notifications';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const styles = {
  root: {
    width: '100%',
    marginTop: 0,
  },
  paper: {
    width: '100%',
    marginBottom: 2,
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  list: {
    maxWidth: 239
  },
  fontSize: {
    fontSize: '1rem'
  }
};

const ProductRequest = (props) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [month, setMonth] = useState(moment().format("MMMM"))
  const [year, setYear] = useState(moment().format("GGGG"))
  const [popOverOpen, setPopOverOpen] = useState(false)
  const [cancelData, setCancelData] = useState({})

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  };

  useEffect(() => {
    if (props.requestFromParent && props.requestFromParent.data && props.requestFromParent.data.length > 0) {
      setMonth(moment(requestFromParent.data[0].createdAt).format("MMMM"))
      setYear(moment(requestFromParent.data[0].createdAt).format("GGGG"))
    }
  }, [props.requestFromParent])

  const cancelClicked = (data) => {
    setPopOverOpen(true)
    setCancelData(data)
  }

  const closeCancel = () => {
    setPopOverOpen(false)
    setCancelData({})
  }

  const confirmCancel = async () => {
    try {
      let status = 'CANCELLED'
      let token = localStorage.getItem('buyer_token');
      let changedData = {
        id: cancelData._id,
        status: status
      }
      const result = await axios({
        method: 'POST',
        url: 'buyerRequest/updateBuyerRequest',
        data: changedData,
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      })
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
        let data = {
          page: 1,
          perPage: 50,
          tab: 'open',
          month: month,
          year: year,
        }
        props.getProductRequestList({ data })
      }
      setPopOverOpen(false)
      setCancelData({})
    } catch (error) {
      NotificationManager.error('Something Went Wrong !')
    }
  }

  const statusStyle = (status) => {
    return status.includes("PENDING") ? "text-white bg-primary" : status.includes("DENIED") ? "text-white bg-danger" : status.includes("APPROVED") ? "text-white bg-warning" : status.includes("FULFILLED") ? "text-white bg-success" : "text-white bg-primary";
  }

  const { identifier, requestFromParent } = props;

  let rows = []

  requestFromParent && requestFromParent.data && requestFromParent.data.result && requestFromParent.data.result.map((dataOne, index) => {
    let manufacturer = dataOne.manufacturer && dataOne.manufacturer.name ? dataOne.manufacturer.name : dataOne.otherManufacturer;
    rows.push({
      productName: dataOne.product_name,
      manufacturer: manufacturer,
      quantity: Number(dataOne.quantity),
      status: <div key={'recent'}
        className={` badge text-uppercase ${statusStyle(
          dataOne.isFulfilled === true ? "FULFILLED"
            : dataOne.status === "PENDING" ? "PENDING"
              : dataOne.status === "APPROVED" ? "APPROVED"
                : dataOne.status === "DENIED" ? "DENIED" : ''
        )}`}>
        {dataOne.isFulfilled === true ? "FULFILLED" : dataOne.status}
      </div>,
      stock: dataOne.inStock ? dataOne.inStock : false,
      requestedDate: (dataOne.createdAt),
      action: <div>{identifier === "productRequestList" ? <Button variant="outlined" className="text-danger" onClick={() => cancelClicked(dataOne)}>CANCEL</Button> : ''}</div>
    })
  })

  let productData = requestFromParent ? requestFromParent.data ? requestFromParent.data : [] : []
  let counts = requestFromParent && requestFromParent.data.metadata[0] && requestFromParent.data.metadata[0].total ? requestFromParent.data.metadata[0].total : 0;

  return (
    <React.Fragment>
      <div style={styles.root}>
        <Paper style={styles.paper}>
          <EnhancedTableToolbar identifier={identifier} />
          {
            props.loading ?
              <div className="loader-view">
                <CircularProgress />
              </div>
              :
              <div style={styles.tableWrapper}>
                <Table
                  style={styles.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    style={styles}
                    identifier={identifier}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {
                      rows.length < 1 ?
                        <TableRow>
                          <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                            Sorry, no matching records found !
                          </TableCell>
                        </TableRow> :
                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableComponent
                                row={row}
                                labelId={labelId}
                                value={props.value}
                                identifier={identifier}
                                itemListFromParent={productData}
                              />
                            )
                          })}
                  </TableBody>
                </Table>
              </div>
          }
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={counts}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {/*------------------------- Confirm Cancel Dialog ------------------------------------------ */}
      <Dialog open={popOverOpen} onClose={closeCancel} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          Cancel Product Request
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Are you sure you want to cancel this product request ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeCancel()} color='secondary' >
            No
          </Button>
          <Button color='primary' onClick={() => confirmCancel()} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { loading, productRequestList } = buyer;
  return { loading, productRequestList }
};

export default connect(mapStateToProps, { getProductRequestList })(ProductRequest)