// 3.] Import the ActionTypes in Reducers
// 4.] Initialize the initial state (get the information regarding INIT_STATE from Postman).
//


import {

    GET_FEATURED_PRODUCT_LIST,
    GET_FEATURED_PRODUCT_LIST_SUCCESS,

    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_SUCCESS,

    GET_TRANSACTION_LIST,
    GET_TRANSACTION_LIST_SUCCESS,

    GET_MEDIWALLET,
    GET_MEDIWALLET_SUCCESS,

    GET_PRODUCTS_FOR_YOU,
    GET_PRODUCTS_FOR_YOU_SUCCESS,

    GET_ORDER_LIST,
    GET_ORDER_LIST_SUCCESS,

    GET_REVIEW_LIST,
    GET_REVIEW_LIST_SUCCESS,

    GET_ORDER_HISTORY_LIST,
    GET_ORDER_HISTORY_LIST_SUCCESS,

    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,

    GET_CART_DETAILS,
    GET_CART_DETAILS_SUCCESS,

    REMOVE_FROM_CART,
    REMOVE_FROM_CART_SUCCESS,

    GET_VISITED_CATEGORY,
    GET_VISITED_CATEGORY_SUCCESS,

    GET_PRODUCTS_BY_COMPANY,
    GET_PRODUCTS_BY_COMPANY_SUCCESS,

    BULK_REQUEST,
    BULK_REQUEST_SUCCESS,

    GET_SEARCH_PRODUCT,
    GET_SEARCH_PRODUCT_SUCCESS,

    GET_PRODUCTS_BY_SELLER,
    GET_PRODUCTS_BY_SELLER_SUCCESS,

    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,

    GET_AUTO_FILL,
    GET_AUTO_FILL_SUCCESS,

    GET_FILTER,
    GET_FILTER_SUCCESS,

    EMPTY_CART,
    EMPTY_CART_SUCCESS,

    GET_UPDATE_ORDER,
    GET_UPDATE_ORDER_SUCCESS,

    GET_PROMOTIONS_LISTING,
    GET_PROMOTIONS_LISTING_SUCCESS,

    GET_MARK_READ_LIST_FOR_BUYER,
    GET_MARK_READ_LIST_FOR_BUYER_SUCCESS,

    GET_PRODUCT_REQUEST_LIST,
    GET_PRODUCT_REQUEST_LIST_SUCCESS,
    
    GET_SHORTBOOK,
    GET_SHORTBOOK_SUCCESS,

    GET_SHORT_PRODUCTS,
    GET_SHORT_PRODUCTS_SUCCESS,

    GET_TICKET_LIST,
    GET_TICKET_LIST_SUCCESS,

    GET_TICKET_HISTORY_LIST,
    GET_TICKET_HISTORY_LIST_SUCCESS,

    GET_BULK_LIST,
    GET_BULK_LIST_SUCCESS,

    GET_BULK_HISTORY_LIST,
    GET_BULK_HISTORY_LIST_SUCCESS,

} from 'constants/ActionTypes'

import { NotificationManager } from 'react-notifications';


const INIT_STATE = {
    data: [],

    featured: [],
    bannerArr: [],
    dealsArr: [],
    fastMovingArr: [],
    jumboArr: [],

    productData: [],
    otherSellers: [],
    relatedProducts: [],

    transactionListData: '',

    mediWallet: '',

    productsForYou: [],

    orderList: '',

    reviewList: '',

    orderHistoryList: '',

    addToCart: [],

    cartDetails: '',

    removeFromCart: '',

    visitedCategory: '',

    productsByCompany: '',

    bulkRequest: '',

    searchProduct: '',

    productsBySeller: '',

    categories: '',

    autoFill: '',

    searchOptions: [],

    filter: '',

    updateOrder: '',

    promotionsList: '',

    getMarkReadListForBuyer:'',

    loading: false,

    productRequestList:'',

    shortbookList:'',

    shortProducts:[],

    ticketList: '',

    ticketHistoryList: '',

    bulkList: '',

    bulkHistoryList: ''
};

export default (state = INIT_STATE, action) => {


    switch (action.type) {

        case EMPTY_CART:
            return {
                loading: true,
                ...state
            }
        case EMPTY_CART_SUCCESS:
            return {
                loading: false,
                ...state
            }

        case GET_FEATURED_PRODUCT_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_FEATURED_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                //data: action.payload,
                bannerArr: action.payload.bannerArr,
                dealsArr: action.payload.dealsArr,
                fastMovingArr: action.payload.fastMovingArr,
                jumboArr: action.payload.jumboArr,
                featured: action.payload.featured,
                loading: false
            }

        //-----------------------//

        case GET_PRODUCT_DETAILS:
            return {
                ...state,
                loading: true
            }

        case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                productData: action.payload.productData,
                otherSellers: action.payload.otherSellers,
                relatedProducts: action.payload.relatedProducts,

                loading: false
            }

        //-----------------------//

        case GET_TRANSACTION_LIST: {
            return {
                ...state,
                loader: false,
            }
        }

        case GET_TRANSACTION_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                transactionListData: action.payload,
            }
        }



        //----------------------------------------------//

        case GET_MEDIWALLET: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_MEDIWALLET_SUCCESS: {
            return {
                ...state,
                loading: false,
                mediWallet: action.payload,
            }
        }


        //----------------------------------------------//

        case GET_PRODUCTS_FOR_YOU: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_PRODUCTS_FOR_YOU_SUCCESS: {
            return {
                ...state,
                loading: false,
                productsForYou: action.payload,
                visitedProduct: action.payload.visitedProduct,
            }
        }


        //----------------------------------------------//


        case GET_ORDER_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_ORDER_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                orderList: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_ORDER_HISTORY_LIST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_ORDER_HISTORY_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                orderHistoryList: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_REVIEW_LIST: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_REVIEW_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                reviewList: action.payload,
            }
        }

        //----------------------------------------------//

        case ADD_TO_CART: {
            return {
                ...state,
                loader: false,
            }
        }
        case ADD_TO_CART_SUCCESS: {
            return {
                ...state,
                loading: false,
                addToCart: action.payload,
            }
        }


        //----------------------------------------------//

        case GET_CART_DETAILS: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_CART_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                cartDetails: action.payload,
            }
        }


        //----------------------------------------------//

        case REMOVE_FROM_CART: {
            return {
                ...state,
                loader: false,
            }
        }
        case REMOVE_FROM_CART_SUCCESS: {
            return {
                ...state,
                loading: false,
                removeFromCart: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_VISITED_CATEGORY: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_VISITED_CATEGORY_SUCCESS: {
            return {
                ...state,
                loading: false,
                visitedCategory: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_PRODUCTS_BY_COMPANY: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_PRODUCTS_BY_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
                productsByCompany: action.payload.detail,
            }
        }


        //----------------------------------------------//

        case BULK_REQUEST: {
            return {
                ...state,
                loader: false,
            }
        }

        case BULK_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                bulkRequest: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_SEARCH_PRODUCT: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_SEARCH_PRODUCT_SUCCESS: {
            return {
                ...state,
                loader: false,
                searchProduct: action.payload,
            }
        }


        //----------------------------------------------//
        case GET_PRODUCTS_BY_SELLER: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_PRODUCTS_BY_SELLER_SUCCESS: {
            return {
                ...state,
                loading: false,
                productsBySeller: action.payload,
            }
        }
        //----------------------------------------------//
        case GET_CATEGORIES: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_CATEGORIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                categories: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_AUTO_FILL: {
            return {
                ...state,
                loader: false,
            }
        }

        case GET_AUTO_FILL_SUCCESS: {
            return {
                ...state,
                loading: false,
                autoFill: action.payload,
                searchOptions: action.payload.data
            }
        }

        //----------------------------------------------//

        case GET_FILTER: {
            return {
                ...state,
                loader: false,
            }
        }

        case GET_FILTER_SUCCESS: {
            return {
                ...state,
                loading: false,
                filter: action.payload,
            }
        }

        //----------------------------------------------//

        case GET_UPDATE_ORDER: {
            return {
                ...state,
                loader: false,
            }
        }

        case GET_UPDATE_ORDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                updateOrder: action.payload.detail,
            }
        }

        //----------------------------------------------//

        case GET_PROMOTIONS_LISTING: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_PROMOTIONS_LISTING_SUCCESS: {
            return {
                ...state,
                loading: false,
                promotionsList: action.payload.detail,
            }
        }
        // -------------------------------------------------
        case GET_MARK_READ_LIST_FOR_BUYER: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_MARK_READ_LIST_FOR_BUYER_SUCCESS: {
            return {
                ...state,
                loading: false,
                getMarkReadListForBuyer: action.payload,
            }
        }


        // -------------------------------------------------
        case GET_PRODUCT_REQUEST_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_PRODUCT_REQUEST_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                productRequestList: action.payload,
            }
        }

        // -------------------------------------------------
        case GET_SHORTBOOK: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_SHORTBOOK_SUCCESS: {
            return {
                ...state,
                loading: false,
                shortbookList: action.payload,
            }
        }

        // -------------------------------------------------
        case GET_SHORT_PRODUCTS: {
            return {
                ...state,
            }
        }

        case GET_SHORT_PRODUCTS_SUCCESS: {
            return {
                ...state,
                shortProducts: action.payload,
            }
        }

        //----------------------------------------------//


         case GET_TICKET_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_TICKET_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                ticketList: action.payload.data,
            }
        }

        //----------------------------------------------//

        case GET_TICKET_HISTORY_LIST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_TICKET_HISTORY_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                ticketHistoryList: action.payload.data,
            }
        }
        //----------------------------------------------//


        case GET_BULK_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_BULK_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                bulkList: action.payload.data,
            }
        }

        //----------------------------------------------//

        case GET_BULK_HISTORY_LIST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_BULK_HISTORY_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                bulkHistoryList: action.payload.data,
            }
        }
        
        default:
            return state;
    }
}