import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';

const OrderSuccessful = (props) => {
    const location = useLocation()
    let url = location.search
    let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''
    const [openModal, setOpenModal] = useState(false)
    const status = json && json.status ? json.status : ''
    const orderId = json && json.orderId ? json.orderId : ''
    const type = json && json.type ? json.type : ''
    const amount = json && json.amount ? Number(json.amount) : ''

    useEffect(() => {
        setOpenModal(true)
    }, [])

    const handleClose = () => {
        setOpenModal(!openModal)
    }

    return (
        <React.Fragment>
            <div className="" >
                <Dialog open={openModal === true}
                    onClose={handleClose}
                    fullWidth={true}>
                    <DialogContent style={{ overflow: "hidden" }}>
                        <DialogTitle className="" id="alert-dialog-title" style={{ textAlign: "center" }}>
                            <span className={`text-${status === 'success' ? 'success' : 'danger'} mb-3`}>
                                <i className={`zmdi zmdi-${status === 'success' ? 'check' : 'alert'}-circle animated fadeInUp zmdi-hc-5x`}></i>
                            </span>
                            {(type !== '' && type === 'wallet') ?
                                <h1 className="mt-4 font-weight-bold">{status === 'success' ? `Congratulations, Wallet recharged with ${(amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} + 1% cashback successfully.` : 'Wallet recharge failed'}</h1> :
                                <h1 className="mt-4 font-weight-bold">{status === 'success' ? 'Order placed successfully' : 'Unable to place order'}</h1>
                            }
                        </DialogTitle>
                        {(status === 'success' && type === '') ?
                            <h3 style={{ textAlign: "center" }}>Your order {`${orderId.length > 12 ? `id's:` : 'id:'}`} {orderId && orderId}</h3> : ''
                        }
                    </DialogContent>
                    <DialogActions className="pr-4">
                        <a href='/'>
                            <Button onClick={handleClose} color='secondary' >{(status === 'success' && type === 'wallet') ? 'Shop now' : 'Shop more'}</Button>
                        </a>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}

export default OrderSuccessful;