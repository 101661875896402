import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableComponent from './TableComponent';
import { getBulkList, getBulkHistoryList } from 'actions/buyer';
import EnhancedTableHead from './EnhancedTableHead'
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';

const styles = {
  root: {
    width: '100%',
    marginTop: 0,
  },
  paper: {
    width: '100%',
    marginBottom: 2,
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  list: {
    maxWidth: 239
  },
  fontSize: {
    fontSize: '1rem'
  }
};

const Bulk = (props) => {
  let rows = [];
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let data;
    if (props.identifier === 'bulkList') {
      data = { page: newPage + 1, perPage: 50 };
      props.getBulkList({ data });
    } else {
      data = { page: newPage + 1, perPage: 50, close: '1' };
      props.getBulkHistoryList({ data });
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  };

  const { identifier, orderFromParent } = props;

  orderFromParent && orderFromParent.data1.map(data => {
    rows.push({
      _id: data._id,
      product_name: data.product_name,
      discount: data.discount,
      quantity: data.quantity,
      status: data.status,
      createdAt: data.createdAt,
      action: data.status,
      ogStatus: (data.status).toLowerCase(),
      offers: data.offers,
      closed_reason: data.closed_reason
    })
  })

  return (
    <div styles={styles.root}>
      <Paper sx={styles.paper}>
        {
          props.loading ?
            <div className="loader-view">
              <CircularProgress />
            </div>
            :
            <div styles={styles.tableWrapper}>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  styles={styles}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rows.length < 1 ? <TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>{identifier === "bulkList" ? `You don't have open bulk requests!` : identifier === "bulkHistoryList" ? `You don't have closed bulk requests!` : 'Sorry no records found'}</TableCell></TableRow> :
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableComponent
                          hitApi={props.hitApi}
                          rowData={row}
                          labelId={labelId}
                          itemListFromParent={rows}
                          show={props.show}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            </div>
        }
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={orderFromParent && orderFromParent.count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { loading } = buyer;
  return { loading }
};

export default connect(mapStateToProps, { getBulkList, getBulkHistoryList })(Bulk)