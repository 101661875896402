import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';
import { getTicketList, getTicketHistoryList } from 'actions/buyer';
import Ticket from 'components/ticketTable'
import CustomScrollbars from 'util/CustomScrollbars';

function TabContainer({ children, dir, index, value }) {
  return (
    <div dir={dir}>
      {value === index && children}
    </div>
  );
}

const FullWidthTabs = (props) => {
  const [tabValue, setTabValue] = useState(0)
  const [show, setShow] = useState(0)

  const handleChange = (event, value) => {
    setTabValue(value)
    setShow(true)
  };

  useEffect(() => {
    let data = { page: 1, perPage: 50, filter: '' };
    props.getTicketList({ data });
    call()
  }, [])

  const call = () => {
    let data = { page: 1, perPage: 50, filter: '', close: '1' };
    props.getTicketHistoryList({ data });
  }

  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="Closed" />
        </Tabs>
      </AppBar>
      <TabContainer dir="ltr" value={tabValue} index={0}>
        <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
          <Ticket
            identifier={'ticketList'}
            show={show}
          />
        </CustomScrollbars>
      </TabContainer>
      <TabContainer dir="ltr" value={tabValue} index={1}>
        <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
          <Ticket
            identifier={'ticketHistoryList'}
            show={show}
          />
        </CustomScrollbars>
      </TabContainer>
    </div>
  );
}

const mapStateToProps = ({ tabAction, buyer }) => {
  const { ticketList, ticketHistoryList } = buyer;
  return { ticketList, ticketHistoryList }
};

export default connect(mapStateToProps, { getTicketList, getTicketHistoryList })(FullWidthTabs);