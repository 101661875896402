import React from 'react'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
}

const RenderSelectField = ({
    input,
    props,
    label,
    meta: { touched, error },
    children,
    ...custom
}) => {
    return <FormControl required error={error} component="fieldset" >
        <FormControlLabel
            control={
                <Checkbox {...input} {...props} color="primary" />
            }
            label={label}
        />
        {renderFromHelper({ touched, error })}
    </FormControl>
}

export default RenderSelectField;