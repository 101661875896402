import React from 'react';
import { FormGroup, Label } from 'reactstrap'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AutoComplete = (props) => {

    const handleChange = (e, val) => {
        props.handleDataChange(val)
    }

    const onInputChange = (e, val) => {
        props.handleChange(e, val)
    }

    const { options, label, value, disabled, standard, searchType } = props

    return (
        <FormGroup style={{ display: standard ? 'inline-flex' : '' }}>
            <Label for="name">{label}</Label>
            <Autocomplete
                id="combo-box-demo"
                autoHighlight={true}
                options={options || []}
                disabled={disabled}
                inputValue={value}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option._id}>
                            {searchType === 'other' && option && option.Products && option.Products.name ? option.Products.name : option && option.Products && option.Products.name ? (option.name + '(' + option.company_id.name + " \u007C " + option.GST.name + ' )') : option.name}
                        </li>
                    );
                }}
                getOptionLabel={option => {
                    return searchType === 'other' && option && option.Products && option.Products.name ? option.Products.name : option && option.Products && option.Products.name ? (option.name + '(' + option.company_id.name + " \u007C " + option.GST.name + ' )') : option.name
                }}
                onChange={(event, val) => handleChange(event, val)}
                onInputChange={(event, val) => onInputChange(event, val)}
                style={{ width: standard ? `250px` : '100%' }}
                renderInput={params => {
                    return <TextField {...params} label="" value={value} variant={standard ? 'standard' : "outlined"} fullWidth placeholder="Select product" />
                }}
            />
        </FormGroup>
    );
}

export default AutoComplete;