import React, { useEffect, useRef } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import { Field, Form } from 'react-final-form'
import { required, emailField, phone } from 'constants/validations';
import ReactStrapTextField from 'components/ReactStrapTextField';
import { Row, Col, FormGroup } from 'reactstrap'
import Button from '@mui/material/Button';
import AxiosRequest from 'sagas/axiosRequest'
import { NotificationManager } from 'react-notifications';
import BuyerContainer from 'components/buyerContainer';

const ContactUs = (props) => {
  const myRef = useRef(null)

  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const submit = async (data) => {
    let postData = {
      shop_name: data.companyName,
      name: data.firstName,
      phone: data.phone,
      city: data.city,
      email: data.email,
      msg: data.msg
    }
    let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'users/sendEmailToAdmin', '', postData)
    if (response.data.error) {
      NotificationManager.error(response.data.title)
    } else {
      NotificationManager.success(response.data.title);
    }
  }

  return (
    <BuyerContainer>
      <div className="app-wrapper" ref={myRef}>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <ContainerHeader title="Contact Us" />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-7 col-12">
            <Form
              onSubmit={submit}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} autoComplete="off" className="contact-form jr-card">
                  <Row>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                      <Field id="firstName"
                        name="firstName" type="text"
                        component={ReactStrapTextField}
                        label='Name'
                      />
                    </Col>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                      <Field id="companyName" name="companyName" type="text"
                        component={ReactStrapTextField}
                        validate={required}
                        label='Company Name'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} lg={4} xs={12} xl={4}>
                      <Field id="email" name="email" type="text"
                        component={ReactStrapTextField}
                        validate={emailField}
                        label='Email'
                      />
                    </Col>
                    <Col sm={12} md={4} lg={4} xs={12} xl={4}>
                      <Field id="phone" name="phone" type="text"
                        component={ReactStrapTextField}
                        validate={phone}
                        label='Phone'
                      />
                    </Col>
                    <Col sm={12} md={4} lg={4} xs={12} xl={4}>
                      <Field id="city" name="city" type="text"
                        component={ReactStrapTextField}
                        validate={required}
                        label='City'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field id="msg" name="msg" type='textarea'
                          component={ReactStrapTextField}
                          rows="6"
                          label='How can we help you ?'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <Button type='submit' color="primary" variant="contained"
                          className="jr-btn jr-btn-lg btnPrimary" >
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-5 col-12">
            <div className="contact-block jr-card py-5 px-4">
              <ul className="contact-info vertical">
                <li>
                  <i className="zmdi zmdi-pin zmdi-hc-fw" />
                  <div className="contact-body">
                    <h4 className="text-uppercase">ADDRESS</h4>
                    <address className="mb-0">
                      FELICITAS DIGITECH PRIVATE LIMITED
                      <br />
                      Unit 511, Opal Square, Wagle Industrial Estate,
                      <br />
                      Near Mulund Toll Naka,
                      <br />
                      Thane (W) - 400604 INDIA
                    </address>
                  </div>
                </li>
                <li>
                  <i className="zmdi zmdi-phone zmdi-hc-fw" />
                  <div className="contact-body">
                    <h4 className="text-uppercase">Phone</h4>
                    <div><span className="jr-link text-primary disable-link">
                      +91 9321927004
                    </span>
                    </div>
                  </div>
                </li>
                <li>
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <div className="contact-body">
                    <h4 className="text-uppercase">E-mail</h4>
                    <div><span className="text-primary jr-link">helpdesk@medimny.com</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BuyerContainer>
  );
}

export default ContactUs;