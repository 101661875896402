import React from 'react'
import { Field } from 'react-final-form'
import renderTextField from 'components/textBox';
import FieldFileInput from 'components/FieldFileInput';
import { required, fassaiLicNoRequired, gstLicNoRequired } from 'constants/validations';
import { Col, Row } from 'reactstrap';
import RenderDatePicker from 'components/RenderDatePicker'
import './style.css'

const Form2 = (props) => {

    return (
        <Row>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="drugLic20B" component={FieldFileInput} label="Drug Lic 20B" validate={(value) => (value === undefined ? 'Please Upload Drug Lic 20B' : '')} />
                <Field name="drugLic20BLicNo" validate={required} label='Drug Lic20B no.' component={renderTextField} />
                <Field name="drugLic20BExpiry" component={RenderDatePicker} />
            </Col>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="drugLic21B" component={FieldFileInput} label="21B  Drug Lic" validate={(value) => (value === undefined ? 'Please Upload Drug Lic 21B' : '')} />
                <Field name="drugLic21BLicNo" validate={required} label='Drug Lic21B no.' component={renderTextField} />
                <Field name="drugLic21BExpiry" component={RenderDatePicker} />
            </Col>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="fassaiLic" component={FieldFileInput} label="FSSAI Lic (Optional)" />
                <Field name="fassaiLicNo" validate={fassaiLicNoRequired} label='FSSAI Lic no.' component={renderTextField} />
                <Field name="fassaiLicExpiry" value={new Date()} component={RenderDatePicker} />
            </Col>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="gstLic" component={FieldFileInput} label="GSTN Lic (Optional)" />
                <Field name="gstLicNo" validate={gstLicNoRequired} label='GSTN Lic no.' component={renderTextField} />
            </Col>
        </Row>
    )
}

export default Form2