import React, { useState, useEffect } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TrackOrder from './trackOrder'
import './index.css'
import { connect } from 'react-redux'
import { getUpdateOrder, getOrderList, getOrderHistoryList } from 'actions/buyer';
import moment from 'moment';
import axios from 'axios';
import AppConfig from 'constants/config';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';

const styles = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}

function StyledRadio(props) {
  return (
    <Radio
      sx={styles.root}
      disableRipple
      color="default"
      checkedIcon={<span style={{ ...styles.icon, ...styles.checkedIcon }} />}
      icon={<span style={styles.icon} />}
      {...props}
    />
  );
}

const CollapseComponent = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [details, setDetails] = useState('')
  const [acceptModal, setAcceptModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState('Reason not listed')
  const [products, setProducts] = useState([])
  const [api, setApi] = useState(1)

  const handleClose = () => {
    setOpenModal(false)
    setDetails('')
  }

  const handleOpenModal = (details) => {
    const { mainOrderData, value } = props;
    let index = mainOrderData.findIndex((val) => val.order_id === value.orderId);
    if (index > -1) {
      setOpenModal(true)
      setDetails(mainOrderData[index])
    }
  }

  const handleAcceptModal = (details) => {
    const { mainOrderData, value } = props;
    let index = mainOrderData.findIndex((val) => val.order_id === value.orderId);
    if (index > -1) {
      setAcceptModal(true)
      setDetails(mainOrderData[index])
    }
  }

  const handleAccept = (details) => {
    const { mainOrderData, value, filterObj } = props;
    let index = mainOrderData.findIndex((val) => val.order_id === value.orderId);
    if (index > -1) {
      const data = {
        orderId: mainOrderData[index].order_id,
        status: "accept",
        acceptFromBuyer: 'accept',
        page: 1, perPage: 50,
        filter: '',
        month: props.show === true ? filterObj && filterObj.month ? filterObj.month : moment().format("MMMM") : '',
        year: props.show === true ? filterObj && filterObj.year ? Number(filterObj.year) : moment().format("GGGG") : ''
      }
      props.getUpdateOrder({ data })
    }
    handleAcceptClose()
  }

  const handleAcceptClose = () => {
    setAcceptModal(false)
  }

  const handleCancelModal = (details) => {
    const { mainOrderData, value } = props;
    let index = mainOrderData.findIndex((val) => val.order_id === value.orderId);
    if (index > -1) {
      setCancelModal(true)
    }
  }

  const handleCancel = (details) => {
    const { mainOrderData, value, filterObj, getUpdateOrder } = props;
    let index = mainOrderData.findIndex((val) => val.order_id === value.orderId);
    if (index > -1) {
      const data = {
        orderId: mainOrderData[index].order_id,
        status: "Cancelled",
        page: 1, perPage: 50, filter: '',
        month: props.show === true ? filterObj && filterObj.month ? filterObj.month : moment().format("MMMM") : '',
        year: props.show === true ? filterObj && filterObj.year ? Number(filterObj.year) : moment().format("GGGG") : '',
        reason: selectedValue,
      }
      getUpdateOrder({ data })
    }
    handleCancelClose()
  }

  const handleCancelClose = () => {
    setCancelModal(false)
  }

  const handleChange = (event) => {
    event.preventDefault();
    setSelectedValue(event.target.value)
  }

  useEffect(() => {
    if (props.collapse && api === 1) {
      axios({
        method: 'post',
        url: `${AppConfig.baseUrl}order/getBuyerOrderDetails`,
        data: { orderId: props.value.orderId },
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('buyer_token')
        }
      }).then((result) => {
        const { detail } = result.data
        setProducts(detail.products)
        setApi(0)
      }).catch(error => error)
    }
  }, [props.collapse, api])

  const { collapse, value, mainOrderData } = props;
  let index = mainOrderData.findIndex((val) => val.order_id === value.orderId);
  let finalPerUnitCost = 0;
  let finalPerUnitCostAndGST = 0

  return (
    <React.Fragment>
      {
        collapse === true &&
        <React.Fragment >
          <TableRow className='w-100 h-50'>
            <TableCell colSpan={4} align="right" className='w-100 h-50' >
              <div className='row ml-3'>
                <div className='col-12 d-flex justify-content-center'>
                  <List className='col-sm-12 col-xs-12 col-md-10 col-lg-10 col-xl-10'>
                    <h5 className="" style={{ textAlign: "left", marginBottom: "-20px", marginLeft: "11px" }}>{moment(value.date).format("MMM Do YYYY, hh:mm A")}</h5>
                    {
                      products.map((data, index) => {
                        if (data.discount_per > 1 && data.discount_on_product && data.discount_on_product === data.inventory_id) {
                          finalPerUnitCost = data.ePTR ? data.ePTR : data.PTR
                        } else if (data.discount_per > 1) {
                          finalPerUnitCost = data.ePTR ? data.ePTR : data.PTR
                        } else {
                          finalPerUnitCost = data.ePTR ? data.ePTR : data.PTR
                        }
                        finalPerUnitCostAndGST = Number(finalPerUnitCost) + Number(finalPerUnitCost) / 100 * Number(data.GST)
                        return (
                          <ListItem key={index} alignItems={'flex-start'}>
                            <ListItemText
                              className={'listMaxWidth'}
                              primary={data.productName}
                              secondary={
                                ((data.discount_name && data.discount_name === 'DifferentAndDiscount') ||
                                  (data.discount_name && data.discount_name === 'SameAndDiscount')) ?
                                  <React.Fragment>
                                    PTR: ₹{(data.PTR).toFixed(2)}
                                    <br />
                                    GST({data.GST}%): ₹{(finalPerUnitCost / 100 * Number(data.GST)).toFixed(2)}
                                    <br />
                                    {data.discount_name === 'SameAndDiscount' ?
                                      <React.Fragment>
                                        Buy {data.discount_on_product.purchase} Get {data.discount_on_product.bonus} Free and {data.discount_per}% Off
                                        <br />
                                        Effective Price: ₹
                                        {data.ePTR ? data.ePTR : data.PTR}
                                      </React.Fragment>
                                      :
                                      data.discount_name === 'DifferentAndDiscount' ?
                                        <React.Fragment>
                                          Buy {data.discount_on_product.purchase} Get {data.discount_on_product.bonus} {data.discount_on_product.name} Free and {data.discount_per}% Off
                                          <br />
                                          Effective Price: ₹{data.ePTR ? data.ePTR : data.PTR}
                                        </React.Fragment> : null
                                    }
                                    <br />
                                    Final Unit Price: ₹{(finalPerUnitCostAndGST).toFixed(2)}
                                    <br />
                                    {data.expiry_date ? <React.Fragment>Expiry: {moment(data.expiry_date).format('MM/YYYY')}
                                      <br /></React.Fragment> : null}
                                    Qty: {data.quantity}
                                    <br />
                                  </React.Fragment>
                                  :
                                  data.discount_name && data.discount_name === 'Discount' ?
                                    <React.Fragment>
                                      PTR: ₹{(data.PTR).toFixed(2)}
                                      <br />
                                      Offer: {data.discount_per}% discount on PTR
                                      <br />
                                      Offer Price: ₹{(data.PTR - (data.PTR / 100 * data.discount_per)).toFixed(2)}
                                      <br />
                                      GST({data.GST}%): ₹{(finalPerUnitCost / 100 * Number(data.GST)).toFixed(2)}
                                      <br />
                                      Final Unit Price: ₹{(finalPerUnitCostAndGST).toFixed(2)}
                                      <br />
                                      {data.expiry_date ? <React.Fragment>Expiry: {moment(data.expiry_date).format('MM/YYYY')}
                                        <br /></React.Fragment> : null}
                                      Qty: {data.quantity}
                                      <br />
                                    </React.Fragment>
                                    :
                                    data.discount_on_product ?
                                      <React.Fragment>
                                        PTR: ₹{(data.PTR).toFixed(2)}
                                        <br />
                                        GST({data.GST}%): ₹{(finalPerUnitCost / 100 * Number(data.GST)).toFixed(2)}
                                        <br />
                                        {data.discount_name === 'Same' ?
                                          <React.Fragment>
                                            Buy {data.discount_on_product.purchase} Get {data.discount_on_product.bonus} Free
                                            <br />
                                            Effective Price: ₹
                                            {(data.ePTR ? data.ePTR : data.PTR).toFixed(2)}
                                          </React.Fragment>
                                          :
                                          <React.Fragment>
                                            Buy {data.discount_on_product.purchase} Get {data.discount_on_product.bonus} {data.discount_on_product.name + " Free"}
                                          </React.Fragment>
                                        }
                                        <br />
                                        Final Unit Price: ₹:{(finalPerUnitCostAndGST).toFixed(2)}
                                        <br />
                                        {data.expiry_date ? <React.Fragment>Expiry: {moment(data.expiry_date).format('MM/YYYY')}
                                          <br /></React.Fragment> : null}
                                        Qty: {data.quantity}
                                        <br />
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                                        PTR: ₹{(data.PTR).toFixed(2)}
                                        <br />
                                        GST({data.GST}%): ₹{(finalPerUnitCost / 100 * Number(data.GST)).toFixed(2)}
                                        <br />
                                        Final Unit Price: ₹:{(finalPerUnitCostAndGST).toFixed(2)}
                                        <br />
                                        {data.expiry_date ? <React.Fragment>Expiry: {moment(data.expiry_date).format('MM/YYYY')}
                                          <br /></React.Fragment> : null}
                                        Qty: {data.quantity}
                                        <br />
                                      </React.Fragment>
                              }
                            />
                            <ListItemText
                              className={'finalPrice pull-right'}
                              primary=
                              {
                                ((data.discount_name && data.discount_name === 'SameAndDiscount') || (data.discount_name && data.discount_name === 'Same')) ?
                                  <React.Fragment>
                                    ₹{(finalPerUnitCostAndGST * (((data.quantity / data.discount_on_product.purchase) * data.discount_on_product.bonus) + Number(data.quantity))).toFixed(2)}
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    ₹{(data.quantity * finalPerUnitCostAndGST).toFixed(2)}
                                  </React.Fragment>
                              }
                            />
                          </ListItem>
                        )
                      }
                      )
                    }
                  </List>
                </div>
              </div>
              <div className='row ml-3'>
                <div className='col-12 d-flex justify-content-center'>
                  <List className='col-sm-12 col-xs-12 col-md-10 col-lg-10 col-xl-10'>
                    {Number(value.delivery_charges) > 0 ?
                      <React.Fragment>
                        <ListItem alignItems={'flex-start'} >
                          <ListItemText
                            className={'listMaxWidth'}
                            secondary={'Delivery Charges:'}
                          />
                          <ListItemText
                            secondary={`₹${Number(value.delivery_charges)}`}
                          />
                        </ListItem>
                      </React.Fragment>
                      :
                      null
                    }
                    <Divider />
                    <ListItem alignItems={'flex-start'} >
                      <ListItemText
                        className={'listMaxWidth'}
                        primary={'Total'}
                      />
                      <ListItemText
                        primary={
                          Number(value.delivery_charges) > 0 ?
                            `₹${(Number(value.amount.props.children[1]) + Number(value.delivery_charges)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            :
                            `₹${(Number(value.amount.props.children[1])).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </div>
              <Divider />
              <div className='row mt-3 mr-2 flex-row-reverse'>
                <div className=''>
                  <div className='list-action mx-auto'>
                    {mainOrderData[index].requested === "Requested" ?
                      <div>
                        <Button className="jr-btn text-uppercase text-primary" onClick={() => handleAcceptModal(value)}>
                          Accept
                        </Button>
                        <Button className="jr-btn text-uppercase text-danger" onClick={() => handleCancelModal(value)}>
                          Cancel
                        </Button>
                        <Button className="jr-btn text-uppercase text-success" onClick={() => handleOpenModal(value)}>
                          Track
                        </Button>
                      </div>
                      :
                      mainOrderData[index].order_status[mainOrderData[index].order_status.length - 1].status === "New" ||
                        mainOrderData[index].order_status[mainOrderData[index].order_status.length - 1].status === "OnHold"
                        ?
                        <div>
                          <Button className="jr-btn text-uppercase text-danger" onClick={() => handleCancelModal(value)}>
                            Cancel
                          </Button>
                          <Button className="jr-btn text-uppercase text-success" onClick={() => handleOpenModal(value)}>
                            Track
                          </Button>
                        </div>
                        :
                        (mainOrderData[index].requested === "Delivered" || mainOrderData[index].requested === "Processed"
                          || mainOrderData[index].requested === "RTO" || mainOrderData[index].requested === "LOST"
                          || mainOrderData[index].requested === "RTO after Delivery") ?
                          <div>
                            <a href={`${AppConfig.baseUrl}invoice/${mainOrderData[index].uploadedInvoice}`} target='_blank' rel="noreferrer">
                              <Button className="jr-btn text-uppercase text-info">
                                View Invoice
                              </Button>
                            </a>
                            <Button className="jr-btn text-uppercase text-success" onClick={() => handleOpenModal(value)}>
                              Track
                            </Button>
                          </div>
                          :
                          <Button className="jr-btn text-uppercase text-success" onClick={() => handleOpenModal(value)}>
                            Track
                          </Button>
                    }
                  </div>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </React.Fragment>
      }

      {/* -------------------------- TrackOrder --------------------------------------- */}

      <Dialog open={openModal} onClose={handleClose} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{"Order tracking details"}</DialogTitle>
        <DialogContent>
          <TrackOrder orderDetails={details} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus> Close </Button>
        </DialogActions>
      </Dialog>

      {/* -------------------------- Accept --------------------------------------- */}

      <Dialog open={acceptModal} onClose={handleAcceptClose} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">Accept Changes</DialogTitle>
        <DialogContent>
          Are you sure you want to accept changes?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAcceptClose} color="secondary" autoFocus> Close </Button>
          <Button className="jr-btn text-uppercase text-primary" onClick={() => handleAccept(value)}> Accept </Button>
        </DialogActions>
      </Dialog>

      {/* -------------------------- Cancel --------------------------------------- */}

      <Dialog open={cancelModal} onClose={handleCancelClose} fullWidth={true}>
        <DialogTitle>
          Cancel Order
          <DialogContentText className="mt-1 mb-0">
            Are you sure you want to cancel the order ?
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup aria-label="reason" name="customized-radios" value={selectedValue} onChange={handleChange}>
              <FormControlLabel value="Late delivery" control={<StyledRadio />} label="Late delivery" />
              <FormControlLabel value="Better price somewhere" control={<StyledRadio />} label="Better price somewhere" />
              <FormControlLabel value="Order by mistake" control={<StyledRadio />} label="Order by mistake" />
              <FormControlLabel value="Reason not listed" control={<StyledRadio />} label="Reason not listed" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="secondary" autoFocus> Close </Button>
          <Button className="jr-btn text-uppercase text-primary" onClick={() => handleCancel(value)}> Cancel Order </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const mapStateToProps = ({ buyer }) => {
  const { updateOrder } = buyer;
  return { updateOrder }
};

export default connect(mapStateToProps, { getUpdateOrder, getOrderList, getOrderHistoryList })(CollapseComponent);

