import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import { FormGroup, Label, Input } from 'reactstrap';
import AppConfig from 'constants/config'
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const OrderHeader = (props) => {
  const [openFilter, setOpenFilter] = useState(false)
  const [from, setFrom] = useState(moment().subtract(1, 'months'))
  const [to, setTo] = useState(moment())
  const [status, setStatus] = useState('')

  const handleChange = (e) => {
    setStatus(e.target.value);
  }

  const applyFilter = async () => {
    window.open(`${AppConfig.baseUrl}order/export?token=${localStorage.getItem('token')}&from_date=${from ? moment(from) : moment()}&to_date=${to ? moment(to) : moment()}&status=${status ? status : ''}&seller=${true} `, '_blank');
    setOpenFilter(false)
    setStatus('')
  }

  const handleRequestClose = () => {
    setOpenFilter(false)
    setStatus('')
  }

  const handleDateChange = (date, key) => {
    if (key === 'from') {
      setFrom(moment(date))
    }
    if (key === 'to') {
      setTo(moment(date))
    }
  }

  return (
    <div className="page-heading buyerDetailsHeader d-flex justify-content-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">Orders </h2>
      <Button variant="outlined" className={'text-primary'} onClick={() => { setOpenFilter(true) }}>
        Download
      </Button>
      <Dialog open={openFilter} onClose={handleRequestClose}>
        <DialogContent >
          <Row form >
            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
              <FormControl className="w-100 mb-2 ">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    onChange={(date) => handleDateChange(date, 'from')}
                    name="date"
                    label="FROM"
                    value={from}
                    fullWidth
                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Col>
            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
              <FormControl >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    onChange={(date) => handleDateChange(date, 'to')}
                    name="date"
                    label="TO"
                    value={to}
                    fullWidth
                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Status</Label>
                <Input type="select" value={status} onChange={(e) => handleChange(e)} name="status" id="status">
                  <option value="" >All</option>
                  <option value="New" >New</option>
                  <option value="Processed" >Processed</option>
                  <option value="Requested" >Requested</option>
                  <option value="Manifested" >Manifested</option>
                  <option value="Cancelled" >Cancelled</option>
                  <option value="Delivered" >Delivered</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <DialogActions>
            <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
            <Button onClick={() => applyFilter()} color='primary'>Download</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default OrderHeader