import { combineReducers } from 'redux';
import Auth from './Auth';
import seller from './seller'
import notification from './notification';
import buyer from './buyer';
import order from './order';
export default () => combineReducers({
  auth: Auth,
  seller,
  notification,
  buyer,
  order
});
