import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import { FormControl } from '@mui/material';
import moment from 'moment';
import { connect } from 'react-redux';
import { getSettlements } from 'actions/seller';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const Settlement = (props) => {
  const perPage = 50
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [to, setTo] = useState(moment())
  const [from, setFrom] = useState(moment())

  useEffect(() => {
    let data = {
      page: 1,
      perPage: 50,
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
    }
    props.getSettlements({ data })
    setTimeout(() => {
      setLoading(false)
    }, 1300)
  }, [])

  const changePage = (page) => {
    let pages = page + 1
    let data = {
      page: pages,
      perPage: perPage,
      filter: '',
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    }
    setPage(page)
    setLoading(true)
    props.getSettlements({ data })
    setTimeout(() => {
      setLoading(false)
    }, 1300)
  };

  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().add(1, 'days').format('YYYY-MM-DD')
    }
    props.getSettlements({ data })
    setTo(moment())
    setFrom(moment())
  }

  const applyFilter = () => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
    }
    setPage(0)
    setLoading(true)
    props.getSettlements({ data })
    setTimeout(() => {
      setLoading(false)
    }, 1300)
  }

  const handleDateChange = (date, key) => {
    if (key === 'from') {
      setFrom(moment(date))
    } else {
      setTo(moment(date))
    }
  }

  let { sellerSettlements } = props;
  const columns = [
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderDate",
      label: "Order Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderValue",
      label: "Order Value",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "tds",
      label: "TDS",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "tcs",
      label: "TCS",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "surg",
      label: "Surchrg",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "commission",
      label: "Commission",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "netAmount",
      label: "Net Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "paymentDueDate",
      label: "Payment Due Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "paymentDate",
      label: "Payment Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "transactionId",
      label: "Transaction ID",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'from')}
                        name="date"
                        label="FROM"
                        value={from}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'to')}
                        name="date"
                        label="TO"
                        value={to}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </React.Fragment>
          ),
        },
      },
    }
  ];

  const statusStyle = (status) => {
    return status.includes("Pending") ? "text-white bg-grey" : status.includes("Settled") ? "text-white bg-success" : "text-white bg-grey";
  }

  let data = []
  sellerSettlements && sellerSettlements.settlements && sellerSettlements.settlements[0] && sellerSettlements.settlements[0].data.map((dataOne) => {
    data.push({
      orderId: dataOne.order.order_id,
      orderDate: dataOne.order.createdAt,
      sellerName: <div >{dataOne.seller && dataOne.seller.company_name}</div>,
      orderValue: "₹" + (dataOne.order_value).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      tds: "₹" + (dataOne.tds).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      tcs: "₹" + (dataOne.tcs).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      commission: "₹" + (dataOne.commission).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      surg: dataOne.commission_comp && dataOne.commission_comp.prod_surge_comm ? (dataOne.commission_comp.prod_surge_comm).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 'N/A',
      netAmount: "₹" + (dataOne.net_amt).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      paymentDueDate: dataOne.payment_due_date_ft,
      status: dataOne.status === 'Pending' ? <div key={'pending'} className={` badge text-uppercase ${statusStyle('Pending')}`}>Pending</div> : <div className={` badge text-uppercase ${statusStyle('Settled')}`}>Settled</div>,
      paymentDate: moment(dataOne.payment_date).format('DD/MM/YYYY'),
      transactionId: dataOne.transaction_id,
    })
  })

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: sellerSettlements && sellerSettlements.settlements && sellerSettlements.settlements[0] && sellerSettlements.settlements[0].metadata && sellerSettlements.settlements[0].metadata[0] && sellerSettlements.settlements[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton mt-4' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton mt-4'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <React.Fragment>
      {
        loading === false ?
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          :
          <div className="loader-view" style={{ textAlign: "center", marginTop: "300px" }}>
            <CircularProgress />
          </div>
      }
    </React.Fragment>
  );
}

const mapStateToProps = ({ seller }) => {
  let { sellerSettlements, totalEarning } = seller;
  return { sellerSettlements, totalEarning }
}
export default connect(mapStateToProps, { getSettlements })(Settlement);