import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import ReactStrapTextField from 'components/ReactStrapTextField';
import { connect } from 'react-redux';
import { addToCart, getCartDetails } from 'actions/buyer'
import { NotificationManager } from 'react-notifications';
import AxiosRequest from 'sagas/axiosRequest'
import './index.css'

const CartPopOver = (props) => {
    const { dataFromParent } = props;
    const [Inventory, setInventory] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [updatedCount, setUpdatedCount] = useState(0)
    const [disabled, setDisabled] = useState(false)

    const handleIncremented = () => {
        if (updatedCount + dataFromParent.min_order_quantity > dataFromParent.max_order_quantity) {
        } else if (updatedCount <= Number(dataFromParent.max_order_quantity)) {
            setUpdatedCount(Number(updatedCount) + Number(dataFromParent.min_order_quantity))
        }
    }

    const handleDecremented = () => {
        if (updatedCount !== dataFromParent.min_order_quantity && updatedCount - dataFromParent.min_order_quantity >= dataFromParent.min_order_quantity) {
            setUpdatedCount(Number(updatedCount) - Number(dataFromParent.min_order_quantity))
        }
    }

    const handleQuantityChanged = () => {
        NotificationManager.warning('Please use buttons for increment and decrement')
    };

    const onSubmit = async (e) => {
        props.closepop()
        setDisabled(true)
        if (dataFromParent && dataFromParent.Discount) {
            if (updatedCount >= dataFromParent.min_order_quantity && updatedCount <= dataFromParent.max_order_quantity && Number.isInteger(updatedCount) === true) {
                let data = {
                    cart_details: [{
                        inventory_id: Inventory,
                        quantity: updatedCount
                    }]
                }
                let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'cart/addtoCart', '', data)
                if (response.data.error) {
                    NotificationManager.error(response.data.title)
                } else {
                    NotificationManager.success(response.data.title)
                    const isLogin = localStorage.getItem("buyer_token");
                    if (isLogin) {
                        props.getCartDetails({})
                    }
                }
            }
        } else if (quantity >= dataFromParent.min_order_quantity && quantity <= dataFromParent.max_order_quantity && Number.isInteger(quantity) === true) {
            let data = {
                cart_details: [{
                    inventory_id: Inventory,
                    quantity: updatedCount
                }]
            }
            let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'cart/addtoCart', '', data)
            if (response.data.error) {
                NotificationManager.error(response.data.title)
            } else {
                NotificationManager.success(response.data.title)
                const isLogin = localStorage.getItem("buyer_token");
                if (isLogin) {
                    props.getCartDetails({})
                }
            }
        } else {
            NotificationManager.error("Please check Minimum & Maximum order quantity.")
        }
    }

    useEffect(() => {
        setQuantity(dataFromParent.min_order_quantity)
        setUpdatedCount(dataFromParent.min_order_quantity)
        setInventory(dataFromParent.inventory_id)
    }, [dataFromParent])

    return (
        <div>
            <div className="media-body " style={{ display: "flex" }}  >
                <React.Fragment>
                    <span className="mt-1 text-grey" style={{ boxShadow: "2px", border: "none" }} onClick={handleDecremented}>
                        <i class="zmdi zmdi-minus-circle-outline  zmdi-hc-2x"></i>
                    </span>
                    <Input
                        id="quantity"
                        name="quantity"
                        type="number"
                        textAlign="center"
                        value={updatedCount}
                        max={dataFromParent.max_order_quantity}
                        min={dataFromParent.min_order_quantity}
                        component={ReactStrapTextField}
                        style={{ width: "70px", fontSize: "17px", marginLeft: "5px", marginRight: "1px", textAlign: 'center', height: "50%", backgroundColor: '#fff' }}
                        onChange={handleQuantityChanged}
                    />
                    <span className="ml-1 mt-1 text-grey" onClick={handleIncremented}>
                        <i class="zmdi zmdi-plus-circle-o  zmdi-hc-2x"></i>
                    </span>
                </React.Fragment>
            </div>
            <div>
                <span style={{ paddingTop: "3px" }}>Min Order: {dataFromParent.min_order_quantity}</span><br />
                <span style={{ paddingBottom: "2px" }}>Max Order: {dataFromParent.max_order_quantity}</span>
            </div>
            <button
                disabled={disabled}
                style={{ padding: '7px', backgroundColor: '#072791', color: 'white', borderRadius: '7px', border: 'none', width: "80%" }}
                onClick={onSubmit}
                variant="contained" color="primary"
            >
                Add To Cart
            </button>
        </div>
    );
}

const mapStateToProps = ({ buyer }) => {
    const { addToCart, cartDetails } = buyer
    return { addToCart, cartDetails };
};
export default connect(mapStateToProps, { addToCart, getCartDetails })(CartPopOver);