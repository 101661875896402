import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import app from '../../firebase-conf';
import { Col, Row } from 'reactstrap';
import TextBox from 'components/textBox/index.js'
import { Field, Form } from 'react-final-form'
import { required, emailField } from 'constants/validations'
import { hideMessage, userSignIn } from 'actions/Auth';
import { getMessaging, getToken } from '@firebase/messaging';
import './index.css';

const SignInPopOver = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
  }, [])

  const onSubmit = async ({ email, password }) => {
    let web_token = ""
    try {
      const msg = await getMessaging(app);
      const Wtoken = await getToken(msg, { vapidKey: "BOBJN6BcqltSJVpB2FZi8-s6q2Glta94R4UEx7JcWLn8upFx3MJ8lhLnpOaTi5Fvwoil_Bbb14yaWq2tqzTuLtk" })
      if (Wtoken) {
        web_token = Wtoken
      } else {
        web_token = ""
      }
    } catch (error) {
      console.log(error, "@@@@@ERROR OF NOTIFICATION pop OVER");
    }
    localStorage.clear();
    props.userSignIn({ email, password, user_type: "buyer", web_token, navigate });
  }

  const local = () => {
    localStorage.setItem('user', 'buyer')
  }

  return (
    <div className="col-xl-12 col-lg-12 mt-3">
      <div className="">
        <div className="">
          <div className="">
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                  </Col>
                  <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                    <Field name="email" type="text"
                      component={TextBox} label="Email"
                      validate={emailField}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                    <Field name="password" type="password"
                      component={TextBox} label="Password"
                      validate={required}
                    />
                  </Col>
                  <Col sm={6} md={6} lg={6} xs={6} xl={6} >
                    <Button type='submit' variant='contained' color='primary' className='m-2 signin' size='small'>LOGIN</Button>
                  </Col>
                  <Col sm={6} md={6} lg={6} xs={6} xl={6} className='pull-right pt-3' style={{ textAlign: "right", fontSize: '13px', fontWeight: '500' }}>
                    <NavLink to="/buyersignup"><span>Sign Up as Retailer</span></NavLink>
                    <br />
                  </Col>
                  <Col sm={6} md={4} lg={6} xs={6} xl={6} className='d-flex  align-items-center'>
                    <NavLink to="/forgotPassword"  >
                      <span color='primary' onClick={local}>Forgot your password?</span>
                    </NavLink>
                  </Col>
                  <Col sm={6} md={4} lg={6} xs={6} xl={6} className='pull-right ' style={{ textAlign: "right", fontSize: '13px', fontWeight: '500' }}>
                    <a href="/signin"><span>Sign In as Stockist</span></a>
                  </Col>
                </Row>
                <div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  const { alertMessage, showMessage, authUser } = auth;
  return { alertMessage, showMessage, authUser }
};
export default connect(mapStateToProps, { userSignIn, hideMessage, })(SignInPopOver)

