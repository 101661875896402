import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Label, Input, FormGroup } from 'reactstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NotificationManager } from 'react-notifications';
import axios from 'axios'
import AppConfig from 'constants/config'

const PrintManifest = (props) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const onSubmit = async () => {
    let newArr = []
    await props.orderArr.map((data) => {
      newArr.push(data.order_id)
    })
    let data = {
      orderArr: props.orderArr,
      orderID: newArr,
      name: name,
      phone: phone
    }
    await axios.post(`${AppConfig.baseUrl}shippingManifest/markManifested`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
        props.handleClick()
      }
    })
      .catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
  }

  let { orderArr } = props;

  return (
    <Dialog open={props.open} onClose={props.handleClick} fullWidth={true} maxWidth={'md'}>
      <DialogTitle className='pb-0'>
        Print Manifest
      </DialogTitle>
      <DialogContent>
        <Paper className={'tableRoot'}>
          {orderArr && orderArr.length > 0 ?
            <Table className={'tableMain'} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" size='small'>Order Id</TableCell>
                  <TableCell align="center" size='small'>Amount</TableCell>
                  <TableCell align="center" size='small'>Awb</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderArr.map((eachOrder, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" size='small'>{eachOrder.order_id}</TableCell>
                    <TableCell align="center" size='small'>{eachOrder.amount}</TableCell>
                    <TableCell align="center" size='small'>{eachOrder.awb}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            : 'No Orders Found'}
        </Paper>
        <div className='mt-2 d-flex'>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
          </FormGroup>
          <FormGroup className='ml-3'>
            <Label for="phone">Phone</Label>
            <Input type="number" name="phone" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </FormGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClick} color='secondary' >
          Cancel
        </Button>
        <Button onClick={onSubmit} color='primary'>
          Print
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PrintManifest;