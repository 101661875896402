import React from 'react';
import { Row } from 'reactstrap'
import Slider from "react-slick";
import BDashboardCategory from 'components/BdashboardCategory'

const FeaturedBCoursel = (props) => {
  const options1 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
  const { identifier } = props;

  return (
    <div>
      {props.data && props.data.length < 4 ?
        <Row>
          {
            props.data && props.data.map((value, index) => {
              return (value && value.data.length >= 3 ?
                <div className="slick-slide-item col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" key={index}>
                  <BDashboardCategory
                    styleName="pb-4"
                    headerStyle="bg-gradient-primary"
                    value={value}
                    index={index}
                    data={props.data}
                    identifier={identifier}
                  />
                </div> : '')
            })
          }
        </Row>
        :
        <Slider className="slick-slider-cr" {...options1}>
          {
            props.data && props.data.map((value, index) => {
              return (value && value.data.length >= 3 ?
                <div className="slick-slide-item " key={index}>
                  <BDashboardCategory
                    key={index}
                    styleName="pb-4"
                    headerStyle="bg-gradient-primary"
                    value={value}
                    index={index}
                    data={props.data}
                    identifier={identifier}
                  />
                </div> : '')
            })}
        </Slider>
      }
    </div>
  );
}

export default FeaturedBCoursel;
