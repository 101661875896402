import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import CustomScrollbars from 'util/CustomScrollbars';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import AppConfig from 'constants/config'
import { NotificationManager } from 'react-notifications';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { getUserDetail } from 'actions/seller'

const SidenavContent = (props) => {
  const location = useLocation()
  const [checkedA, setCheckedA] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(moment())
  const [vacationMode, setVacationMode] = useState(false)

  useEffect(() => {
    setCheckedA(props.userDetails && props.userDetails.vaccation ? props.userDetails.vaccation.vaccationMode : false)
    setVacationMode(props.userDetails && props.userDetails.vaccation ? props.userDetails.vaccation.vaccationMode : false)
    setSelectedDate(props.userDetails && props.userDetails.vaccation && props.userDetails.vaccation.tillDate ? moment(props.userDetails.vaccation.tillDate) : moment())
  }, [props.userDetails])

  const handleChange = name => (event, checked) => {
    setCheckedA(checked)
    setOpen(!open);
  };

  const handleRequestClose = () => {
    setOpen(false)
    setVacationMode(props.userDetails && props.userDetails.vaccation && props.userDetails.vaccation.vaccationMode === true ? true : false)
    setCheckedA(props.userDetails && props.userDetails.vaccation ? props.userDetails.vaccation.vaccationMode : false)
  }

  const handleRequestSave = () => {
    axios({
      method: 'post',
      url: `${AppConfig.baseUrl}seller/enableDisableVaccation`,
      headers: {
        token: localStorage.getItem('token')
      },
      data: {
        vacationMode: !vacationMode,
        tillDate: moment(selectedDate).format()
      }
    })
      .then((result) => {
        if (result.data.error === false) {
          NotificationManager.success(result.data.title)
          setOpen(false)
          setVacationMode(!vacationMode)
          setSelectedDate(moment())
          props.getUserDetail({})
        } else {
          NotificationManager.error(result.data.title)
        }
      })
  }

  const handleDateChange = (e) => {
    setSelectedDate(e);
  }

  return (
    <CustomScrollbars className=" scrollbar">
      <ul className="nav-menu">
        <li className="nav-header">
        </li>
        {
          props.sidebar.length > 0 && props.sidebar.map((data) => {
            return <li key={data.name} className={location.pathname.includes(data.pathname) ? 'open menu no-arrow' : 'menu no-arrow'} >
              <NavLink to={data.pathname}>
                <i className={`zmdi ${data.icon} zmdi-hc-fw`} />
                <span className="nav-text">{data.name}</span>
              </NavLink>
            </li>
          })
        }
        {
          props.isVaccatioMode ?
            <li className=" no-arrow">
              <span className="vacationMode">
                <i className="zmdi zmdi-card-travel zmdi-hc-fw" />
                <span className="nav-text">Vacation
                  <Switch
                    color='primary'
                    checked={checkedA}
                    onChange={handleChange('checkedA')}
                    aria-label="checkedA"
                    className="vacationToggle"
                  />
                </span>
              </span>
              <span className={(vacationMode && checkedA) ? 'vacationMode vacationDate' : 'd-none'}>
                On Vacation Untill {moment(selectedDate).format('DD-MM-YYYY')}
              </span>
            </li> : ''
        }
      </ul>
      <div className='sellerSideBarModal'>
        <Dialog open={open} onClose={handleRequestClose}
          fullWidth={true}
          scroll='paper'
          className='sdfsfsf'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth={'sm'}>
          <DialogTitle id="scroll-dialog-title">
            {
              !checkedA && 'End your vacation'
            }
            {
              checkedA && 'On Vacation untill'
            }
            <DialogContentText className="mt-3">
              {
                !checkedA ? 'Are you sure you want to end your vacation?' : `Please select vacation end date`
              }
            </DialogContentText>
          </DialogTitle>
          <DialogContent className="mb-5" style={{ minHeight: vacationMode === false ? "250px" : "10px" }}>
            <DialogContentText id="scroll-dialog-description" className="mt-3 mb-5">
              {
                checkedA &&
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    onChange={handleDateChange}
                    name="date"
                    label="Date"
                    value={selectedDate}
                    fullWidth
                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                    format="MM/DD/YYYY"
                    slotProps={{ textField: { variant: 'outlined' } }}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button onClick={handleRequestSave} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </CustomScrollbars>
  );
}

const mapStateToProps = ({ seller }) => {
  const { sidebar, userDetails, isVaccatioMode } = seller
  return { sidebar, userDetails, isVaccatioMode }
};

export default connect(mapStateToProps, { getUserDetail })(SidenavContent)

