import React, { useEffect } from 'react';
import AppConfig from 'constants/config'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const AdminSignIn = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  let url = location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  useEffect(() => {
    login()
  }, [])

  const login = async () => {
    if (json && Boolean(json.admin) ? Boolean(json.admin) : false) {
      localStorage.clear();
      localStorage.setItem('asAdmin', 1);
      let data = { otp: json && json.otp ? json.otp : '' }
      let signInUser = await axios.post(`${AppConfig.baseUrl}admin/adminToUser`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      localStorage.setItem('isDocumentExpired', false);
      localStorage.setItem('user_type', 'buyer')
      localStorage.setItem('buyer_token', signInUser.data.token)
      navigate('/');
      setTimeout(function () { window.location.reload(); }, 1000);
    }
  }

  return (
    <div className="col-xl-8 col-lg-8 signinContainer" >
      <div className="jr-card signinCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="login-header mb-0">
            <a className="app-logo" href='javascript:void(0);' title="Jambo">
              <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
            </a>
          </div>
        </div>

      </div>
    </div>

  );
}

export default AdminSignIn;
