import React, { useState, useEffect, useRef } from 'react';
import { getUserDetail } from 'actions/seller'
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import AppConfig from 'constants/config'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const ComplianceForm = (props) => {
    const { userDetails, fileKey, label, label2 } = props
    const [value, setValue] = useState('')
    const [licNo, setLicNo] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [error, setError] = useState(false)
    let drugLic20B = useRef(null)

    useEffect(() => {
        if (fileKey !== undefined) {
            setValue((userDetails[fileKey] && userDetails[fileKey].name !== undefined) ? `${AppConfig.baseUrl}users/${userDetails[fileKey].name}` : '')
            setLicNo(userDetails[fileKey] ? userDetails[fileKey].lic : licNo)
        }
    }, [userDetails, fileKey])

    const handleFileSelect = (e) => {
        let document = "";
        let reader = new FileReader();
        if (e.target.files.length > 0) {
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                let data = {}
                document = reader.result;
                data[fileKey] = document
                setValue(document)
            };
            reader.onerror = function (error) {
            };
        }
    }

    const apiCall = (data) => {
        axios.post('/users/updateComplainceForm', data, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('buyer_token')
            },
        }
        ).then(result => {
            if (result.data.error) {
                NotificationManager.error(result.data.title);
            } else {
                NotificationManager.success('Compliance details updated successfully.');
                setTimeout(() => {
                    props.getUserDetail({ userType: 'buyer' })
                }, 2000);
            }
        })
            .catch(error => {
                NotificationManager.error('Something went wrong, Please try again')
            });
    }

    const handleChange = (e) => {
        if (e.target.value && (e.target.value.length < 15 || !(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(e.target.value)))) {
            setError(true)
        } else {
            setError(false)
        }
        setLicNo(e.target.value)
    }

    const handleCall = () => {
        if (value && licNo) {
            let data = {}
            data[fileKey] = value.length > 200 ? value : ''
            data[props.label2] = licNo
            apiCall(data)
        } else if (!value) {
            NotificationManager.error('Please upload photo')
        }
        handleClose()
    }

    const getDisabled = () => {
        let imageValue = (userDetails[fileKey] && userDetails[fileKey].name !== undefined) ? `${AppConfig.baseUrl}users/${userDetails[fileKey].name}` : ''
        let licNo1 = userDetails[fileKey] ? userDetails[fileKey].lic : licNo
        if (imageValue === value && licNo1 === licNo) {
            return true
        } else {
            return false
        }
    }

    const handleOpenModal = (e) => {
        e.preventDefault()
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    return (
        <React.Fragment>
            <div className='text-center'>
                <div className="p-2">
                    <input
                        type='file'
                        accept='.jpg, .png, .jpeg, .pdf'
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileSelect(e)} ref={(ref) => drugLic20B = ref}
                    />
                    <div class="img-wrap">
                    </div>
                    <img src={value !== '' ? require('assets/images/uploaded.png') : require('assets/images/file.png')} className='d-block mx-auto' alt="upload" />
                </div>
                <label className="text-dark" style={{ fontSize: 17, marginBottom: '29px' }}>{label}</label>
            </div>
            <form onSubmit={handleOpenModal} autoComplete="off">
                <div className="pt-2 text-center">
                    <p>{label2}</p>
                    <TextField
                        error={error}
                        value={licNo}
                        name="licNo"
                        id='licNo'
                        helperText={error ? "Invalid GST" : ""}
                        onChange={(e) => handleChange(e)}
                        label={''}
                        className={'cursor-pointer liCNo'}
                        margin="normal"
                        variant="standard"
                    />
                </div>
                <div className='mx-md-n5 d-flex justify-content-center pt-2'>
                    <div className='mr-1 ml-1'>
                        <Tooltip title={`Upload ${label}`}>
                            <Avatar className='bg-primary cursor-pointer' onClick={(e) => drugLic20B.click()} size={2}>
                                <i className='zmdi zmdi-upload'></i>
                            </Avatar>
                        </Tooltip>
                    </div>
                    <div className='mr-1 ml-1'>
                        <Tooltip title={`${value ? 'View ' + label : label + ' not uploaded'} `} >
                            <Avatar className='bg-warning' color='primary' size={2}>
                                <a href={value} style={value ? { padding: 0 } : { pointerEvents: 'none', cursor: 'default' }} disabled={value ? false : true} target='_blank' rel="noreferrer"><i className='zmdi zmdi-eye text-white'></i></a>
                            </Avatar>
                        </Tooltip>
                    </div>
                    {
                        !getDisabled() &&
                        <div className='mr-1'>
                            <Tooltip title={`Save ${label}`}>
                                <Button type='submit' style={{ margin: '0px', padding: '0px' }}>
                                    <Avatar className={`bg-success cursor-pointer `} size={2}>
                                        <i style={{ margin: '0px', padding: '0px' }} className='zmdi zmdi-floppy'></i>
                                    </Avatar>
                                </Button>
                            </Tooltip>
                        </div>
                    }
                </div >
            </form>
            <Dialog open={openModal} onClose={handleClose} fullWidth={true}>
                <DialogTitle id="alert-dialog-title" ><h2 style={{ color: "#fd4081" }}>Important Message</h2></DialogTitle>
                <DialogContent>
                    <h3>If you save, your profile will have to go under verification process again.</h3>
                </DialogContent>
                <DialogActions>
                    <Button className="jr-btn text-uppercase text-primary" onClick={handleCall}>Save</Button>
                    <Button onClick={handleClose} color="secondary" autoFocus> Cancel </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

const mapStateToProps = ({ seller }) => {
    const { userDetails } = seller;
    return { userDetails }
}

export default connect(mapStateToProps, { getUserDetail })(ComplianceForm)