import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import './index.css'
import helpertFn from 'constants/helperFunction';
import { isMobile } from 'react-device-detect';
import { EllipsisTooltips } from '../../TooltipEllipsis/EllipsisTooltips'
import { EllipsedText } from "../../TooltipEllipsis/EllipsedText";
import { Tooltip2 } from "../../TooltipEllipsis/Tooltip";
const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const CompanyProductCard = (props) => {
  const location = useLocation()
  const [tooltipElement, setTooltipElement] = React.useState();
  const [activeIndex, setActiveIndex] = useState(0)

  function next(size) {
    if (size === 1) {
      setActiveIndex(0)
    } else if (activeIndex === 0) {
      setActiveIndex(activeIndex + 1)
    }
  }


  function previous(size) {
    if (size === 1) {
      setActiveIndex(0)
    } else if (activeIndex === 1) {
      setActiveIndex(activeIndex - 1)
    }
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const { product } = props
  const chemCombination = product && product && product && product.chem_combination
  const type = product && product.medicine_type_id && product.medicine_type_id.name;
  const bgColor = type === 'Ethical branded' || type === 'Others' ? '#ff7000' :
    type === 'Cool chain' ? '#0b68a8' :
      type === 'Surgical' || type === 'OTC' || type === 'Generic' ? '#038d0e' : '#072791'
  const slides = product.images.length > 0 ? product.images.map((image, index) => {
    return (
      <CarouselItem
        key={index}
      >
        <NavLink className="buyerRedirectNavlink" to={`/search-results/category/search=${encodeURIComponent(product.name)}`}>
          <img src={product.images.length === 0 ? logo : `${helpertFn.productImg(image)}`} onError={addDefaultSrc} alt="product" />
        </NavLink>
      </CarouselItem>
    );
  }) : [<CarouselItem
    key={'images'}
  >
    <NavLink className="buyerRedirectNavlink" to={`/search-results/category/search=${encodeURIComponent(product.name)}`}>
      <img src={logo} alt="product" />
    </NavLink>
  </CarouselItem>]

  return (
    <React.Fragment>
      <EllipsisTooltips onShowTooltip={setTooltipElement} />
      {tooltipElement && <Tooltip2 element={tooltipElement} />}
      <div className="jr-card-buyer text-left pb-2" style={{ overflow: 'hidden' }}>
        <div className="jr-card-header-color card-header-style seller-card-min-height">
          <Carousel
            autoPlay={false}
            indicators={true}
            activeIndex={activeIndex}
            next={() => next(slides.length)}
            interval={false}
            previous={() => previous(slides.length)}
          >
            {slides}
          </Carousel>
          <span style={{ padding: '5px 12px', borderRadius: "0 0 10px 0", fontWeight: 'bold', position: 'absolute', zIndex: 1, backgroundColor: `${bgColor}`, color: 'white', width: 'auto' }} >{type === 'Others' ? 'PCD' : type}</span>
        </div>
        <div className="jr-card-body mb-2 pt-2" >
          <div className="product-details cardBodyPadding">
              <EllipsedText>
                <div style={{marginBottom: "10px"}}>
                <h4 className="card-title card-titleIntranet w-100 cardHeading ellipsis" style={{ minHeight: "31px", maxHeight: "31px" }} >
                  {(product && product.name)}
                 </h4>
                </div>
              </EllipsedText>
            {helpertFn.showPrepaid(product.medicine_type_id.name, product.isPrepaid && product.isPrepaid, false) &&
            <div style={{ minHeight: "15px", maxHeight: "15px", marginBottom: "10px", padding: "0px" }}>
              {
                helpertFn.showPrepaid(product.medicine_type_id.name, product.isPrepaid && product.isPrepaid, false) &&
                <Chip
                  label={'Only Prepaid'}
                  className='text-white bg-danger'
                  size='small'
                  style={{ padding: '17px 10px', float: "left" }}
                />
              }
            </div>}
            <div style={{ minHeight: "45px", maxHeight: "45px", marginBottom: "5px", padding: "0px" }}>
                  <React.Fragment >
                  <EllipsedText>
                  <p style={{ color: "#202790", margin: "10px 0 0 0" }}>Description :</p>
                  <h5 className='mb-0 companyDescription ellipsis'>{(product && product.chem_combination)}</h5>
                  </EllipsedText>
                  </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyProductCard;