import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_BUYER_NOTIFICATIONS,
    GET_BUYER_NOTIFICATIONS_SUCCESS
} from 'constants/ActionTypes';

export function getNotification(notification) {
    return {
        type: GET_NOTIFICATIONS,
        payload: notification
    }
}

export function getNotificationSuccess(notification) {
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: notification
    }
}

export function getBuyerNotification(notification) {
    return {
        type: GET_BUYER_NOTIFICATIONS,
        payload: notification
    }
}

export function getBuyerNotificationSuccess(notification) {
    return {
        type: GET_BUYER_NOTIFICATIONS_SUCCESS,
        payload: notification
    }
}