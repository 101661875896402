import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import { connect } from 'react-redux';
import { getTicketListSeller } from 'actions/seller'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const Open = (props) => {
  const [params] = useSearchParams()
  let navigate = useNavigate();
  const tab = params && params.get("tab") ? Number(params.get("tab")) : 0
  const perPage = 100
  const searchedText = params && params.get("searchT") ? params.get("searchT") : ''
  const page = params && Number(params.get("pageT")) ? Number(params.get("pageT")) : 0

  useEffect(() => {
    let data = {
      page: (params && Number(params.get("pageT")) ? Number(params.get("pageT")) : 0) + 1,
      perPage,
      filter: '',
      searchText: params && params.get("searchT") ? params.get("searchT") : '',
    }
    if (tab === 0) {
      props.getTicketListSeller({ data })
    }
  }, [params])

  useEffect(() => {
    if (props.tabValue === 0) {
      navigate(`/seller/tickets?tab=0`, { replace: true })
    }
  }, [props.tabValue])

  const changePage = (page) => {
    let obj = { pageT: page, searchT: searchedText, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/tickets?${url}`, { replace: true })
  };

  const handleSearch = (searchText) => {
    let obj = { pageT: 0, searchT: searchText, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/tickets?${url}`, { replace: true })
  };

  const handleCloseSearch = () => {
    let obj = { pageT: 0, searchT: '', tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/tickets?${url}`, { replace: true })
  }

  const { ticketListSeller } = props;
  const columns = ['Ticket ID', 'Type', 'Date', 'Created by', 'Order Id', 'Order Date', 'Issue']
  let data = [];
  ticketListSeller && ticketListSeller.data1.map((d) => data.push(
    {
      'Ticket ID': <NavLink to={`/seller/tickets/ticketDetails/${d._id}`} className={'text-primary'}>{d.ticketNo}</NavLink>,
      'Type': d.type === 'order' ? 'Order related' : 'Other',
      'Date': moment(d.createdAt).format('DD/MM/YYYY'),
      'Order Id': d.type === 'order' ? d.order.orderNo : 'N/A',
      'Order Date': d.type === 'order' ? moment(d.order.order_id.createdAt).format('DD/MM/YYYY') : 'N/A',
      'Issue': d.issue,
      'Created by': (d.createdBy).includes('Admin') ? 'Medimny staff' : d.createdBy
    }
  ));

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: ticketListSeller && ticketListSeller.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      <ThemeProvider theme={createTheme({ components: styles })}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
          style={{ borderRadius: '0px !important' }}
        />
      </ThemeProvider>
    </div>
  );
}

const mapStateToProps = ({ seller }) => {
  const { ticketListSeller, loading } = seller;
  return { ticketListSeller, loading }
};

export default connect(mapStateToProps, { getTicketListSeller })(Open);

