import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'constants/axios';
import { connect } from 'react-redux';
import { getCartDetails } from 'actions/buyer'
import { NotificationManager } from 'react-notifications';
import './index.css'
import moment from 'moment';

const TableComponent = (props) => {
    const [popUp, setPopUp] = useState(false)
    const [closePopup, setClosePopup] = useState(false)
    const [openDialogPopup, setOpenDialogPopup] = useState(false)
    const [expirePopup, setExpirePopup] = useState(false)
    const [dialogData, setDialogData] = useState('')
    const [row, setRow] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);

    const openPopup = () => {
        setPopUp(true)
    }

    const openDialog = (data, key) => {
        setOpenDialogPopup(true)
        setDialogData({ ...data, key: key })
    }

    const handleLogClose = () => {
        setPopUp(false)
        setClosePopup(false)
        setDialogData('')
        setOpenDialogPopup(false)
        setExpirePopup(false)
    }

    const handleUpdateRequest = async () => {
        try {
            let data = {
                status: dialogData ? dialogData.key : '',
                requestId: row ? row._id : '',
                offerId: dialogData ? dialogData._id : ''
            }
            let api = '';
            if (dialogData && dialogData.key === 'confirmed') {
                api = '/bulkRequest/buyerConfirm';
            } else if (dialogData && dialogData.key === 'supplied') {
                api = '/cart/addtoCart';
                data = {
                    ...data, cart_details: [
                        {
                            inventory_id: dialogData.inventory_id,
                            quantity: dialogData.quantity
                        }
                    ]
                }
            }
            const result = await axios.post(api, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('buyer_token')
                }
            })
            if (result.data.error) {
                NotificationManager.error(result.data.title)
            } else {
                NotificationManager.success(result.data.title);
                if (api === '/bulkRequest/buyerConfirm') {
                    let ogStatus = (result.data.data.status).toLowerCase()
                    let newData = { ...result.data.data, ogStatus }
                    setRow(newData)
                } else {
                    props.getCartDetails({})
                }
            }
            handleLogClose();
        } catch (err) {
            NotificationManager.error('Something went wrong, Please try again')
        }
    }

    const handleCloseRequest = async () => {
        try {
            let data = { requestId: row ? row._id : '' }
            const result = await axios.post('/bulkRequest/closeRequest', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('buyer_token')
                }
            })
            if (result.data.error) {
                NotificationManager.error(result.data.title)
            } else {
                NotificationManager.success(result.data.title);
            }
            handleLogClose();
            props.hitApi();
        } catch (err) {
            NotificationManager.error('Something went wrong, Please try again')
        }
    }

    const statusStyle = (data) => {
        let status = data ? data.toLowerCase() : '';
        return status.includes("supplied") ? "#B45F06" : status.includes("requested") ? '#2B78E4' : status.includes("confirmed") ? "#38761D" : status.includes("offered") ? "#990000" : status.includes("expired") ? "#ea9a99" : status.includes("fulfilled") ? "#b6d8a8" : status.includes("closed") ? "#9fc4f7" : '';
    }

    const action = (data) => {
        let status = data ? data.toLowerCase() : '';
        return status.includes("supplied") ? "Details" : status.includes("requested") ? 'Close' : status.includes("confirmed") ? "Details" : status.includes("offered") ? "Details" : "View";
    }

    const text = (data) => {
        if (data === '') return
        return data.includes("expired") ? "#903230" : data.includes("fulfilled") ? '#698c57' : data.includes("closed") ? "#305d8b" : '';
    }

    useEffect(() => {
        setRow(props.rowData)
    }, [])

    const openClosedDialog = (data) => {
        setExpirePopup(true)
        setDialogData(data)
    }

    const theme1 = createTheme({
        overrides: {
            MuiTableCell: {
                sizeSmall: {
                    padding: '15px',
                },
            }
        }
    });

    return (
        <React.Fragment>
            <TableRow hover className='cursor-pointer' key={row?._id + 'bulk'} align={'left'} >
                <TableCell align="left">{row?.product_name}</TableCell>
                <TableCell align="left">{row?.discount}%</TableCell>
                <TableCell align="left">{row?.quantity}</TableCell>
                {(row?.ogStatus === 'expired' || row?.ogStatus === 'closed' || row?.ogStatus === 'fulfilled') ?
                    <TableCell align="left">
                        <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle(row?.status) }}>
                            <span style={{ color: text(row?.ogStatus) }}>{row?.status}</span>
                        </div>
                    </TableCell> :
                    <TableCell align="left"><div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle(row.status) }}>{row?.status}</div></TableCell>
                }
                <TableCell align="left">{row && moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                {(row?.ogStatus === 'offered' || row?.ogStatus === 'confirmed' || row?.ogStatus === 'supplied') ?
                    <TableCell align="left" className={'text-primary'} style={{ textDecoration: 'underline' }}>
                        <span onClick={() => openPopup(row?.ogStatus)}>{(action(row.status)).toUpperCase()}</span>
                        <span aria-describedby={row?._id + 'bulk'} onClick={(e) => setAnchorEl(e.currentTarget)}><KeyboardArrowDownIcon /></span>
                        <Popover
                            open={Boolean(anchorEl)}
                            id={row?._id + 'bulk'}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }} >
                            <React.Fragment>
                                <MenuItem onClick={() => setAnchorEl(null)} className='text-danger'><span onClick={(e) => setClosePopup(true)}>{`CLOSE`}</span></MenuItem>
                            </React.Fragment>
                        </Popover>
                    </TableCell> : (row?.ogStatus === 'expired' || row?.ogStatus === 'closed') ?
                        <TableCell align="left" className={`${'text-primary'}`} style={{ textDecoration: 'underline' }} onClick={(e) => openClosedDialog(row)}>{row && (action(row.status)).toUpperCase()}</TableCell> :
                        row?.ogStatus === 'fulfilled' ?
                            <TableCell align="left" className={`${'text-primary'}`} style={{ textDecoration: 'underline' }} onClick={(e) => openPopup(row?.ogStatus)}>{row && (action(row.status)).toUpperCase()}</TableCell> :
                            <TableCell align="left" className={`${'text-danger'}`} style={{ textDecoration: 'underline' }} onClick={(e) => setClosePopup(true)}>{row && (action(row.status)).toUpperCase()}</TableCell>
                }
            </TableRow>
            <Dialog open={popUp} onClose={handleLogClose} fullWidth={true} maxWidth={'md'}>
                <DialogTitle className='pb-0'>
                    OFFER DETAILS
                </DialogTitle>
                <DialogContent>
                    <Paper className={'tableRoot MuiPaper-rounded '} elevation={0}>
                        <ThemeProvider theme={theme1}>
                            <Table className={'tableMain MuiPaper-rounded'} aria-label="spanning table" >
                                <TableHead>
                                    <TableRow style={{ backgroundColor: '#dedede', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                        <TableCell align="center" size='small'>#</TableCell>
                                        <TableCell align="center" size='small'>Quantity</TableCell>
                                        <TableCell align="center" size='small'>Discount</TableCell>
                                        <TableCell align="center" size='small'>Final cost</TableCell>
                                        <TableCell align="center" size='small'>{row?.ogStatus === 'offered' ? 'Action' : 'Status'}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row && row.offers.length > 0 && row.offers.map((eachStatus, index) => (
                                        <TableRow key={index} style={{ marginBottom: '5px' }}>
                                            <TableCell align="center" size='small' style={{ fontSize: '12px' }}>OFFER {index + 1}</TableCell>
                                            <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.quantity}</TableCell>
                                            <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.discount}%</TableCell>
                                            <TableCell align="center" size='small' style={{ fontSize: '12px' }}>₹{(eachStatus.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                            {(row.ogStatus === 'offered') ? <TableCell align="center" size='small' className='text-primary' style={{ textDecoration: 'underline', fontSize: '12px', cursor: 'pointer' }} onClick={(e) => openDialog(eachStatus, 'confirmed')}>
                                                CONFIRM
                                            </TableCell> :
                                                (row.ogStatus === 'supplied' && eachStatus.isSupplied === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ textDecoration: 'underline', fontSize: '12px', cursor: 'pointer' }} onClick={(e) => openDialog(eachStatus, 'supplied')}>
                                                    ADD TO CART
                                                </TableCell> :
                                                    (row.ogStatus === 'confirmed' && eachStatus.isConfirmed === true) ? <TableCell align="center" size='small' className={'text-success'} style={{ fontSize: '12px' }}>
                                                        CONFIRMED
                                                    </TableCell> :
                                                        (row.ogStatus === 'fulfilled' && eachStatus.isSupplied === true) ? <TableCell align="center" size='small' className={'text-success'} style={{ fontSize: '12px' }}>
                                                            FULFILLED
                                                        </TableCell> : <TableCell align="center" size='small' className={'text-muted'} style={{ fontSize: '12px' }}>
                                                            {'N/A'}
                                                        </TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table></ThemeProvider>
                    </Paper>
                </DialogContent>
                <DialogActions className='mr-2'>
                    <Button onClick={handleLogClose} color='secondary' >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={closePopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle className='pb-0'>
                    CLOSE REQUEST
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to close this request ?
                </DialogContent>
                <DialogActions className='mr-2'>
                    <Button onClick={handleLogClose} color='secondary' >
                        Cancel
                    </Button>
                    <Button onClick={() => handleCloseRequest()} color='primary' >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={expirePopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    {dialogData.status === 'FULFILLED' ?
                        <Paper className={'tableRoot MuiPaper-rounded '} elevation={0}>
                            <ThemeProvider theme={theme1}>
                                <Table className={'tableMain MuiPaper-rounded'} aria-label="spanning table" >
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#dedede', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                            <TableCell align="center" size='small'>#</TableCell>
                                            <TableCell align="center" size='small'>Quantity</TableCell>
                                            <TableCell align="center" size='small'>Discount</TableCell>
                                            <TableCell align="center" size='small'>Final cost</TableCell>
                                            <TableCell align="center" size='small'>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dialogData && dialogData.offers.length > 0 && dialogData.offers.map((eachStatus, index) => (
                                            <TableRow key={index} style={{ marginBottom: '5px' }}>
                                                <TableCell align="center" size='small' style={{ fontSize: '12px' }}>OFFER {index + 1}</TableCell>
                                                <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.quantity}</TableCell>
                                                <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.discount}</TableCell>
                                                <TableCell align="center" size='small' style={{ fontSize: '12px' }}>₹{(eachStatus.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                                {(dialogData.ogStatus === 'supplied' && eachStatus.isSupplied === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ textDecoration: 'underline', fontSize: '12px', cursor: 'pointer' }} onClick={(e) => openDialog(eachStatus, 'supplied')}>
                                                    SUPPLIED
                                                </TableCell> :
                                                    <TableCell align="center" size='small' className={'text-muted'} style={{ fontSize: '12px' }}>
                                                        {'N/A'}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table></ThemeProvider>
                        </Paper>
                        : <h3>{dialogData.closed_reason}</h3>
                    }
                </DialogContent>
                <DialogActions className='mr-2 pt-0'>
                    <Button onClick={handleLogClose} color='secondary' >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogPopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle className='pb-0'>
                    {dialogData && dialogData.key === 'confirmed' ? 'CONFIRM REQUEST' : dialogData.key === 'supplied' ? 'ADD TO CART' : 'N/A'}
                </DialogTitle>
                <DialogContent>
                    {dialogData && dialogData.key === 'confirmed' ? 'Are you sure you want to confirm this offer ?' :
                        dialogData.key === 'supplied' ? 'Are you sure you want to add this offer to your cart ?' : 'N/A'}
                </DialogContent>
                <DialogActions className='mr-2'>
                    <Button onClick={handleLogClose} color='secondary' >
                        No
                    </Button>
                    <Button onClick={() => handleUpdateRequest()} color='primary' >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = ({ buyer }) => {
    const { addToCart, cartDetails } = buyer
    return { addToCart, cartDetails };
};
export default connect(mapStateToProps, { getCartDetails })(TableComponent);