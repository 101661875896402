import React, { useEffect } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import './CartNoti.css'
import { Col, Row, Container } from 'reactstrap'
import { connect } from 'react-redux';
import { getUserDetail } from 'actions'
import { getCartDetails, removeFromCart } from 'actions/buyer'
import helpertFn from 'constants/helperFunction';

const CartNotification = (props) => {

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("buyer_token");
    if (isUserLoggedIn !== null) {
      props.getCartDetails({})
    }
  }, [])

  const handleRemoveFromCart = (index) => {
    props.removeFromCart({ inventory_id: index })
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const { cartDetails } = props;
  let notifications = [];
  cartDetails && cartDetails.detail && cartDetails.detail.length > 0 && cartDetails.detail.map((dataOne, index) => {
    notifications.push({
      image: dataOne.Inventory.product_id.images[0],
      title: dataOne.Inventory.product_id.name,
      time: dataOne.Inventory.updatedAt,
      offer: dataOne.discount_per,
      quantity: dataOne.cart_details.quantity,
      MRP: dataOne.Inventory.MRP,
      ePTR: dataOne.Inventory.ePTR,
      PTR: dataOne.Inventory.PTR,
      inventory_id: dataOne.Inventory._id,
      discount: dataOne.Discount,
      discountProduct: dataOne.DiscountProduct,
    })
  })

  return (
    <CustomScrollbars className="messages-list scrollbar container1" style={{ height: 200 }}>
      <ul className="list-unstyled">
        {notifications && notifications.length > 0 ? notifications.map((notification, index) => {
          return <li className="media p-2">
            <Container>
              <Row>
                <Col className='1' md={3}>
                  <img
                    alt={notification.image}
                    src={`${helpertFn.productImg(notification.image)}`}
                    className="mr-2 CartImage"
                    onError={addDefaultSrc}
                  />
                </Col>
                <Col className='2' md={7}>
                  <div className="media-body  pt-1">
                    <p className="h6 mb-0 orderTitle">{notification.title} <i class="zmdi zmdi-close" /> {notification.quantity} Qty
                      <br />
                      {(notification.discount.type === "Discount" || notification.discount.type === "Same" || notification.discount.type === "SameAndDiscount" || notification.discount.type === "DifferentAndDiscount") ?
                        <div>
                          ₹{(notification.ePTR).toFixed(2)}&nbsp;&nbsp;
                          <strike>₹{(notification.PTR).toFixed(2)}</strike>
                        </div>
                        :
                        "₹" + (notification.PTR).toFixed(2)
                      }
                    </p>
                  </div>
                </Col>
                <Col className='3' md={2}>
                  <div style={{ float: "right", cursor: "pointer" }}> <i className="zmdi zmdi-close" onClick={(e) => handleRemoveFromCart(notification.inventory_id)}></i> </div>
                </Col>
              </Row>
            </Container>
          </li>
        }) : <div className="media-body align-self-center">
          <p className="heading mb-0 text-center"> No Items Found </p>
        </div>
        }
      </ul>
    </CustomScrollbars>
  )
}

const mapStateToProps = ({ auth, seller, buyer }) => {
  const { userDetails } = seller;
  const { user_details } = auth;
  const { cartDetails, removeFromCart } = buyer;
  let users = userDetails ? userDetails : user_details
  return { user_details, users, cartDetails, removeFromCart }
};

export default connect(mapStateToProps, { getUserDetail, getCartDetails, removeFromCart })(CartNotification);