import React from 'react';
import { Col, Row } from 'reactstrap'
import './index.css'
import ios from 'assets/images/iosAppStore.png'
import android from 'assets/images/androidPlayStore.png'
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="app-footer newfooter">
      <div className="mt-3">
        <div>
          <Row className="rowstyle">
            <Col md="6">
              <h5 className=""><strong>About</strong></h5>
              <ul style={{ paddingInlineStart: "0px" }}>
                <NavLink className="buyerRedirectNavlink" to={`/about-us`}>
                  <li className="liststyle mt-1 text-primary">
                    About Us
                  </li>
                </NavLink>
                <NavLink className="buyerRedirectNavlink" to={`/ContactUs`}>
                  <li className="liststyle mt-1 text-primary">
                    Contact Us
                  </li>
                </NavLink>
              </ul>
            </Col>
            <Col md="6">
              <h5 className=""><strong>Policy</strong></h5>
              <ul style={{ paddingInlineStart: "0px" }}>
                <NavLink className="buyerRedirectNavlink" to={`/TermsOfUse`}>
                  <li className="liststyle mt-1 text-primary">
                    Terms Of Use
                  </li>
                </NavLink>
                <NavLink className="buyerRedirectNavlink" to={`/Privacy`}>
                  <li className="liststyle mt-1 text-primary">
                    Privacy & Cookies
                  </li>
                </NavLink>
                <NavLink className="buyerRedirectNavlink" to={`/ShippingAndReturnPolicy`}>
                  <li className="liststyle mt-1 text-primary">
                    Shipping And Return Policy
                  </li>
                </NavLink>
              </ul>
            </Col>
          </Row>
          <div className="approw">
            <Row className="rowstyle pull-right">
              <Col md="12" className="text-right appStyle">
                <ul style={{ paddingInlineStart: "0px" }}>
                  <li className="liststyle">
                    <a href="https://apps.apple.com/in/app/medimny/id1560601461" target="_blank" rel="noreferrer">
                      <img src={ios} style={{ width: "55%" }} alt="ios" />
                    </a>
                  </li>
                  <li className="liststyle mt-2">
                    <a href="https://play.google.com/store/apps/details?id=com.medimny" target="_blank" rel="noreferrer">
                      <img src={android} style={{ width: "55%" }} alt="android" />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className="footText">
          <p className="">  &copy; Felicitas Digitech Private Limited 2021. All Rights Reserved.</p>
          <p>
            The trademarks, logos and service marks displayed on the Website (&quot;Marks&quot;) are the property of Felicitas
            Digitech Private Limited or their seller or respective pharmaceutical companies or third parties. You are not
            permitted to use the Marks without the prior consent of Felicitas Digitech Private Limited or their seller or
            respective pharmaceutical companies or third parties that may own the Marks.
          </p>
        </div>
      </div>
    </footer>
  );
}


export default Footer;
