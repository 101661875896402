import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
}

const RenderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
}) => {
    return <FormControl error={touched && error} variant="standard" className={`w-100 ${(label !== 'User Type' && label !== 'State') ? 'mt-3' : ''}`}>
        <InputLabel htmlFor={input.id}>{label}</InputLabel>
        <Select
            native
            {...input}
            {...custom}
            inputProps={{
                name: input.name,
                id: input.id,
            }}
        >
            {children}
        </Select>
        {renderFromHelper({ touched, error })}
    </FormControl>
}

export default RenderSelectField;