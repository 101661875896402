import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess } from "actions/Auth";
import {
  addToCart,
  getCartDetails,
} from 'actions/buyer'
import { getProductsForYou } from '../actions/buyer'
import { getMediWallet } from '../actions/buyer'
import { getBuyerNotification } from '../actions/notification';
import { getUserDetail } from 'actions'
import { getSidebar, apiFailed } from 'actions/seller'
import { NotificationManager } from 'react-notifications';
import AxiosRequest from './axiosRequest'

function* signInUserWithEmailPassword({ payload }) {
  const { navigate, user_type, location } = payload;
  try {
    let signInUser
    if (user_type === "seller") {
      signInUser = yield call(AxiosRequest.axiosHelperFunc, 'post', 'users/login', '', payload);
      if (signInUser && signInUser.data && signInUser.data.error === false && signInUser.data.detail) {
        let id = signInUser.data.detail.mainUser ? signInUser.data.detail.mainUser : signInUser.data.detail._id;
        localStorage.setItem('Id', id);
      }
    } else {
      signInUser = yield call(AxiosRequest.axiosBuyerHelperFunc, 'post', 'users/signin', '', payload);
    }
    if (signInUser && signInUser.data && signInUser.data.detail && signInUser.data.detail.user_type === 'admin') {
      NotificationManager.error('Invalid credentials');
    }
    if (signInUser && signInUser.data && signInUser.data.error === true) {
      if (signInUser.data.detail && !signInUser.data.detail.isVerified) {
        NotificationManager.success(signInUser.data.title)
        localStorage.setItem('verifyToken', signInUser.data.token);
        navigate('/verifyToken');
      } else {
        yield put(apiFailed(signInUser.data.title));
      }
    } else if (signInUser && signInUser.data && signInUser.data.isDocumentExpired !== undefined && signInUser.data.isDocumentExpired === true && signInUser.data.detail && signInUser.data.detail.user_type === 'seller') {
      if (signInUser.data.detail && !signInUser.data.detail.isVerified) {
        if (signInUser.data.detail.user_type === "seller") {
          localStorage.setItem('token', signInUser.data.token);
        }
        else {
          localStorage.setItem('buyer_token', signInUser.data.token);
        }
        localStorage.setItem('user_type', signInUser.data.detail.user_type);
        localStorage.setItem('isDocumentExpired', signInUser.data.isDocumentExpired);
        yield put(userSignInSuccess(signInUser.data));
        yield put(getSidebar({ navigate, location }))
      }
    } else {
      localStorage.setItem('isDocumentExpired', false);
      localStorage.setItem('user_type', signInUser && signInUser.data && signInUser.data.detail ? signInUser.data.detail.user_type : 'buyer')
      yield put(userSignInSuccess(signInUser && signInUser.data));
      if (signInUser && signInUser.data && signInUser.data.detail && signInUser.data.detail.user_type === 'buyer') {
        localStorage.setItem('buyer_token', signInUser.data.token);
        yield put(getUserDetail({ userType: 'buyer' }))
        yield put(getProductsForYou({ navigate }))
        yield put(getMediWallet({ navigate }))
        yield put(getBuyerNotification({ email: '', password: '', user_type: 'buyer' }))
        yield put(getCartDetails({ navigate }))
        let inventoryData = localStorage.getItem('obj');
        let data = JSON.parse(inventoryData);
        if (data !== null) {
          yield put(addToCart({ cart_details: data }))
          yield put(getCartDetails({ cart_details: data }))
        }
        yield put(userSignInSuccess(signInUser.data));
      }
      if (signInUser && signInUser.data && signInUser.data.detail && signInUser.data.detail.user_type === 'seller') {
        localStorage.setItem('token', signInUser.data.token);
        yield put(userSignInSuccess(signInUser.data));
        yield put(getSidebar({ navigate, location }))
      }
    }
  } catch (error) {
    NotificationManager.error(error && error.message ? error.message : 'Something went wrong, please try again!');
  }
}

function* signOut({ payload }) {
  let { navigate } = payload
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('forgotPassword');
    localStorage.removeItem('verifyToken');
    localStorage.removeItem('user_type');
    localStorage.removeItem('buyer_token');
    localStorage.removeItem('seller_token');
    localStorage.removeItem('asAdmin');
    // localStorage.clear();
    yield put(userSignOutSuccess(signOutUser));
    navigate('/signin')

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeLatest(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(signOutUser)]);
}