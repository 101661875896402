import React, { useState, useEffect } from "react";
import About from "components/profile/About";
import Contact from "components/profile/Contact";
import SalesStatistics from "./salesStatistics";
import ProfileHeader from "components/profile/ProfileHeader";
import helperFunction from 'constants/helperFunction';
import { getProductsBySeller, getCategories } from "actions/buyer";
import { connect } from 'react-redux'
import { getReviewList } from 'actions/buyer';
import { useParams, useLocation } from "react-router-dom";
import BuyerContainer from 'components/buyerContainer';

const ViewSeller = (props) => {
  const params = useParams()
  const location = useLocation()
  const [perPage, setPerPage] = useState(12)

  useEffect(() => {
    handleScroll()
    props.getProductsBySeller({ seller_id: params.id, page: 1, perPage: perPage })
    const type1 = localStorage.getItem("buyer_token");
    if (location.pathname.includes("/view-seller/") && params.id) {
      let data = {
        sellerId: params.id,
        sellerReviewList: true
      }
      if (type1) {
        props.getReviewList({ data })
      }
    } else {
      props.getReviewList({})
    }
  }, [])

  const handleScroll = () => {
    const { index, selected } = props
    if (index === selected) {
      const element = document.getElementById("sellerProducts")
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const seeMoreProducts = () => {
    setPerPage(Number(perPage) + 12)
  }

  const { productsBySeller, categories, reviewList } = props;
  const type = localStorage.getItem("buyer_token");

  return (
    <BuyerContainer>
      <div className="app-wrapper" id="sellerProducts">
        <ProfileHeader dataFromParent={productsBySeller} dataFromParent1={reviewList} />
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-12">
              <About
                showAddToCard={!location.pathname.includes('/view-company')}
                dataFromParent={productsBySeller}
                categoriesFromParent={categories}
                seeMoreProducts={seeMoreProducts}
                page={perPage}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-5 col-12 ">
              <div className="jr-card jr-full-card ">
                <div className={`jr-card-header d-flex align-items-center pb-0`}>
                  <div className="mr-auto">
                    <h2>Sales Statistic</h2>
                  </div>
                </div>
                <div className='row p-4 pl-4'>
                  <SalesStatistics />
                </div>
              </div>
              {type ?
                <div className="jr-card jr-full-card ">
                  <div className={`jr-card-header d-flex align-items-center reviewSellerCard`}>
                    <div className="mr-auto">
                      <h2>Reviews</h2>
                    </div>
                    <span style={{ textAlign: '-webkit-center' }}>
                      <span>
                        {
                          reviewList && reviewList.averageRating > 0 ? <i className={helperFunction.getRatingValueForBuyer(reviewList.averageRating)} set={'google'} style={{ fontSize: '25px' }} /> : ''
                        }
                      </span>
                      <br />
                      Average
                    </span>
                  </div>
                  <Contact />
                </div>
                :
                null}
            </div>
          </div>
        </div>
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { productsBySeller, categories, reviewList } = buyer
  return { productsBySeller, categories, reviewList }
};
export default connect(mapStateToProps, { getProductsBySeller, getCategories, getReviewList })(ViewSeller);
