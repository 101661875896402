import React, { useState } from 'react';
import { Row, Col } from 'reactstrap'
import Button from '@mui/material/Button';
import { FormGroup } from 'reactstrap'
import ReactStrapTextField from 'components/ReactStrapTextField';
import { Field, Form } from 'react-final-form'
import { required, validatePincode, validatePhone, emailField, specailChar } from './BEditValidation';
import { connect } from 'react-redux'
import AxiosRequest from 'sagas/axiosRequest'
import { NotificationManager } from 'react-notifications';
import AppConfig from 'constants/config'
import axios from 'axios'
import ReactStrapSelectField from 'components/reactstrapSelectField';
import { getUserDetail, logoutUser } from 'actions'

const EditProfile = (props) => {
  const [stateList, setStateList] = useState([])

  const onSave = async (values) => {
    let data = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      user_address: values.userAddress,
      user_city: values.userCity,
      user_state: values.userState,
      user_pincode: values.pincode
    }
    let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'users/updateProfile', '', data)
    if (response.data.error) {
      NotificationManager.error(response.data.message)
      props.logoutUser(response.data)
    } else {
      NotificationManager.success(response.data.message)
      props.getUserDetail({ userType: "buyer" })
    }
  }

  useState(() => {
    axios({
      method: 'get',
      url: `${AppConfig.baseUrl}admin/getStates`
    }).then((result) => {
      const { detail } = result.data
      setStateList(detail)
    })
  }, [])

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  const { first_name, last_name, email, phone, user_address, user_pincode, user_city, user_state } = props.userDataFromParentTwo;

  return (
    <div className='mt-4'>
      <Form
        onSubmit={onSave}
        initialValues={{
          firstName: first_name || '',
          lastName: last_name || '',
          email: email || '',
          phone: phone || '',
          userAddress: user_address || '',
          userCity: user_city || '',
          userState: user_state && user_state._id ? user_state._id : '',
          pincode: user_pincode || '',
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Row className='row justify-content-center '>
              <Col sm={12} md={12} lg={3} xs={12} xl={3}>
                <Field id="firstName"
                  name="firstName" type="text"
                  component={ReactStrapTextField}
                  validate={composeValidators(required, specailChar)}
                  label='First Name'
                />
              </Col>
              <Col sm={12} md={12} lg={3} xs={12} xl={3}>
                <Field id="lastName" name="lastName" type="text"
                  component={ReactStrapTextField}
                  validate={composeValidators(required, specailChar)}
                  label='Last Name'
                />
              </Col>
              <Col sm={12} md={12} lg={3} xs={12} xl={3}>
                <Field id="email" name="email" type="text"
                  component={ReactStrapTextField}
                  validate={emailField}
                  label='Email'
                />
              </Col>
              <Col sm={12} md={12} lg={3} xs={12} xl={3}>
                <Field id="phone" name="phone" type="text"
                  component={ReactStrapTextField}
                  validate={validatePhone}
                  label='Phone'
                />
              </Col>
            </Row>
            <Row className='row justify-content-center '>
              <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                <Field id="userAddress" name="userAddress" type="text"
                  component={ReactStrapTextField}
                  validate={required}
                  label='Address'
                />
              </Col>
            </Row>
            <Row className='row justify-content-center '>
              <Col sm={12} md={12} lg={4} xs={12} xl={4}>
                <Field id="userCity" name="userCity" type="text"
                  component={ReactStrapTextField}
                  validate={composeValidators(required, specailChar)}
                  label='City'
                />
              </Col>
              <Col sm={12} md={12} lg={4} xs={12} xl={4}>
                <Field id="userState" name="userState" type="select"
                  component={ReactStrapSelectField}
                  validate={required}
                  label='State'
                >
                  <option value={''}></option>
                  {
                    stateList && stateList.map((val) => {
                      return (<option value={val._id}>{val.name}</option>)
                    })
                  }
                </Field>
              </Col>
              <Col sm={12} md={12} lg={4} xs={12} xl={4}>
                <Field id="pincode" name="pincode" type="text"
                  component={ReactStrapTextField}
                  validate={validatePincode}
                  label='Pincode'
                />
              </Col>
            </Row>
            <Row className='row justify-content-center mt-2'>
              <FormGroup className={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10 buyerEditProfile'}>
              </FormGroup>
              <FormGroup className={'col-sm-2 col-lg-2 col-md-2 col-xs-2 col-xl-2 buyerEditProfile'}>
                <Button type='submit'
                  color="primary" variant="contained" className="jr-btn jr-btn-lg btnPrimary" >
                  Save
                </Button>
              </FormGroup>
            </Row>
          </form>
        )}
      />
    </div>
  );
}

const mapStateToProps = ({ seller }) => {
  return { seller }
}

export default connect(mapStateToProps, { getUserDetail, logoutUser })(EditProfile)