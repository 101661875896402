import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import moment from 'moment';
import { getOrderHistory } from 'actions/order'
import { connect } from 'react-redux';
import AppConfig from 'constants/config'
import { Label, Input, FormGroup } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Tooltip from '@mui/material/Tooltip';
import helper from 'constants/helperFunction';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const OrderHistory = (props) => {
  const [params] = useSearchParams()
  let navigate = useNavigate();
  const tab = params && params.get("tab") ? Number(params.get("tab")) : 0
  const perPage = 100
  const searchedText = params && params.get("searchOH") ? params.get("searchOH") : ''
  const page = params && Number(params.get("pageOH")) ? Number(params.get("pageOH")) : 0
  const [from, setFrom] = useState(params && params.get("from_dateOH") ? moment(params.get("from_dateOH")) : moment().subtract(1, 'months'))
  const [to, setTo] = useState(params && params.get("to_dateOH") ? moment(params.get("to_dateOH")) : moment())
  const [status, setStatus] = useState(params && params.get("statusOH") ? params.get("statusOH") : '')
  const [type, setType] = useState(params && params.get("typeOH") ? params.get("typeOH") : '')

  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("Ready for dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-danger" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("RTO after Delivery") ? "text-white bg-brown" : status.includes("LOST") ? "text-white bg-black" : status.includes("RTO") ? "text-white bg-cyan" : "text-white bg-danger";
  }

  const button = (e) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <a target='blank' download href={`${AppConfig.baseUrl}invoice/${e}`}>
        <Button variant="outlined" color="primary">
          Invoice
        </Button>
      </a>
    </ButtonGroup>
  )

  useEffect(() => {
    let data = {
      page: (params && Number(params.get("pageOH")) ? Number(params.get("pageOH")) : 0) + 1,
      perPage,
      filter: '',
      searchText: params && params.get("searchOH") ? params.get("searchOH") : '',
      from_date: moment(params && params.get("from_dateOH") ? params.get("from_dateOH") : moment().subtract(1, 'months')).format('YYYY-MM-DD'),
      to_date: moment(params && params.get("to_dateOH") ? params.get("to_dateOH") : moment()).add(1, 'days').format('YYYY-MM-DD'),
      status: (params && params.get("statusOH") ? params.get("statusOH") : '').split(','),
      type: params && params.get("typeOH") ? params.get("typeOH") : '',
      tab: 'orderHistory'
    }
    if (tab === 1) {
      props.getOrderHistory({ data })
    }
  }, [params])

  useEffect(() => {
    if (props.tabValue === 1) {
      let obj = { from_dateOH: from, to_dateOH: to, pageOH: page, searchOH: searchedText, statusOH: status, typeOH: type, tab: 1 }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      navigate(`/seller/orders?${url}`, { replace: true })
    }
  }, [props.tabValue])

  const changePage = (page) => {
    let obj = { from_dateOH: from, to_dateOH: to, pageOH: page, searchOH: searchedText, statusOH: status, typeOH: type, tab: 1 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  };

  const handleSearch = (searchText) => {
    let obj = { from_dateOH: from, to_dateOH: to, pageOH: 0, searchOH: searchText, statusOH: status, typeOH: type, tab: 1 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  };

  const handleCloseSearch = () => {
    let obj = { from_dateOH: from, to_dateOH: to, pageOH: 0, searchOH: '', statusOH: status, typeOH: type, tab: 1 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const resetFilter = () => {
    let obj = { from_dateOH: '', to_dateOH: '', pageOH: 0, searchOH: searchedText, statusOH: '', typeOH: '', tab: 1 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const handleClick = (orderId) => {
    navigate(`/seller/orders/orderDetails/${orderId}`)
  }

  const handleDateChange = (date, key) => {
    if (key === "from") {
      setFrom(date)
    }
    if (key === "to") {
      setTo(date)
    }
  }

  const applyFilter = (filter) => {
    let obj = { from_dateOH: from, to_dateOH: to, pageOH: 0, searchOH: searchedText, statusOH: status, typeOH: type, tab: 1, key: Math.random() * 10 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  let { orderHistoryList, loading } = props;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: orderHistoryList && orderHistoryList.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderId",
      label: "Order",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, applyFilter, index, column) => (
            <React.Fragment >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'from')}
                        name="date"
                        label="FROM"
                        value={from}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'to')}
                        name="date"
                        label="TO"
                        value={to}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormGroup className={'mt-1'}>
                    <Label for="exampleSelect">Status</Label>
                    <Input type="select" value={status} onChange={(e) => setStatus(e.target.value)} name="status" id="status">
                      <option value="" >Select Status</option>
                      <option value="Cancelled" >Cancelled</option>
                      <option value="Delivered" >Delivered</option>
                    </Input>
                  </FormGroup>
                </Grid>
                <Grid item md={6}>
                  <FormGroup className={'mt-1'}>
                    <Label for="exampleSelect">Type</Label>
                    <Input type="select" value={type} onChange={(e) => setType(e.target.value)} name="type" id="type">
                      <option value="" >Select Type</option>
                      <option value="Bulk" >Bulk Prepaid</option>
                      <option value="COD" >COD</option>
                      <option value="Online" >Prepaid</option>
                    </Input>
                  </FormGroup>
                </Grid>
              </Grid>
            </React.Fragment>
          ),
        },
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  let data = []
  orderHistoryList && orderHistoryList.detail && orderHistoryList.detail.length > 0 && orderHistoryList.detail.map((data1, index) => {
    const ShortExp = data1.products.filter(data => data.expiry_status === 'Short Expiry') 
    data.push([
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        {ShortExp.length > 0 && <Tooltip title={`Short expiry`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine('Short Expiry')}`}>&nbsp;&nbsp;</span>
        </Tooltip> }
        {data1.orderType ? <Tooltip title={`${data1.orderType}`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine(data1.orderType)}`}>&nbsp;&nbsp;</span>
        </Tooltip> : <span style={helper.styleForDiv}>&nbsp;&nbsp;</span>}
        {data1.serviceName ? <Tooltip title={`${data1.serviceName}`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine2(data1.serviceName)}`}>&nbsp;&nbsp;</span>
        </Tooltip> :
          <span style={helper.styleForDiv}>&nbsp;&nbsp;</span>
        }
      </span>,
      <div onClick={(e) => handleClick(data1.order_id)} style={{ cursor: 'pointer' }}>
        <h4><span className="font-weight-bolder">{data1.products.length} {data1.products.length > 1 ? 'items' : 'item'} by </span>{data1.user_id.company_name} </h4>
        <p className="small">{data1.order_id}{data1.seller_invoice ? ` | ${data1.seller_invoice}` : ''} | {data1.isBulk ? 'Bulk Prepaid' : data1.paymentType === 'Online' ? 'Prepaid' : data1.paymentType}</p>
      </div>,
      <div onClick={(e) => handleClick(data1.order_id)} style={{ cursor: 'pointer' }}><p className={'m-0'}>&#8377;{(data1.total_amount).toFixed(2)}</p></div>,
      <div onClick={(e) => handleClick(data1.order_id)} style={{ cursor: 'pointer' }} key={'recent'} className={` badge text-uppercase ${statusStyle(data1.order_status[data1.order_status.length - 1].status)}`}>{data1.order_status[data1.order_status.length - 1].status}</div>,
      <div onClick={(e) => handleClick(data1.order_id)} style={{ cursor: 'pointer' }}>{moment(data1.order_status[data1.order_status.length - 1].date).format(' D MMM, YYYY h:mm a')}</div>,
      data1.order_status[data1.order_status.length - 1].status === 'Cancelled' ? data1.order_cancel_reason : button(data1.uploadedInvoice)
    ])
  })

  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {
        loading ?
          <div className="loader-view" style={{ height: 'calc(100vh - 238px)' }}>
            <CircularProgress />
          </div>
          :
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
          </ThemeProvider>
      }
    </div>
  );
}

const mapStateToProps = ({ order }) => {
  const { orderHistoryList, loading } = order;
  return { orderHistoryList, loading }
};

export default connect(mapStateToProps, { getOrderHistory })(OrderHistory);