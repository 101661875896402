import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomFilter from 'components/Filter/index';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Popover } from 'reactstrap';
import moment from 'moment';
import './index.css'
import { connect } from 'react-redux';
import { getProductRequestList } from 'actions/buyer';

const EnhancedTableToolbar = (props) => {
    const { identifier } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [month, setMonth] = useState(moment().format("MMMM"));
    const [year, setYear] = useState(moment().format("GGGG"));
    const [searchText, setSearchText] = useState('');
    const [hide, setHide] = useState(true);

    const toggle = () => {
        setPopoverOpen(!popoverOpen)
        setHide(true)
        setSearchText('')
    }

    const handleResetFilter = (e, filter) => {
        e.preventDefault();
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
        setSearchText('')
        let data = {
            page: 1,
            perPage: 50,
            tab: identifier === "productRequestHistory" ? 'history' : 'open',
            month: moment().format("MMMM"),
            year: moment().format("GGGG"),
        }
        props.getProductRequestList({ data })
        toggle()
    }

    useEffect(() => {
        let data = {
            page: 1,
            perPage: 50,
            tab: identifier === "productRequestHistory" ? 'history' : 'open',
            month: moment().format("MMMM"),
            year: moment().format("GGGG"),
        }
        props.getProductRequestList({ data })
    }, [])


    const onFilterChange = (e, key) => {
        if (key === "month") {
            setMonth(e.target.value)
        } else {
            setYear(e.target.value)
        }
    }

    const applyFilter = () => {
        toggle();
        let data = {
            page: 1,
            perPage: 50,
            tab: identifier === "productRequestHistory" ? 'history' : 'open',
            month: month,
            year: parseInt(year) ? parseInt(year) : ''
        }
        props.getProductRequestList({ data })
    }

    const handleTextChange = (e) => {
        setSearchText(e.target.value)
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
    }

    const clearSearch = () => {
        setSearchText('')
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
        setHide(true)
        let data = {
            page: 1,
            perPage: 50,
            searchText: '',
            tab: identifier === "productRequestHistory" ? 'history' : 'open',
            month: moment().format("MMMM"),
            year: moment().format("GGGG")
        }
        props.getProductRequestList({ data })
    }

    const applySearch = () => {
        let data = {
            page: 1,
            perPage: 50,
            tab: identifier === "productRequestHistory" ? 'history' : 'open',
            searchText: searchText
        }
        props.getProductRequestList({ data })
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            applySearch()
        }
    }

    return (
        <Toolbar>
            <React.Fragment>
                {hide ? <h3 style={{ marginRight: "0px", marginLeft: "-8px" }}>Showing Product Requests {identifier === "productRequestHistory" ? "History" : ""} for {month} {year}</h3> :
                    <div style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "0px", width: "80%" }}>
                        <TextField
                            className='search'
                            autoFocus={true}
                            fullWidth={true}
                            hidden={hide}
                            value={searchText}
                            onChange={handleTextChange}
                            placeholder='Search Product'
                            onKeyUp={handleKeyPress}
                            variant="standard"
                        />
                    </div>
                }
                <Grow appear in={true} timeout={300}>
                    <div className='searchBox' style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "auto" }}>
                        <IconButton className='clear'>
                            {hide ? <SearchIcon onClick={() => { setHide(false) }} /> : <ClearIcon onClick={clearSearch} />}
                        </IconButton>
                    </div>
                </Grow>
                <div style={{ marginTop: "-10px" }}>
                    <IconButton aria-label="filter list">
                        <FilterListIcon
                            onClick={toggle}
                            id="filter"
                        />
                    </IconButton>
                </div>
                <Popover
                    trigger="legacy" className="SignInPopOver" placement="bottom" isOpen={popoverOpen} target="filter" toggle={toggle}
                >
                    <h5 className="font-weight-bold" style={{ color: '#000000de' }}>FILTERS</h5>
                    <CustomFilter
                        onFilterChange={onFilterChange}
                        handleResetFilter={handleResetFilter}
                        month={month}
                        applyFilter={applyFilter}
                        year={year}
                        filterFor={identifier === "productRequestHistory" ? 'buyerProductRequestHistory' : "buyerOpenProductRequest"}
                    />
                </Popover>
            </React.Fragment>
        </Toolbar>
    );
}

const mapStateToProps = ({ buyer }) => {
    const { productRequestList } = buyer;
    return { productRequestList }
};

export default connect(mapStateToProps, { getProductRequestList })(EnhancedTableToolbar);