import React from "react";
import FullWidthTabs from 'components/tabs/FullWidthTabsForTicket';
import TicketHeader from './TicketHeader'
import SellerContainer from "components/sellerContainer"

const Tickets = (props) => {
    return (
        <SellerContainer>
            <TicketHeader />
            <FullWidthTabs />
        </SellerContainer>
    );
}

export default Tickets;