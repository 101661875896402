import React from "react";
import FullWidthTabsForBulk from 'components/tabs/FullWidthTabsForBulk';
import BulkHeader from './BulkHeader';
import SellerContainer from "components/sellerContainer"

const BulkTab = (props) => {
  return (
    <SellerContainer>
      <BulkHeader />
      <FullWidthTabsForBulk />
    </SellerContainer>
  );
}

export default BulkTab;