import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { getOrderDetails, getOrder } from 'actions/order';
import { FormGroup } from 'reactstrap';
import moment from 'moment';
import ReactStrapTextField from 'components/ReactStrapTextField';
import ReactstrapSelectField from 'components/reactstrapSelectField';
import { Field, Form } from 'react-final-form'
import { required, invoiceValue, invoiceValueOnline } from 'constants/validations';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import InvoiceValidate from '../orders/InvoiceValidate';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';

const ChangeStatus = (props) => {
  const params = useParams()
  const [open, setOpen] = useState(false)
  const [set, setSet] = useState(false)

  useEffect(() => {
    props.getOrderDetails({ data: { orderId: params.id } })
  }, [])

  const handleRequestClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    setSet(true)
    let data = {
      invoice: Number(values.invoice),
      weight: values.weight,
      invoiceNumber: values.invoiceNumber,
      selectedDate: moment().format(),
      orderId: props.orderId !== undefined ? props.orderId : params.id,
      status: 'Processed',
      length: values.length,
      breadth: values.breadth,
      height: values.height,
      uploadedInvoice: values.uploadedInvoice
    }
    await axios.post('/order/proccessCancelOrder', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
        setSet(false)
      } else {
        NotificationManager.success(result.data.title);
        props.getOrderDetails({ data: { orderId: props.orderId ? props.orderId : params.id } })
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
    setOpen(false);
  }

  let { status, orderDetails } = props;

  return (
    <div>
      <Button className={'jr-btn-xs'} variant="contained" disabled={((orderDetails && orderDetails.user_id && orderDetails.user_id.user_status !== 'active') || ((status === 'Requested' || status === 'Cancelled' || status === "Processed")) || (status === 'Delivered') || (status === 'LOST') || (status === 'RTO') || (status === 'RTO after Delivery') ? true : false)} color="primary" onClick={() => setOpen(true)}> Process Order</Button>
      <Dialog open={open} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            length: orderDetails.length ? orderDetails.length : 25,
            breadth: orderDetails.breadth ? orderDetails.breadth : 25,
            height: orderDetails.height ? orderDetails.height : 25,
            invoice: orderDetails.total_amount,
            invoice2: orderDetails.total_amount,
            uploadedInvoice: orderDetails.uploadedInvoice,
            check1: false,
            check2: false,
            check3: false,
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                Process Order
                <DialogContentText className="mt-3">
                </DialogContentText>
              </DialogTitle>
              <DialogContent>
                <React.Fragment>
                  <FormGroup>
                    <Field id="invoice2" name="invoice2" type="text" hide={true}
                      component={ReactStrapTextField} label={"Value of invoice ( 10% modification allowed )"}
                    />
                    {orderDetails && orderDetails.paymentType === 'COD' ?
                      <Field id="invoice" name="invoice" type="text"
                        component={ReactStrapTextField} label={"Value of invoice ( 10% modification allowed )"}
                        validate={invoiceValue}
                      /> :
                      <Field id="invoice" name="invoice" type="text"
                        component={ReactStrapTextField} label={"Value of invoice ( 10% modification allowed )"}
                        validate={invoiceValueOnline}
                      />
                    }
                  </FormGroup>
                  <FormGroup>
                    <Field id="invoiceNumber" name="invoiceNumber" type="text"
                      component={ReactStrapTextField} label="Invoice Number"
                      validate={required}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field id="length" name="length" type="text"
                      component={ReactStrapTextField} label="Length (cm)"
                      validate={required}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field id="breadth" name="breadth" type="text"
                      component={ReactStrapTextField} label="Breadth (cm)"
                      validate={required}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field id="height" name="height" type="text"
                      component={ReactStrapTextField} label="Height (cm)"
                      validate={required}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="weight"
                      component={ReactstrapSelectField}
                      label="Weight"
                      validate={required}
                      type="select"
                    >
                      <option value=" ">Select weight</option>
                      <option value='0.5'>0.5 kg</option>
                      <option value='1'>1.0 kg</option>
                      <option value='1.5'>1.5 kg</option>
                      <option value='2'>2.0 kg</option>
                      <option value='2.5'>2.5 kg</option>
                      <option value='3'>3.0 kg</option>
                      <option value='3.5'>3.5 kg</option>
                      <option value='4'>4.0 kg</option>
                    </Field>
                  </FormGroup>
                  {
                    orderDetails && orderDetails.orderType && orderDetails.orderType.toLowerCase() === 'cool chain' ?
                      <>
                        <FormGroup className='mb-0'>
                          <Checkbox color="primary" checked={values.check1} onClick={(e) => form.change("check1", !values.check1)} />
                          I confirm that I have used Thermocol box for packing this order.
                        </FormGroup>
                        <FormGroup className='mb-0'>
                          <Checkbox checked={values.check2} color="primary" onChange={(e) => form.change("check2", !values.check2)} />
                          I confirm that I have put 3 frozen chillpacks in this order.
                        </FormGroup>
                        <FormGroup className='mb-0'>
                          <Checkbox checked={values.check3} color="primary" onChange={(e) => form.change("check3", !values.check3)} />
                          I undertake full responsibility for RTO due to non compliance.
                        </FormGroup>
                      </> : ''
                  }
                  <FormGroup>
                    <Field
                      name="uploadedInvoice"
                      component={InvoiceValidate}
                      validate={required}
                      label="UploadInvoice"
                    />
                  </FormGroup>
                </React.Fragment>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                {
                  orderDetails && orderDetails.orderType && orderDetails.orderType.toLowerCase() === 'cool chain' && !set && values.check1 && values.check2 && values.check3 ?
                    <Button type="submit" color='primary'>
                      Process
                    </Button> :
                    ((orderDetails && orderDetails.orderType === '') || (orderDetails && orderDetails.orderType === undefined) || (orderDetails && orderDetails.orderType.toLowerCase() !== 'cool chain')) && !set ?
                      <Button type="submit" color='primary'>
                        Process
                      </Button> :
                      set ? <Button color='primary'>
                        Processing...
                      </Button> :
                        <Button color='primary' disabled={true}>
                          Process
                        </Button>
                }
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ order }) => {
  const { orderDetails } = order;
  return { orderDetails }
};

export default connect(mapStateToProps, { getOrderDetails, getOrder })(ChangeStatus)