import React, { useState, useEffect } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import ManufacturersPopOver from './ManufacturersPopOver'
import CategoryPopOver from './CategoryPopOver'
import { Popover } from 'reactstrap';
import './index.css';
import { connect } from 'react-redux';
import { getSearchProduct } from 'actions/buyer';
import { Helmet } from "react-helmet";

const PinnedSubheaderList = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [checked, setChecked] = useState([0])
  const [obj, setObj] = useState({})
  const [popoverOpenCompanies, setPopoverOpenCompanies] = useState(false)
  const [popoverOpenCategories, setPopoverOpenCategories] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectCat, setSelectCat] = useState('')

  function toggleCompaniesPopOver() {
    setPopoverOpenCompanies(!popoverOpenCompanies);
  }

  function toggleCategoryPopOver() {
    setPopoverOpenCategories(!popoverOpenCategories);
  }

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleClick = (key, e) => {
    if (e !== '') {
      const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
      let newObj = { ...obj }
      newObj.search = decodedURL.search ? decodedURL.search : ''
      newObj.sortBy = decodedURL.sortBy ? decodedURL.sortBy : 'price'
      newObj.category_id = decodedURL.category_id ? decodedURL.category_id.split(',') : []
      newObj[key] = e;
      newObj.medicineType = decodedURL.medicineType ? decodedURL.medicineType : ''
      let url = Object.keys(newObj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(newObj[k])}`).join('&');
      setObj(newObj)
      let catName = selectCat ? ((selectCat).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase() : 'category'
      navigate({ pathname: `/search-results/${catName && catName !== '' ? catName : 'category'}/${url}`, state: 'searchPage' })
      const data = {
        key: newObj.search ? newObj.search : '',
        company_id: newObj.company_id ? newObj.company_id : '',
        category_id: newObj.category_id ? newObj.category_id : '',
        seller_id: newObj.seller_id ? newObj.seller_id : '',
        mediType: newObj.medicineType ? newObj.medicineType : '',
        discount: newObj.discount && newObj.discount === '10 or more' ? 10 : newObj.discount === '20 or more' ? 20 : newObj.discount === '50 or more' ? 50 : '',
        page: 1,
        perPage: 12,
        sortBy: newObj.sortBy
      }
      props.getSearchProduct({ data })
    }
  }

  const setInitData = async () => {
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    let { filterFromParent } = props;
    let cateogoryData = await filterFromParent && filterFromParent.detail && filterFromParent.detail.categories.filter((e) => e._id === decodedURL.category_id);
    let catData = cateogoryData && cateogoryData.length > 0 ? cateogoryData[0].name : ''
    await setSelectCat(catData)
    let newObj = { ...obj }
    newObj.search = decodedURL.search ? decodedURL.search : ''
    newObj.company_id = decodedURL.company_id ? decodedURL.company_id : ''
    newObj.category_id = decodedURL.category_id ? decodedURL.category_id.split(',') : ''
    newObj.seller_id = decodedURL.seller_id ? decodedURL.seller_id : ''
    newObj.sortBy = decodedURL.sortBy ? decodedURL.sortBy : 'price'
    newObj.medicineType = decodedURL.medicineType ? decodedURL.medicineType : ''
    newObj.discount = decodedURL.discount ? decodedURL.discount : ''
    setObj(newObj)
    setCategories(decodedURL.category_id ? decodedURL.category_id.split(',') : [])
  }

  useEffect(() => {
    setInitData()
  }, [])

  const reset = () => {
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    let newObj = { search: decodedURL.search ? decodedURL.search : '', sortBy: decodedURL.sortBy, medicineType: decodedURL.medicineType ? decodedURL.medicineType : '' }
    let url = Object.keys(newObj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(newObj[k])}`).join('&');
    navigate({ pathname: `/search-results/category/${url}`, state: 'searchPage' })
    const data = {
      key: decodedURL.search ? decodedURL.search : '',
      company_id: '',
      category_id: '',
      seller_id: '',
      page: 1,
      perPage: 12,
      sortBy: decodedURL.sortBy ? decodedURL.sortBy : 'price',
      mediType: decodedURL.medicineType ? decodedURL.medicineType : ''
    }
    props.getSearchProduct({ data })
    setChecked([0])
    setCategories([])
    setObj({ search: decodedURL.search, company_id: '', category_id: '', seller_id: '', medicineType: decodedURL.medicineType ? decodedURL.medicineType : '', discount: '' })
  }

  const handleCategories = async (key, e) => {
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    let newObj = { ...obj }
    newObj.search = decodedURL.search ? decodedURL.search : ''
    newObj[key] = e;
    newObj.sortBy = decodedURL.sortBy ? decodedURL.sortBy : 'price'
    newObj.medicineType = decodedURL.medicineType ? decodedURL.medicineType : ''
    newObj.discount = decodedURL.discount ? decodedURL.discount : ''
    let temp = [...categories]
    if (e.target.checked) {
      temp.push(e.target.value)
      setCategories(temp)
    } else {
      temp.splice(getChecked(e.target.value), 1)
      setCategories(temp)
    }
    let { filterFromParent } = props;
    let cateogoryData = await filterFromParent && filterFromParent.detail && filterFromParent.detail.categories.filter((e) => e._id === categories[0]);
    await setSelectCat(cateogoryData && cateogoryData.length > 0 ? cateogoryData[0].name : '')
    const data = {
      key: newObj.search ? newObj.search : '',
      company_id: newObj.company_id ? newObj.company_id : '',
      category_id: temp,
      seller_id: newObj.seller_id ? newObj.seller_id : '',
      mediType: newObj.medicineType ? newObj.medicineType : '',
      discount: newObj.discount && newObj.discount === '10 or more' ? 10 : newObj.discount === '20 or more' ? 20 : newObj.discount === '50 or more' ? 50 : '',
      page: 1,
      perPage: 12,
      sortBy: newObj.sortBy
    }
    if (decodedURL.search) {
      newObj.search = decodedURL.search
    }
    newObj.category_id = temp.length > 0 ? temp.toString() : ''
    let url = Object.keys(newObj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(newObj[k])}`).join('&');
    setObj(newObj)
    let catName = selectCat && ((selectCat).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()
    navigate({ pathname: `/search-results/${catName && catName !== '' ? catName : 'category'}/${url}`, state: 'searchPage' })
    props.getSearchProduct({ data })
  }

  const getChecked = (data) => {
    let index = categories.findIndex((val) => val === data)
    return index
  }

  const { filterFromParent } = props;
  return (
    <List className="" subheader={<div />}>
      {selectCat !== 'category' ? <Helmet>
        <title>Buy {selectCat} Medication Online In India - Medimny</title>
        <meta name="title" content={`Buy ${selectCat} Medication Online In India - Medimny`} />
        <meta name="description" content={`Medimny is a B2B E-Commerce platform for Pharmaceutical Products for Distributors, Stockists and Retail Drug Stores serving entire nation. Buy ${selectCat} Medication Online In India.`} />
      </Helmet> : ''
      }
      <button className="mt-3  mb-3 ml-2"
        style={{
          backgroundColor: "white",
          color: '#f3554c', borderRadius: '5px', border: 'none'
        }} onClick={reset} >
        Clear Filters
      </button>

      {/* //------------------------------------------- DISCOUNT ------------------------------------------------------------// */}

      <ListSubheader className="text-muted bg-grey lighten-4">{'Discount'}</ListSubheader>
      <ListItem className="listItemName" button key={'10 or more'} onClick={event => handleToggle('10 or more')} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Radio color="primary"
          checked={obj.discount === '10 or more'}
          onClick={(e) => {
            handleClick('discount', '10 or more');
          }}
          value="a" name="radio button demo"
          aria-label="A" />
        <ListItemText className="" primary={"10% OFF OR MORE"} />
      </ListItem>
      <ListItem className="listItemName" button key={'20 or more'} onClick={event => handleToggle('20 or more')} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Radio color="primary"
          checked={obj.discount === '20 or more'}
          onClick={(e) => {
            handleClick('discount', '20 or more');
          }}
          value="a" name="radio button demo"
          aria-label="A" />
        <ListItemText className="" primary={"20% OFF OR MORE"} />
      </ListItem>
      <ListItem className="listItemName" button key={'50 or more'} onClick={event => handleToggle('50 or more')} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Radio color="primary"
          checked={obj.discount === '50 or more'}
          onClick={(e) => {
            handleClick('discount', '50 or more');
          }}
          value="a" name="radio button demo"
          aria-label="A" />
        <ListItemText className="listItemName" primary={"50% OFF OR MORE"} />
      </ListItem>

      {/* //------------------------------------------- CATEGORY ------------------------------------------------------------// */}

      <ListSubheader className="text-muted bg-grey lighten-4">Categories</ListSubheader>
      {filterFromParent && filterFromParent.detail && filterFromParent.detail.categories.slice(0, 5).map((listItem) => (
        <div key={listItem._id}>
          <ListItem className="listItemName" button key={listItem._id} onClick={event => handleToggle(listItem._id)} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Checkbox
              onClick={(e) => {
                handleCategories('category_id', e);
              }}
              value={listItem._id}
              color="primary"
              checked={getChecked(listItem._id) > -1 ? true : false}
              tabIndex="-1"
            />
            <ListItemText className="" primary={listItem.name} />
          </ListItem>
        </div>
      ))}
      <div className="ml-4 mb-3">
        <button className="moreButton" id="Categories" onClick={toggleCategoryPopOver}>
          More
        </button>
        <Popover trigger="legacy" className="manufacturersPopOver" placement="right" isOpen={popoverOpenCategories} target="Categories" toggle={toggleCategoryPopOver}>
          <i class="zmdi zmdi-close zmdi-hc-2x" style={{ float: "right", cursor: "pointer", zIndex: 1, }} onClick={toggleCategoryPopOver}></i>
          <CategoryPopOver
            handleCategories={handleCategories}
            getChecked={getChecked}
            category_id={obj.category_id}
            showAddToCard={!location.pathname.includes('/view-company')}
            dataFromParent={filterFromParent && filterFromParent.detail && filterFromParent.detail.categories ? filterFromParent.detail.categories : []}
          />
        </Popover>
      </div>

      {/* //------------------------------------------- COMAPNY ------------------------------------------------------------// */}

      <ListSubheader className="text-muted bg-grey lighten-4">Companies</ListSubheader>
      {filterFromParent && filterFromParent.detail && filterFromParent.detail.comapnies.slice(0, 5).map((listItem) =>
      (<div key={listItem._id}>
        <ListItem className="listItemName" button key={listItem._id} onClick={event => handleToggle(listItem._id)} style={{ paddingTop: "0px", paddingBottom: "0px", fontSize: "10px" }}>
          <Radio color="primary"
            checked={obj.company_id === listItem._id}
            onClick={(e) => {
              handleClick('company_id', listItem._id);
            }}
            value="a" name="radio button demo"
            aria-label="A"
          />
          <ListItemText primary={listItem.name} />
        </ListItem>
      </div>
      ))}

      <div className="ml-4 mb-3">
        <button className="moreButton" id="Companies" onClick={toggleCompaniesPopOver}>
          More
        </button>
        <Popover trigger="legacy" className="manufacturersPopOver" placement="right" isOpen={popoverOpenCompanies} target="Companies" toggle={toggleCompaniesPopOver}>
          <i class="zmdi zmdi-close zmdi-hc-2x" style={{ float: "right", cursor: "pointer", zIndex: 1, }} onClick={toggleCompaniesPopOver}></i>
          <ManufacturersPopOver
            handleClick={handleClick}
            company_id={obj.company_id}
            showAddToCard={!location.pathname.includes('/view-company')}
            dataFromParent={filterFromParent && filterFromParent.detail && filterFromParent.detail.comapnies ? filterFromParent.detail.comapnies : []}
          />
        </Popover>
      </div>
    </List>
  );
}


const mapStateToProps = ({ buyer }) => {
  const { searchProduct } = buyer;
  return { searchProduct }
};

export default connect(mapStateToProps, { getSearchProduct })(PinnedSubheaderList);