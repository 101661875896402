import React, { useState, useEffect } from 'react';
import { getNotification } from 'actions/notification';
import { connect } from 'react-redux';
import axios from 'constants/axios'
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment"
import { useLocation } from 'react-router-dom';
import AppConfig from 'constants/config'
import BuyerContainer from 'components/buyerContainer';
import ContainerHeader from 'components/ContainerHeader';


const BuyerNotification = (props) => {
  const location = useLocation()
  const perPage = 20
  const [fetch, setFetch] = useState(true)
  const [page, setPage] = useState(1)
  const [items, setItems] = useState('')
  const [dataL, setDataL] = useState(10)

  const getData = async () => {
    let user = location.pathname.includes('seller') ? 'seller' : 'buyer';
    let data = {
      page: page,
      perPage: 20,
      user_type: user
    }
    let response = await axios.post(`${AppConfig.baseUrl}users/getNotification`, data, {
      headers: {
        token: user === "buyer" ? localStorage.getItem('buyer_token') : localStorage.getItem('token')
      }
    })
    if (response.data.error) {
    } else {
      setItems(response.data.detail);
    }

    await axios.post('/users/markNotificationRead', {}, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
      } else {
      }
    }).catch(error => {
    });
  }

  useEffect(() => {
    getData()
  }, [])

  const fetchData1 = async () => {
    let user = location.pathname.includes('seller') ? 'seller' : 'buyer';
    let { total } = props.notiData;
    if (props.notificationData.length >= total) {
      setFetch(false);
      return;
    }
    let data = {
      page: page,
      perPage: perPage,
      user_type: user
    }
    let response = await axios.post(`${AppConfig.baseUrl}users/getNotification`, data, {
      headers: {
        token: user === "buyer" ? localStorage.getItem('buyer_token') : localStorage.getItem('token')
      }
    })
    if (response.data.error) {
    } else {
      setItems(items.concat(response.data.detail));
      setDataL(dataL + 10);
      setPage(page + 1);
    }
  };

  let { notiData } = props;
  let outOfPage = notiData.total > 0 ? page > Math.round(notiData.total / perPage) : true;

  return (
    <BuyerContainer>
      <div className="app-wrapper">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <ContainerHeader match={props.match} title="Notifications" />
          </div>
        </div>
        <div className='jr-card' id='scrollableDiv1' style={{ height: 450, overflow: 'auto', display: 'flex' }}>
          <InfiniteScroll
            dataLength={dataL}
            next={fetchData1}
            hasMore={fetch}
            loader={!outOfPage && <p style={{ textAlign: 'center', width: '100%' }}>
              <CircularProgress size={40} />
            </p>}
            scrollableTarget="scrollableDiv1"
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>}>
            {items && items.length > 0 ? items.map((data, index) => (
              <div key={index} className={`user-list d-flex flex-row card-strip`}>
                <div className="description">
                  <p className="text-muted">{data.message}</p>
                  <ul className="list-inline d-sm-flex flex-sm-row ">
                    <li style={{ fontSize: 13 }}>{moment(data.createdAt).format('MMM Do YYYY, hh:mm A')}</li>
                  </ul>
                </div>
              </div>
            )) : <p>No Notifications found</p>}
          </InfiniteScroll>
        </div >
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ notification }) => {
  const { notificationData, notiData } = notification;
  return { notificationData, notiData }
};

export default connect(mapStateToProps, { getNotification })(BuyerNotification);