import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CustomFilter from 'components/Filter/index';
import { Popover } from 'reactstrap';
import moment from 'moment';
import './index.css'
import { getOrderList } from 'actions/buyer';
import { connect } from 'react-redux';

const EnhancedTableToolbar = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [month, setMonth] = useState(moment().format("MMMM"))
    const [year, setYear] = useState(moment().format("GGGG"))
    const [show, setShow] = useState(false)
    const [hide, setHide] = useState(true)
    const [searchText, setSearchText] = useState()

    const toggle = () => {
        setPopoverOpen(!popoverOpen)
        setHide(true)
        setSearchText('')
    }

    const handleResetFilter = (e, filter) => {
        e.preventDefault();
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
        setSearchText('')
        setShow(true)
        let data = {
            page: 1,
            perPage: 50,
            month: moment().format("MMMM"),
            year: moment().format("GGGG"),
        }
        props.getOrderList({ data })
        toggle()
    }

    useEffect(() => {
        let data = {
            page: 1,
            perPage: 50,
        }
        props.getOrderList({ data })
    }, [])


    const onFilterChange = async (e, key) => {
        if (key === 'month') {
            setMonth(e.target.value)
        } else {
            setYear(e.target.value)
        }
        if (key === 'month' || key === 'year') {
            props.filterData({ month: key === 'month' ? e.target.value : month, year: key === 'year' ? e.target.value : year, page: 1 })
        }
    }

    const applyFilter = () => {
        toggle();
        setShow(true);
    }

    const handleTextChange = (e) => {
        setSearchText(e.target.value)
        setMonth('')
        setYear('')
    }

    const clearSearch = () => {
        setSearchText('')
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
        setHide(true)
        let data = {
            page: 1,
            perPage: 50,
            filter: '',
            searchText: '',
            month: moment().format("MMMM"),
            year: moment().format("GGGG")
        }
        props.getOrderList({ data })
    }

    const applySearch = () => {
        let data = {
            page: 1,
            perPage: 50,
            filter: '',
            searchText: searchText,
            month: month,
            year: year
        }
        props.getOrderList({ data })
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            applySearch()
        }
    }

    const { identifier } = props;

    return (
        <Toolbar >
            {
                identifier === "orderList" ?
                    <React.Fragment>
                        {(hide && props.show && show) ? <h3>Showing Orders for {month} {year}</h3> :
                            <div style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "0px", width: "80%" }}>
                                <TextField
                                    className='search'
                                    autoFocus={true}
                                    fullWidth={true}
                                    hidden={hide}
                                    value={searchText}
                                    onChange={handleTextChange}
                                    placeholder='Search Order'
                                    onKeyUp={handleKeyPress}
                                    variant="standard"
                                />
                            </div>
                        }
                        <Grow appear in={true} timeout={300}>
                            <div className='searchBox' style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "auto" }}>
                                <IconButton className='clear'>
                                    {hide ? <SearchIcon onClick={() => { setHide(false) }} /> : <ClearIcon onClick={clearSearch} />}
                                </IconButton>
                            </div>
                        </Grow>
                        <div style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "0px" }}>
                            <IconButton aria-label="filter list">
                                <FilterListIcon
                                    onClick={toggle}
                                    id="filter"
                                />
                            </IconButton>
                        </div>
                        <Popover
                            trigger="legacy" className="SignInPopOver" placement="bottom" isOpen={popoverOpen} target="filter" toggle={toggle}
                        >
                            <h5 className="font-weight-bold" style={{ color: '#000000de' }}>FILTERS</h5>
                            <CustomFilter
                                onFilterChange={onFilterChange}
                                handleResetFilter={handleResetFilter}
                                applyFilter={applyFilter}
                                month={month}
                                year={year}
                                filterFor='buyerOpenOrders'
                            />
                        </Popover>
                    </React.Fragment>
                    :
                    null
            }
        </Toolbar>
    );
}

const mapStateToProps = ({ buyer }) => {
    const { getOrderList } = buyer;
    return { getOrderList }
};

export default connect(mapStateToProps, { getOrderList })(EnhancedTableToolbar);