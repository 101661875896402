import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle, Row, Col, Popover } from 'reactstrap';
import { userSignOut } from 'actions/Auth';
import AppNotification from '../AppNotification';
import CardHeader from 'components/CardHeader';
import './index.css'
import { isMobile } from 'react-device-detect';
import { getUserDetail } from 'actions';
import { getMarkReadForBuyer } from 'actions/buyer';
import SignInPopOver from './SignInPopOver';
import { getMediWallet, getShortProducts } from 'actions/buyer';
import { getAutoFill } from 'actions/buyer';
import { getSearchProduct } from 'actions/buyer';
import { getCartDetails } from 'actions/buyer';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './secondaryHeader.css'
import helperFunction from 'constants/helperFunction';
import { getBuyerNotification } from 'actions/notification';
import { createFilterOptions } from '@mui/material/Autocomplete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CartNotification from 'components/myCart/CartNotification';
import axios from 'axios';
import appConfig from '../../constants/config'
import { NotificationManager } from 'react-notifications';

const Header = (props) => {
  const { searchOptions, cartDetails, notificationData, unReadNotification } = props;
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const decodedURL = params && params.search ? JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : {}
  const [searchText, setSearchText] = useState(decodedURL.search && location.pathname.includes('search-results') ? decodedURL.search : '')
  const [appNotification, setAppNotification] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [apps, setApps] = useState(false)
  const [appShoppingCart, setAppShoppingCart] = useState(false)
  const [marquee, setMarquee] = useState('')

  const getMarquee = async () => {
    try {
      const { data } = await axios.get(`${appConfig.baseUrl}promotions/get-marquee`);
      if (data && data.marqueeData && data.marqueeData.marquee) {
        setMarquee(data.marqueeData.marquee);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    props.getUserDetail({ userType: "buyer" })
    getMarquee()
  }, [])

  const onAppNotificationSelect = () => {
    props.getUserDetail({ userType: "buyer" })
    setAppNotification(!appNotification)
  };

  const onAppShoppingCartSelect = () => {
    props.getUserDetail({ userType: "buyer" })
    props.getCartDetails({})
    setAppShoppingCart(!appShoppingCart)
  };

  const onAppsSelect = () => {
    props.getUserDetail({ userType: "buyer" })
    setApps(!apps)
  };

  const handleLogout = () => {
    onAppsSelect()
    navigate('/')
    props.userSignOut({ navigate })
    localStorage.clear();
    setTimeout(function () {
      window.location.reload();
    }, 500)
  }

  const toggle = () => {
    if (popoverOpen === false) {
      localStorage.clear();
    }
    setPopoverOpen(!popoverOpen)
  }

  const Apps = () => {
    return (
      <React.Fragment>
        {!localStorage.getItem('asAdmin') ?
          <ul className="jr-list w-100 mx-auto mb-1">
            <li className="jr-list-item w-100 ">
              <Link className="jr-list-link buyerHeaderLink" to="/profile" onClick={onAppsSelect}>
                <i className="zmdi zmdi-account zmdi-hc-fw" />
                <span className="jr-list-text">Profile</span>
              </Link>
            </li>
          </ul> : ''}
        {!localStorage.getItem('asAdmin') ?
          <ul className="jr-list w-100 mx-auto mb-1">
            <li className="jr-list-item w-100 ">
              <Link className="jr-list-link buyerHeaderLink" to={{ pathname: "/profile", title: 'MediWallet' }} onClick={onAppsSelect}>
                <i className="zmdi zmdi-balance-wallet zmdi-hc-fw" />
                <span className="jr-list-text">MNY {props.users ? (props.users && props.users.wallet_balance).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "/-" : null}</span>
              </Link>
            </li>
          </ul> :
          <ul className="jr-list w-100 mx-auto mb-1">
            <li className="jr-list-item w-100 ">
              <Link className="jr-list-link buyerHeaderLink">
                <i className="zmdi zmdi-balance-wallet zmdi-hc-fw" />
                <span className="jr-list-text">MNY {props.users ? (props.users && props.users.wallet_balance).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "/-" : null}</span>
              </Link>
            </li>
          </ul>}
        <ul className="jr-list w-100 mx-auto">
          <li className="jr-list-item w-100 ">
            <Link className="jr-list-link buyerHeaderLink" onClick={handleLogout}>
              <i className="zmdi zmdi-sign-in  zmdi-hc-fw" />
              <span className="jr-list-text">Logout</span>
            </Link>
          </li>
        </ul>
      </React.Fragment>)
  };

  const handleClose = () => {
    setAppNotification(false)
    setAppShoppingCart(false)
  }

  const onChangeAutoCompleteSearch = (event, value) => {
    if (event.type === "keydown" || event.type === "click") {
      let url2 = location.pathname;
      let strip = url2.split('/');
      let catN = strip ? strip[3] ? strip[3] : 'category' : 'category';
      let obj = { search: value.replace(/"/g, ''), company: '', seller: '', discount: '', sortBy: 'price' }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      let catName = catN && ((catN).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()
      navigate(`/search-results/${catName}/${url}`, { state: 'searchPage' })
      const data = {
        key: obj.search ? obj.search.replace(/"/g, '') : '',
        company_id: obj.company_id ? obj.company_id : '',
        category_id: obj.category_id ? obj.category_id : [],
        seller_id: obj.seller_id ? obj.seller_id : '',
        page: 1,
        perPage: 12, sortBy: 'price'
      }
      props.getSearchProduct({ data })
    }
  }

  const throttleHandleChange = (edata) => {
    props.getAutoFill({ key: edata })
  }

  const updateSearchText = (evt, value) => {
    let search2 = (value).replace(/[^a-zA-Z0-9\s]/g, '').replace(/"/g, '')
    throttleHandleChange(search2)
    setSearchText(value)
  }


  const handleSigninOnEnter = (e) => {
    let url2 = location.pathname;
    let strip = url2.split('/');
    let catN = strip ? strip[3] ? strip[3] : 'category' : 'category';
    let catName = catN && ((catN).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()
    if (e.key === 'Enter' && searchText !== '') {
      let obj = { search: searchText.replace(/"/g, ''), company: '', seller: '', discount: '', sortBy: 'price' }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      navigate(`/search-results/${catName}/${url}`, { state: 'searchPage' })
      const data = {
        key: obj.search ? obj.search : '',
        company_id: obj.company_id ? obj.company_id : '',
        category_id: obj.category_id ? obj.category_id : [],
        seller_id: obj.seller_id ? obj.seller_id : '',
        page: 1,
        perPage: 12, sortBy: 'price'
      }
      props.getSearchProduct({ data })
    }
    if (e.key === 'Enter' && searchText === '') {
      let obj = { search: '', company: '', seller: '', discount: '', sortBy: 'price' }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      navigate(`/search-results/${catName}/${url}`, { state: 'searchPage' })
      const data = {
        key: '',
        company_id: obj.company_id ? obj.company_id : '',
        category_id: obj.category_id ? obj.category_id : [],
        seller_id: obj.seller_id ? obj.seller_id : '',
        page: 1,
        perPage: 12, sortBy: 'price'
      }
      props.getSearchProduct({ data })
    }
  }

  const handleChange = () => {
    props.getMarkReadForBuyer({})
  }

  const filter = createFilterOptions();

  return (
    <AppBar
      color='default'
      className={`app-main-header app-main-headerB noShadow app-main-header-top`}>
      {marquee && <marquee attribute_name="attribute_value" scrollamount="3%" attributes className='text-white bg-primary' style={{ padding: '10px' }}>
        {marquee}
      </marquee>}
      {isMobile === false ?
        <div className="app-toolbarB" disableGutters={false}>
          <Row>
            <Col sm={3} md={3} lg={3} xl={3}>
              <Link className="app-logo app-logoB mr-2 " to="/">
                <img src={require("assets/images/medimny.png")} alt="Medimny" title="Medimny" />
              </Link>
            </Col>
            <Col sm={5} md={5} lg={5} xl={5}>
              <div className="search-bar right-side-icon bg-transparent">
                <div className="form-group search-container d-flex">
                  <Autocomplete
                    style={{ marginTop: "2px", paddingTop: "0px !important", borderStyle: 'solid', borderColor: "#072791", borderWidth: "2px", width: '100%' }}
                    variant='outlined'
                    className="autoCompleteSearch"
                    id="free-solo-demo"
                    filterSelectedOptions
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.unshift(params.inputValue);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    disableClearable
                    clearOnEscape
                    freeSolo
                    inputValue={searchText}
                    onKeyPress={handleSigninOnEnter}
                    onInputChange={(event, val) => updateSearchText(event, val)}
                    onChange={(event, val) => onChangeAutoCompleteSearch(event, val)}
                    options={searchOptions && searchOptions.map(option => option.word)}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.inputValue;
                    }}
                    getOptionKey={(option) => {
                      if (typeof option === 'string') {
                        return option + Math.random() * 100000;
                      }
                      if (option.inputValue) {
                        return option.inputValue + Math.random() * 100000;
                      }
                      return option.inputValue + Math.random() * 100000;
                    }}
                    renderInput={params => (
                      <div>
                        <TextField
                          name="searchTextBoxField"
                          className="searchTextField"
                          {...params}
                          fullWidth
                          variant='outlined'
                          placeholder="Search Medicine"
                        />
                      </div>
                    )}
                  />
                  <i className="zmdi zmdi-search  zmdi-hc-2x" style={{ textAlign: "right", color: "#072791", marginLeft: "-46px", zIndex: "5000", marginTop: "8px" }}></i>
                </div>
              </div>
            </Col>
            <Col sm={4} md={4} lg={4} xl={4}>
              <Row >
                <Col md={5} lg={5} xl={7} className="cartIcon pr-0">
                  <Row className='justify-content-center ml-2'>
                    {!props.users ? null :
                      <Col md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                        <Dropdown
                          className="quick-menu"
                          isOpen={appNotification}
                          toggle={onAppNotificationSelect}
                        >
                          <DropdownToggle
                            className="d-inline-block"
                            tag="span"
                            data-toggle="dropdown">
                            {unReadNotification && unReadNotification.length > 0 ?
                              <IconButton className="icon-btn text-primary" onClick={handleChange}>
                                <i className="zmdi zmdi-notifications icon-alert" />
                              </IconButton>
                              :
                              <IconButton className="icon-btn text-primary ">
                                <i className="zmdi zmdi-notifications" />
                              </IconButton>
                            }<br />
                            <span className='text-primary navbarNotification' style={{ fontSize: '12px', fontWeight: 'bold' }}>Notification</span>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <CardHeader styleName="align-items-center" heading="Notifications" />
                            <AppNotification notificationData={notificationData} type='buyer' />
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    }
                    {!props.users ?
                      null
                      :
                      <Col md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                        <NavLink to={{
                          pathname: "/shortbook",
                          title: 'My Shortbook'
                        }} >
                          <IconButton className="icon-btn text-primary" style={{ padding: '4px' }}>
                            <MenuBookIcon />
                          </IconButton>
                        </NavLink><br />
                        <span className='text-primary navbarNotification' style={{ fontSize: '12px', fontWeight: 'bold' }}>ShortBook</span>
                      </Col>
                    }
                    {!props.users ? null :
                      <Col md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                        <Dropdown
                          className="quick-menu"
                          isOpen={appShoppingCart}
                          toggle={onAppShoppingCartSelect}
                        >
                          <DropdownToggle
                            className="d-inline-block"
                            tag="span"
                            data-toggle="dropdown"
                          >
                            {cartDetails && cartDetails.detail && cartDetails.detail.length > 0 ?
                              <IconButton className="icon-btn text-primary">
                                <i className="zmdi zmdi-shopping-cart icon-alert" />
                              </IconButton>
                              :
                              <IconButton className="icon-btn text-primary">
                                <i className="zmdi zmdi-shopping-cart" />
                              </IconButton>
                            }<br />
                            <span className='text-primary navbarNotification' style={{ fontSize: '12px', fontWeight: 'bold' }}> MyCart</span>
                          </DropdownToggle>
                          <DropdownMenu right className="pb-0">
                            <CardHeader styleName="align-items-center" heading="My Cart" />
                            <CartNotification />
                            {cartDetails && cartDetails.detail && cartDetails.detail.length > 0 ?
                              <NavLink tag={Link} to="/MyCart" className={'text-white jr-list-link buyerHeaderLink'} onClick={handleClose}>
                                <div className="orderFooter">
                                  <span>Go To Cart</span>
                                </div>
                              </NavLink>
                              :
                              <NavLink className="jr-list-link buyerHeaderLink" to={{ pathname: '/', state: '/' }}>
                                <div className="orderFooter">
                                  <span style={{ color: 'white', cursor: 'default' }}>Add some items</span>
                                </div>
                              </NavLink>
                            }
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    }
                  </Row>
                </Col>
                {!props.users ?
                  <Col md={7} lg={6} xl={5} className="header-notifications">
                    <ul className="header-notifications list-inline pull-right mr-1">
                      <li className="list-inline-item">
                        <div className="">
                          <button id="signInPopOver" onClick={toggle}
                            style={{
                              marginTop: 3,
                              backgroundColor: "#072791",
                              color: "#ffffff",
                              border: "none",
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 7,
                              paddingBottom: 7,
                              borderRadius: "5px",
                            }}>
                            LOGIN
                          </button>
                          <Popover trigger="legacy" className="SignInPopOver" placement="bottom" isOpen={popoverOpen} target="signInPopOver" toggle={toggle}>
                            <i class="zmdi zmdi-close zmdi-hc-2x" style={{ float: "right", cursor: "pointer" }} onClick={toggle}></i>
                            <SignInPopOver />
                          </Popover>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  :
                  null}
                {!props.users ?
                  null
                  :
                  <Col md={7} lg={6} xl={5} className="header-notifications ">
                    <ul className="header-notifications list-inline ">
                      <li className="list-inline-item">
                        <Dropdown
                          className="quick-menu app-notification text-white ml-4"
                          isOpen={apps}
                          toggle={onAppsSelect}>
                          <DropdownToggle
                            className="d-inline-block"
                            tag="span"
                            data-toggle="dropdown">
                            <span className="app-notification-menu bg-primary">
                              <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg icon" />
                              <span className='mt-1'>
                                {helperFunction.getNameInitials(`${props.users.first_name} ${props.users.last_name}`)}
                              </span>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            {Apps()}
                          </DropdownMenu>
                        </Dropdown>
                      </li>
                    </ul>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </div>
        :
        <div className="mobileview-app-toolbar" disableGutters={false}>
          <Row className='d-flex'>
            <Col sm={1} xs={1} >
            </Col>
            <Col sm={3} xs={3} className="pt-1" >
              <Link className="app-logo app-logoB mr-2 " to="/">
                <img src={require("assets/images/medimny.png")} alt="Medimny" title="Medimny" />
              </Link>
            </Col>
            <Col sm={8} xs={8}>
              <Row style={{ float: "right" }}>
                {!props.users ? null :
                  <Col sm={2} xs={2} className="cartIcon pl-5 pr-4">
                    <ul className='header-notifications list-inline '>
                      <li className="list-inline-item app-tour">
                        <Dropdown
                          className="quick-menu"
                          isOpen={appNotification}
                          toggle={onAppNotificationSelect}
                        >
                          <DropdownToggle
                            className="d-inline-block"
                            tag="span"
                            data-toggle="dropdown"
                          >
                            {unReadNotification && unReadNotification.length > 0 ?
                              <NavLink to="/list-notification" className={'text-black NavLink'} onClick={handleClose}>
                                <IconButton className="icon-btn text-primary" onClick={handleChange}>
                                  <i className="zmdi zmdi-notifications icon-alert" />
                                </IconButton>
                              </NavLink>
                              :
                              <NavLink to="/list-notification" className={'text-black NavLink'} onClick={handleClose}>
                                <IconButton className="icon-btn text-primary">
                                  <i className="zmdi zmdi-notifications" />
                                </IconButton>
                              </NavLink>
                            }
                          </DropdownToggle>
                        </Dropdown>
                      </li>
                    </ul>
                  </Col>
                }
                {!props.users ?
                  null
                  :
                  <Col sm={2} xs={2} className="cartIcon p-0 ml-2">
                    <NavLink to={{
                      pathname: "/shortbook",
                      title: 'My Shortbook'
                    }} >
                      <IconButton className="icon-btn text-primary" style={{ padding: '4px' }}>
                        <MenuBookIcon />
                      </IconButton>
                    </NavLink>
                  </Col>
                }
                {!props.users ? null :
                  <Col sm={2} xs={2} className="cartIcon p-0" >
                    <ul className='header-notifications list-inline ml-auto pull-right'>
                      <li className="list-inline-item app-tour">
                        <Dropdown
                          className="quick-menu"
                          isOpen={appShoppingCart}
                          toggle={onAppShoppingCartSelect}
                        >
                          <DropdownToggle
                            className="d-inline-block"
                            tag="span"
                            data-toggle="dropdown"
                          >
                            <NavLink to="/MyCart" className={'text-white NavLink'} onClick={handleClose}>
                              {cartDetails && cartDetails.detail && cartDetails.detail.length > 0 ?
                                <IconButton className="icon-btn text-primary">
                                  <i className="zmdi zmdi-shopping-cart icon-alert" />
                                </IconButton>
                                :
                                <IconButton className="icon-btn text-primary">
                                  <i className="zmdi zmdi-shopping-cart" />
                                </IconButton>
                              }
                            </NavLink>
                          </DropdownToggle>
                        </Dropdown>
                      </li>
                    </ul>
                  </Col>
                }
                <Col sm={2} xs={2} className="header-notifications dropDown">
                  <ul className="header-notifications list-inline ">
                    <li className="list-inline-item">
                      <Dropdown
                        className="quick-menu app-notification"
                        isOpen={apps}
                        toggle={onAppsSelect}
                      >
                        {!props.users ?
                          <div>
                            <button id="signInPopOver" onClick={toggle}
                              style={{
                                marginTop: 3,
                                marginBottom: 5,
                                backgroundColor: "#072791",
                                color: "#ffffff",
                                border: "none",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                                borderRadius: "3px",
                                fontSize: 10
                              }}>
                              LOGIN
                            </button>
                            <Popover trigger="legacy" className="SignInPopOver" placement="bottom" isOpen={popoverOpen} target="signInPopOver" toggle={toggle}>
                              <h2><strong></strong></h2>
                              <SignInPopOver />
                            </Popover>
                          </div>
                          :
                          <DropdownToggle
                            className="d-inline-block text-white"
                            tag="span"
                            data-toggle="dropdown">
                            <span className="app-notification-menu bg-primary">
                              <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg icon" />
                            </span>
                          </DropdownToggle>
                        }
                        <DropdownMenu>
                          {Apps()}
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row >
            <Col sm={12} md={12} lg={5} xl={5}>
              <div className="search-bar right-side-icon bg-transparent">
                <div className="form-group search-container d-flex">
                  <Autocomplete
                    style={{ marginTop: "2px", paddingTop: "0px !important", borderStyle: 'solid', borderColor: "#072791", borderWidth: "2px", width: '100%' }}
                    variant='outlined'
                    className="autoCompleteSearch"
                    id="free-solo-demo"
                    filterSelectedOptions
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.unshift(params.inputValue);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    disableClearable
                    clearOnEscape
                    freeSolo
                    inputValue={searchText}
                    onInputChange={(event, val) => updateSearchText(event, val)}
                    onKeyPress={handleSigninOnEnter}
                    onChange={(event, val) => onChangeAutoCompleteSearch(event, val)}
                    options={searchOptions && searchOptions.map(option => option.word)}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.inputValue;
                    }}
                    getOptionKey={(option) => {
                      if (typeof option === 'string') {
                        return option + Math.random() * 100000;
                      }
                      if (option.inputValue) {
                        return option.inputValue + Math.random() * 100000;
                      }
                      return option.inputValue + Math.random() * 100000;
                    }}
                    renderInput={params => (
                      <div>
                        <TextField
                          name="searchTextBoxField"
                          className="searchTextField"
                          {...params}
                          fullWidth
                          variant='outlined'
                          placeholder="Search Medicine"
                        />
                      </div>
                    )}
                  />
                  <i className="zmdi zmdi-search  zmdi-hc-2x" style={{ textAlign: "right", color: "#072791", marginLeft: "-46px", zIndex: "2", marginTop: "8px" }}></i>
                </div>
              </div>
            </Col>
            {/* <div style={{ textAlign: "right", color: "#072791", marginLeft: "-46px", zIndex: "100", marginTop: "8px" }}>
              <i className="zmdi zmdi-search  zmdi-hc-2x"></i>
            </div> */}
          </Row>
        </div>
      }
    </AppBar>
  );
}


const mapStateToProps = ({ auth, seller, buyer, notification }) => {
  const { userDetails } = seller
  const { user_details } = auth;
  const { mediWallet, autoFill, searchOptions, searchProduct, cartDetails, getMarkReadListForBuyer } = buyer;
  const { unreadNotificationCount, notificationData, unReadNotification } = notification;
  let users = userDetails ? userDetails : user_details
  return {
    user_details, users, mediWallet, autoFill, searchOptions, searchProduct, cartDetails,
    unreadNotificationCount, notificationData, unReadNotification, getMarkReadListForBuyer
  }
};

export default connect(mapStateToProps, { userSignOut, getUserDetail, getMediWallet, getAutoFill, getSearchProduct, getCartDetails, getBuyerNotification, getMarkReadForBuyer, getShortProducts })(Header);