import React from 'react';
import Avatar from '@mui/material/Avatar'
import helperFunction from 'constants/helperFunction';
import moment from 'moment'

const NotificationItem = ({ notification }) => {
  const { createdAt, message, buyerId, sellerId, flag, image } = notification;
  let user = localStorage.user_type

  const initials = (data) => {
    switch (data) {
      case "Order Placed":
      case "Order Processed":
      case "Order Accepted / Declined":
      case "Order Received":
        return 'OP'
      case "Order Declined":
        return 'OD'
      case "Shortbook Available In Stock":
      case "Shortbook Notification":
      case "Product Added To ShortBook":
        return 'SB'
      case "MEDIMNY PRESENTS":
        return 'D'
      case "Product Request Generated":
      case "Product Notification":
      case "Product Request Fulfilled":
      case "Product Request Notification":
        return 'PR'
      case "MediWallet":
        return 'MW'
      case "Bulk request":
        return 'BR'
      default:
        return false
    }
  }

  const initialColor = (data) => {
    switch (data) {
      case "Order Placed":
      case "Order Processed":
      case "Order Accepted / Declined":
      case "Order Received":
        return 'blue'
      case "Order Declined":
        return 'red'
      case "Shortbook Available In Stock":
      case "Shortbook Notification":
      case "Product Added To ShortBook":
        return '#078492'
      case "MEDIMNY PRESENTS":
        return 'orange'
      case "Product Request Generated":
      case "Product Notification":
      case "Product Request Fulfilled":
      case "Product Request Notification":
        return '#430792'
      case "MediWallet":
        return '#48bf24'
      case "Bulk request":
        return '#48bf24'
      default:
        return false
    }
  }

  let userType = user === 'buyer' ? buyerId : sellerId;

  return (
    <li className="media pt-2">
      <Avatar
        alt={'image'}
        style={{ background: initialColor(notification.flag) ? initialColor(notification.flag) : helperFunction.getGradient(userType && userType.backGroundColor) }}
        className=" mr-2"
      >
        {
          initials(notification.flag) ? initials(notification.flag) :
            userType !== undefined && userType ? helperFunction.getNameInitials(`${userType.first_name} ${userType.last_name}`) : ''
        }
      </Avatar>
      <div className="media-body " style={{ marginBottom: "15px" }}>
        <h6 className={` mb-0 ${flag === 'Shortbook Notification' && 'text-primary'}`} style={{ fontWeight: "500" }}>{message}</h6>
        <span className="mb-0 text-grey"><small>{moment(createdAt).format('DD-MM-YYYY')}</small></span>
        <span className="meta-date ml-1"><small>{moment(createdAt).format('HH:mm A')}</small></span>
        {image && image.length > 0 && <div> <a href={image} target="_blank" rel="noreferrer"> <img height={100} src={image} alt="Notification" /></a>  </div>}
      </div>
    </li>
  );
};

export default NotificationItem;
