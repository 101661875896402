import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OrderList from 'components/orders/OrderList';
import OrderHistory from 'components/orders/OrderHistory';
import OldManifest from 'components/orders/OldManifest';
import { useSearchParams } from 'react-router-dom';

function TabContainer({ children, tab, tabValue, dir }) {
  return (
    <div dir={dir}>
      {tab === tabValue && children}
    </div>
  );
}

const FullWidthTabs = (props) => {
  const [params] = useSearchParams()
  const [tabValue, setTabValue] = useState(Number(params.get("tab")) ? Number(params.get("tab")) : 0)

  const handleChange = (event, value) => {
    setTabValue(value)
  };

  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="History" />
          <Tab className="tab" label="Old Manifest" />
        </Tabs>
      </AppBar>
      <TabContainer dir="ltr" tab={0} tabValue={tabValue}>
        <OrderList tab={0} tabValue={tabValue} />
      </TabContainer>
      <TabContainer dir="ltr" tab={1} tabValue={tabValue}>
        <OrderHistory tab={1} tabValue={tabValue} />
      </TabContainer>
      <TabContainer dir="ltr" tab={2} tabValue={tabValue}>
        <OldManifest tab={2} tabValue={tabValue} />
      </TabContainer>
    </div>
  );
}

export default FullWidthTabs;