import { all, call, fork, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import {
  GET_SIDEBAR,
  LIST_PERMISSION_MODULES,
  ADD_GROUP,
  GET_INVENTORY_POPUP,
  LIST_GROUP,
  DELETE_GROUP,
  ADD_STAFF,
  LIST_STAFF,
  DELETE_STAFF,
  REQUEST_PRODUCT,
  GET_REQUEST_PRODUCT,
  GET_USERDETAILS,
  GET_INVENTORY,
  GET_DASHBOARD_CARD,
  GET_DASHBOARD_TOP_SELLING_PRODUCTS,
  GET_SETTLEMENT,
  GET_DASHBOARD_LEAST_SELLING_PRODUCTS,
  GET_MARK_READ_LIST,
  GET_SETTLEMENTS,
  GET_LIST_GROUP_SETTLEMENT,
  GET_TICKET_LIST_SELLER,
  GET_ADD_TICKET,
  GET_BULK_LIST_SELLER
} from "constants/ActionTypes";

import {
  apiFailed,
  getSidebarSuccess,
  listPermissionModulesSuccess,
  addGroupSuccess,
  deleteGroupSuccess,
  listGroup,
  listGroupSuccess,
  addStaffSuccess,
  deleteStaffSuccess,
  listStaff,
  listStaffSuccess,
  getProductRequest,
  getProductRequestSuccess,
  addRequestProductSuccess,
  getUserDetailSuccess,
  getInventoryListSuccess,
  getDashboardCardSuccess,
  getDashboardTopSellingProductsSuccess,
  getDashboardLeastSellingProductsSuccess,
  getSettlementListSuccess,
  getMarkReadSuccess,
  getInventoryListPopUpSuccess,
  getSettlementsSuccess,
  getListGroupSettlementSuccess,
  getTicketListSeller,
  getTicketListSellerSuccess,
  getAddTicketSuccess,
  getBulkListSellerSuccess,
} from "actions/seller";

import { getNotification } from 'actions/notification'
import Axios from './axiosRequest'
import { NotificationManager } from "react-notifications";

function* getSideFunction({ payload }) {
  const { location, navigate } = payload
  try {
    const getSidebarResponse = yield call(Axios.axiosRequest, 'post', 'users/getSidbarContent', '', payload);
    if (getSidebarResponse && getSidebarResponse.data && getSidebarResponse.data.error) {
      yield put(apiFailed(getSidebarResponse.data.title));
    } else if (getSidebarResponse && getSidebarResponse.data) {
      if (getSidebarResponse.data.detail && getSidebarResponse.data.detail.length > 0) {
        let path = location.pathname.split('/')
        let index = path[2] ? getSidebarResponse.data.detail.findIndex((val) => val.pathname.includes(path[2])) : -1
        if (index === -1 && !location.pathname.includes("list-notification") && !location.pathname.includes("change-Password") && !location.pathname.includes("edit-Profile")) {
          navigate(`${getSidebarResponse.data.detail[0].pathname}`);
        }
      }
      yield put(getSidebarSuccess(getSidebarResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getSideBarDispatcher() {
  yield takeLeading(GET_SIDEBAR, getSideFunction);
}

function* listPermissionModules({ payload }) {
  try {
    const listPermissionModuleResponse = yield call(Axios.axiosRequest, 'post', 'seller/getPermissionModule', '', payload);
    if (listPermissionModuleResponse && listPermissionModuleResponse.data && listPermissionModuleResponse.data.error) {
      yield put(apiFailed(listPermissionModuleResponse.data.title));
    } else if (listPermissionModuleResponse && listPermissionModuleResponse.data) {
      yield put(listPermissionModulesSuccess(listPermissionModuleResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getPermissionModuleDispatcher() {
  yield takeEvery(LIST_PERMISSION_MODULES, listPermissionModules);
}

function* addGroupFunction({ payload }) {
  try {
    const addGroupResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/addEditGroupModule', '', payload.data);
    if (addGroupResponse && addGroupResponse.data && addGroupResponse.data.error) {
      yield put(apiFailed(addGroupResponse.data.title));
    } else if (addGroupResponse && addGroupResponse.data) {
      yield put(addGroupSuccess(addGroupResponse.data));
      yield put(listGroup(payload.listGroup))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* addGroupDispatcher() {
  yield takeLatest(ADD_GROUP, addGroupFunction);
}

function* listGroupFunction({ payload }) {
  try {
    const listGroupResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/getGroupModule', '', payload);
    if (listGroupResponse && listGroupResponse.data && listGroupResponse.data.error) {
      yield put(apiFailed(listGroupResponse.data.title));
    } else if (listGroupResponse && listGroupResponse.data) {
      yield put(listGroupSuccess(listGroupResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* listGroupDispatcher() {
  yield takeEvery(LIST_GROUP, listGroupFunction);
}

function* deleteGroupFunction({ payload }) {
  try {
    const deleteGroupResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/deleteGroupModule', '', payload);
    if (deleteGroupResponse && deleteGroupResponse.data && deleteGroupResponse.data.error) {
      yield put(apiFailed(deleteGroupResponse.data.title));
    } else if (deleteGroupResponse && deleteGroupResponse.data) {
      yield put(deleteGroupSuccess(deleteGroupResponse.data))
      yield put(listGroup(payload.listGroup))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* deleteGroupDispatcher() {
  yield takeLatest(DELETE_GROUP, deleteGroupFunction);
}

function* addStaffFunction({ payload }) {
  try {
    const addStaffResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/addEditStaffMember', '', payload.data);
    if (addStaffResponse && addStaffResponse.data && addStaffResponse.data.error) {
      yield put(apiFailed(addStaffResponse.data.title));
    } else if (addStaffResponse && addStaffResponse.data) {
      yield put(addStaffSuccess(addStaffResponse.data));
      yield put(listStaff(payload.listStaff))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* addStaffDispatcher() {
  yield takeLatest(ADD_STAFF, addStaffFunction);
}

function* listStaffFunction({ payload }) {
  try {
    const listStaffResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/getStaffList', '', payload);
    if (listStaffResponse && listStaffResponse.data && listStaffResponse.data.error) {
      yield put(apiFailed(listStaffResponse.data.title));
    } else if (listStaffResponse && listStaffResponse.data) {
      yield put(listStaffSuccess(listStaffResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* listStaffDispatcher() {
  yield takeEvery(LIST_STAFF, listStaffFunction);
}

function* deleteStaffFunction({ payload }) {
  try {
    const deleteStaffResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/deleteStaff', '', payload);
    if (deleteStaffResponse && deleteStaffResponse.data && deleteStaffResponse.data.error) {
      yield put(apiFailed(deleteStaffResponse.data.title));
    } else if (deleteStaffResponse && deleteStaffResponse.data) {
      yield put(deleteStaffSuccess(deleteStaffResponse.data))
      yield put(listStaff(payload.listStaff))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* deleteStaffDispatcher() {
  yield takeLatest(DELETE_STAFF, deleteStaffFunction);
}


function* addProductRequestFunction({ payload }) {
  try {
    const addProductRequestResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/addProductRequest', '', payload.data);
    if (addProductRequestResponse && addProductRequestResponse.data && addProductRequestResponse.data.error) {
      yield put(apiFailed(addProductRequestResponse.data.title));
    } else if (addProductRequestResponse && addProductRequestResponse.data) {
      yield put(addRequestProductSuccess(addProductRequestResponse.data))
      yield put(getProductRequest(payload.listProduct))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* addProductRequestDispatcher() {
  yield takeLatest(REQUEST_PRODUCT, addProductRequestFunction);
}

function* listRequestedProductsFunction({ payload }) {
  try {
    const listRequestedProductsResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/getRequestedProductList', '', payload);
    if (listRequestedProductsResponse && listRequestedProductsResponse.data && listRequestedProductsResponse.data.error) {
      yield put(apiFailed(listRequestedProductsResponse.data.title));
    } else if (listRequestedProductsResponse && listRequestedProductsResponse.data) {
      yield put(getProductRequestSuccess(listRequestedProductsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* listRequestedProductsDispatcher() {
  yield takeEvery(GET_REQUEST_PRODUCT, listRequestedProductsFunction);
}


function* getUserDetailFunction({ payload }) {
  try {
    const getUserDetailRequest = payload.userType === "buyer" ? yield call(Axios.axiosBuyerHelperFunc, 'get', 'users/getuserDetails', '', payload) : yield call(Axios.axiosHelperFunc, 'get', 'users/getuserDetails', '', payload);
    if (getUserDetailRequest && getUserDetailRequest.data && getUserDetailRequest.data.error) {
      // yield put(apiFailed(getUserDetailRequest.data.title));
    } else if (getUserDetailRequest && getUserDetailRequest.data) {
      yield put(getUserDetailSuccess(getUserDetailRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getUserdetailDispatcher() {
  yield takeEvery(GET_USERDETAILS, getUserDetailFunction);
}

function* getInventoryFunction({ payload }) {
  try {
    const getInventoryRequest = yield call(Axios.axiosHelperFunc, 'post', 'inventory_V2/listingInventory_V2', '', payload.data);
    if (getInventoryRequest && getInventoryRequest.data && getInventoryRequest.data.error) {
      yield put(apiFailed(getInventoryRequest.data.title));
    } else if (getInventoryRequest && getInventoryRequest.data) {
      yield put(getInventoryListSuccess(getInventoryRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getInventoryDispatcher() {
  yield takeLatest(GET_INVENTORY, getInventoryFunction);
}


// -----------------------------------------------------------------------------------------------

function* getInventoryPopUpFunction({ payload }) {
  try {
    const getInventoryPopUpRequest = yield call(Axios.axiosHelperFunc, 'post', 'inventory_V2/inventoryDetailPopup_V2', '', payload);
    if (getInventoryPopUpRequest && getInventoryPopUpRequest.data && getInventoryPopUpRequest.data.error) {
      yield put(apiFailed(getInventoryPopUpRequest.data.title));
    } else if (getInventoryPopUpRequest && getInventoryPopUpRequest.data) {
      yield put(getInventoryListPopUpSuccess(getInventoryPopUpRequest.data.Detail))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getInventoryPopUpDispatcher() {
  yield takeEvery(GET_INVENTORY_POPUP, getInventoryPopUpFunction);
}



function* getDashboardCardFunction({ payload }) {
  try {
    const getDashboardCardRequest = yield call(Axios.axiosHelperFunc, 'get', 'seller/getDashBoardCardDetails', '', '');
    if (getDashboardCardRequest && getDashboardCardRequest.data && getDashboardCardRequest.data.error) {
      yield put(apiFailed(getDashboardCardRequest.data.title));
    } else if (getDashboardCardRequest && getDashboardCardRequest.data) {
      yield put(getDashboardCardSuccess(getDashboardCardRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getDashboardCardDispatcher() {
  yield takeEvery(GET_DASHBOARD_CARD, getDashboardCardFunction);
}

function* getDashboardTopSellingProductsFunction({ payload }) {
  try {
    const getDashboardTopSellingProductsRequest = yield call(Axios.axiosHelperFunc, 'post', 'seller/getDashboardTopSellingProducts', '', payload.data);
    if (getDashboardTopSellingProductsRequest && getDashboardTopSellingProductsRequest.data && getDashboardTopSellingProductsRequest.data.error) {
      yield put(apiFailed(getDashboardTopSellingProductsRequest.data.title));
    } else if (getDashboardTopSellingProductsRequest && getDashboardTopSellingProductsRequest.data) {
      yield put(getDashboardTopSellingProductsSuccess(getDashboardTopSellingProductsRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getDashboardTopSellingProductsDispatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_SELLING_PRODUCTS, getDashboardTopSellingProductsFunction);
}

function* getSettlementListFunction({ payload }) {
  try {
    const getSettlementListRequest = yield call(Axios.axiosHelperFunc, 'post', 'seller/getSettlements', '', payload.data);
    if (getSettlementListRequest.data && getSettlementListRequest.data && getSettlementListRequest.data.error) {
      yield put(apiFailed(getSettlementListRequest.data.title));
    } else if (getSettlementListRequest && getSettlementListRequest.data) {
      yield put(getSettlementListSuccess(getSettlementListRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getSettlementListDispatcher() {
  yield takeEvery(GET_SETTLEMENT, getSettlementListFunction);
}

function* getDashboardLeastSellingProductsFunction({ payload }) {
  try {
    const getDashboardLeastSellingProductsRequest = yield call(Axios.axiosHelperFunc, 'post', 'seller/getDashboardLeastSellingProducts', '', payload.data1);
    if (getDashboardLeastSellingProductsRequest && getDashboardLeastSellingProductsRequest.data && getDashboardLeastSellingProductsRequest.data.error) {
      yield put(apiFailed(getDashboardLeastSellingProductsRequest.data.title));
    } else if (getDashboardLeastSellingProductsRequest && getDashboardLeastSellingProductsRequest.data) {
      yield put(getDashboardLeastSellingProductsSuccess(getDashboardLeastSellingProductsRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getDashboardLeastSellingProductsDispatcher() {
  yield takeEvery(GET_DASHBOARD_LEAST_SELLING_PRODUCTS, getDashboardLeastSellingProductsFunction);
}

// ----------------------------------------------------------------------------

function* getMarkReadFunction({ payload }) {
  try {
    const getMarkReadRequest = yield call(Axios.axiosHelperFunc, 'post', 'users/markRead', '', payload.data);
    if (getMarkReadRequest && getMarkReadRequest.data && getMarkReadRequest.data.error) {
      yield put(apiFailed(getMarkReadRequest.data.title));
    } else if (getMarkReadRequest && getMarkReadRequest.data) {
      yield put(getMarkReadSuccess(getMarkReadRequest.data))
      yield put(getNotification(getMarkReadRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getMarkReadDispatcher() {
  yield takeLatest(GET_MARK_READ_LIST, getMarkReadFunction);
}

// ----------------------------------------------------------------------------------

function* getSettlementsFunction({ payload }) {
  try {
    const getSettlementsResponse = yield call(Axios.axiosHelperFunc, 'post', 'settlements/list', '', payload.data);
    if (getSettlementsResponse && getSettlementsResponse.data && getSettlementsResponse.data.error) {
      yield put(apiFailed(getSettlementsResponse.data.title));
    } else if (getSettlementsResponse && getSettlementsResponse.data) {
      yield put(getSettlementsSuccess(getSettlementsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getSettlementsDispacher() {
  yield takeLatest(GET_SETTLEMENTS, getSettlementsFunction);
}
// -----------------------------------------------------
function* getListGroupSettlementFunction({ payload }) {
  try {
    const getListGroupSettlementResponse = yield call(Axios.axiosHelperFunc, 'post', 'groupSettlements/listGroupSettlement', '', payload.data);
    if (getListGroupSettlementResponse && getListGroupSettlementResponse.data && getListGroupSettlementResponse.data.error) {
      yield put(apiFailed(getListGroupSettlementResponse.data.title));
    } else if (getListGroupSettlementResponse && getListGroupSettlementResponse.data) {
      yield put(getListGroupSettlementSuccess(getListGroupSettlementResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getListGroupSettlementDispatcher() {
  yield takeLatest(GET_LIST_GROUP_SETTLEMENT, getListGroupSettlementFunction);
}
// -----------------------------------------------------
function* getListTicketSellerFunction({ payload }) {
  try {
    const getListTicketSellerResponse = yield call(Axios.axiosHelperFunc, 'post', 'ticket/ticketListing', '', payload.data);
    if (getListTicketSellerResponse && getListTicketSellerResponse.data && getListTicketSellerResponse.data.error) {
      yield put(apiFailed(getListTicketSellerResponse.data.title));
    } else if (getListTicketSellerResponse && getListTicketSellerResponse.data) {
      yield put(getTicketListSellerSuccess(getListTicketSellerResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getListTicketSellerDispatcher() {
  yield takeLatest(GET_TICKET_LIST_SELLER, getListTicketSellerFunction);
}
// -----------------------------------------------------
function* getAddTicketFunction({ payload }) {
  try {
    const getAddTicketResponse = yield call(Axios.axiosHelperFunc, 'post', 'ticket/addTicket', '', payload.data);
    if (getAddTicketResponse && getAddTicketResponse.data && getAddTicketResponse.data.error) {
      yield put(apiFailed(getAddTicketResponse.data.title));
    } else if (getAddTicketResponse && getAddTicketResponse.data) {
      yield put(getAddTicketSuccess(getAddTicketResponse.data.title))
      NotificationManager.success(getAddTicketResponse.data.title)
      let data = {}
      yield put(getTicketListSeller(data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getAddTicketDispatcher() {
  yield takeLatest(GET_ADD_TICKET, getAddTicketFunction);
}

// -----------------------------------------------------
function* getSellerBulkListingFunction({ payload }) {
  try {
    const getSellerBulkListingResponse = yield call(Axios.axiosHelperFunc, 'post', 'bulkRequest/sellerBulkListing', '', payload.data);
    if (getSellerBulkListingResponse && getSellerBulkListingResponse.data && getSellerBulkListingResponse.data.error) {
      yield put(apiFailed(getSellerBulkListingResponse.data.title));
    } else if (getSellerBulkListingResponse && getSellerBulkListingResponse.data) {
      yield put(getBulkListSellerSuccess(getSellerBulkListingResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getSellerBulkListingDispatcher() {
  yield takeLatest(GET_BULK_LIST_SELLER, getSellerBulkListingFunction);
}
export default function* rootSaga() {
  yield all([
    fork(getSideBarDispatcher),
    fork(getPermissionModuleDispatcher),
    fork(addGroupDispatcher),
    fork(getInventoryPopUpDispatcher),
    fork(listGroupDispatcher),
    fork(deleteGroupDispatcher),
    fork(addStaffDispatcher),
    fork(listStaffDispatcher),
    fork(deleteStaffDispatcher),
    fork(addProductRequestDispatcher),
    fork(listRequestedProductsDispatcher),
    fork(getUserdetailDispatcher),
    fork(getInventoryDispatcher),
    fork(getDashboardCardDispatcher),
    fork(getDashboardTopSellingProductsDispatcher),
    fork(getSettlementListDispatcher),
    fork(getDashboardLeastSellingProductsDispatcher),
    fork(getMarkReadDispatcher),
    fork(getSettlementsDispacher),
    fork(getListGroupSettlementDispatcher),
    fork(getListTicketSellerDispatcher),
    fork(getAddTicketDispatcher),
    fork(getSellerBulkListingDispatcher)
  ]);
}