import { useMemo } from "react";

import "./EllipsedText.css";

export const EllipsedText = ({ children }) => {
  const wordsCount = useMemo(() => Math.ceil(20 * Math.random()), []);
  return (
    <div className="ellipsed-text">
      {children}
    </div>
  );
};