import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import TableComponent from './TableComponent';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableToolbarOpen from './EnhancedTableToolbarOpen'
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { getTicketList, getTicketHistoryList } from 'actions/buyer';

const styles = {
  root: {
    width: '100%',
    marginTop: 0,
  },
  paper: {
    width: '100%',
    marginBottom: 2,
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  list: {
    maxWidth: 239
  },
  fontSize: {
    fontSize: '1rem'
  }
};

const TicketTable = (props) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(50)
  const [filterObj, setFilterObj] = useState('')
  const [ticketData, setTicketData] = useState('')
  let rows = []

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let data;
    if (props.identifier === 'ticketList') {
      data = { page: newPage + 1, perPage: perPage };
      props.getTicketList({ data });
    } else {
      data = { page: newPage + 1, perPage: perPage, close: '1' };
      props.getTicketHistoryList({ data });
    }
  };

  const handleChangeRowsPerPage = event => {
    setPerPage(parseInt(event.target.value, 10))
    setPage(0)
  };

  const filterData = (data) => {
    setFilterObj(data);
  }

  useEffect(() => {
    if (props.identifier === 'ticketList') {
      setTicketData(props.ticketList)
    }
    if (props.identifier === 'ticketHistoryList') {
      setTicketData(props.ticketHistoryList)
    }
  }, [props.identifier, props.ticketList, props.ticketHistoryList])

  const { identifier } = props;

  ticketData && ticketData.data1 && ticketData.data1.map((d) => {
    rows.push({
      createdAt: moment(d.createdAt).format('DD/MM/YYYY'),
      type: d.type === 'other' ? 'Other' : 'Order related',
      ticketNo: d.ticketNo,
      orderId: d.type === 'other' ? 'N/A' : d.order.orderNo,
      issue: d.issue,
      refund: 'N/A',
      refundDate: 'N/A',
      type1: d.type,
      data: d,
      comments: d.comments,
      status: d.status
    })
  })

  return (
    <div style={styles.root}>
      <Paper sx={styles.paper}>
        {
          identifier === "ticketHistoryList" ?
            <EnhancedTableToolbar identifier={identifier} show={props.show} />
            :
            identifier === "ticketList" ?
              <EnhancedTableToolbarOpen identifier={identifier} filterData={filterData} show={props.show} />
              :
              null
        }
        {
          props.loading ?
            <div className="loader-view">
              <CircularProgress />
            </div>
            :
            <div style={styles.tableWrapper}>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  styles={styles}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rows.length < 1 ? <TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>{identifier === "ticketList" ? `You don't have open tickets !` : identifier === "ticketHistoryList" ? `You don't have tickets !` : 'Sorry no records found'}</TableCell></TableRow> :
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableComponent
                          row={row}
                          labelId={labelId}
                          itemListFromParent={rows}
                          show={props.show}
                          filterObj={filterObj}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            </div>
        }
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={ticketData && ticketData.count}
          rowsPerPage={perPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { loading, ticketList, ticketHistoryList } = buyer;
  return { loading, ticketList, ticketHistoryList }
};

export default connect(mapStateToProps, { getTicketList, getTicketHistoryList })(TicketTable)