import React from 'react';

const BulkHeader = (props) => {
	return (
		<div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
			<h2 className="title mb-3 mb-sm-0">Bulk Requests </h2>
		</div>
	)
}

export default BulkHeader;