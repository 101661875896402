import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomFilter from 'components/Filter/index';
import { Popover } from 'reactstrap';
import moment from 'moment';
import './index.css'
import { getOrderHistoryList } from 'actions/buyer';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap'

const EnhancedTableToolbar = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [month, setMonth] = useState(moment().format("MMMM"))
    const [year, setYear] = useState(moment().format("GGGG"))
    const [show, setShow] = useState(false)

    const toggle = () => {
        setPopoverOpen(!popoverOpen);
    }

    const handleResetFilter = (e) => {
        e.preventDefault();
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
        setShow(true)
        let data = {
            page: 1,
            perPage: 50,
            filter: '',
            month: moment().format("MMMM"),
            year: moment().format("GGGG"),
        }
        props.getOrderHistoryList({ data })
        this.toggle()
    }

    useEffect(() => {
        let data = {
            page: 1,
            perPage: 50,
            filter: '',
        }
        props.getOrderHistoryList({ data })
    }, [])

    const onFilterChange = (e, key) => {
        if (key === 'month') {
            setMonth(e.target.value)
        } else {
            setYear(e.target.value)
        }
        props.filterData({ month: key === 'month' ? e.target.value : month, year: key === 'year' ? e.target.value : year, page: 0 })
    }

    const applyFilter = () => {
        setShow(true)
        props.filterData({ page: 0 })
    }

    const { identifier } = props;

    return (
        <Toolbar>
            {
                identifier === "orderHistoryList" ?
                    <React.Fragment>
                        <Row className='w-100'>
                            <Col sm={12} md={11} lg={11} xs={12} xl={11}>
                                {(show && props.show) ? <h3>{`Showing Order History for ${month} ${year}`}</h3> : <h3>&nbsp;</h3>}
                            </Col>
                            <Col sm={12} md={1} lg={1} xs={12} xl={1}>
                                <div style={{ marginTop: "-10px", textAlign: 'right' }}>
                                    <IconButton aria-label="filter list">
                                        <FilterListIcon
                                            onClick={toggle}
                                            id="filter"
                                        />
                                    </IconButton>
                                </div>
                            </Col>
                        </Row>
                        <Popover
                            trigger="legacy" className="SignInPopOver" placement="bottom" isOpen={popoverOpen} target="filter" toggle={toggle}
                        >
                            <h5 className="font-weight-bold" style={{ color: '#000000de' }}>FILTERS</h5>
                            <CustomFilter
                                onFilterChange={onFilterChange}
                                handleApplyFilter={toggle}
                                applyFilter={applyFilter}
                                handleResetFilter={handleResetFilter}
                                month={month}
                                year={year}
                                filterFor='buyerOrderHistory'
                            />
                        </Popover>
                    </React.Fragment>
                    :
                    null
            }
        </Toolbar>
    );
}

const mapStateToProps = ({ buyer }) => {
    const { orderHistoryList } = buyer;
    return { orderHistoryList }
};

export default connect(mapStateToProps, { getOrderHistoryList })(EnhancedTableToolbar)