import React, { useEffect, useState } from 'react';
import NotificationItem from './NotificationItem';
import { getBuyerNotification } from 'actions/notification';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import AxiosRequest from 'sagas/axiosRequest'

const AppNotification = (props) => {
  const [fetch, setFetch] = useState(true)
  const [page, setPage] = useState(1)
  const [items, setItems] = useState('')
  const [dataL, setDataL] = useState(20)
  const [total, setTotal] = useState(0)
  const perPage = 20

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    if (items && items.length >= total) {
      setFetch(false);
      return;
    }
    let data = {
      page,
      perPage,
      user_type: props.type
    }
    let response = await AxiosRequest.axiosTokenSwitch('post', 'users/getNotification', '', data)
    if (response.data.error) {
    } else {
      const detail = response && response.data && response.data.detail ? response.data.detail : []
      setItems(items ? items.concat(detail) : detail)
      setTotal(response && response.data && response.data.total ? response.data.total : 0)
      setDataL(dataL + 10)
      setPage(page + 1)
    }
  };

  return (
    <div id="scrollableDiv" style={{ height: 300, overflow: 'auto', display: 'flex' }}>
      <InfiniteScroll
        dataLength={dataL}
        next={fetchData}
        hasMore={fetch}
        loader={!(total > 0 ? page > Math.round(total / perPage) : true) && <div style={{ textAlign: 'center' }}>
          <CircularProgress size={25} />
        </div>}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>}>
        <ul className="list-unstyled">
          {(items.length > 0 && total > 0) ?
            items && items.length > 0 && items.map((notification, index) => <NotificationItem key={notification._id} notification={notification} />) : <li><p className="sub-heading mb-0">No Notification found</p> </li>
          }
        </ul>
      </InfiniteScroll>
    </div>
  )
}

const mapStateToProps = ({ notification }) => {
  const { unreadNotificationCount, notificationData, unReadNotification, notiData } = notification;
  return { unreadNotificationCount, notificationData, unReadNotification, notiData }
};

export default (connect(mapStateToProps, { getBuyerNotification })(AppNotification));

