import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux'
import { getTransactionList } from 'actions/buyer';
import moment from 'moment';
import CustomFilter from 'components/Filter'
import CustomScrollbars from 'util/CustomScrollbars';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import { Grid, Button } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const Transaction = (props) => {
  const [page, setPage] = useState(1)
  const perPage = 50
  const [month, setMonth] = useState(moment().format("MMMM"))
  const [year, setYear] = useState(moment().format("GGGG"))
  const [tableData, setTableData] = useState([])

  const onFilterChange = (e, key) => {
    if (key === "month") {
      setMonth(e.target.value)
    } else {
      setYear(e.target.value)
    }
  }

  const handleResetFilter = () => {
    setMonth(moment().format("MMMM"))
    setYear(moment().format("GGGG"))
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      month: moment().format("MMMM"),
      year: moment().format("GGGG")
    }
    setPage(1)
    props.getTransactionList({ data })
  }

  const applyFilter = () => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      month: month,
      year: year
    }
    setPage(1)
    props.getTransactionList({ data })
  }

  useEffect(() => {
    let data = {
      page: 1,
      perPage,
      filter: '',
      month: month,
      year: year
    }
    props.getTransactionList({ data })
  }, [])

  const changePage = (page) => {
    let pages = page + 1
    let data = {
      page: pages,
      perPage: perPage,
      filter: '',
      searchText: '',
      month: month,
      year: year
    }
    setPage(pages)
    props.getTransactionList({ data })
  };

  const { transactionListData } = props;

  useEffect(() => {
    let data = []
    transactionListData && transactionListData.details && transactionListData.details.length > 0 && transactionListData.details[0] && transactionListData.details[0].data.map((dataOne, index) => {
      data.push({
        narration: dataOne.narration,
        amount: "₹" + (dataOne.settle_amount.toFixed(2)),
        status: <div className={` badge text-uppercase ${statusStyle(dataOne.status === "Settled" ? 'Success' : dataOne.status)}`}>{dataOne.status}</div>,
        date: moment(dataOne.createdAt).format('MMM Do YYYY')
      })
    })
    setTableData(data)
  }, [transactionListData])

  const statusStyle = (status) => {
    return status.includes("Pending") ? "text-white bg-grey" : status.includes("Success") ? "text-white bg-success" : "text-white bg-grey";
  }

  const columns = [
    {
      name: "narration",
      label: "Narration",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => {
            return (
              <CustomFilter
                onFilterChange={onFilterChange}
                applyFilter={applyFilter}
                handleResetFilter={handleResetFilter}
                month={month}
                year={year}
                filterFor='transaction'
              />
            )
          },
        }
      }
    }
  ];

  const options = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: true,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    page: page - 1,
    count: transactionListData && transactionListData.details && transactionListData.details.length > 0 && transactionListData.details[0] && transactionListData.details[0].metadata[0] && transactionListData.details[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS"
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default: break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
       <div style={{paddingTop:"23px"}}> 
       <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        </div>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }

  };

  return (
    <React.Fragment>
      <h1 style={{
        backgroundColor: "white",
        padding: "11px",
        marginBottom: "inherit",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        fontWeight: 500,
        paddingLeft: "24px",
        paddingTop: "20px",
      }}>
        Transactions
      </h1>
      <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
        <ThemeProvider theme={createTheme({ components: styles })}>
          <MUIDataTable
            title={`Showing Transactions for ${month} ${year}`}
            data={tableData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </CustomScrollbars>
    </React.Fragment>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { transactionListData } = buyer;
  return { transactionListData };
}
export default connect(mapStateToProps, { getTransactionList })(Transaction);