import React, { useState, useEffect } from "react";
import UserProfileCard from './userProfileData';
import Interests from "components/wall/Interests/index";
import Tickets from 'components/BTickets'
import Orders from 'components/BOrders'
import BulkRequests from 'components/bBulkRequests'
import Transaction from 'components/BTransactions'
import EditProfile from './editProfile';
import MediWallet from './MediWallet';
import Bnpl from './Bnpl';
import ComplainceForm from "./bCompliance/ComplainceForm";
import ProductRequest from 'components/BProductRequests';
import { connect } from 'react-redux';
import './index.css'
import CircularProgress from '@mui/material/CircularProgress';
import { getUserDetail } from 'actions'
import { getReviewList, getTransactionList, getMediWallet } from 'actions/buyer';
import { useLocation, useParams } from "react-router";
import BuyerContainer from 'components/buyerContainer'

const Profile = (props) => {
  const location = useLocation()
  const params = useParams()
  const [title, setTitle] = useState('Edit Profile')

  const changeTab = (value) => {
    setTitle(value)
    props.getUserDetail({ userType: "buyer" });
    const element = document.getElementById("buyerDetails")
    element.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (location.title && location.title === 'MediWallet') {
      setTitle(location.title)
    }
    if (location.state && location.state === "/MyCart") {
      setTitle('Orders')
    }
    if (location.pathname.includes("/view-seller")) {
      let data = {
        sellerId: params.id,
        sellerReviewList: true
      }
      props.getReviewList({ data })
    }
    else {
      props.getReviewList({})
    }
    const element = document.getElementById("buyerDetails")
    element.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const { users } = props;

  return (
    <BuyerContainer>
      <div className="app-wrapper" id="buyerDetails">
        {users !== "" ? <div className="jr-main-content">
          <div className="row">
            <div className=" col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 p-0 mt-1">
              <UserProfileCard
                styleName="pb-4"
                headerStyle="bg-gradient-primary"
                DataFromParent={users}
              />
              <Interests changeTab={changeTab} title={title} userDataFromParent={users} />
            </div>
            <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-12">
              <div className="jr-wall-postlist ">
                {
                  title === 'Edit Profile' &&
                  <EditProfile userDataFromParent={users} />
                }
                {
                  title === 'Product Requests' &&
                  <ProductRequest />
                }
                {
                  (title === 'Bulk Requests' && users && users.bulkRequest === true) &&
                  <BulkRequests />
                }
                {
                  title === 'Orders' &&
                  <Orders />
                }
                {
                  title === 'Ticketing' &&
                  <Tickets />
                }
                {
                  title === 'Transactions' &&
                  <Transaction />
                }
                {
                  title === 'MediWallet' &&
                  <MediWallet userDataFromParent={users} />
                }
                {
                  title === 'BNPL' &&
                  <Bnpl data={users} changeTab={changeTab} />
                }
                {
                  title === 'Compliance' &&
                  <ComplainceForm />
                }
              </div>
            </div>
          </div>
        </div> : <div className="loader-view">
          <CircularProgress />
        </div>}
      </div>
    </BuyerContainer>
  )
}

const mapStateToProps = ({ auth, seller, buyer }) => {
  const { userDetails } = seller
  const { user_details } = auth;
  const { reviewList, transactionListData, mediWallet } = buyer;
  let users = userDetails ? userDetails : user_details
  return { user_details, users, reviewList, transactionListData, mediWallet }
};

export default connect(mapStateToProps, { getUserDetail, getReviewList, getTransactionList, getMediWallet })(Profile);
