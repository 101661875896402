import {
    GET_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    GET_ORDER_HISTORY,
    GET_ORDER_HISTORY_SUCCESS,
    GET_DASHBOARD_SALES_STATS,
    GET_DASHBOARD_SALES_STATS_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    orderData: [],
    orderDetails: {},
    orderHistoryList: [],
    lastWeekRevenueOrders: [],
    lastThirtyDaysRevenueOrders: [],
    totalRevenueOrders: [],
    montlyRevenueOrders: [],
    totalValue: 0,
    text: '',
    cancelText: '',
    statsData: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ORDER: {
            return {
                ...state,
                loader: false,
                loading: true
            }
        }
        case GET_ORDER_SUCCESS: {

            return {
                ...state,
                orderData: action.payload.data,
                loading: false,
            }
        }
        case GET_ORDER_DETAILS: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_ORDER_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                orderDetails: action.payload.detail,
                text: action.payload.text,
                cancelText: action.payload.cancelText,
                totalValue: action.payload.totalValue
            }
        }
        case UPDATE_ORDER: {
            return {
                ...state,
                loader: false,
            }
        }
        case UPDATE_ORDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                orderDetails: action.payload.detail,
            }
        }
        case GET_ORDER_HISTORY: {
            return {
                ...state,
                loader: false,
                loading: true,
            }
        }
        case GET_ORDER_HISTORY_SUCCESS: {
            return {
                ...state,
                loading: false,
                orderHistoryList: action.payload.data,
            }
        }
        case GET_DASHBOARD_SALES_STATS: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_DASHBOARD_SALES_STATS_SUCCESS: {
            return {
                ...state,
                loading: false,
                lastWeekRevenueOrders: action.payload.lastWeekRevenueOrders,
                lastThirtyDaysRevenueOrders: action.payload.lastThirtyDaysRevenueOrders,
                totalRevenueOrders: action.payload.totalRevenueOrders,
                montlyRevenueOrders: action.payload.montlyRevenueOrders,
                statsData: action.payload
            }
        }
        default:
            return state;
    }
}
