import React from 'react';
import moment from 'moment'

const OrderTimeline = ({ styleName, color, timeLine, children }) => {
  const { date, status, description } = timeLine;

  return (
    <div className={`timeline-item timeline-time-item ${styleName}`}>
      <div className="timeline-time">{moment(date).format('DD MMM, YYYY')}</div>
      <div className={`timeline-badge bg-${color}`}>{children}</div>
      <div className="timeline-panel">
        <h4 className={`timeline-tile text-${color}`}>
          {status === "New" ? "Placed" : status}
        </h4>
        <p>{description}</p>
      </div>
    </div>
  )
};
export default OrderTimeline;