import React from 'react'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const headCells = [
  { id: 'productName', numeric: false, disablePadding: true, label: 'Product Name' },
  { id: 'manufacturer', numeric: false, disablePadding: false, label: 'Manufacturer' },
  { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
  { id: 'requestedDate', numeric: false, disablePadding: false, label: 'Request Date' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
  const { identifier } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
        {identifier === 'productRequestList' ? <TableCell key='action' align={'left'} padding={'default'} >Action</TableCell> : <TableCell key='stock' align={'left'} padding={'default'} >Availability</TableCell>}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;