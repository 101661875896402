import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from 'components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddStaff from './addStaff';
import AddGroup from 'components/groups/addGroup';
import EditStaff from './editStaff';
import DeleteStaff from 'components/inventory/deleteInventory';
import { listStaff } from 'actions/seller';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import AppConfig from 'constants/config'
import SellerContainer from "components/sellerContainer"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'components/PaginationCount';

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "email",
    label: "Email ID",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "group",
    label: "Group",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
    }
  }
];

const StaffList = (props) => {
  const perPage = 50
  const [edit, setEdit] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [add, setAdd] = useState(false)
  const [addGroup, setAddGroup] = useState(false)
  const [searchedText, setSearchedText] = useState('')
  const [page, setPage] = useState(0)
  const [selectedData, setSelectedData] = useState({})

  useEffect(() => {
    props.listStaff({ userId: '', searchText: '', page: 1, perPage })
  }, [])

  const changePage = (page) => {
    let pages = page + 1
    props.listStaff({ userId: '', searchText: searchedText, page: pages, perPage: perPage })
    setPage(page)
  };

  const handleSearch = (searchText) => {
    props.listStaff({ userId: '', searchText: searchText, page: page + 1, perPage: perPage })
    setSearchedText(searchText)
  };

  const handleClick = (key, value) => {
    setSelectedData(value)
    if (key === 'edit') {
      setEdit(!edit)
    }
    if (key === 'delete') {
      setDeleteOpen(!deleteOpen)
    }
    if (key === 'add') {
      setAdd(!add)
    }
    if (key === 'addGroup') {
      setAddGroup(false)
    }
  }

  const button = (data) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button className={'text-primary'} onClick={() => handleClick('edit', data)} > Edit</Button>
      <Button className={'text-danger'} onClick={() => handleClick('delete', data)}> Delete</Button>
      <Button className={'text-success'} onClick={() => handleReset(data)}> Reset </Button>
    </ButtonGroup>
  )

  const addGroupFn = () => {
    setAddGroup(true)
    setAdd(false)
  }

  const handleReset = (data) => {
    axios({
      method: 'post',
      url: `${AppConfig.baseUrl}seller/addEditStaffMember`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token')
      },
      data: {
        id: data._id,
        reset: true,
        email: data.email
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
      }
    })
  }

  const { staffList } = props;
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: staffList && staffList[0] && staffList[0].metadata && staffList[0].metadata[0] && staffList[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const listData = [];
  staffList.length > 0 && staffList[0].data.length > 0 && staffList[0].data.map((data, index) => {
    listData.push([data.first_name, data.email, data.Groups.name, button(data)]);
  });

  return (
    <SellerContainer>
      <ContainerHeader title="Staff Members" />
      {
        props.loading === false ?
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              title={<Button className={'text-primary'} selectedData={selectedData} onClick={() => handleClick('add')}> Add Staff Member+</Button>}
              data={listData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          :
          <div className="loader-view" style={{ textAlign: "center", marginTop: "300px" }}>
            <CircularProgress />
          </div>
      }

      {
        edit &&
        <EditStaff selectedData={selectedData} edit={edit} title={'Edit Staff Member'} handleClick={handleClick} />
      }
      {
        add &&
        <AddStaff addGroup={addGroupFn} selectedData={selectedData} add={add} title={'Add Staff Member'} handleClick={handleClick} />
      }
      {
        deleteOpen &&
        <DeleteStaff selectedData={selectedData} delete={deleteOpen} deleteFor={'staff'} handleClick={handleClick} />
      }
      {
        addGroup &&
        <AddGroup add_group={addGroup} permission={props.permission} title={'Add Group'} handleClick={handleClick} />
      }

    </SellerContainer>
  );
}

const mapStateToProps = ({ seller }) => {
  const { staffList, loading } = seller;
  return { staffList, loading }
};

export default connect(mapStateToProps, { listStaff })(StaffList)