import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './index.css'
import ComponentTable from './ComponentTable';
import CollapseComponent from './CollapseComponent';

const TableComponent = (props) => {
    const [collapse, setCollapse] = useState(false)

    const OnCollapseProject = () => {
        setCollapse(!collapse)
    }

    const { itemListFromParent, row } = props;

    return (
        <React.Fragment>
            <TableRow
                hover
                className='cursor-pointer'
                key={row.name}
                align={'left'}
            >
                <TableCell onClick={OnCollapseProject} align={'left'} component="th" id={props.labelId} scope="row" >
                    <div className="d-contents">
                        <ComponentTable value={row.status} handleCollapse={OnCollapseProject} />
                        {row.orderId}
                    </div>

                </TableCell>
                <TableCell align="left">{row.orderType}</TableCell>
                <TableCell align="left">{row.seller}</TableCell>
                <TableCell onClick={OnCollapseProject} align="left">
                    {row.tableAmout}
                </TableCell>
                <TableCell onClick={OnCollapseProject} align="left">{row.description}</TableCell>
                <TableCell onClick={OnCollapseProject} align="left">{row.status}</TableCell>
            </TableRow>
            <CollapseComponent
                collapse={collapse}
                value={row}
                show={props.show}
                mainOrderData={itemListFromParent}
                filterObj={props.filterObj}
            />
        </React.Fragment>
    )
}

export default TableComponent;