// import AxiosFunction from '../sagas/axiosRequest'
export const required = value => {
    return value ? undefined : 'This field is required'
  }
  export const validatePincode = value=>{
    let pin = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/
   return value? !pin.test(value)?"Invalid pincode":'':'This field is required'
  }
  export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined
  export const maxLength15 = maxLength(15)
  export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : undefined
  export const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined
  export const minValue18 = minValue(18)
  export const minValue4 = minValue(4)
  export const minValue10 = minValue(10)
  export const minLength = min => value =>
    value && value.length < min ? `Must contain at least ${min} characters.` : undefined;
  export const minLength6 = minLength(6);
  
  export const validatePhone = value => {
    return value !== undefined ? (value.length < 10 || value.length !== 10 || value.length > 10) ? 'Please enter valid Mobile number' : '' : 'This field is required'
  }
  export const maxPurchase = matchName =>(value, allValues, props)=>Number(allValues.minimumOrderQty)>Number(allValues.maximumOrderQty)?'Maximum quantity should be greater than minimum quantity':''
  
  export const emailField = (value) => {
    return (value ?
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' :value.toLowerCase()==value?"": 'Email should be in lower Case' : 'Email is required')
  }
  export const tooOld = value =>
    value && value > 65 ? 'You might be too old for this' : undefined
  export const aol = value =>
    value && /.+@aol\.com/.test(value) ?
      'Really? You still use AOL for your email?' : undefined
  export const passwordMatch =  (value, allValues) => {
    return allValues.confirmPassword !== allValues.password
      ? //? `This field must match with ${matchName} field`
      "Passwords do not match."
      : undefined;
  }
  
  export const confirmPasswordMatch = matchName => (value, allValues, props) => {
    return allValues.confirmPassword !== allValues.newPassword
      ? //? `This field must match with ${matchName} field`
      "Passwords do not match."
      : undefined;
  }
  export const fassaiLicNoRequired = matchName =>(value,allValues,props)=>{
    return allValues.fassaiLic?!allValues.fassaiLicNo?'This field is required':undefined:undefined
  }
  
  export const gstLicNoRequired = matchName =>(value,allValues,props)=>{
    return allValues.gstLic?!allValues.gstLicNo?'This field is required':undefined:undefined
  }
  
  export const accountNumber = (value) => {
    return (value === undefined ? 'This field is required' : isNaN(value) ? 'Please enter valid account number' : value.length < 10 ? 'Please enter valid account number' : '')
  }
  
  export const ifscCode = (value) => {
    return (value === undefined ? 'This field is required' : !/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/.test(value) ? 'Please enter valid IFSC Code ' : '')
  }
  
  export const emailPhone = (value) => {
    return (value === undefined ? 'This field is required' : isNaN(value) ? !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter valid Email address' : '' : value.length < 10 ? 'Please enter valid Mobile number' : '')
    // return (value === undefined ? 'This field is required' : isNaN(value) ? !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter valid Email address': value.length < 10 ? 'Please enter valid Mobile number' : '' : '') 
  }
  
  export const validatePassword = (value) => {
    return (value === undefined ? 'This field is required' : value.length < 6 ? 'Must contain at least 6 characters.' : '')
  }
  
  export const validateData = (values) => {
    const errors = {};
    const requiredFields = [
      'email'
    ];
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
    return errors;
  } 
  export const noValidations = (value)=> {return ''}
  
  export const percentageValidation = value => {
    if(parseInt(value) < 0) {
      return "% off cannot be less than 0."
    } else if(parseInt(value) > 100) {
      return "% off cannot be greater than 100."
    } else {
      return ""
    }
  }
  export const specailChar = (value) => {
    return(value === undefined ? 'This field is required' : !/^[a-zA-Z 0-9]*$/.test(value) ? 'Special characters not allowed' : '' 
    )
  }