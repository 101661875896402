import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Col, Row, Input } from 'reactstrap';
import TicketDetailsHeader from './TicketDetailsHeader';
import Paper from '@mui/material/Paper';
import AxiosRequest from 'sagas/axiosRequest';
import helperFunction from 'constants/helperFunction';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import TimeLine from './timeLine';
import { EditOutlined, ErrorOutlineOutlined, AddCircleOutlineOutlined, CheckCircleOutlineOutlined, CheckOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import SellerContainer from "components/sellerContainer"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const TicketDetails = (props) => {
    const params = useParams()
    const [comment, setComment] = useState('')
    const [ticketData, setTicketData] = useState('')
    const [commentData, setCommentData] = useState('')
    const [submitBoolean, setSubmitBoolean] = useState(false)

    const getTicketData = async () => {
        let data = { Id: params.id }
        let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/getTicketDetails', '', data)
        if (result.data.error) {
            NotificationManager.error(result.data.title);
        } else {
            setTicketData(result.data.data)
            setCommentData(result.data.data.comments)
        }
    }

    useEffect(() => {
        getTicketData()
    }, [])

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (comment.length > 0 && e.key === 'Enter' && !submitBoolean) {
            handleReply();
        }
    }

    const handleReply = async () => {
        setSubmitBoolean(true);
        let data = { Id: ticketData._id, comment: comment }
        if (!comment) {
            NotificationManager.error('Reply is required.');
        } else {
            let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/addComment', '', data)
            if (result.data.error) {
                NotificationManager.error(result.data.title);
                setComment('')
                setSubmitBoolean(false)
            } else {
                NotificationManager.success(result.data.title)
                setCommentData(result.data.data.comments)
                setComment('')
                setSubmitBoolean(false)
            }
        }
    }

    const getTimeLine = (data) => {
        return <div className='timeline-section timeline-center' style={{ maxHeight: '490px', minHeight: '161px', overflow: 'auto', overflowX: 'hidden' }}>
            {
                data && data.length > 0 ?
                    data.map((value, key) => {
                        return <TimeLine styleName={(key === 0 || key === 2 || key === 4 || key === 6 || key === 8 || key === 10) ? 'timeline-inverted' : ''} timeLine={value} color={(value.action === 'Seller open' || value.action === 'Buyer open' || value.action === 'RTO open') ? 'bg-primary' : value.action === 'Ticket created' ? 'bg-danger' : value.action === 'Ticket updated' ? 'bg-warning' : value.action === 'Ticket closed' ? 'bg-success' : 'bg-info'}>
                            {
                                (value.action === 'Seller open' || value.action === 'Buyer open' || value.action === 'RTO open') ? <ErrorOutlineOutlined /> : value.action === 'Ticket created' ? <AddCircleOutlineOutlined /> : value.action === 'Cancelled' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 0 }} class="zmdi zmdi-close"></i></div> : value.action === 'Ticket updated' ? <EditOutlined /> : value.action === 'Ticket closed' ? <CheckOutlined /> : (value.action === 'Seller close' || value.action === 'Buyer close' || value.action === 'RTO close') ? <CheckCircleOutlineOutlined /> : <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-truck"></i></div>}
                        </TimeLine>
                    }) : ''
            }
        </div>
    }

    let width = window.innerWidth <= 1366 ? { paddingLeft: '45px' } : { paddingLeft: '0px' };

    return (
        <SellerContainer>
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <TicketDetailsHeader ticketDetails={ticketData} />
                <div className="row">
                    <div className="col-12">
                        <div className="jr-card chart-user-statistics bg-primary darken-4 text-white">
                            <div className="orderDetails px-4 mb-4">
                                <Row className="pb-4 pl-4">
                                    <Col xs={12} xl={4} sm={12} md={5} lg={5} className="orderDetailsMainCol">
                                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> Order details</h3>
                                        <div className="p-1 ml-1">
                                            Ticket No: {ticketData.ticketNo}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Order Id: {ticketData.type === 'order' ? ticketData.order.orderNo : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Order Date: {ticketData.type === 'order' ? moment(ticketData.order.order_id.createdAt).format('DD/MM/YYYY') : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Original Invoice Amt: {ticketData.type === 'order' ? `₹${(ticketData.orderAmount).toLocaleString('en-IN', { maximumFractionDigits: 2 })}` : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Revised Invoice Amt: {ticketData.type === 'order' ? `₹${(ticketData.revisedInvoiceAmt ? ticketData.revisedInvoiceAmt : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}` : 'N/A'}
                                        </div>
                                    </Col>
                                    <Col xs={12} xl={4} sm={12} md={5} lg={5} className="orderDetailsMainCol">
                                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> Buyer details</h3>
                                        <div className="p-1 ml-1">
                                            Name: {ticketData.buyer && ticketData.buyer.buyerName ? ticketData.buyer.buyerName : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            RTO Chargeable: {ticketData.rtoChargeable && ticketData.rtoChargeable === 'buyer' ? 'Yes' : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            RTO Amount: {ticketData.rtoAmt && ticketData.rtoAmt > 0 && ticketData.rtoChargeable === 'buyer' ? `₹${ticketData.rtoAmt}` : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Adjustment Amount: {ticketData.buyerAdjustmentAmt && ticketData.buyerAdjustmentAmt > 0 ? `₹${(ticketData.buyerAdjustmentAmt).toLocaleString('en-IN', { maximumFractionDigits: 2 })}` : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Status: {ticketData.type === 'other' && ticketData.status === 'close' ? 'N/A' : ticketData.buyerStatus && ticketData.buyerStatus.length > 0 ? (ticketData.buyerStatus === 'close' ? 'closed' : ticketData.buyerStatus).toUpperCase() : 'N/A'}
                                        </div>
                                    </Col>
                                    <Col xs={12} xl={4} sm={12} md={5} lg={5} className="orderDetailsMainCol">
                                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> Seller details</h3>
                                        <div className="p-1 ml-1">
                                            Name: {ticketData.seller && ticketData.seller.sellerName ? ticketData.seller.sellerName : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            RTO Chargeable: {ticketData.rtoChargeable && ticketData.rtoChargeable === 'seller' ? 'Yes' : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            RTO Amount with GST: {ticketData.gstOnRto && ticketData.gstOnRto > 0 && ticketData.rtoChargeable === 'seller' ? `₹${(ticketData.rtoAmt + ticketData.gstOnRto)}` : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Adjustment Amount: {ticketData.sellerAdjustmentAmt && ticketData.sellerAdjustmentAmt > 0 ? `₹${(ticketData.sellerAdjustmentAmt).toLocaleString('en-IN', { maximumFractionDigits: 2 })}` : 'N/A'}
                                        </div>
                                        <div className="p-1 ml-1">
                                            Status: {ticketData.type === 'other' && ticketData.status === 'close' ? 'N/A' : ticketData.sellerStatus && ticketData.sellerStatus.length > 0 ? (ticketData.sellerStatus === 'close' ? 'closed' : ticketData.sellerStatus).toUpperCase() : 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Row>
                <Col xs={12} xl={4} sm={12} md={4} lg={4}>
                    <Card sx={{ overflowX: 'auto', overflow: 'auto', height: '100%', maxHeight: '600px', minHeight: '500px', borderBottomRightRadius: '15px', borderBottomLeftRadius: '15px' }} >
                        <CardContent>
                            <div style={{ maxHeight: '500px' }}>{getTimeLine(ticketData.activities)}</div>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} xl={8} sm={12} md={8} lg={8} className="mt-3 mt-md-0">
                    <Paper className={'p-3'} style={{ maxHeight: '550px' }}>
                        <div style={{ maxHeight: '450px', overflow: 'auto', overflowX: 'hidden' }}>
                            {commentData && commentData.map((data) => <>
                                <Row className='pr-4 pt-4 pl-4 pb-0'>
                                    <Col xs={4} xl={1} sm={2} md={2} lg={2} style={{ margin: 'auto' }} className='mt-0'>
                                        <div className="user-img-container rounded-circle avatar size-50" style={{ background: helperFunction.getGradient(data.user_id.backGroundColor), textAlign: 'center' }}>
                                            <span style={{ fontSize: 'x-large', color: 'white' }}>{(data.name).includes('ADMIN') ? 'MS' : helperFunction.getNameInitials(`${data.name}`)}</span>
                                        </div>
                                    </Col>
                                    <Col xs={8} xl={11} sm={10} md={10} lg={10} style={width}>
                                        <Row>
                                            <Col xs={12} xl={12} sm={12} md={12} lg={12} className="pl-2">
                                                <h4 style={{ fontWeight: '500', marginBottom: '5px' }}>{(data.name).includes('ADMIN') ? 'MEDIMNY STAFF' : (data.name).toUpperCase()}</h4>
                                            </Col>
                                            <Col xs={12} xl={12} sm={12} md={12} lg={12} className="pl-2">
                                                <h6> {data.description}</h6>
                                            </Col>
                                            <Col xs={12} xl={12} sm={12} md={12} lg={12} className="pl-2">
                                                <h6 className='text-muted'>{moment(data.date).format(' D MMM, YYYY h:mm a')}</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                            )}
                        </div>
                        {ticketData.status === 'open' &&
                            <Row className='pr-4 pt-2 pl-4 pb-4'>
                                <Col md={11} xl={11} xs={11} sm={11} lg={11}>
                                    <Input type="text" name="issueReply" id="exampleSelect" placeholder='Reply here.' onChange={handleChange} value={comment} onKeyDown={handleKeyDown} />
                                </Col>
                                <Col md={1} xl={1} xs={1} sm={1} lg={1} style={{ textAlign: 'left' }} className='pt-1'>
                                    <Button className={'text-primary'} style={{ padding: '2px 15px' }} onClick={() => handleReply()} disabled={submitBoolean}>
                                        Reply
                                    </Button>
                                </Col>
                            </Row>}
                    </Paper>
                </Col>
            </Row>
        </SellerContainer>
    )
}

export default TicketDetails