import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import SidenavContent from './SidenavContent';
import UserInfo from 'components/UserInfo';
import { Button, useMediaQuery, useTheme } from '@mui/material';

const SideNav = (props) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onToggleCollapsedNav = (e) => {
    props.setOpen(!props.open)
  };
  const handleToggleDrawer = () => {
    props.setOpen(!props.open);
  };

  return (
    <div className={`app-sidebar ${isMobile?"d-none": "d-flex"}`}>
      <Drawer
        className="app-sidebar-content"
        variant={`${isMobile?"temporary":"permanent"}`}
        open={props.open}
        onClose={isMobile?handleToggleDrawer:""}
        classes={{
          paper: 'side-nav',
        }}
      >
        <UserInfo />
        <SidenavContent />
        <div className='medilogo-container'>
          <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
        </div>
      </Drawer>
    </div>
  );
}

export default SideNav;

