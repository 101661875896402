import React, { useState } from 'react'
import Form1 from 'components/BuyerSignUp/form1'
import Form2 from 'components/BuyerSignUp/form2'
import { NotificationManager } from 'react-notifications';
import CustomScrollbars from 'util/CustomScrollbars'
import axios from 'constants/axios';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import helperFunction from 'constants/helperFunction';
import moment from "moment";
import Button from '@mui/material/Button';
import { Form } from 'react-final-form'
import { Col, Row } from 'reactstrap';

const getSteps = () => {
  return ['Welcome', 'Compliance Form'];
}

const BuyerSignUp = (props) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)

  function previousPage() {
    setPage(page - 1)
  }

  const onChangeHandle = async (data) => {
    if (page !== 1) {
      return setPage(page + 1)
    }
    let rgbValue = helperFunction.getRGB();
    let postData = {
      drugLic20BLicNo: data.drugLic20BLicNo,
      drugLic21BLicNo: data.drugLic21BLicNo,
      fassaiLicNo: data.fassaiLicNo,
      gstLicNo: data.gstLicNo,
      password: data.password,
      confirmPassword: data.confirmPassword,
      email: (data.email).toLowerCase(),
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      drugLic20BExpiry: data.drugLic20BExpiry ? data.drugLic20BExpiry : moment().add(2, 'M'),
      drugLic21BExpiry: data.drugLic21BExpiry ? data.drugLic21BExpiry : moment().add(2, 'M'),
      fassaiLicExpiry: data.fassaiLicExpiry ? data.fassaiLicExpiry : moment().add(2, 'M'),
      drugLic20B: data.drugLic20B,
      drugLic21B: data.drugLic21B,
      gstLic: data.gstLic,
      fassaiLic: data.fassaiLic,
      user_type: data.user_type,
      user_address: data.user_address + " " + (data.user_address1 ? data.user_address1 : ''),
      user_state: data.user_state,
      red: rgbValue[0],
      green: rgbValue[1],
      blue: rgbValue[2],
      user_pincode: data.user_pincode,
      user_city: data.user_city,
      company_name: data.company_name
    }

    const result = await axios.post('/users/register', postData, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    if (result.data.error) {
      NotificationManager.error(result.data.title)
    } else {
      NotificationManager.success(result.data.title, '', 200);
      localStorage.setItem('verifyToken', result.data.token);
      localStorage.setItem('isBuyerVerification', true)
      return navigate('/verifyToken')
    }
  }

  return (
    <CustomScrollbars>
      <div className="col-xl-12 col-lg-12 signinContainer" >
        <div className="jr-card signinCard mt-5">
          <React.Fragment>
            <div className="login-header mb-0">
              <div className="app-logo">
                <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
              </div>
            </div>
            <Stepper activeStep={page} alternativeLabel={true} className="horizontal-stepper-linear">
              {getSteps().map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Form
              onSubmit={onChangeHandle}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  {page === 0 && <Form1 />}
                  {page === 1 && <Form2 />}
                  <Row>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
                      <div className='list-action mt-3'>
                        {
                          !submitting && page !== 0 &&
                          <Button type="button" variant='contained' color='primary' className='m-2 signin' size='medium' onClick={previousPage}>Previous</Button>
                        }
                        {
                          page !== 1 &&
                          <Button style={{ color: 'white' }} type='submit' variant='contained' color='primary' className='m-2 signin' size='medium'> Next </Button>
                        }
                        {
                          page === 1 &&
                          <Button style={{ color: 'white' }} type='submit' variant='contained' disabled={submitting} color='primary' className='m-2 signin' size='medium'>{submitting ? 'Processing' : 'Finish'}</Button>
                        }
                      </div>
                    </Col>
                    {
                      page === 0 &&
                      <Col className='signInFooterend'>
                        <span style={{ textAlign: 'end' }}>
                          <p>Already have an account? <a href='/'>Sign in</a></p>
                        </span>
                      </Col>
                    }
                  </Row>
                </form >
              )}
            />
          </React.Fragment>
        </div>
      </div>
    </CustomScrollbars>
  )
}

export default BuyerSignUp