import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import ContainerHeader from 'components/ContainerHeader';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import AxiosRequest from 'sagas/axiosRequest'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { connect } from 'react-redux'
import ReactStrapTextField from 'components/ReactStrapTextField';
import ReactStrapSelectField from 'components/reactstrapSelectField';
import { getProductRequest, addRequestProduct } from 'actions'
import { Field, Form } from 'react-final-form'
import { required } from 'constants/validations';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import SellerContainer from "components/sellerContainer"
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const columns = [
  {
    name: "title",
    label: "Title",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "company",
    label: "Company",
    options: {
      filter: false,
      sort: false
    }
  },

  {
    name: "requested",
    label: "Request Date",
    options: {
      filter: false,
      sort: false
    }
  },

  {
    name: "status",
    label: "Status",
    options: {
      filter: false,
      sort: false
    }
  },

  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false
    }
  }
];

const ProductRequest = (props) => {
  const perPage = 50
  const [add, setAdd] = useState(false)
  const [gstArray, setGstArray] = useState([])
  const [productCategoryArray, setProductCategoryArray] = useState([])
  const [productTypeArray, setProductTypeArray] = useState([])
  const [page, setPage] = useState(0)
  const [searchedText, setSearchedText] = useState('')

  useEffect(() => {
    let data = AxiosRequest.axiosHelperFunc('get', 'seller/getDataforProductRequest', '', '');
    props.getProductRequest({ searchText: searchedText, page: 1, perPage: perPage })
    data.then((result) => {
      setGstArray(result.data ? result.data.gst : [])
      setProductCategoryArray(result.data ? result.data.productCategory : [])
      setProductTypeArray(result.data ? result.data.productType : [])
    })
  }, [])

  const handleClick = (key) => {
    setAdd(!add)
  }

  const handleRequestClose = () => {
    setAdd(false)
  }

  const changePage = (page) => {
    let pages = page + 1
    props.getProductRequest({ userId: '', searchText: searchedText, page: pages, perPage: perPage })
    setPage(page)
  };

  const handleSearch = (searchText) => {
    props.getProductRequest({ userId: '', searchText: searchText, page: 1, perPage: perPage })
    setSearchedText(searchText)
    setPage(0)
  };

  const onSubmit = (values) => {
    let data = {
      name: values.name,
      companyName: values.companyName,
      chemicalCombination: values.chemicalCombination,
      gst: values.GST,
      mrp: values.MRP,
      ptr: values.PTR,
      productType: values.type,
      productCategory: values.productCategory,
    }
    props.addRequestProduct({ data, listProduct: { searchText: '', page: 1, perPage: 50 } });
    handleRequestClose()
  }

  const cancelClicked = async (data) => {
    let status = 'cancelled'
    let token = localStorage.getItem('token');
    let changedData = {
      id: data._id,
      status: status,
    }
    await axios({
      method: 'POST',
      url: 'seller/cancelProductRequest',
      data: changedData,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(`Request cancelled sussessfully !`)
        props.getProductRequest({ searchText: searchedText, page: page + 1, perPage: perPage })
      }
    })
      .catch(error => {
        NotificationManager.error('Something Went Wrong !')
      });
  }

  const { requestedProducts } = props;

  const statusStyle = (status) => {
    return status.includes("pending") ? "text-primary" : status.includes("approved") ? "text-success" : status.includes("declined") ? "text-danger" : status.includes("cancelled") ? "text-grey" : "text-primary";
  }

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: requestedProducts && requestedProducts[0] && requestedProducts[0].metadata && requestedProducts[0].metadata[0] && requestedProducts[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/> 
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  let data = [];
  requestedProducts.length > 0 && requestedProducts[0].data.length > 0 && requestedProducts[0].data.map((data1, index) => {
    data.push([
      data1.name,
      data1.companyName,
      moment(data1.createdAt).format('DD/MM/YYYY HH:mm A'),
      <div className={` badge text-uppercase ${statusStyle(data1.status)}`}>{data1.status}</div>,
      <Button onClick={() => cancelClicked(data1)} disabled={data1.status === 'pending' ? false : true} className={data1.status === 'pending' ? 'bg-primary text-white' : 'bg-grey text-white'}>CANCEL</Button>
    ])
  })

  return (
    <SellerContainer>
      <ContainerHeader title="Product Requests" />
      {
        props.loading === false ?
          <MUIDataTable
            title={<Button className={'text-primary'} onClick={() => handleClick('add')}> Add Product +</Button>}
            data={data}
            columns={columns}
            options={options}
          />
          :
          <div className="loader-view" style={{ textAlign: "center", marginTop: "300px" }}>
            <CircularProgress />
          </div>
      }

      <Dialog open={add} onClose={handleRequestClose} fullWidth={true} maxWidth={'md'}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                Add product request
                <DialogContentText className='mt-3'>
                </DialogContentText>
              </DialogTitle>
              <DialogContent>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <Field id="name" name="name" type="text"
                      component={ReactStrapTextField} label={"Name"}
                      validate={required}
                    />
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <Field id="chemicalCombination" name="chemicalCombination" type="text"
                      component={ReactStrapTextField} label={"Chemical Combination"}
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <Field id="companyName" name="companyName" type="text"
                      component={ReactStrapTextField} label={"Company Name"}
                      validate={required}
                    />
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={6} lg={6}>
                    <Field id="GST" name="GST" type="select"
                      component={ReactStrapSelectField} label={"GST"}
                      validate={required}
                    >
                      <option value="">Select GST</option>
                      {
                        gstArray.map((val, index) =>
                          <option value={val._id} key={val._id}>{val.name}</option>
                        )
                      }
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} xs={12} lg={6} xl={6}>
                    <Field id="type" name="type" type="select"
                      component={ReactStrapSelectField} label={"Type"}
                      validate={required}
                    >
                      <option value="">Select Type</option>
                      {
                        productTypeArray.map((val, index) =>
                          <option value={val._id} key={val._id}>{val.name}</option>
                        )
                      }
                    </Field>

                  </Col>
                  <Col sm={12} md={6} xs={12} lg={6} xl={6}>
                    <Field id="type" name="productCategory" type="select"
                      component={ReactStrapSelectField} label={"Product Category"}
                      validate={required}
                    >
                      <option value="">Product Category</option>
                      {
                        productCategoryArray.map((val, index) =>
                          <option value={val._id} key={val._id}>{val.name}</option>
                        )
                      }
                    </Field>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <React.Fragment>
                  <Button type='submit' color='primary'>
                    Add
                  </Button>
                </React.Fragment>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </SellerContainer>
  );
}

const mapStateToProps = ({ seller }) => {
  const { requestedProducts, loading } = seller;
  return { requestedProducts, loading }
};

export default connect(mapStateToProps, { getProductRequest, addRequestProduct })(ProductRequest)
