import React, { useRef, useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import BuyerContainer from 'components/buyerContainer';

const Privacy = (props) => {
    const myRef = useRef()

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <BuyerContainer>
            <div className="app-wrapper" ref={myRef}>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <ContainerHeader title="Privacy & Cookies" />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 signinContainer  " >
                        <div className="jr-card Terms Of Use">
                            <div className="animated slideInUpTiny animation-duration-3 ">
                                <p style={{ color: "#5b5b5b", fontSize: '15px', textAlign: 'justify' }} >
                                    Your visit to this Website <em>(defined below)</em> is subject to this Privacy Policy and Terms of Use available at <a href='javascript: void(0)'>www.medimny.com</a><br />
                                    This website <a href='javascript: void(0)'>www.medimny.com</a> and all the web–pages, hyperlinks, tools and mobile application provided
                                    thereunder (the <em>“Website”</em>) is owned by Felicitas Digitech Private Limited <em>(“our”, “us”, “we”,“Company”)</em>.<br />
                                    We value your trust and respect your privacy. Unless otherwise defined in this Privacy Policy, terms used
                                    in this Privacy Policy have the same meanings as in our Terms of Use. This Privacy Policy provides you
                                    with details about the manner in which your data is collected, stored and used by us. You are advised toread this Privacy Policy carefully.<br />
                                    BY VISITING OUR WEBSITE, YOU EXPRESSLY GIVE US CONSENT TO USE AND DISCLOSE YOUR
                                    PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY. IF YOU DO NOTAGREE TO THE TERMS OF THE POLICY, PLEASE DO NOT USE OR ACCESS OUR WEBSITE.<br />
                                    This Privacy Policy is published by us, in compliance of:<br />
                                    1. Section 43A of the Information Technology Act, 2000; and<br />
                                    2. Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures andSensitive Personal Information) Rules, 2011<br />
                                    <strong>General</strong><br />
                                    We will not sell, share or rent your personal information to any third party or use your email
                                    address/mobile number for unsolicited emails and/or SMS. Any emails and/or SMS sent by us will only
                                    be in connection with the provision of agreed Services, Birkenstock Products and this Privacy Policy. To
                                    carry out general marketing we would be sending out emails to users registered on our Website (or by any
                                    means and in any media, including, but not limited to, on our Website or through our merchants or in
                                    advertisements / marketing campaigns / any form of audio or visual media or websites). We reserve the
                                    right to communicate your personal information to any third party that makes a legally compliant request for its disclosure.<br />
                                    <strong>Collection of Information</strong><br />
                                    In order to provide our Services, we may collect the following types of information:<br />
                                    <ul>
                                        <li>
                                            User information – When you use the Services, we automatically receive and record information
                                            from your device and, applicable browser. Such information may include your contacts, the
                                            location of your device, text messages, your device storage, your IP address, cookie information,
                                            software and hardware attributes, contacts, and unique device ID. Most of the information we
                                            collect during your use of our Website, such as your device and hardware IDs and device type, the
                                            content of your request, and basic usage stats about your device and general use of our Services
                                            does not by itself identify you to us, though it may be unique or consist of or contain information
                                            that you consider personal. Specifically, we collect, retain and use information collected, retained
                                            and shared through Facebook platform, Twitter, Google, and YouTube, according to the applicable terms and conditions of their privacy policies.<br />
                                            You are not ordinarily required to register or provide personal information in order to access the
                                            Website, though certain functionalities such as a membership program, through which purchasers
                                            of products may receive discounts on online purchases, participating in any contests, etc., may require registration.
                                        </li><br />
                                        <li>
                                            User ID – In order to avail the Services, you shall have to sign up on the Website.
                                        </li><br />
                                        <li>
                                            User history and preferences – We save your query terms as well as the experience/category
                                            selected, these may be associated with the unique User ID of your device. We also save your preferences as recorded through your use of the Services and use them for personalization features.
                                        </li><br />
                                        <li>
                                            Cookies – When you use our Services, we may send one or more cookies. We use cookies to
                                            improve the quality of our service by storing user preferences and tracking user information.
                                            Certain of our Birkenstock Products and Services allow you to download and/or personalize the
                                            content you receive from us. For Birkenstock Products and Services, we will record information about your preferences, along with any information you provide yourself.
                                        </li><br />
                                    </ul>
                                    <strong>Use of Information</strong><br />
                                    <ul>
                                        <li>
                                            The personal information we collect allows us to keep you posted on our latest announcements,
                                            upcoming events, including confirmations, security alerts and support, and administrative
                                            messages. It also helps us to improve our Services. If you do not want to be on our mailing list, you can write us a mail at support@medimny.com mentioning your preferences.
                                        </li><br />
                                        <li>
                                            From time to time, we may use your personal information to send important notices, such as
                                            communications and changes to our terms, conditions, and policies. Since this information is important to your interaction with us, you cannot opt-out of receiving these communications.
                                        </li><br />
                                        <li>
                                            We may also use personal information for internal purposes such as data analysis and research to improve our Services, Birkenstock Products and customer communication.
                                        </li><br />
                                        <li>
                                            We collect non-personally identifiable information about you generally to improve the features
                                            and functionality of the Website. We may analyze trends through aggregated demographic and
                                            psychographic offers to you. We may also share this non-personally identifiable information with
                                            our partners to allow them to assist us in delivering tailored advertisements, promotions, deals,
                                            discounts, and other offers to you when you use the Services. We also collect information from you
                                            when you participate in sweepstakes, games or other promotions offered on our Website.
                                        </li>
                                        <li>
                                            We may also collect information about you through other methods, including research surveys or
                                            information we obtain from third parties, including verification services, data services, as well as public sources.
                                        </li>
                                    </ul><br />
                                    <strong>Sharing of Personal Information</strong><br />
                                    We only share personal information with other companies or individuals in the following limited circumstances:<br />
                                    <ul>
                                        <li>
                                            We may share with third parties the location of your device in order to improve and personalize your experience of the Services.
                                        </li><br />
                                        <li>
                                            We may share with third parties, certain pieces of non-personal information, such as the number
                                            of users who used a particular Service, users who clicked on a particular advertisement or who
                                            skipped a particular advertisement, as well as similar aggregated information. Such information does not identify you individually.
                                        </li><br />
                                        <li>
                                            We provide such information to trusted businesses or persons for the purpose of processing
                                            personal information on our behalf. We require that these parties agree to process such information
                                            based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.
                                        </li><br />
                                        <li>
                                            Advertisers: We allow advertisers and/or merchant partners (“Advertisers”) to choose the
                                            demographic information of users who will see their advertisements and/or promotional offers
                                            and you agree that we may provide any of the information we have collected from you in non-
                                            personally identifiable form to an Advertiser, in order for that Advertiser to select the appropriate audience for those advertisements and/or offers.
                                        </li><br />
                                        <li>
                                            If you sign through a third-party social networking site or service, your list of “friends” from that
                                            site or service may be automatically imported to the Website and or Service. Again, we do not control the policies and practices of any other third-party site or service.
                                        </li>
                                    </ul><br />
                                    <strong>Security</strong><br />
                                    The security of your personal information is important to us. We take precautions, including
                                    administrative, technical and physical measures to safeguard your personal information against loss, theft,
                                    and misuse, as well as against unauthorized access, disclosure, alteration and destruction. When you enter
                                    sensitive information on our registration, we encrypt that information.
                                    This Website has various electronic, procedural, and physical security measures in place to protect the loss,
                                    misuse, and alteration of information, or any accidental loss, destruction or damage to data. When you submit your information via the Website, your information is protected through our security systems.<br />
                                    <strong>Accessing Third-Party Websites</strong><br />
                                    Website, mobile site and mobile app may contain links to other websites. Please note that when you click
                                    on one of these links, you are entering another website for which the Company has no responsibility. We
                                    encourage you to read the privacy statements of all such sites as their policies may be materially different
                                    from this Privacy Policy. Of course, you are solely responsible for maintaining the secrecy of your
                                    passwords, and your membership account information. Please be very careful, responsible, and alert with this information, especially whenever you are online.<br />
                                    <strong>Protection of Children</strong><br />
                                    If you are a user under the age of 18 (“Child User”), please do not access the Website.
                                    We also reserve the right to remove, at our sole discretion, any content or material posted by a user which
                                    may be of defamatory or sexual nature or offensive to other users in any manner, and take appropriate legal action against such user in accordance with our Terms of Use.<br />
                                    <strong>Cookies</strong><br />
                                    A cookie is a chunk of data that is sent to the website from a server and stored on the hard drive of your
                                    computer and/or mobile (“Cookies”). A session Cookie disappears automatically after you close your
                                    browser. A persistent Cookie remains after you close your browser and may be used by your browser on
                                    subsequent visits to the Website. We may use Cookies related information to: (a) remember your data
                                    and/or personal information so that you will not have to re-enter it during your visit or the next time you
                                    access the Services; (b) provide customized third-party advertisements, content and information; (c)
                                    monitor the effectiveness of third-party marketing campaigns; (d) monitor aggregate site usage metrics
                                    such as total number of visitors and pages viewed; and (e) track your entries, submissions and status in any promotions or other activities.<br />
                                    We may allow third-party service providers, like advertisers, to place and read their own Cookies, web
                                    beacons and similar technologies to collect data and/or personal information. This data and/or personal
                                    information are collected directly and automatically by these third parties, and we do not participate in these data transmissions and these third-party cookies are not covered under this Privacy Policy.<br />
                                    <strong>Changes to the Privacy Policy</strong><br />
                                    We reserve the right to change the Privacy Policy at our sole discretion. We will inform users of any such
                                    change by us posting the updated Privacy Policy on our Website. We encourage you to review this Privacy
                                    Policy regularly for any such changes. You can determine when we last modified the Privacy Policy by
                                    referring to the “Last Updated” legend below. Your continued use of the Services will be subject to the then-current Privacy Policy.<br />
                                    <strong>Compliance with Laws and Law Enforcement</strong><br />
                                    We will cooperate with government and law enforcement officials and private parties to enforce and
                                    comply with the law. We reserve the right to track IP addresses for the purposes of fraud prevention and
                                    to release IP addresses to legal authorities. We will disclose information about you to government or law
                                    enforcement officials or private parties when we believe reasonably necessary to comply with law, to
                                    protect the property and rights of the Company or a third party, to protect the safety of the public or any
                                    person, or to prevent or stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable activity.<br />
                                    <strong>Email Notifications</strong><br />
                                    You may be contacted by email or other means; for example, we may send you a promotional offer on
                                    behalf of other businesses or communicate with you about your use of the service. Also, we may receive a
                                    confirmation when you open an email from us. This confirmation helps us improve our Services. If you do
                                    not want to receive email or other mail from us, please indicate your preference by writing to us at
                                    support@medimny.com. Please note that if you do not want to receive legal notices from us, those legal
                                    notices will still govern your use of the Services and you are responsible for reviewing such legal notices for changes.<br />
                                    <strong>Governing Law and Dispute Resolution</strong><br />
                                    This Privacy Policy shall be subject to and construed in accordance with the laws of India and the parties hereby submit to the exclusive jurisdiction of the courts of Mumbai.<br />
                                    In the event of any disputes, differences, or controversies between the parties hereto, out of or in connection
                                    with the provisions of this Privacy Policy, or any action taken hereunder, the parties hereto shall thoroughly
                                    explore all possibilities for an amicable settlement. In case an amicable settlement cannot be reached, such
                                    disputes, differences or controversies shall be subject to the exclusive jurisdiction of the Courts at Mumbai, India and You hereby accede to and accept the jurisdiction of such Courts.<br />
                                    <strong>Contact Information</strong><br />
                                    If you have any questions or concerns with respect to this Privacy Policy or the Website or any information
                                    contained on thereon, you may contact us by writing to us at <a href='javascript: void(0)'>support@medimny.com</a> . This Privacy Policy supersedes any previous versions.<br />
                                    Last updated on [Feb 27th, 2020]<br />
                                    <em>Copyright © All rights reserved.</em>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BuyerContainer>
    );
}

export default Privacy;