import React, { useRef, useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CustomScrollbars from 'util/CustomScrollbars';
import '../index.css'
import BuyerContainer from 'components/buyerContainer';

const ShippingAndReturnPolicy = (props) => {
    const myRef = useRef(null)

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <BuyerContainer>
            <div className="app-wrapper" ref={myRef}>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <ContainerHeader title="Shipping And Return Policy" />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 signinContainer  " >
                        <div className="jr-card">
                            <div className="animated slideInUpTiny animation-duration-3 "><CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
                                <p style={{
                                    color: "#5b5b5b", fontSize: '15px', textAlign: 'justify',
                                    paddingLeft: '25px', paddingRight: '25px',
                                }} >
                                    &#9642;&nbsp;&nbsp;What is the standard delivery timeline?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;The normal delivery timeline is 5-7 business days from the date of dispatch. The seller usually dispatches with 1-2 business days from receipt of the order. Business days are defined as Monday to Saturday (excluding public holidays and Sundays)<br /><br />
                                    &#9642;&nbsp;&nbsp;Is there any Minimum Order Value?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;Yes. Minimum Order value is INR 3,000 (Net Value Excl GST) per order, per seller.<br /><br />
                                    &#9642;&nbsp;&nbsp;What is the delivery charges?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;There is NO DELIVERY CHARGES for order, per seller, over Net value of INR 2,500 (Excluding GST).<br /><br />
                                    &#9642;&nbsp;&nbsp;Under what circumstances will the delivery get delayed?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;Under usual circumstances the shipment will be delivered within the estimated timeline. However, in case of special situation such as transport disruption, political turmoil, natural catastrophe (flood, earthquake, pandemic etc), or force majeure events the shipment can get delayed. However, our team will keep you posted on the status of your shipment along with revised estimated delivery schedule.<br /><br />
                                    &#9642;&nbsp;&nbsp;How do I find out if MEDIMNY delivers to my locations?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;MEDIMNY, at present, delivers at ~26,000 pin codes in India, thanks to it logistics partners. If your areas is not serviceable by our logistic partners, we’ll try make alternative arrangements, on best effort basis. We’ll keep you informed about the same.<br /><br />
                                    &#9642;&nbsp;&nbsp;Can my shipping address be different from my billing address<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;No. The billing address and shipping address will be same as the one provided in the Drug License provided during registration / reverification.<br /><br />
                                    &#9642;&nbsp;&nbsp;When can I cancel my order?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;You can cancel the order any time before being dispatched by the seller. <br /><br />
                                    &#9642;&nbsp;&nbsp;Are there any hidden charges?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;No. There is no hidden charges for cancellation of order before goods being dispatched by the seller.<br /><br />
                                    &#9642;&nbsp;&nbsp;How can I make payment for my order.<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;When confirming the order, you can choose to make Cash Payment on Delivery (COD). You can also opt to make prepaid order using flexible options of Netbanking / UPI / Credit Card / Wallets / Googlepay etc. (NOTE: Payment Gateway is Coming Soon).<br /><br />
                                    &#9642;&nbsp;&nbsp;How can I return a shipment delivered to me?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;Once you’ve received your shipment, you must inform us about faulty supply by writing to us at <a href='javascript: void(0)'>helpdesk@medimny.com</a>, referring your Order ID, within 24 hours with images of the supplied product & invoice. A faulty supply is defined as a mismatch in description of the product ordered vs product received, breakage in the shipment, shipment in non-saleable condition. Under such circumstance, MEDIMNY will confirm the same with the seller and arrange for pick-up of the same at no extra cost.<br />
                                    In case there is no difference in the product ordered vs product received, but you want to return the goods, the same can be pick up with pick-up charges being borne by you. Under both situation, you’ll be provide with a docket label, which you need to paste on the shipment.<br /><br />
                                    &#9642;&nbsp;&nbsp;How should I pack my product for return?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;While preparing the product for reverse pick-up, all you need to do is bubble wrap the product and pack it properly in 3-5 ply Corrugated box, paste the shipment docket label provided by us and write us when ready. Our logistic partners will do the needful.<br /><br />
                                    &#9642;&nbsp;&nbsp;When is a return not possible?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;You can return the shipment anytime within 7 business days from receipt of the shipment, provided the goods are in saleable conditions. However, if returning a faulty supply, the same must be done within 24 hours, post which a return will be allowed.<br /><br />
                                    <strong style={{ fontSize: '20px' }}>Return & Refund Policy</strong><br /><br />
                                    &#9642;&nbsp;&nbsp;My order is not getting processed. Can I cancel it?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;Yes. You can cancel the order anytime before it is dispatched. The orders cannot be cancelled after being dispatched.<br /><br />
                                    &#9642;&nbsp;&nbsp;I’ve cancelled prepaid order, which is yet to be dispatched. When can I get refund?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;If you’re prepaid order is not dispatched & you’ve cancelled it, the refund will be processed within 3-5 business days. In case of delay, you can write to us on <a href='javascript: void(0)'>helpdesk@medimny.com</a> referring you Order ID.<br /><br />
                                    &#9642;&nbsp;&nbsp;I’ve returned a shipment delivered to me. When can I get refund?<br />
                                    <i class="zmdi zmdi-arrow-right"></i>&nbsp;In case of delivered shipment returned you, the refund, post deduction of pick-up and other charges (if applicable), will be initiated after the same is received by the seller to his satisfaction. <br /><br />
                                    NOTE: - MEDIMNY reserves the right to amend the Shipping, Returns & Refund Policy as and when deemed necessary.
                                </p></CustomScrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BuyerContainer>
    );
}

export default ShippingAndReturnPolicy;