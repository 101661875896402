import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CustomScrollbars from 'util/CustomScrollbars';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import helperFunction from 'constants/helperFunction';
import { connect } from 'react-redux';
import { getReviewList } from 'actions/buyer';
import moment from "moment";

const Contact = (props) => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.includes("/view-seller/") && params.id) {
      let data = {
        sellerId: params.id,
        sellerReviewList: true
      }
      props.getReviewList({ data })
    } else {
      props.getReviewList({})
    }
  }, [])

  const toSeller = (e) => {
    navigate(`/view-seller/${e._id}`)
  }

  const { reviewList } = props;
  return (
    location.pathname.includes("/view-seller/") ?
      <CustomScrollbars className="messages-list scrollbar" style={{ height: location.pathname.includes('/profile') ? 360 : 350 }}>
        <table className="default-table table-sm table full-table mb-0">
          <tbody>
            {reviewList && reviewList.detail.length > 0 ? reviewList.detail.map(data => {
              return (
                <tr tabIndex={-1} key={data.id}>
                  <td>
                    <div className="user-profile d-flex flex-row align-items-center">
                      <Avatar
                        alt={''}
                        src={''}
                        className="user-avatar"
                        style={{ background: helperFunction.gradientGenerator() }}
                      >
                        {helperFunction.getNameInitials(data.user_id ? data.user_id.first_name : '')}
                      </Avatar>
                      <div className="user-detail">
                        <h4 className="user-name">{data.user_id ? data.user_id.company_name : ''}</h4>
                        <p className="user-description">{moment(data.createdAt).format("MMM Do YY")}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="ratingSmileEmoji" >
                      <span className='mr-lg-4 mr-md-4 mr-sm-4 mr-xs-4 mr-xl-4 float-lg-right float-md-right float-sm-right'>
                        {
                          data.ratingValue && data.ratingValue > 0 ? <i className={helperFunction.getRatingValueForBuyer(data.ratingValue)} set={'google'} size={30} />
                            : ''
                        }
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
              :
              <td>
                <div className="user-profile d-flex flex-row align-items-center">
                  <div className="user-detail">
                    <h4 className="user-name">No reviews found</h4>
                    <p className="user-description"></p>
                  </div>
                </div>
              </td>}
          </tbody>
        </table>
      </CustomScrollbars>
      :
      <CustomScrollbars className="messages-list scrollbar" style={{ height: location.pathname.includes('/profile') ? 360 : 350 }}>
        <table className="default-table table-sm table full-table mb-0">
          <tbody>
            {reviewList && reviewList.detail.length > 0 ? reviewList.detail.map(data => {
              return (
                <tr
                  tabIndex={-1}
                  key={data.id}>
                  <td>
                    <div className="user-profile d-flex flex-row align-items-center" onClick={(e) => toSeller(data.seller_id)}>
                      <Avatar
                        alt={''}
                        src={''}
                        className="user-avatar"
                        style={{ background: helperFunction.gradientGenerator() }}
                      >
                        {helperFunction.getNameInitials(data.seller_id ? data.seller_id.first_name : '')}
                      </Avatar>
                      <div className="user-detail">
                        <h4 className="user-name">{data.seller_id ? data.seller_id.company_name : ''}</h4>
                        <p className="user-description">{moment(data.createdAt).format("MMM Do YY")}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="ratingSmileEmoji" onClick={(e) => toSeller(data.seller_id)}>
                      <span className='mr-lg-4 mr-md-4 mr-sm-4 mr-xs-4 mr-xl-4 float-lg-right float-md-right float-sm-right'>
                        {
                          data.ratingValue && data.ratingValue > 0 ? <i className={helperFunction.getRatingValueForBuyer(data.ratingValue)} set={'google'} size={30} />
                            : ''
                        }
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
              :
              <td>
                <div className="user-profile d-flex flex-row align-items-center">
                  <div className="user-detail">
                    <h4 className="user-name">No reviews found</h4>
                    <p className="user-description"></p>
                  </div>
                </div>
              </td>}
          </tbody>
        </table>
      </CustomScrollbars>
  )
}

const mapStateToProps = ({ buyer }) => {
  const { reviewList } = buyer;
  return { reviewList };
}
export default connect(mapStateToProps, { getReviewList })(Contact);