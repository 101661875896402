import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { Col, Row } from 'reactstrap';
import renderTextField from 'components/textBox';
import RenderSelectField from 'components/RenderSelectField';
import { required, emailField, passwordMatch, validatePassword, specailChar, phone } from 'constants/validations';
import axios from 'constants/axios';

const Form1 = (props) => {
	const [states, setStates] = useState([])

	const handleChange = () => {
		props.touch('email')
	}

	useEffect(() => {
		getStates()
	}, [])

	const getStates = async () => {
		try {
			const result = await axios.get('/admin/getStates', {}, {
				headers: {
					'Content-Type': 'application/json',
				}
			})
			if (result.data.error) {
			} else {
				setStates(result.data.detail)
			}
		} catch (error) {
		}
	}

	const composeValidators = (...validators) => value =>
		validators.reduce((error, validator) => error || validator(value), undefined)

	return (
		<Row>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="company_name" component={renderTextField} validate={composeValidators(required, specailChar)} label="Company Name" />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="email" component={renderTextField} onChange={(e) => handleChange(e)} validate={emailField} label="Email" />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="first_name" component={renderTextField} label="First Name" validate={composeValidators(required, specailChar)} />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="last_name" component={renderTextField} label="Last Name" validate={composeValidators(required, specailChar)} />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="user_address" component={renderTextField} label="Address Line 1" validate={required} />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="user_address1" component={renderTextField} label="Address Line 2" />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="user_city" component={renderTextField} label="City" validate={composeValidators(required, specailChar)} />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field
					name="user_state"
					component={RenderSelectField}
					label="State"
					validate={required}
					fullWidth={true}
					margin="normal"
				>
					<option value="" />
					{
						states && states.length > 0 ? states.sort(function (a, b) {
							var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
							if (nameA < nameB)
								return -1
							if (nameA > nameB)
								return 1
							return 0
						}).map((state, key) => {
							return <option value={state._id}>{state.name}</option>
						}) : ''
					}
				</Field>
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="user_pincode" component={renderTextField} label="Pin Code" validate={(value) => (value === undefined ? 'This field is required' : isNaN(value) ? 'Please enter valid pin code number' : '')} />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="phone" component={renderTextField} label="Contact No" validate={phone} />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field id="password" type="password" name="password" component={renderTextField} label="Password" validate={validatePassword} autocomplete='off' />
			</Col>
			<Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
				<Field name="confirmPassword" type="password" component={renderTextField} label="Confirm Password" validate={passwordMatch} />
			</Col>
		</Row>
	)
}

export default Form1