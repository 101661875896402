import React from 'react'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

const headCells = [
  { id: 'ticketNo', numeric: false, disablePadding: true, label: 'Ticket No' },
  { id: 'type', numeric: false, disablePadding: true, label: 'Type' },
  { id: 'createdAt', numeric: false, disablePadding: true, label: 'Date' },
  { id: 'orderId', numeric: false, disablePadding: true, label: 'Order Id' },
  { id: 'issue', numeric: false, disablePadding: true, label: 'Issue' },
];

const EnhancedTableHead = (props) => {
  const { styles, order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
              sx={styles.fontSize}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={styles.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;