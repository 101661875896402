import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import OrderDetailsHeader from './OrderDetailsHeader';
import { Col, Row } from 'reactstrap';
import OrderDetailsTable from "./orderDetailTable";
import { getOrderDetails } from 'actions/order';
import { connect } from "react-redux";
import moment from 'moment'
import AppConfig from 'constants/config'
import CircularProgress from '@mui/material/CircularProgress';
import SellerContainer from "components/sellerContainer"
import { useParams } from 'react-router-dom';

const OrderDetails = (props) => {
  const params = useParams()
  const [updatedProduct, setUpdatedProduct] = useState([])
  const [status, setStatus] = useState('New')
  const [orderDetailsState, setOrderDetailsState] = useState({})
  const [statusBadge, setStatusBadge] = useState('')
  const { orderDetails } = props

  useEffect(() => {
    props.getOrderDetails({ data: { orderId: params.id } })
  }, [])

  useEffect(() => {
    if (orderDetails && Object.keys(orderDetails).length > 0) {
      let tempStatusBadge = orderDetails.requested === orderDetails.order_status[orderDetails.order_status.length - 1].status ? orderDetails.requested : orderDetails.requested === "Requested" ? "Requested" : orderDetails.requested === "Approved" ? "Approved" : orderDetails.order_status[orderDetails.order_status.length - 1].status;
      setStatus(orderDetails.requested)
      setOrderDetailsState(orderDetails)
      setStatusBadge(tempStatusBadge)
    }
  }, [orderDetails])

  const onClickHandle = (e, value, orderProductData) => {
    e.preventDefault();
    if (orderProductData) {
      setUpdatedProduct(orderProductData)
    }
  }

  const getValue = (data) => {
    if (!data.discount_on_product && data.discount_per > 0) {
      let totalPtr = (((data.discount_per / 100) * data.PTR) + data.PTR)
      return totalPtr * Number(data.quantity)
    } else {
      return data.PTR * Number(data.quantity)
    }
  }

  const getFinalValue = (data) => {
    let tempValue = getValue(data)
    let finalValue = ((data.inventory_id.product_id.GST.value / 100) * tempValue) + tempValue;
    return finalValue
  }

  let finalGrand = 0;
  orderDetails && orderDetails.products && orderDetails.products.map((eachProduct) => {
    return (
      finalGrand = finalGrand + Number(getFinalValue(eachProduct))
    )
  })

  return (
    <SellerContainer>
      <div className="order-details" >
        {
          (statusBadge !== ' ') ? <Button className={statusBadge.includes("Processed") ? "text-primary" : statusBadge.includes("Ready For Dispatch") ? "text-amber" : statusBadge.includes("Placed") ? "text-yellow" : statusBadge.includes("Cancelled") ? "text-grey" : statusBadge.includes("Delivered") ? "text-green" : statusBadge.includes("Requested") ? "text-info" : statusBadge.includes("New") ? "text-danger" : "text-info"}>{statusBadge === 'Processed' ? 'Processed' : statusBadge}</Button> :
            <div className="loader-view">
              <CircularProgress />
            </div>
        }
      </div>
      {(orderDetailsState && Object.keys(orderDetailsState).length > 0) ?
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <OrderDetailsHeader
            text={props.text}
            cancelText={props.cancelText}
            status={status}
            updatedProduct={updatedProduct}
            title="Order ID"
          />
          <div className="row">
            <div className="col-12">
              <div className="jr-card chart-user-statistics bg-primary darken-4 text-white">
                <div className="orderDetails px-4 mb-4">
                  <Row className="pb-4">
                    <Col xs={12} xl={6} sm={12} md={6} lg={6} className="orderDetailsMainCol">
                      <h3 style={{ fontSize: 15 }}> ORDER PLACED BY</h3>
                      <div className="p-1">
                        <i className="zmdi zmdi-info-outline zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                        <span className="align-self-center ml-1">
                          {params.id}
                        </span>
                      </div>
                      <div className="p-1">
                        <i className="zmdi zmdi-account-o zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                        <span className="align-self-center ml-1">
                          {
                            orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.company_name : 'N/A'
                          }
                        </span>
                      </div>
                      <div className="p-1">
                        <i className="zmdi zmdi-pin zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                        <span className="align-self-center ml-1">
                          {
                            orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.user_address : 'N/A'
                          }
                        </span>
                      </div>
                      <div className="p-1">
                        <i className="zmdi zmdi-city zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                        <span className="align-self-center ml-1">
                          {
                            orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.user_city : ''
                          },&nbsp;
                          {
                            (orderDetailsState.user_id !== undefined && orderDetailsState.user_id.user_state !== undefined) ? orderDetailsState.user_id.user_state.name : 'N/A'
                          }
                        </span>
                        <p className="ml-4 mb-0">
                          {
                            orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.user_pincode : 'N/A'
                          }
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} xl={4} sm={4} md={4} lg={4} className="orderDetailsMainCol">
                      <h3 style={{ fontSize: 15 }}> KYC DETAILS</h3>
                      {orderDetailsState.user_id && orderDetailsState.user_id.drugLic20B && orderDetailsState.user_id.drugLic20B.name ?
                        <div className="p-1">
                          <span className="align-self-center ml-1">
                            FORM 20&nbsp;<span className="small">&#40;{orderDetailsState.user_id && orderDetailsState.user_id.drugLic20B && orderDetailsState.user_id.drugLic20B.expires ? `${orderDetailsState.user_id.drugLic20B.lic ? orderDetailsState.user_id.drugLic20B.lic : ''} \u007C ` + moment(orderDetailsState.user_id.drugLic20B.expires).format('DD/MM/YYYY') : ''}&#41;</span>
                          </span>
                          <span className="align-self-center ml-4 pull-right">
                            <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.drugLic20B && orderDetailsState.user_id.drugLic20B.name ? orderDetailsState.user_id.drugLic20B.name : ''}`}>
                              <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                            </a>
                          </span>
                        </div>
                        : ''}
                      {orderDetailsState.user_id && orderDetailsState.user_id.drugLic21B && orderDetailsState.user_id.drugLic21B.name ?
                        <div className="p-1">
                          <span className="align-self-center ml-1">
                            FORM 21&nbsp;<span className="small">&#40;{orderDetailsState.user_id && orderDetailsState.user_id.drugLic21B && orderDetailsState.user_id.drugLic21B.expires ? `${orderDetailsState.user_id.drugLic21B.lic ? orderDetailsState.user_id.drugLic21B.lic : ''} \u007C ` + moment(orderDetailsState.user_id.drugLic21B.expires).format('DD/MM/YYYY') : ''}&#41;</span>
                          </span>
                          <span className="align-self-center ml-4 pull-right">
                            <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.drugLic21B && orderDetailsState.user_id.drugLic21B.name ? orderDetailsState.user_id.drugLic21B.name : ''}`}>
                              <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                            </a>
                          </span>
                        </div>
                        : ''}
                      {orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && orderDetailsState.user_id.fassaiLic.name ?
                        <div className="p-1">
                          <span className="align-self-center ml-1">
                            FASSAI&nbsp;
                            {orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic &&
                              <span className="small">
                                &#40;
                                {orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && orderDetailsState.user_id.fassaiLic.expires ?
                                  `${orderDetailsState.user_id.fassaiLic.lic ? orderDetailsState.user_id.fassaiLic.lic : ""} \u007C ` +
                                  moment(orderDetailsState.user_id.fassaiLic.expires).format('DD/MM/YYYY')
                                  + '' : ''
                                }
                                &#41;</span>}
                          </span>
                          <span className="align-self-center ml-4 pull-right">
                            <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && orderDetailsState.user_id.fassaiLic.name ? orderDetailsState.user_id.fassaiLic.name : ''}`}>
                              <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                            </a>
                          </span>
                        </div>
                        : ''}
                      {orderDetailsState.user_id && orderDetailsState.user_id.gstLic && orderDetailsState.user_id.gstLic.name ?
                        <div className="p-1">
                          <span className="align-self-center ml-1">
                            GSTN&nbsp;
                            {orderDetailsState.user_id && orderDetailsState.user_id.gstLic &&
                              <span className="small">
                                {orderDetailsState.user_id.gstLic.lic ?
                                  <React.Fragment>
                                    &#40;
                                    {orderDetailsState.user_id && orderDetailsState.user_id.gstLic ?
                                      `${orderDetailsState.user_id.gstLic.lic ? orderDetailsState.user_id.gstLic.lic : " "}` : ''}
                                    &#41;
                                  </React.Fragment>
                                  : null}
                              </span>}
                          </span>
                          <span className="align-self-center ml-4 pull-right">
                            <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.gstLic && orderDetailsState.user_id.gstLic.name ? orderDetailsState.user_id.gstLic.name : ''}`}>
                              <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                            </a>
                          </span>
                        </div>
                        : ''}
                      <h3 style={{ fontSize: 15, marginBottom: '0px', marginTop: '10px' }}>PAYMENT TYPE &#40;{orderDetails && orderDetails.isBulk ? 'Bulk Prepaid' : orderDetails.paymentType === 'Online' ? 'Prepaid' : orderDetails && orderDetails.paymentType}&#41;</h3>
                      <div className="p-1">
                        <span className="align-self-center ml-1">
                          Grand Total ₹{(orderDetailsState.total_amount).toFixed(2)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <OrderDetailsTable onClickHandle={onClickHandle} cancelText={props.cancelText} />
            </div>
          </div>
        </div>
        : <div className="loader-view">
          <CircularProgress />
        </div>
      }
    </SellerContainer>
  );
}

const mapStateToProps = ({ order }) => {
  const { orderDetails, totalValue, text, cancelText } = order;
  return { orderDetails, totalValue, text, cancelText }
};

export default connect(mapStateToProps, { getOrderDetails })(OrderDetails);