import React from 'react';
import Button from '@mui/material/Button';
import { FormGroup } from 'reactstrap';
import { Field, Form } from 'react-final-form'
import { required, passwordMatch, minLength6 } from '../BEditProfile/BEditValidation';
import { NotificationManager } from 'react-notifications';
import ReactStrapTextField from 'components/ReactStrapTextField';
import axios from 'constants/axios';

const BuyerChangePassword = (props) => {
  const onSubmit = async (values) => {
    try {
      let data = {
        password: values.password,
        confirmPassword: values.confirmPassword,
      }

      const result = await axios.post('/users/changePassword', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('buyer_token')
        }
      })
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
      }
    } catch (error) {
      NotificationManager.error('Something went wrong, Please try again')
    }
  }

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  return (
    <div className='mt-5 mb-5'>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className='row justify-content-center'>
              <Field name="password" id="password" type="password"
                classNameField={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10'}
                label='New Password'
                component={ReactStrapTextField}
                validate={composeValidators(required, minLength6)}
                autoComplete="off"
              />
            </div>
            <div className='row justify-content-center '>
              <Field name="confirmPassword" id="confirmPassword" type="password"
                classNameField={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10'}
                label='Confirm Password'
                component={ReactStrapTextField}
                validate={passwordMatch}
                autoComplete="off"
              />
            </div>
            <div className='row justify-content-center mt-2 '>
              <FormGroup className={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10 buyerEditProfile'}>
                <Button color="primary" type='submit' variant="contained" className="jr-btn jr-btn-lg btnPrimary" >
                  Save
                </Button>
              </FormGroup>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default BuyerChangePassword

