import React, { useState } from 'react';
import Widget from "components/Widget/index";
import { Row, Col } from 'reactstrap'
import Button from '@mui/material/Button';
import './index.css'
import { NavLink, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import helpertFn from 'constants/helperFunction';
const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const BDashboardCategory = (props) => {
  const navigate = useNavigate()
  const [categories, setCategories] = useState([])

  const handleCategories = async (key, e) => {
    let obj = { ...categories }
    let temp = [...categories]
    temp.push(e)
    setCategories(temp)
    obj.category_id = temp.length > 0 ? temp.toString() : ''
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    let catName = props.value && props.value.name ? ((props.value.name).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase() : 'category';
    navigate(`/search-results/${catName}/${url}`)
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const { data, name } = props.value;
  return (
    <Widget title={name.length > 24 ? <Tooltip className='ellipsis' title={<h4 className="text-white"
      style={{ marginTop: "13px" }}>{name.slice(0, 26) + '...'}</h4>}><h4 className='font-weight-bold mb-0'>{name}</h4></Tooltip> : <h4 className='font-weight-bold mb-0'>{name}</h4>} styleName='mavHeight'>
      <Row >
        {
          data.map((val) =>
            <Col lg={6} xl={6} xs={6} >
              <div className="card-image" >
                <div className="grid-thumb-equal">
                  <NavLink className="buyerRedirectNavlink" to={`/product-details/${((val.Product.name).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()}/${val.inventory_id}`}>
                    <span className="grid-thumb-cover jr-link">
                      <img onError={addDefaultSrc} alt="product" src={val.Product.images.length > 0 ? `${helpertFn.productImg(val.Product.images[0])}` : logo} />
                    </span>
                  </NavLink>
                </div>
                <NavLink className="buyerRedirectNavlink" to={`/product-details/${((val.Product.name).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()}/${val.inventory_id}`}>
                  {val.Product.name.length > 15 ?
                    <React.Fragment>
                      <Tooltip
                        className="d-inline-block"
                        id="tooltip-right"
                        title={<h6 className="text-white"
                          style={{ marginTop: "13px" }}>{val.Product.name}</h6>}
                        placement="right"
                      >
                        <h5 className="greyText ellipsis w-100"> {val.Product.name} </h5>
                      </Tooltip>
                    </React.Fragment>
                    :
                    <h5 className="greyText ellipsis w-100"> {val.Product.name} </h5>
                  }
                </NavLink>
                <h6 className="greyText">
                  PTR: ₹{(val.PTR).toFixed(2)}
                </h6>
                {
                  val.Discount && val.Discount.discount_type === "Same" ?
                    <React.Fragment>
                      <Tooltip
                        className="d-inline-block "
                        id="tooltip-right"
                        title={
                          <h6 className="text-white" style={{ marginTop: "13px" }}>
                            Buy {val.Discount.discount_on_product.purchase} Get {val.Discount.discount_on_product.bonus} Free
                          </h6>
                        }
                        placement="right"
                      >
                        <h6 className="ellipsis greyText  w-100">
                          {"Buy " + val.Discount.discount_on_product.purchase + " Get " + val.Discount.discount_on_product.bonus + " Free"}
                        </h6>
                      </Tooltip>
                    </React.Fragment>
                    :
                    val.Discount && val.Discount.discount_type === "Different" ?
                      <React.Fragment>
                        <Tooltip
                          className="d-inline-block ellipsis"
                          id="tooltip-right"
                          title={
                            <h6 className="text-white" style={{ marginTop: "13px" }}>
                              Buy {val.Discount.discount_on_product.purchase} Get {val.Discount.discount_on_product.bonus} {val.OtherProducts.name} Free
                            </h6>
                          }
                          placement="right"
                        >
                          <h6 className="ellipsis greyText  w-100">
                            {"Buy " + val.Discount.discount_on_product.purchase + " Get " + val.Discount.discount_on_product.bonus + " " + val.OtherProducts.name + " Free"}
                          </h6>
                        </Tooltip>
                      </React.Fragment>
                      :
                      val.Discount && val.Discount.discount_type === "Discount" ?
                        <React.Fragment>
                          <h6 style={{ marginTop: "13px" }} className="greyText">
                            {" " + (val.Discount.discount_per).toFixed(2)}% discount
                          </h6>
                        </React.Fragment>
                        :
                        val.Discount && val.Discount.discount_type === "" ?
                          <React.Fragment>
                          </React.Fragment>
                          :
                          <React.Fragment>
                          </React.Fragment>
                }
              </div>
            </Col>)
        }
      </Row>
      <Row className='d-flex' style={{ alignSelf: 'center' }}>
        <Button className="jr-btn text-uppercase text-primary"
          onClick={(e) => { handleCategories('category_id', data[0].Product_Category._id) }} >See More</Button>
      </Row>
    </Widget>
  );
}
export default BDashboardCategory;