import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import moment from 'moment';
import axios from 'constants/axios';
import AppConfig from 'constants/config'
import { NotificationManager } from 'react-notifications';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const OldManifest = (props) => {
  const [params] = useSearchParams()
  let navigate = useNavigate();
  const tab = params && params.get("tab") ? Number(params.get("tab")) : 0
  const perPage = 100
  const page = params && Number(params.get("pageOM")) ? Number(params.get("pageOM")) : 0
  const [resData, setResData] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)
    let data = {
      page: (params && Number(params.get("pageOM")) ? Number(params.get("pageOM")) : 0) + 1,
      perPage,
    }
    await axios.post(`${AppConfig.baseUrl}shippingManifest/getManifest`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        setResData(result.data.detail[0])
      }
      setLoading(false)
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
      setLoading(false)
    });
  }

  useEffect(() => {
    if (tab === 2) {
      getData()
    }
  }, [params])

  useEffect(() => {
    if (props.tabValue === 2) {
      let obj = { pageOM: page, tab: 2 }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      navigate(`/seller/orders?${url}`, { replace: true })
    }
  }, [props.tabValue])

  const changePage = (page) => {
    let obj = { pageOM: page, tab: 2 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  };

  const handleOpen = (data) => {
    window.open(`${AppConfig.baseUrl}shippingManifest/getManifestReport?Id=${data._id}`, '_blank');
  }

  const handleDelete = async (data) => {
    await axios.get(`${AppConfig.baseUrl}shippingManifest/deleteManifested?Id=${data._id}`, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        getData()
        NotificationManager.success(result.data.title)
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const button = (data) => {
    return (
      <ButtonGroup>
        <Button variant="outlined" className={'text-danger'} onClick={(e) => handleDelete(data)} >
          Delete
        </Button>
        <Button variant="outlined" className={'text-warning'} onClick={(e) => handleOpen(data)} >
          Print
        </Button>
      </ButtonGroup>
    )
  }

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: resData && resData.metadata && resData.metadata[0] ? resData.metadata[0].total : 0,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderId",
      label: "Order Id",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  let data = []
  resData && resData.data && resData.data.map((data1, index) => {
    let orderids = data1.order_id.map(order => {
      return order;
    }).join(',');
    data.push([
      <div>{moment(data1.date).format('DD/MM/YYYY')}</div>,
      <div>{orderids}</div>,
      button(data1)
    ])
  })

  return (
    <div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {
          loading ?
            <div className="loader-view" style={{ height: 'calc(100vh - 238px)' }}>
              <CircularProgress />
            </div>
            :
            <ThemeProvider theme={createTheme({ components: styles })}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
                style={{ borderRadius: '0px !important' }}
              />
            </ThemeProvider>
        }
      </div>
    </div>
  );
}

export default OldManifest;