import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import React from 'react';

import {

  LOGOUT_USER,

  GET_FEATURED_PRODUCT_LIST,

  GET_PRODUCT_DETAILS,

  GET_TRANSACTION_LIST,

  GET_MEDIWALLET,

  GET_PRODUCTS_FOR_YOU,

  GET_ORDER_LIST,

  GET_REVIEW_LIST,

  GET_ORDER_HISTORY_LIST,

  GET_TICKET_LIST,

  GET_TICKET_HISTORY_LIST,

  ADD_TO_CART,

  GET_CART_DETAILS,

  REMOVE_FROM_CART,

  GET_VISITED_CATEGORY,

  GET_PRODUCTS_BY_COMPANY,

  BULK_REQUEST,

  GET_SEARCH_PRODUCT,


  GET_PRODUCTS_BY_SELLER,

  GET_CATEGORIES,
  GET_AUTO_FILL,

  GET_FILTER,

  EMPTY_CART,

  GET_UPDATE_ORDER,

  GET_PROMOTIONS_LISTING,

  GET_MARK_READ_LIST_FOR_BUYER,

  GET_PRODUCT_REQUEST_LIST,

  GET_SHORTBOOK,

  GET_SHORT_PRODUCTS,

  GET_BULK_LIST,

  GET_BULK_HISTORY_LIST

} from "constants/ActionTypes";



import {

  getFeaturedProductListSuccess,

  getProductDetailsSuccess,

  getTransactionListSuccess,

  getMediWalletSuccess,

  getProductsForYouSuccess,

  getOrderList,

  getOrderListSuccess,

  getReviewListSuccess,

  getOrderHistoryList,
  getOrderHistoryListSuccess,
  getTicketListSuccess,
  getTicketHistoryListSuccess,

  getCartDetails,
  getCartDetailsSuccess,

  removeFromCartSuccess,

  getVisitedCategorySuccess,
  getVisitedCategory,

  getProductsByCompanySuccess,

  bulkRequestSuccess,

  getSearchProductSuccess,

  getProductsBySellerSuccess,

  getCategoriesSuccess,

  getAutoFillSuccess,

  getFilterSuccess,

  emptyCartSuccess,

  getUpdateOrderSuccess,

  getPromotionsListingSuccess,

  getMarkReadForBuyerSuccess,

  getProductRequestListSuccess,

  getShortbookSuccess,

  getShortProductsSuccess,

  getBulkListSuccess,
  getBulkHistoryListSuccess

} from "actions/buyer";
import {
  apiFailed, getUserDetailSuccess,
  getUserDetail
} from 'actions/seller'
import { userSignInSuccess } from 'actions/Auth'
import { getBuyerNotification } from 'actions/notification'
import Axios from './axiosRequest'

function* getFeaturedProductListFunction({ payload }) {
  try {
    const getFeaturedProductListRequest = yield call(Axios.axiosBuyerHelperFunc, 'post', 'productBuyer/v1/featuredProductList', '', payload.data);
    if (getFeaturedProductListRequest && getFeaturedProductListRequest.data && getFeaturedProductListRequest.data.error) {
      yield put(apiFailed(getFeaturedProductListRequest.data.title));
    } else if (getFeaturedProductListRequest && getFeaturedProductListRequest.data) {
      yield put(getFeaturedProductListSuccess(getFeaturedProductListRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getFeaturedProductList() {
  yield takeLatest(GET_FEATURED_PRODUCT_LIST, getFeaturedProductListFunction);
}

//--------------------------------------------------------------------------------------------------------------------//

function* getProductDetailsFunction({ payload }) {
  try {
    const getProductDetailsRequest = yield call(Axios.axiosBuyerHelperFunc, 'post', 'productBuyer/v1/productDetails', '', payload);
    if (getProductDetailsRequest && getProductDetailsRequest.data && getProductDetailsRequest.data.error) {
      yield put(apiFailed(getProductDetailsRequest.data.title));
    } else if (getProductDetailsRequest && getProductDetailsRequest.data) {
      yield put(getProductDetailsSuccess(getProductDetailsRequest.data))
      const user = localStorage.getItem("buyer_token")
      if (user !== null && getProductDetailsRequest.data.productData && getProductDetailsRequest.data.productData[0] && getProductDetailsRequest.data.productData[0].Product_Category) {
        yield put(getVisitedCategory({ visited_category: getProductDetailsRequest.data.productData[0].Product_Category._id }))
      }
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}


export function* getProductDetails() {
  yield takeLatest(GET_PRODUCT_DETAILS, getProductDetailsFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getTransactionListFunction({ payload }) {
  try {
    const getTransactionListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'transactions/listingTransactions', '', payload.data);
    if (getTransactionListResponse && getTransactionListResponse.data && getTransactionListResponse.data.error) {
      yield put(apiFailed(getTransactionListResponse.data.title));
    } else if (getTransactionListResponse && getTransactionListResponse.data) {
      yield put(getTransactionListSuccess(getTransactionListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getTransactionList() {
  yield takeLatest(GET_TRANSACTION_LIST, getTransactionListFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getMediWalletFunction({ payload }) {
  try {
    const getMediWalletResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'transactions/listingWalletTransaction', '', payload.data);
    if (getMediWalletResponse && getMediWalletResponse.data && getMediWalletResponse.data.error) {
      yield put(apiFailed(getMediWalletResponse.data.title));
    } else if (getMediWalletResponse && getMediWalletResponse.data) {
      yield put(getMediWalletSuccess(getMediWalletResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getMediWallet() {
  yield takeLatest(GET_MEDIWALLET, getMediWalletFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getProductsForYouFunction({ payload }) {
  try {
    const getProductsForYouRequest = yield call(Axios.axiosBuyerHelperFunc, 'post', 'productBuyer/v1/productForYou', '', payload);
    if (getProductsForYouRequest && getProductsForYouRequest.data && getProductsForYouRequest.data.error) {
      yield put(apiFailed(getProductsForYouRequest.data.title));
    } else if (getProductsForYouRequest && getProductsForYouRequest.data) {
      yield put(getProductsForYouSuccess(getProductsForYouRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getProductsForYou() {
  yield takeLatest(GET_PRODUCTS_FOR_YOU, getProductsForYouFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


function* getOrderListFunction({ payload }) {
  try {
    const getOrderListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'order/orderListingBuyers', '', payload.data);
    if (getOrderListResponse && getOrderListResponse.data && getOrderListResponse.data.error) {
      yield put(apiFailed(getOrderListResponse.data.title));
    } else if (getOrderListResponse && getOrderListResponse.data) {
      yield put(getOrderListSuccess(getOrderListResponse.data))

    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getOrderListDispatcher() {
  yield takeLatest(GET_ORDER_LIST, getOrderListFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


function* getOrderHistoryListFunction({ payload }) {
  try {
    const getOrderHistoryListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'order/orderHistoryBuyers', '', payload.data);
    if (getOrderHistoryListResponse && getOrderHistoryListResponse.data && getOrderHistoryListResponse.data.error) {
      yield put(apiFailed(getOrderHistoryListResponse.data.title));
    } else if (getOrderHistoryListResponse && getOrderHistoryListResponse.data) {
      yield put(getOrderHistoryListSuccess(getOrderHistoryListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getOrderHistoryListDispatcher() {
  yield takeLatest(GET_ORDER_HISTORY_LIST, getOrderHistoryListFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getReviewListFunction({ payload }) {
  try {
    const getReviewListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'users/getRating', '', payload.data);
    if (getReviewListResponse && getReviewListResponse.data && getReviewListResponse.data.error) {
      yield put(apiFailed(getReviewListResponse.data.title));
    } else if (getReviewListResponse && getReviewListResponse.data) {
      yield put(getReviewListSuccess(getReviewListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getReviewList() {
  yield takeLatest(GET_REVIEW_LIST, getReviewListFunction);
}


//----------------------------------------------------------------------------------------------------------------- //



function* addToCartFunction({ payload }) {
  try {
    const addToCartResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'cart/addtoCart', '', payload.data);
    if (addToCartResponse && addToCartResponse.data && addToCartResponse.data.error) {
      yield put(apiFailed(addToCartResponse.data.title));
    } else if (addToCartResponse && addToCartResponse.data) {
      yield put(getCartDetails({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* addToCart() {
  yield takeLatest(ADD_TO_CART, addToCartFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getCartDetailsFunction({ payload }) {
  try {
    const getCartDetailsResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'cart/cartDetails', '', payload);
    if (getCartDetailsResponse && getCartDetailsResponse.data && getCartDetailsResponse.data.error) {
      yield put(apiFailed(getCartDetailsResponse.data.title));
    } else if (getCartDetailsResponse && getCartDetailsResponse.data) {
      yield put(getCartDetailsSuccess(getCartDetailsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getCartDetailsDispatcher() {
  yield takeLatest(GET_CART_DETAILS, getCartDetailsFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* removeFromCartFunction({ payload }) {
  try {
    const removeFromCartResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'cart/removeFromCart', '', payload);
    if (removeFromCartResponse && removeFromCartResponse.data && removeFromCartResponse.data.error) {
      yield put(apiFailed(removeFromCartResponse.data.title));
    } else if (removeFromCartResponse && removeFromCartResponse.data) {
      yield put(removeFromCartSuccess(removeFromCartResponse.data))
      yield put(getCartDetails({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* removeFromCart() {
  yield takeLatest(REMOVE_FROM_CART, removeFromCartFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getVisitedCategoryFunction({ payload }) {
  try {
    const getVisitedCategoryResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'buyer/visitedCategory', '', payload);
    if (getVisitedCategoryResponse && getVisitedCategoryResponse.data && getVisitedCategoryResponse.data.error) {
      yield put(apiFailed(getVisitedCategoryResponse.data.title));
    } else if (getVisitedCategoryResponse && getVisitedCategoryResponse.data) {
      yield put(getVisitedCategorySuccess(getVisitedCategoryResponse.data))

    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getVisitedCategoryDispatcher() {
  yield takeLatest(GET_VISITED_CATEGORY, getVisitedCategoryFunction);
}


//----------------------------------------------------------------------------------------------------------------- //


function* getProductsByCompanyFunction({ payload }) {
  try {
    const getProductsByCompanyResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'product/by_company', '', payload);
    if (getProductsByCompanyResponse && getProductsByCompanyResponse.data && getProductsByCompanyResponse.data.error) {
      yield put(apiFailed(getProductsByCompanyResponse.data.title));
    } else if (getProductsByCompanyResponse && getProductsByCompanyResponse.data) {
      yield put(getProductsByCompanySuccess(getProductsByCompanyResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getProductsByCompany() {
  yield takeLatest(GET_PRODUCTS_BY_COMPANY, getProductsByCompanyFunction);
}


//----------------------------------------------------------------------------------------------------------------- //

function* bulkRequestFunction({ payload }) {
  try {
    const bulkRequestResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'buyer/bulkRequest', '', payload.data);
    if (bulkRequestResponse && bulkRequestResponse.data && bulkRequestResponse.data.error) {
      yield put(apiFailed(bulkRequestResponse.data.title));
    } else if (bulkRequestResponse && bulkRequestResponse.data) {
      yield put(bulkRequestSuccess(bulkRequestResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* bulkRequestResponseDispatcher() {
  yield takeLatest(BULK_REQUEST, bulkRequestFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getSearchProductFunction({ payload }) {
  try {
    const getSearchProductResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'inventory/v1/search', '', payload.data);
    if (getSearchProductResponse && getSearchProductResponse.data && getSearchProductResponse.data.error) {
      yield put(apiFailed(getSearchProductResponse.data.title));
    } else if (getSearchProductResponse && getSearchProductResponse.data) {
      yield put(getSearchProductSuccess(getSearchProductResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getSearchProductDispacher() {
  yield takeLatest(GET_SEARCH_PRODUCT, getSearchProductFunction);
}

//----------------------------------------------------------------------------------------------------------------- //
function* getProductsBySellerFunction({ payload }) {
  try {
    const getProductsBySellerResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'inventory/v1/search', '', payload);
    if (getProductsBySellerResponse && getProductsBySellerResponse.data && getProductsBySellerResponse.data.error) {
      yield put(apiFailed(getProductsBySellerResponse.data.title));
    } else if (getProductsBySellerResponse && getProductsBySellerResponse.data) {
      yield put(getProductsBySellerSuccess(getProductsBySellerResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getProductsBySeller() {
  yield takeLatest(GET_PRODUCTS_BY_SELLER, getProductsBySellerFunction);
}
//----------------------------------------------------------------------------------------------------------------- //
function* getCategoriesFunction({ payload }) {
  try {
    const getCategoriesResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'inventory/get_categories', '', payload);
    if (getCategoriesResponse && getCategoriesResponse.data && getCategoriesResponse.data.error) {
      yield put(apiFailed(getCategoriesResponse.data.title));
    } else if (getCategoriesResponse && getCategoriesResponse.data) {
      yield put(getCategoriesSuccess(getCategoriesResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getCategories() {
  yield takeLatest(GET_CATEGORIES, getCategoriesFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getAutoFillFunction({ payload }) {
  const { key } = payload
  try {
    const getAutoFillResponse = yield call(Axios.axiosBuyerHelperFunc, 'get', `product/autofill?key=${key}`, '', payload);
    if (getAutoFillResponse && getAutoFillResponse.data && getAutoFillResponse.data.error) {
      yield put(apiFailed(getAutoFillResponse.data.title));
    } else if (getAutoFillResponse && getAutoFillResponse.data) {
      yield put(getAutoFillSuccess(getAutoFillResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getAutoFill() {
  yield takeLatest(GET_AUTO_FILL, getAutoFillFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getFilterFunction({ payload }) {
  try {
    const getFilterResponse = yield call(Axios.axiosBuyerHelperFunc, 'get', 'inventory/get_filters', '', payload);
    if (getFilterResponse && getFilterResponse.data && getFilterResponse.data.error) {
      yield put(apiFailed(getFilterResponse.data.title));
    } else if (getFilterResponse && getFilterResponse.data) {
      yield put(getFilterSuccess(getFilterResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getFilter() {
  yield takeLatest(GET_FILTER, getFilterFunction);
}

/***************Empty Cart**************** *****************************************************/
function* emptyCartFunction({ payload }) {
  try {
    const emptyCartResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'cart/removeAll', '', payload);
    if (emptyCartResponse && emptyCartResponse.data && emptyCartResponse.data.error) {
      yield put(apiFailed(emptyCartResponse.data.title));
    } else if (emptyCartResponse && emptyCartResponse.data) {
      yield put(emptyCartSuccess(emptyCartResponse.data))
      yield put(getCartDetails({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* emptyCartDispatcher() {
  yield takeLatest(EMPTY_CART, emptyCartFunction);
}
//----------------------------------------------------------------------------------------------------------------- //

function* getUpdateOrderFunction({ payload }) {
  try {
    const getUpdateOrderResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'order/updateOrder', '', payload.data);
    if (getUpdateOrderResponse && getUpdateOrderResponse.data && getUpdateOrderResponse.data.error) {
      yield put(apiFailed(getUpdateOrderResponse.data.title));
    } else if (getUpdateOrderResponse && getUpdateOrderResponse.data) {
      yield put(getUpdateOrderSuccess(getUpdateOrderResponse.data.title))
      yield put(getOrderList({ data: payload.data }))
      yield put(getOrderHistoryList({ data: payload.data }))
      yield put(getUserDetail({ userType: "buyer" }))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getUpdateOrder() {
  yield takeLatest(GET_UPDATE_ORDER, getUpdateOrderFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getPromotionsListingFunction({ payload }) {
  try {
    const getPromotionsListingResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'promotions/listing', '', payload);
    if (getPromotionsListingResponse && getPromotionsListingResponse.data && getPromotionsListingResponse.data.error) {
      yield put(apiFailed(getPromotionsListingResponse.data.title));
    }
    else if (getPromotionsListingResponse && getPromotionsListingResponse.data) {
      yield put(getPromotionsListingSuccess(getPromotionsListingResponse.data))
    }
  }
  catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getPromotionsListing() {
  yield takeLatest(GET_PROMOTIONS_LISTING, getPromotionsListingFunction);
}

//----------------------------------------------------------------------------------------------------------------- //

function* getMarkReadForBuyerFunction({ payload }) {
  try {
    const getMarkReadForBuyerResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'users/markRead', '', payload);
    if (getMarkReadForBuyerResponse && getMarkReadForBuyerResponse.data && getMarkReadForBuyerResponse.data.error) {
      yield put(apiFailed(getMarkReadForBuyerResponse.data.title));
    }
    else if (getMarkReadForBuyerResponse && getMarkReadForBuyerResponse.data) {
      yield put(getMarkReadForBuyerSuccess(getMarkReadForBuyerResponse.data))
      yield put(getBuyerNotification(getMarkReadForBuyerResponse.data))
    }
  }
  catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getMarkReadForBuyerDispatcher() {
  yield takeLatest(GET_MARK_READ_LIST_FOR_BUYER, getMarkReadForBuyerFunction);
}

function* logoutFunction({ payload }) {
  const { data, history } = payload
  try {
    if ((data.isDenied == true) || (data.isBlocked == true)) {
      history.push('/')
      let detail = ''
      yield put(userSignInSuccess(detail))
      yield put(getUserDetailSuccess(detail))
      localStorage.removeItem('buyer_token');
      localStorage.removeItem('isDocumentExpired');
      localStorage.removeItem('user_type');
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* logoutDispatcher() {
  yield takeLatest(LOGOUT_USER, logoutFunction)
}

//--------------------------------------------------------------------------------------------------------------------//

function* getProductRequestListFunction({ payload }) {
  try {
    const getProductRequestListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'buyerRequest/listBuyerRequest', '', payload.data);
    if (getProductRequestListResponse && getProductRequestListResponse.data && getProductRequestListResponse.data.error) {
      yield put(apiFailed(getProductRequestListResponse.data.title));
    } else if (getProductRequestListResponse && getProductRequestListResponse.data) {
      yield put(getProductRequestListSuccess(getProductRequestListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getProductRequestListDispatcher() {
  yield takeLatest(GET_PRODUCT_REQUEST_LIST, getProductRequestListFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


function* getShortbookListFunction({ payload }) {
  try {
    const getShortbookListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'shortBook/listShortBook', '', payload.data);
    if (getShortbookListResponse && getShortbookListResponse.data && getShortbookListResponse.data.error) {
      yield put(apiFailed(getShortbookListResponse.data.title));
    } else if (getShortbookListResponse && getShortbookListResponse.data) {
      yield put(getShortbookSuccess(getShortbookListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getShortbookListDispatcher() {
  yield takeLatest(GET_SHORTBOOK, getShortbookListFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


function* getShortProductsFunction({ payload }) {
  try {
    const getShortProductsResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'shortBook/getShortProducts', '', payload.data);
    if (getShortProductsResponse && getShortProductsResponse.data && getShortProductsResponse.data.error) {
      yield put(apiFailed(getShortProductsResponse.data.title));
    } else {
      yield put(getShortProductsSuccess(getShortProductsResponse.data.products))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getShortProductsDispatcher() {
  yield takeLatest(GET_SHORT_PRODUCTS, getShortProductsFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getTicketListFunction({ payload }) {
  try {
    const getTicketListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'ticket/ticketListing', '', payload.data);
    if (getTicketListResponse && getTicketListResponse.data && getTicketListResponse.data.error) {
      yield put(apiFailed(getTicketListResponse.data.title));
    } else if (getTicketListResponse && getTicketListResponse.data) {
      yield put(getTicketListSuccess(getTicketListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getTicketListDispatcher() {
  yield takeLatest(GET_TICKET_LIST, getTicketListFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


function* getTicketHistoryListFunction({ payload }) {
  try {
    const getTicketHistoryListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'ticket/ticketListing', '', payload.data);
    if (getTicketHistoryListResponse && getTicketHistoryListResponse.data && getTicketHistoryListResponse.data.error) {
      yield put(apiFailed(getTicketHistoryListResponse.data.title));
    } else if (getTicketHistoryListResponse && getTicketHistoryListResponse.data) {
      yield put(getTicketHistoryListSuccess(getTicketHistoryListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getTicketHistoryListDispatcher() {
  yield takeLatest(GET_TICKET_HISTORY_LIST, getTicketHistoryListFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getBulkListFunction({ payload }) {
  try {
    const getBulkListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'bulkRequest/buyerBulkListing', '', payload.data);
    if (getBulkListResponse && getBulkListResponse.data && getBulkListResponse.data.error) {
      yield put(apiFailed(getBulkListResponse.data.title));
    } else if (getBulkListResponse && getBulkListResponse.data) {
      yield put(getBulkListSuccess(getBulkListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getBulkListDispatcher() {
  yield takeLatest(GET_BULK_LIST, getBulkListFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


function* getBulkHistoryListFunction({ payload }) {
  try {
    const getBulkHistoryListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'bulkRequest/buyerBulkListing', '', payload.data);
    if (getBulkHistoryListResponse && getBulkHistoryListResponse.data && getBulkHistoryListResponse.data.error) {
      yield put(apiFailed(getBulkHistoryListResponse.data.title));
    } else if (getBulkHistoryListResponse && getBulkHistoryListResponse.data) {
      yield put(getBulkHistoryListSuccess(getBulkHistoryListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}

export function* getBulkHistoryListDispatcher() {
  yield takeLatest(GET_BULK_HISTORY_LIST, getBulkHistoryListFunction);
}

export default function* rootSaga() {
  yield all([
    fork(getFeaturedProductList),
    fork(getProductDetails),
    fork(getTransactionList),
    fork(getMediWallet),
    fork(getProductsForYou),
    fork(getOrderListDispatcher),
    fork(getOrderHistoryListDispatcher),
    fork(getReviewList),
    fork(addToCart),
    fork(getCartDetailsDispatcher),
    fork(removeFromCart),
    fork(getVisitedCategoryDispatcher),
    fork(getProductsByCompany),
    fork(bulkRequestResponseDispatcher),
    fork(getSearchProductDispacher),
    fork(getProductsBySeller),
    fork(getCategories),
    fork(getAutoFill),
    fork(getFilter),
    fork(emptyCartDispatcher),
    fork(getUpdateOrder),
    fork(getPromotionsListing),
    fork(getMarkReadForBuyerDispatcher),
    fork(logoutDispatcher),
    fork(getProductRequestListDispatcher),
    fork(getShortbookListDispatcher),
    fork(getShortProductsDispatcher),
    fork(getTicketListDispatcher),
    fork(getTicketHistoryListDispatcher),
    fork(getBulkListDispatcher),
    fork(getBulkHistoryListDispatcher)
  ]);
}
