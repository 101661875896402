import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import DialogContentText from '@mui/material/DialogContentText';
import { Field, Form } from 'react-final-form'
import { requiredImage } from 'constants/validations';
import ImageValidate from './imagesvalidate.js';
import * as moment from 'moment';
import axios from 'axios';
import { NotificationManager } from 'react-notifications'
import AppConfig from 'constants/config';
import { useNavigate } from 'react-router-dom';

const AddImages = (props) => {
  const { add } = props;
  const navigate = useNavigate()

  const handleRequestClose = () => {
    props.handleClick('add');
  };

  const onSubmit = (values) => {
    let data = {
      images: values.promotion,
      orderId: props.menifestId
    }
    axios.post(`${AppConfig.baseUrl}manifest/SellerManifestImg`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error('Something went wrong, Please try again');
      } else {
        NotificationManager.success(result.data.title);
        let obj = {
          from_dateO: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to_dateO: moment().add(1, 'days').format('YYYY-MM-DD'),
          pageO: 0,
          searchO: '',
          manifest: true,
          openCheckbox: true,
        }
        let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
        navigate(`/seller/orders?${url}`, { replace: true })
      }
    })
      .catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
    props.handleClick('add')
  }

  return (
    <React.Fragment>
      <Dialog open={add} onClose={handleRequestClose}
        maxWidth={'md'}
      >
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                Add Images
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <Row>
                  <Col sm={2} md={2} lg={2} xs={2} xl={2} >   </Col>
                  <Col sm={7} md={7} lg={7} xs={7} xl={7} >
                    <Field
                      name="promotion"
                      id='promotion'
                      component={ImageValidate}
                      label="Upload Image"
                      validate={requiredImage}
                    />
                  </Col>
                  <Col sm={4} md={4} lg={4} xs={4} xl={4} >   </Col>
                </Row>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={submitting}>
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default AddImages