import React from 'react';
import { Breadcrumb } from 'reactstrap';
import Button from '@mui/material/Button';
import { NavLink, useLocation } from 'react-router-dom';
import helperFunction from 'constants/helperFunction';
import Tooltip from '@mui/material/Tooltip';
import AppConfig from 'constants/config'

const applyFilterforDownload = async () => {
  window.open(`${AppConfig.baseUrl}inventory/inventory_data?token=${localStorage.getItem('token')} `, '_blank');
}

const ContainerHeader = ({ title, rating, title1 }) => {
  const location = useLocation()
  return (
    <div className="page-heading d-flex justify-content-between align-items-sm-center">
      <Tooltip className='d-inline-block' id='tooltip-right' placement='left' title={
        <h6 className="text-white" style={{ marginTop: "13px" }}>
          {title}
        </h6>
      }>
        <h2 className="title mb-3 mb-sm-0" style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%'
        }} >
          {title}
        </h2>
      </Tooltip>
      <Breadcrumb className="mb-0" tag="nav">
        {
          location.pathname.includes("dashboard") && <NavLink NavLink to="/seller/ratings">
            <div className="ratingSmileEmoji" >
              {
                rating && rating.length > 0 ? <i className={helperFunction.getRatingValueForBuyer(Math.round(Number(rating[0].average)))} set={'google'} size={30} /> : ''
              }
            </div>
          </NavLink>
        }
      </Breadcrumb>
      {
        title1 === "inventory" && <Button variant="outlined" className={'text-primary'} onClick={() => applyFilterforDownload()}>
          Download
        </Button>
      }
    </div >
  )
};

export default ContainerHeader;

