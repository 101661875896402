import React from "react";
import FullWidthTabs from 'components/tabs/FullWidthTabsSettlement';
import ContainerHeader from 'components/ContainerHeader';
import SellerContainer from "components/sellerContainer"

const SettlemetsTab = (props) => {
  return (
    <SellerContainer>
      <ContainerHeader title="Settlements" />
      <FullWidthTabs />
    </SellerContainer>
  );
}

export default SettlemetsTab;