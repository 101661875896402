import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from 'components/buyersHeader';
import Footer from 'components/Footer';
import { getUserDetail } from 'actions'
import { isIOS, isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';

const BuyerContainer = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const drawerStyle = 'mini-drawer';
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height')
  }
  else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height')
  }
  let pathname = location.pathname
  let urls = pathname.split('/')
  let mobilePath = (urls[urls.length - 1]).toLowerCase() === 'mobile'

  useEffect(() => {
    if (!localStorage.getItem("buyer_token") && (pathname.includes("profile") || pathname.includes("shortbook") || pathname.includes("myCart"))) {
      navigate("/")
    }
  }, [pathname])

  return (
    <div className={`app-container ${drawerStyle}`}>
      <div className="app-main-container">
        {!mobilePath &&
          <div className={`app-header ${'app-header-horizontal'}`}>
            <Header />
          </div>}
        <main className="app-main-content-wrapper">
          <div className="app-main-content buyersMainContent">
            {props.children}
          </div>
          <Footer />
        </main>
      </div>

    </div>
  );
}


const mapStateToProps = ({ settings, auth, seller }) => {
  const { userDetails } = seller
  const { user_details } = auth;
  let users = userDetails ? userDetails : user_details
  return { user_details, users }
};

export default connect(mapStateToProps, { getUserDetail })(BuyerContainer);