import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';
import moment from 'moment';
import CustomScrollbars from 'util/CustomScrollbars';
import { getProductRequestList } from 'actions/buyer';
import ProductRequest from './ProductRequest'

function TabContainer({ children, dir, index, value }) {
  return (
    <div dir={dir}>
      {value === index && children}
    </div>
  );
}

const FullWidthProductRequestTab = (props) => {
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    let data = { page: 1, rowsPerPage: 50, month: moment().format("MMMM"), year: moment().format("GGGG"), tab: 'open' };
    props.getProductRequestList({ data });
  }, [])

  const handleChange = (event, value) => {
    setTabValue(value)
  };

  const { productRequestList } = props;

  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="History" />
        </Tabs>
      </AppBar>
      <TabContainer dir="ltr" value={tabValue} index={0}>
        <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
          <ProductRequest
            requestFromParent={productRequestList}
            identifier={'productRequestList'}
          />
        </CustomScrollbars>
      </TabContainer>
      <TabContainer dir="ltr" value={tabValue} index={1}>
        <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
          <ProductRequest
            requestFromParent={productRequestList}
            identifier={'productRequestHistory'}
          />
        </CustomScrollbars>
      </TabContainer>
    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { productRequestList } = buyer;
  return { productRequestList }
};

export default connect(mapStateToProps, { getProductRequestList })(FullWidthProductRequestTab);