import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FormGroup } from 'reactstrap';
import { Field, Form, FormSpy } from 'react-final-form'
import DialogTitle from '@mui/material/DialogTitle';
import ReactstrapSelectField from 'components/reactstrapSelectField';
import ReactStrapTextField from 'components/ReactStrapTextField';
import Drop from 'components/ticketTable/Drop'
import AxiosRequest from 'sagas/axiosRequest'
import { required } from 'constants/validations';
import axios from 'axios';
import { getAddTicket } from 'actions/seller';
import { connect } from 'react-redux';
import AppConfig from 'constants/config';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import moment from "moment"

const TicketHeader = (props) => {
    const [newFileUpload, setNewFileUpload] = useState([])
    const [error, setError] = useState('')
    const [countData, setCountData] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [uploadedFileArr, setUploadedFileArr] = useState([])
    const [openFilter, setOpenFilter] = useState(false)

    const handleRequestClose = () => {
        setOpenFilter(false)
        setError('')
    }

    const getFiles = async (data1) => {
        setUploadedFileArr([...uploadedFileArr, ...data1]);
        data1.map(data => {
            let reader;
            let file;
            file = data;
            reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
                let joined = [...newFileUpload].concat(e.target.result);
                setNewFileUpload(joined)
            }
        })
    }

    const remove = (file, i) => {
        let index = uploadedFileArr.findIndex(e => e.name === file.name);
        let temp = [...uploadedFileArr]
        temp.splice(index, 1)
        setUploadedFileArr(temp)
        let temp1 = [...newFileUpload]
        temp1.splice(i, 1)
        setNewFileUpload(temp1)
    }

    const onSubmit = async (values) => {
        setSubmitting(true)
        let { type, orderId, ogInvoiceAmt, order_id, issue, issueDescriptionOR, issueDescriptionO } = values;
        let data = {
            type: type,
            orderId: orderId,
            issue: issue,
            ogInvoiceAmt: ogInvoiceAmt,
            order_id: order_id,
            supportings: newFileUpload,
            issueDescription: type === 'order' ? issueDescriptionOR : issueDescriptionO
        }
        props.getAddTicket({ data })
        setSubmitting(false)
        handleRequestClose()
    }

    useEffect(() => {
        getTicketCount()
    }, [])

    const getTicketCount = async () => {
        await axios({
            method: 'get',
            url: `${AppConfig.baseUrl}ticket/ticketCount`,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('token')
            }
        }).then(result => {
            if (result.data.error) {
            } else {
                setCountData(result.data.data)
            }
        }).catch(error => { });
    }

    const getOrderData = async (values, form) => {
        let data = { orderId: values.orderId }
        let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/getOrderForTicket', '', data)
        if (result.data.error) {
            setError(result.data.title);
            form.initialize({
                ...values,
                orderDate: '',
                order_id: '',
                buyerName: '',
                ogInvoiceAmt: ''
            })
        } else {
            setError('')
            form.initialize({
                ...values,
                orderDate: moment(result.data.data.createdAt).format('DD/MM/YYYY'),
                order_id: result.data.data._id,
                buyerName: result.data.data.buyerCompName,
                ogInvoiceAmt: result.data.data.total_amount
            })
            // this.setState({ , submitting: false });
        }
    }

    return (
        <div className="page-heading buyerDetailsHeader d-flex justify-content-between align-items-sm-center">
            <h2 className="mb-3 mb-sm-0 font-weight-bold">No of open tickets: {countData && countData} </h2>
            <Button variant="outlined" className={'text-primary'} onClick={() => { setOpenFilter(true) }}>
                Add +
            </Button>
            <Dialog open={openFilter} onClose={handleRequestClose} fullWidth={true} maxWidth='md'>
                <DialogTitle className='pb-0'>
                    Add Ticket
                </DialogTitle>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        type: 'order',
                    }}
                    render={({ handleSubmit, values, form }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={props => {
                                    if (props.values.orderId !== values.orderId) {
                                        getOrderData(props.values, form)
                                    }
                                }}
                            />
                            <DialogContent >
                                {values.type === 'order' ?
                                    <>
                                        <Row>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field
                                                        name="type"
                                                        component={ReactstrapSelectField}
                                                        id="type"
                                                        type="select"
                                                        label='Ticket type'
                                                    >
                                                        <option value="order">Order Related</option>
                                                        <option value="other">Other</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field
                                                        name="issue"
                                                        component={ReactstrapSelectField}
                                                        id="issue"
                                                        type="select"
                                                        label='Issue type'
                                                        validate={required}
                                                    >
                                                        <option value="">Select issue</option>
                                                        <option value="Short supply">Short supply</option>
                                                        <option value="Damaged products">Damaged products</option>
                                                        <option value="Wrong invoice">Wrong invoice</option>
                                                        <option value="Diff in Invoice and Docket">Diff in Invoice and Docket</option>
                                                        <option value="Short Expiry">Short Expiry</option>
                                                        <option value="Order by mistake">Order by mistake</option>
                                                        <option value="Extra product received">Extra product received</option>
                                                        <option value="Damaged package received">Damaged package received</option>
                                                        <option value="Rescheduling related">Rescheduling related</option>
                                                        <option value="Order not processed">Order not processed</option>
                                                        <option value="Other issue">Other issue</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup className='mb-0'>
                                                    <Field id="orderId" name="orderId" type="text"
                                                        component={ReactStrapTextField} label="Order Id"
                                                        validate={required}
                                                    />
                                                </FormGroup>
                                                {error.length > 0 && <span className='text-danger'>{error}</span>}
                                            </Col>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field id="orderDate" name="orderDate" type="text"
                                                        component={ReactStrapTextField} label="Order Date"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field id="buyerName" name="buyerName" type="text"
                                                        component={ReactStrapTextField} label="Buyer Name"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field id="ogInvoiceAmt" name="ogInvoiceAmt" type="text"
                                                        component={ReactStrapTextField} label="Original Invoice Amt"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup >
                                                    <Field label='Issue Description' type='textarea' name='issueDescriptionOR'
                                                        rows="3"
                                                        component={ReactStrapTextField}
                                                        id="issueDescriptionOR" validate={required}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Drop handleFiles={getFiles} accept="image/*, application/pdf" />
                                                {uploadedFileArr.length > 0 && <div className="dropzone-content">
                                                    <ul className="mb-2" style={{ listStyleType: 'none' }}>
                                                        {
                                                            uploadedFileArr.map((file, i) => <li key={file.path}>
                                                                <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
                                                            </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                    : values.type === 'other' ?
                                        <>
                                            <Row>
                                                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                    <FormGroup>
                                                        <Field
                                                            name="type"
                                                            component={ReactstrapSelectField}
                                                            id="type"
                                                            validate={required}
                                                            type="select"
                                                            label='Ticket type'
                                                        >
                                                            <option value="order">Order Related</option>
                                                            <option value="other">Other</option>
                                                        </Field>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                    <FormGroup>
                                                        <Field
                                                            name="issue"
                                                            component={ReactstrapSelectField}
                                                            id="issue"
                                                            validate={required}
                                                            type="select"
                                                            label='Issue type'
                                                        >
                                                            <option value=" ">Select issue</option>
                                                            <option value="License related">License related</option>
                                                            <option value="Address related">Address related</option>
                                                            <option value="Delivery serviceability related">Delivery serviceability related</option>
                                                            <option value="COD disabled">COD disabled</option>
                                                            <option value="Delivery charges activated">Delivery charges activated</option>
                                                            <option value="Other issue">Other issue</option>
                                                        </Field>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup >
                                                        <Field label='Issue Description' type='textarea' name='issueDescriptionO'
                                                            rows="3"
                                                            component={ReactStrapTextField}
                                                            validate={required}
                                                            id="issueDescriptionO"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Drop handleFiles={getFiles} accept="image/*, application/pdf" />
                                                    {uploadedFileArr.length > 0 && <div className="dropzone-content">
                                                        <ul className="mb-2" style={{ listStyleType: 'none' }}>
                                                            {
                                                                uploadedFileArr.map((file, i) => <li key={file.path}>
                                                                    <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
                                                                </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </> :
                                        <div style={{ fontSize: 'larger' }}> Please select ticket type</div>
                                }
                                <DialogActions>
                                    <Button color='secondary' onClick={handleRequestClose}>Cancel</Button>
                                    {(values.type !== ' ' && !submitting) ?
                                        <Button type='submit' color='primary' disabled={submitting}>Sumbit</Button> :
                                        <Button disabled={true} color='primary'>Sumbit</Button>
                                    }
                                </DialogActions>
                            </DialogContent>
                        </form>
                    )}
                />
            </Dialog>

        </div>
    )
}

const mapStateToProps = ({ seller }) => {
    const { actionList } = seller
    return { actionList }
}
export default connect(mapStateToProps, { getAddTicket })(TicketHeader)