import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from 'components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteGroup from 'components/inventory/deleteInventory';
import AddGroup from './addGroup';
import EditGroup from './editGroup';
import { listGroup } from 'actions/seller';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import SellerContainer from "components/sellerContainer"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'components/PaginationCount';

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "noOfMember",
    label: "No Of Members",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
    }
  }
];

const Groups = (props) => {
  const [editGroup, setEditGroup] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [addGroup, setAddGroup] = useState(false)
  const [page, setPage] = useState(0)
  const [searchedText, setSearchedText] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const perPage = 50

  const changePage = (page) => {
    let pages = page + 1
    props.listGroup({ userId: '', searchText: searchedText, page: pages, perPage: perPage })
    setPage(page)
  };

  const handleSearch = (searchText) => {
    props.listGroup({ userId: '', searchText: searchText, page: page + 1, perPage: perPage })
    setSearchedText(searchText)
  };

  const handleClick = (key, value) => {
    setSelectedData(value)
    if (key === 'addGroup') {
      setAddGroup(!addGroup)
    }
    if (key === 'editGroup') {
      setEditGroup(!editGroup)
    }
    if (key === 'delete') {
      setDeleteOpen(!deleteOpen)
    }
  }

  useEffect(() => {
    props.listGroup({ searchText: searchedText, page: 1, perPage: perPage })
  }, [])

  const button = (data) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button className={'text-primary'} onClick={() => handleClick('editGroup', data)} > Edit</Button>
      <Button className={'text-danger'} onClick={() => handleClick('delete', data)}> Delete</Button>
    </ButtonGroup>
  )

  let { listGroupData } = props;
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: listGroupData && listGroupData[0] && listGroupData[0].metadata && listGroupData[0].metadata[0] && listGroupData[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  var groupData = [];
  listGroupData.length > 0 && listGroupData[0].data.length > 0 && listGroupData[0].data.map((data, index) => {
    groupData.push([data.name, data.members, button(data)]);
  });

  return (
    <SellerContainer>
      <ContainerHeader title="Groups" />
      {
        props.loading === false ?
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              title={<Button className={'text-primary'} selectedData={selectedData} onClick={(e) => handleClick('addGroup', selectedData)}> Add Group +</Button>}
              data={groupData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          :
          <div className="loader-view" style={{ textAlign: "center", marginTop: "300px" }}>
            <CircularProgress />
          </div>
      }
      <EditGroup selectedData={selectedData} editGroup={editGroup} title={'Edit Group'} handleClick={(e) => handleClick('editGroup')} />
      <AddGroup buttonType={'addGroup'} add_group={addGroup} title={'Add Group'} handleClick={(e) => handleClick('addGroup')} />
      <DeleteGroup delete={deleteOpen} selectedData={selectedData} deleteFor={'group'} handleClick={() => handleClick("delete")} />
    </SellerContainer>
  );
}

const mapStateToProps = ({ seller }) => {
  const { listGroupData, loading } = seller;
  return { listGroupData, loading }
};

export default connect(mapStateToProps, { listGroup })(Groups)