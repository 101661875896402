import React, { useState, useEffect, useRef } from 'react';
import { getUserDetail } from 'actions/seller'
import moment from "moment";
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Tooltip from '@mui/material/Tooltip';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import AppConfig from 'constants/config'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ComplianceForm = (props) => {
    const [expiryDate, setExpiryDate] = useState(moment().add(2, 'M'))
    const [value, setValue] = useState('')
    const [isVisited, setIsVisited] = useState(false)
    const [licNo, setLicNo] = useState('')
    const [openModal, setOpenModal] = useState(false)
    let drugLic20B = useRef(null)

    useEffect(() => {
        if (props.fileKey !== undefined) {
            setValue((props.userDetails[props.fileKey] && props.userDetails[props.fileKey].name !== undefined) ? `${AppConfig.baseUrl}users/${props.userDetails[props.fileKey].name}` : '')
            setExpiryDate(props.userDetails[props.fileKey] ? moment(props.userDetails[props.fileKey].expires) : expiryDate)
            setLicNo(props.userDetails[props.fileKey] ? props.userDetails[props.fileKey].lic : expiryDate)
        }
    }, [props.userDetails, props.fileKey])

    const handleFileSelect = (e) => {
        let document = "";
        let reader = new FileReader();
        if (e.target.files.length > 0) {
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                let data = {}
                document = reader.result;
                data[props.fileKey] = document
                setValue(document)
            };
            reader.onerror = function (error) {
            };
        }
    }

    const apiCall = (key, data) => {
        axios.post('/users/updateComplainceForm', data, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('buyer_token')
            },
        }
        ).then(result => {
            this.setState({ loader: false })
            if (result.data.error) {
                NotificationManager.error(result.data.title);
            } else {
                NotificationManager.success('Compliance details updated successfully.');
                setTimeout(() => {
                    props.getUserDetail({ userType: 'buyer' })
                }, 2000);
            }
        })
            .catch(error => {
                this.setState({ loader: false })
                NotificationManager.error('Something went wrong, Please try again')
            });
    }

    const handledateChange = (value) => {
        let data = {}
        data[props.name] = value
        setExpiryDate(value)
    }

    const handleChange = (e) => {
        setLicNo(e.target.value)
        setIsVisited(true)
    }

    const handleFocus = () => {
        setIsVisited(true)
    }

    const handleSubmit = () => {
        if (value && licNo) {
            let data = {}
            data[props.fileKey] = value.length > 200 ? value : '';
            data[props.name] = expiryDate;
            data[props.label2] = licNo
            apiCall('value', data)
        } else if (!licNo) {
            setIsVisited(true)
        } else if (!value) {
            NotificationManager.error('Please upload photo')
        }
        handleClose()
    }

    const getDisabled = () => {
        let expiryDate1 = props.userDetails[props.fileKey] ? props.userDetails[props.fileKey].expires : expiryDate
        let imageValue1 = (props.userDetails[props.fileKey] && props.userDetails[props.fileKey].name !== undefined) ? `${AppConfig.baseUrl}users/${props.userDetails[props.fileKey].name}` : ''
        let licNo1 = props.userDetails[props.fileKey] ? props.userDetails[props.fileKey].lic : licNo
        if (expiryDate === expiryDate1 && imageValue1 === value && licNo1 === licNo) {
            return true
        } else {
            return false
        }
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const { label, name, label2 } = props
    return (
        <React.Fragment>
            <div className='text-center'>
                <div className="p-2">
                    <input
                        type='file'
                        accept='.jpg, .png, .jpeg, .pdf'
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileSelect(e)} ref={(ref) => drugLic20B = ref}
                    />
                    <div class="img-wrap">
                    </div>
                    <img alt="upload" src={value !== '' ? require('assets/images/uploaded.png') : require('assets/images/file.png')} className='d-block mx-auto' />
                </div>
                <label className="text-dark" style={{ fontSize: 17 }}>{label}</label>
            </div>
            {
                name &&
                <div className="pt-2 text-center">
                    <span>Expiry date : </span>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            onChange={handledateChange}
                            name={name}
                            value={expiryDate}
                            minDate={moment()}
                            id="datePickerStyle"
                            className={'datePickerStyle cursor-pointer'}
                            leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                            rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                            format="MM/DD/YYYY"
                            variant="standard"
                        />
                    </LocalizationProvider>
                </div>
            }
            <div className="pt-2 text-center">
                <p>{label2}</p>
                <TextField
                    error={isVisited && !licNo}
                    id="outlined-error-helper-text"
                    label={''}
                    className={'cursor-pointer liCNo'}
                    helperText={isVisited && !licNo ? "This field is required" : ""}
                    margin="normal"
                    variant="standard"
                    value={licNo}
                    onClick={handleFocus}
                    onChange={(e) => handleChange(e)}
                />
            </div>

            <div className='mx-md-n5 d-flex justify-content-center pt-2'>
                <div className='mr-1 ml-1'>
                    <Tooltip title={`Upload ${label}`}>
                        <Avatar className='bg-primary cursor-pointer' onClick={(e) => drugLic20B.click()} size={2}>
                            <i className='zmdi zmdi-upload'></i>
                        </Avatar>
                    </Tooltip>
                </div>
                <div className='mr-1 ml-1'>
                    <Tooltip title={`${value ? 'View ' + label : label + ' not uploaded'} `} >
                        <Avatar className='bg-warning' color='primary' size={2}>
                            <a href={value} style={value ? { padding: 0 } : { pointerEvents: 'none', cursor: 'default' }} disabled={value ? false : true} target='_blank' rel="noreferrer"><i className='zmdi zmdi-eye text-white'></i></a>
                        </Avatar>
                    </Tooltip>
                </div>
                {
                    !getDisabled() &&
                    <div className='mr-1 ml-1'>
                        <Tooltip title={`Save ${label}`}>
                            <Avatar className={`bg-success cursor-pointer `} onClick={handleOpenModal} size={2}>
                                <i className='zmdi zmdi-floppy'></i>
                            </Avatar>
                        </Tooltip>
                    </div>
                }
            </div >
            <Dialog open={openModal} onClose={handleClose} fullWidth={true}>
                <DialogTitle id="alert-dialog-title" ><h2 style={{ color: "#fd4081" }}>Important Message</h2></DialogTitle>
                <DialogContent>
                    <h3>If you save, your profile will have to go under verification process again.</h3>
                </DialogContent>
                <DialogActions>
                    <Button className="jr-btn text-uppercase text-primary" onClick={handleSubmit}>Save</Button>
                    <Button onClick={handleClose} color="secondary" autoFocus> Cancel </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

const mapStateToProps = ({ seller }) => {
    const { userDetails } = seller;
    return { userDetails }
}
export default connect(mapStateToProps, { getUserDetail })(ComplianceForm);