import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import axios from 'constants/axios'
import { NotificationManager } from 'react-notifications';

const MyCartModal = (props) => {
  const [errorProd, setErrorProd] = useState([])
  const [LicenseErr, setLicenseErr] = useState(false)

  const add = async (val) => {
    let data = {
      product: val.productName.toUpperCase(),
      id: val.productId,
      quantity: Number(val.qty)
    }
    const result = await axios.post('shortBook/addShortBook', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('buyer_token')
      }
    })
    if (result.data.error) {
      NotificationManager.error(result.data.title);
    } else {
      setErrorProd(errorProd.concat(val.productId))
      NotificationManager.success(result.data.title);
    }
  }

  useEffect(() => {
    const { errorData } = props;
    errorData.map((val) => {
      if (val.productName && val.productName === "License Related") {
        setLicenseErr(true)
      }
    })
  }, [])

  const { errorData } = props;

  return (
    <React.Fragment>
      <DialogTitle className="mt-2" id="alert-dialog-title" style={{ textAlign: "center" }}>
        <span className="text-danger">
          <i class="zmdi zmdi-alert-circle animated wobble zmdi-hc-5x"></i>
        </span>
      </DialogTitle>
      {!LicenseErr ? <h4 className="font-weight-bold mt-1 mb-3">Following are the changes in your cart items:</h4> : ''}
      <div style={{ overflow: 'auto', height: '160px', width: '100%', overflowX: 'hidden' }}>
        {
          errorData.map((value, i) => {
            let there = errorProd.findIndex(e => e === value.productId);
            return (
              <Row className="mb-3">
                <Col md={4} xl={4} lg={4} sm={4} xs={4} className='mt-2'>
                  <h4>{i + 1}. {value.productName}: </h4>
                </Col>
                <Col md={6} xl={6} lg={6} sm={6} xs={6} className='mt-2'>
                  <h4>{value.msg}</h4>
                </Col>
                {value.showButton && there === -1 ?
                  <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                    <button style={{ padding: '7px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} onClick={(e) => add(value)}>
                      Add to ShortBook
                    </button>
                  </Col> :
                  (!value.showButton) ?
                    null
                    : there > -1 ?
                      <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                        <button style={{ padding: '10px', width: '100%', backgroundColor: '#dfdfdf', color: 'black', borderRadius: '5px', border: 'none', cursor: "default" }}  >
                          Added
                        </button>
                      </Col> : ''
                }
              </Row>
            )
          })
        }
      </div>
    </React.Fragment>
  );
}

export default MyCartModal;