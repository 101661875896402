import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,

  GET_BUYER_NOTIFICATIONS,
  GET_BUYER_NOTIFICATIONS_SUCCESS
} from "constants/ActionTypes";

import {
  getNotification,
  getNotificationSuccess,
  getBuyerNotification,
  getBuyerNotificationSuccess,

} from "actions/notification";

import { showAuthMessage } from "actions/Auth";
import Axios from './axiosRequest'

function* getNotificationFunction({ payload }) {
  try {
    const getNotificationResponse = yield call(Axios.axiosRequest, 'post', 'users/getNotification', '', payload);
    if (getNotificationResponse && getNotificationResponse.data && getNotificationResponse.data.error) {
      yield put(showAuthMessage(getNotificationResponse.data.title));
    } else if (getNotificationResponse && getNotificationResponse.data) {
      yield put(getNotificationSuccess(getNotificationResponse.data))
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getNotificationDispatcher() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationFunction);
}

//----------------------------------------------------------------------------------

function* getBuyerNotificationFunction({ payload }) {
  try {
    const getBuyerNotificationResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'users/getNotification', '', payload);
    if (getBuyerNotificationResponse && getBuyerNotificationResponse.data && getBuyerNotificationResponse.data.error) {
      yield put(showAuthMessage(getBuyerNotificationResponse.data.title));
    } else if (getBuyerNotificationResponse && getBuyerNotificationResponse.data) {
      yield put(getBuyerNotificationSuccess(getBuyerNotificationResponse.data))
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
  }
}
export function* getBuyerNotificationDispatcher() {
  yield takeLatest(GET_BUYER_NOTIFICATIONS, getBuyerNotificationFunction);
}



export default function* rootSaga() {
  yield all([
    fork(getNotificationDispatcher),
    fork(getBuyerNotificationDispatcher)
  ]);
}