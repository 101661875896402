import React, { useState, useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import { getUserDetail } from 'actions';
import { getMediWallet } from 'actions/buyer';
import AppConfig from 'constants/config';
import axios from 'axios';

const lists1 = [
  { id: 1, icon: 'edit', name: 'Edit Profile', color: 'warning' },
  { id: 2, icon: 'shopping-basket', name: 'Product Requests', color: 'secondary' },
  { id: 3, icon: 'view-headline', name: 'Bulk Requests', color: 'secondary' },
  { id: 4, icon: 'truck', name: 'Orders', color: 'info' },
  { id: 5, icon: 'ticket-star', name: 'Ticketing', color: 'secondary' },
  { id: 6, icon: 'card', name: 'Transactions', color: 'success' },
  { id: 7, icon: 'balance-wallet', name: 'MediWallet', color: 'danger' },
  { id: 8, icon: 'card', name: 'BNPL', color: 'dark' },
  { id: 9, icon: 'receipt', name: 'Compliance', color: 'primary' },
];

const Interests = (props) => {
  const [wallet, setWallet] = useState(0)
  const [bnplBal, setBnplBal] = useState('')

  const handleClick = (value) => {
    props.changeTab(value)
  }

  useEffect(() => {
    setWallet(props.users ? props.users.wallet_balance : 0)
  }, [props.users])

  useEffect(() => {
    callApi();
  }, [])

  const callApi = async () => {
    try {
      const result = await axios({
        method: 'post',
        url: `${AppConfig.baseUrl}bnpl/getBnplBal`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('buyer_token')
        }
      })
      if (!result.data.error) {
        setBnplBal(result.data.data.availableLimit)
      }
    } catch (error) { }
  }

  useEffect(() => {
    callApi();
  }, [props.title])

  const { userDataFromParent } = props;
  let lists = []
  lists1.map(d => {
    if (d.name === 'Bulk Requests' && userDataFromParent.bulkRequest === false) {
    } else {
      lists.push(d)
    }
  })

  return (
    <div className="jr-entry-sec">
      <div className="jr-card p-0">
        <List dense={true}>
          {lists.map((value, i) =>
            <ListItem key={i} selected={value.name === props.title} button onClick={() => handleClick(value.name)}>
              <IconButton>
                <i className={`zmdi zmdi-${value.icon} text-${value.color}`} />
              </IconButton>
              <ListItemText
                primary={<div>
                  <span>
                    {
                      value.name
                    }
                  </span>
                  <span className="float-right">
                    {value.name === 'MediWallet' ? props.users === undefined ? '' : 'MNY ' + (wallet).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '/-' : ''}
                    {value.name === 'BNPL' ? bnplBal && '₹ ' + (bnplBal).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '/-' : ''}
                  </span>
                </div>}
              >
              </ListItemText>
            </ListItem>
          )}
        </List>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, seller, buyer }) => {
  const { userDetails } = seller
  const { user_details } = auth;
  let users = userDetails ? userDetails : user_details
  return { user_details, users }
};

export default connect(mapStateToProps, { getUserDetail, getMediWallet })(Interests);