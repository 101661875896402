//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

//Auth const

export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';

export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';

export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';

export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';


//------------- BUYER AUTHENTICATION ---------------//

export const SIGNUP_BUYER = 'signup_buyer';
export const SIGNUP_BUYER_SUCCESS = 'signup_buyer_success';


// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

export const CHANGE_TAB = 'change_tab'


/***************************Seller************************** */

export const API_FAILED = 'API_FAILED';

export const GET_SIDEBAR = 'GET_SIDEBAR';
export const GET_SIDEBAR_SUCCESS = 'GET_SIDEBAR_SUCCESS';

export const VACCATION_MODE = 'VACCATION_MODE';
export const VACCATION_MODE_SUCCESS = 'VACCATION_MODE_SUCCESS';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const LIST_GROUP = 'LIST_GROUP';
export const LIST_GROUP_SUCCESS = 'LIST_GROUP_SUCCESS';

export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';

export const DELETE_STAFF = 'DELETE_STAFF';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';

export const LIST_STAFF = 'LIST_STAFF';
export const LIST_STAFF_SUCCESS = 'LIST_STAFF_SUCCESS';

export const LIST_PERMISSION_MODULES = 'LIST_PERMISSION_MODULES';
export const LIST_PERMISSION_MODULES_SUCCESS = 'LIST_PERMISSION_MODULES_SUCCESS';

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const REQUEST_PRODUCT_SUCCESS = 'REQUEST_PRODUCT_SUCCESS';

export const GET_REQUEST_PRODUCT = 'GET_REQUEST_PRODUCT'
export const GET_REQUEST_PRODUCT_SUCCESS = 'GET_REQUEST_PRODUCT_SUCCESS';

export const GET_USERDETAILS = 'GET_USERDETAILS';
export const GET_USERDETAILS_SUCCESS = 'GET_USERDETAILS_SUCCESS';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';

export const GET_INVENTORY_POPUP = 'GET_INVENTORY_POPUP';
export const GET_INVENTORY_POPUP_SUCCESS = 'GET_INVENTORY_POPUP_SUCCESS';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';

export const GET_DASHBOARD_CARD = 'GET_DASHBOARD_CARD'
export const GET_DASHBOARD_CARD_SUCCESS = 'GET_DASHBOARD_CARD_SUCCESS';

export const GET_DASHBOARD_TOP_SELLING_PRODUCTS = 'GET_DASHBOARD_TOP_SELLING_PRODUCTS';
export const GET_DASHBOARD_TOP_SELLING_PRODUCTS_SUCCESS = 'GET_DASHBOARD_TOP_SELLING_PRODUCTS_SUCCESS';

export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';

export const GET_SETTLEMENT = 'GET_SETTLEMENT';
export const GET_SETTLEMENT_SUCCESS = 'GET_SETTLEMENT_SUCCESS';

export const GET_DASHBOARD_SALES_STATS = 'GET_DASHBOARD_SALES_STATS';
export const GET_DASHBOARD_SALES_STATS_SUCCESS = 'GET_DASHBOARD_SALES_STATS_SUCCESS';

export const GET_DASHBOARD_LEAST_SELLING_PRODUCTS = 'GET_DASHBOARD_LEAST_SELLING_PRODUCTS';
export const GET_DASHBOARD_LEAST_SELLING_PRODUCTS_SUCCESS = 'GET_DASHBOARD_LEAST_SELLING_PRODUCTS_SUCCESS';
export const REMOVE_USER = 'REMOVE_USER'


/***************************Buyer************************** */

// 1.] Firstly, define ActionTypes

export const REMOVE_AUTH = 'REMOVE_AUTH'
export const LOGOUT_USER = 'LOGOUT_USER'
export const GET_FEATURED_PRODUCT_LIST = 'GET_FEATURED_PRODUCT_LIST';
export const GET_FEATURED_PRODUCT_LIST_SUCCESS = 'GET_FEATURED_PRODUCT_LIST_SUCCESS';

export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';


export const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST';
export const GET_TRANSACTION_LIST_SUCCESS = 'GET_TRANSACTION_LIST_SUCCESS';

export const GET_MEDIWALLET = 'GET_MEDIWALLET';
export const GET_MEDIWALLET_SUCCESS = 'GET_MEDIWALLET_SUCCESS';

export const GET_PRODUCTS_FOR_YOU = 'GET_PRODUCTS_FOR_YOU';
export const GET_PRODUCTS_FOR_YOU_SUCCESS = 'GET_PRODUCTS_FOR_YOU_SUCCESS';


export const GET_ORDER_LIST = 'GET_ORDER_LIST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';

export const GET_ORDER_HISTORY_LIST = 'GET_ORDER_HISTORY_LIST';
export const GET_ORDER_HISTORY_LIST_SUCCESS = 'GET_ORDER_HISTORY_LIST_SUCCESS';

export const GET_REVIEW_LIST = 'GET_REVIEW_LIST';
export const GET_REVIEW_LIST_SUCCESS = 'GET_REVIEW_LIST_SUCCESS';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';

export const GET_CART_DETAILS = "GET_CART_DETAILS";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS"

export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";

export const GET_VISITED_CATEGORY = "GET_VISITED_CATEGORY";
export const GET_VISITED_CATEGORY_SUCCESS = "GET_VISITED_CATEGORY_SUCCESS";

export const GET_PRODUCTS_BY_COMPANY = 'GET_PRODUCTS_BY_COMPANY';
export const GET_PRODUCTS_BY_COMPANY_SUCCESS = 'GET_PRODUCTS_BY_COMPANY_SUCCESS';

export const BULK_REQUEST = "BULK_REQUEST";
export const BULK_REQUEST_SUCCESS = "BULK_REQUEST_SUCCESS";

export const GET_SEARCH_PRODUCT = "GET_SEARCH_PRODUCT";
export const GET_SEARCH_PRODUCT_SUCCESS = "GET_SEARCH_PRODUCT_SUCCESS"

export const GET_PRODUCTS_BY_SELLER = 'GET_PRODUCTS_BY_SELLER';
export const GET_PRODUCTS_BY_SELLER_SUCCESS = 'GET_PRODUCTS_BY_SELLER_SUCCESS';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const GET_AUTO_FILL = "GET_AUTO_FILL";
export const GET_AUTO_FILL_SUCCESS = "GET_AUTO_FILL_SUCCESS";

export const GET_FILTER = "GET_FILTER";
export const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";

export const EMPTY_CART = 'EMPTY_CART';
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';

export const GET_UPDATE_ORDER = 'GET_UPDATE_ORDER';
export const GET_UPDATE_ORDER_SUCCESS = 'GET_UPDATE_ORDER_SUCCESS';

export const GET_BUYER_NOTIFICATIONS = 'GET_BUYER_NOTIFICATIONS';
export const GET_BUYER_NOTIFICATIONS_SUCCESS = 'GET_BUYER_NOTIFICATIONS_SUCCESS';

export const GET_PROMOTIONS_LISTING = 'GET_PROMOTIONS_LISTING';
export const GET_PROMOTIONS_LISTING_SUCCESS = 'GET_PROMOTIONS_LISTING_SUCCESS';

export const GET_MARK_READ_LIST = 'GET_MARK_READ_LIST';
export const GET_MARK_READ_LIST_SUCCESS = 'GET_MARK_READ_LIST_SUCCESS';

export const GET_MARK_READ_LIST_FOR_BUYER = 'GET_MARK_READ_LIST_FOR_BUYER';
export const GET_MARK_READ_LIST_FOR_BUYER_SUCCESS = 'GET_MARK_READ_LIST_FOR_BUYER_SUCCESS';

export const GET_PRODUCT_REQUEST_LIST = 'GET_PRODUCT_REQUEST_LIST';
export const GET_PRODUCT_REQUEST_LIST_SUCCESS = 'GET_PRODUCT_REQUEST_LIST_SUCCESS';

export const GET_SHORTBOOK = 'GET_SHORTBOOK';
export const GET_SHORTBOOK_SUCCESS = 'GET_SHORTBOOK_SUCCESS';

export const GET_SHORT_PRODUCTS = 'GET_SHORT_PRODUCTS';
export const GET_SHORT_PRODUCTS_SUCCESS = 'GET_SHORT_PRODUCTS_SUCCESS';

// -----------------------------------SETTLEMENTS---------------------------------------

export const GET_SETTLEMENTS = 'GET_SETTLEMENTS'
export const GET_SETTLEMENTS_SUCCESS = 'GET_SETTLEMENTS_SUCCESS';

export const GET_LIST_GROUP_SETTLEMENT = 'GET_LIST_GROUP_SETTLEMENT';
export const GET_LIST_GROUP_SETTLEMENT_SUCCESS = 'GET_LIST_GROUP_SETTLEMENT_SUCCESS';

// -------------------------------TICKET-----------------------------------

export const GET_TICKET_LIST = 'GET_TICKET_LIST';
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';

export const GET_TICKET_HISTORY_LIST = 'GET_TICKET_HISTORY_LIST';
export const GET_TICKET_HISTORY_LIST_SUCCESS = 'GET_TICKET_HISTORY_LIST_SUCCESS';

export const GET_TICKET_LIST_SELLER = 'GET_TICKET_LIST_SELLER';
export const GET_TICKET_LIST_SELLER_SUCCESS = 'GET_TICKET_LIST_SELLER_SUCCESS';

export const GET_ADD_TICKET = 'GET_ADD_TICKET';
export const GET_ADD_TICKET_SUCCESS = 'GET_ADD_TICKET_SUCCESS';

// -------------------------------BULK REQ-----------------------------------

export const GET_BULK_LIST = 'GET_BULK_LIST';
export const GET_BULK_LIST_SUCCESS = 'GET_BULK_LIST_SUCCESS';

export const GET_BULK_HISTORY_LIST = 'GET_BULK_HISTORY_LIST';
export const GET_BULK_HISTORY_LIST_SUCCESS = 'GET_BULK_HISTORY_LIST_SUCCESS';

export const GET_BULK_LIST_SELLER = 'GET_BULK_LIST_SELLER';
export const GET_BULK_LIST_SELLER_SUCCESS = 'GET_BULK_LIST_SELLER_SUCCESS';