import {
  API_FAILED,
  VACCATION_MODE,
  VACCATION_MODE_SUCCESS,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  LIST_GROUP,
  LIST_GROUP_SUCCESS,
  LIST_PERMISSION_MODULES,
  LIST_PERMISSION_MODULES_SUCCESS,
  GET_SIDEBAR,
  GET_SIDEBAR_SUCCESS,
  ADD_STAFF,
  ADD_STAFF_SUCCESS,
  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  LIST_STAFF,
  LIST_STAFF_SUCCESS,
  GET_REQUEST_PRODUCT,
  GET_REQUEST_PRODUCT_SUCCESS,
  REQUEST_PRODUCT,
  REQUEST_PRODUCT_SUCCESS,
  GET_USERDETAILS,
  GET_USERDETAILS_SUCCESS,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_DASHBOARD_CARD,
  GET_DASHBOARD_CARD_SUCCESS,
  GET_DASHBOARD_TOP_SELLING_PRODUCTS,
  GET_DASHBOARD_TOP_SELLING_PRODUCTS_SUCCESS,
  GET_SETTLEMENT,
  GET_SETTLEMENT_SUCCESS,
  GET_DASHBOARD_LEAST_SELLING_PRODUCTS,
  GET_DASHBOARD_LEAST_SELLING_PRODUCTS_SUCCESS,
  GET_MARK_READ_LIST,
  GET_MARK_READ_LIST_SUCCESS,

  GET_INVENTORY_POPUP,
  GET_INVENTORY_POPUP_SUCCESS,

    // ------------SETTLEMENS-------------------

    GET_SETTLEMENTS,
    GET_SETTLEMENTS_SUCCESS,
    REMOVE_USER,

  GET_LIST_GROUP_SETTLEMENT,
  GET_LIST_GROUP_SETTLEMENT_SUCCESS,
  GET_TICKET_LIST_SELLER,
  GET_TICKET_LIST_SELLER_SUCCESS,
  GET_ADD_TICKET,
  GET_ADD_TICKET_SUCCESS,
  GET_BULK_LIST_SELLER,
  GET_BULK_LIST_SELLER_SUCCESS
} from 'constants/ActionTypes'

export const removeUser = ()=>{
  return{
    type:REMOVE_USER,
    data:''
  }
}
export const apiFailed = (error) => {
  
  return {
    type: API_FAILED,
    payload: error
  };
};

export const vaccationMode = (data) => {
  return {
    type: VACCATION_MODE,
    payload: data
  };
};

export const vaccationModeSuccess = (data) => {
  return {
    type: VACCATION_MODE_SUCCESS,
    payload: data
  };
};

export const addGroup = (data) => {
  return {
    type: ADD_GROUP,
    payload: data
  };
};

export const addGroupSuccess = (data) => {
  return {
    type: ADD_GROUP_SUCCESS,
    payload: data
  };
};

export const deleteGroup = (data) => {
  return {
    type: DELETE_GROUP,
    payload: data
  };
};

export const deleteGroupSuccess = (data) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: data
  };
};


export const listGroup = (data) => {
  return {
    type: LIST_GROUP,
    payload: data
  };
};

export const listGroupSuccess = (data) => {
  return {
    type: LIST_GROUP_SUCCESS,
    payload: data
  };
};

export const listPermissionModules = (data) => {
  return {
    type: LIST_PERMISSION_MODULES,
    payload: data
  };
};

export const listPermissionModulesSuccess = (data) => {
  return {
    type: LIST_PERMISSION_MODULES_SUCCESS,
    payload: data
  };
};

export const getSidebar = (data) => {
  return {
    type: GET_SIDEBAR,
    payload: data
  };
};

export const getSidebarSuccess = (data) => {
  return {
    type: GET_SIDEBAR_SUCCESS,
    payload: data
  };
};

export const getSettlement = (data) => {
  return {
    type: GET_SETTLEMENT,
    payload: data
  };
};

export const getSettlementSuccess = (data) => {
  return {
    type: GET_SETTLEMENT_SUCCESS,
    payload: data
  };
};


export const addStaff = (data) => {
  return {
    type: ADD_STAFF,
    payload: data
  };
};

export const addStaffSuccess = (data) => {
  return {
    type: ADD_STAFF_SUCCESS,
    payload: data
  };
};

export const deleteStaff = (data) => {
  return {
    type: DELETE_STAFF,
    payload: data
  };
};

export const deleteStaffSuccess = (data) => {
  return {
    type: DELETE_STAFF_SUCCESS,
    payload: data
  };
};


export const listStaff = (data) => {
  return {
    type: LIST_STAFF,
    payload: data
  };
};

export const listStaffSuccess = (data) => {
  return {
    type: LIST_STAFF_SUCCESS,
    payload: data
  };
};


export const addRequestProduct = (data) => {
  return {
    type: REQUEST_PRODUCT,
    payload: data
  };
};

export const addRequestProductSuccess = (data) => {
  return {
    type: REQUEST_PRODUCT_SUCCESS,
    payload: data
  };
};


export const getProductRequest = (data) => {
  return {
    type: GET_REQUEST_PRODUCT,
    payload: data
  };
};

export const getProductRequestSuccess = (data) => {
  return {
    type: GET_REQUEST_PRODUCT_SUCCESS,
    payload: data
  };
};

export const getUserDetail = (data) => {
  return {
    type: GET_USERDETAILS,
    payload: data
  };
};

export const getUserDetailSuccess = (data) => {
  return {
    type: GET_USERDETAILS_SUCCESS,
    payload: data
  };
};


export const getInventoryList = (data) => {
  return {
    type: GET_INVENTORY,
    payload: data
  };
};

export const getInventoryListSuccess = (data) => {
  return {
    type: GET_INVENTORY_SUCCESS,
    payload: data
  };
};

export const getInventoryListPopUp = (data) => {
  return {
    type: GET_INVENTORY_POPUP,
    payload: data
  };
};

export const getInventoryListPopUpSuccess = (data) => {
  return {
    type: GET_INVENTORY_POPUP_SUCCESS,
    payload: data
  };
};


export const getDashboardCard = (data) => {
  return {
    type: GET_DASHBOARD_CARD,
    payload: data
  };
};

export const getDashboardCardSuccess = (data) => {
  return {
    type: GET_DASHBOARD_CARD_SUCCESS,
    payload: data
  };
};
export const getDashboardTopSellingProducts = (data) => {
  return {
    type: GET_DASHBOARD_TOP_SELLING_PRODUCTS,
    payload: data
  };
};

export const getDashboardTopSellingProductsSuccess = (data) => {
  return {
    type: GET_DASHBOARD_TOP_SELLING_PRODUCTS_SUCCESS,
    payload: data
  };
};

export const getSettlementList = (data) => {
  return {
    type: GET_SETTLEMENT,
    payload: data
  };
};

export const getSettlementListSuccess = (data) => {
  return {
    type: GET_SETTLEMENT_SUCCESS,
    payload: data
  };
};

export const getDashboardLeastSellingProducts = (data) => {
  return {
    type: GET_DASHBOARD_LEAST_SELLING_PRODUCTS,
    payload: data
  };
};

export const getDashboardLeastSellingProductsSuccess = (data) => {
  return {
    type: GET_DASHBOARD_LEAST_SELLING_PRODUCTS_SUCCESS,
    payload: data
  };
};

export const getMarkRead = (data) =>{
  return {
      type: GET_MARK_READ_LIST,
      payload: data
  };
};

export const getMarkReadSuccess = (data) => {
  return {
      type: GET_MARK_READ_LIST_SUCCESS,
      payload: data
  };
};


export const getSettlements = (data) => {
  return {
    type: GET_SETTLEMENTS,
    payload: data
  };
};

export const getSettlementsSuccess = (data) => {
  return {
    type: GET_SETTLEMENTS_SUCCESS,
    payload: data
  };
};

export function getListGroupSettlement(data) {
  return {
    type: GET_LIST_GROUP_SETTLEMENT,
    payload: data
  }
}

export function getListGroupSettlementSuccess(data) {
  return {
    type: GET_LIST_GROUP_SETTLEMENT_SUCCESS,
    payload: data
  }
}

export const getTicketListSeller = (data) =>{
  return {
      type: GET_TICKET_LIST_SELLER,
      payload: data
  };
};

export const getTicketListSellerSuccess = (data) => {
  return {
      type: GET_TICKET_LIST_SELLER_SUCCESS,
      payload: data
  };
};

export const getAddTicket = (data) =>{
  return {
      type: GET_ADD_TICKET,
      payload: data
  };
};

export const getAddTicketSuccess = (data) => {
  return {
      type: GET_ADD_TICKET_SUCCESS,
      payload: data
  };
};

export const getBulkListSeller = (data) =>{
  return {
      type: GET_BULK_LIST_SELLER,
      payload: data
  };
};

export const getBulkListSellerSuccess = (data) => {
  return {
      type: GET_BULK_LIST_SELLER_SUCCESS,
      payload: data
  };
};