import axios from 'axios';
import AppConfig from '../constants/config'

/*
#purpose: Common function to call api
#
*/
const axiosRequest = async(method='post',url,type,data)=>{
    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token:localStorage.getItem('token')
        },
        data:{
            email:data.email?data.email:'',
            password:data.password?data.password:'',
            user_type:data.user_type?data.user_type:'seller'
        }
    })
    .then(result=>result)
    .catch(error=>error)
}


const axiosHelperFunc = async(method='post',url,type,data)=>{
    
    

    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token: localStorage.getItem('token')
        },
        data
    })
    .then(result=>result)
    .catch(error=>error)
}

const axiosBuyerHelperFunc = async(method='post',url,type,data)=>{


    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token: localStorage.getItem('buyer_token')
        },
        data
    })
    .then(result=>result)
    .catch(error=>error)
}
const axiosTokenSwitch = async(method='post',url,type,data)=>{
    let tokens = data.user_type == 'buyer' ? localStorage.getItem('buyer_token') : localStorage.getItem('token');
    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token: tokens
        },
        data
    })
    .then(result=>result)
    .catch(error=>error)
}

export default {
    axiosRequest,
    axiosHelperFunc,
    axiosBuyerHelperFunc,
    axiosTokenSwitch
}
