import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { deleteGroup, deleteStaff } from 'actions/seller';
import { getInventoryList } from 'actions'
import AxiosRequest from 'sagas/axiosRequest'
import { NotificationManager } from 'react-notifications';

const DeleteInventory = (props) => {
  const [groupName, setGroupName] = useState('')

  useEffect(() => {
    if (props.deleteFor === 'group') {
      setGroupName(props.selectedData ? props.selectedData.name : '')
    }
  }, [props])

  const handleRequestClose = () => {
    if (props.hideFor && props.hideFor === 'inventory') {
      return props.handleClick('hide');
    }
    props.handleClick('delete');
  }

  const handleSubmit = async () => {
    if (props.hideFor && props.hideFor === 'inventory') {
      let data = {
        inventoryId: props.editData._id,
        hide: !props.editData.isHidden
      }
      let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/hideInventoryItem', '', data)
      if (response.data.error) {
        NotificationManager.error(response.data.title)
        props.handleClick('hide');
      } else {
        NotificationManager.success(response.data.title)
        props.handleClick('hide');
        props.callMount();
      }
    }
    if (props.deleteFor === 'group') {
      let memberCount = parseInt(props.selectedData.members);
      if (memberCount === 0) {
        props.deleteGroup({ name: groupName, listGroup: { searchText: '', page: 1, perPage: 10 } });
        props.handleClick('delete');
      } else {
        NotificationManager.error("This group contains active members, please remove the members and try again.")
        props.handleClick('delete');
      }
    }
    if (props.deleteFor === 'staff') {
      props.deleteStaff({ staffId: props.selectedData._id, listStaff: { searchText: '', page: 1, perPage: 10 } });
      props.handleClick('delete');
    }
    if (props.deleteFor === 'inventory') {
      let data = { inventoryId: props.editData._id }
      let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/deleteInventoryItem', '', data)
      if (response.data.error) {
        NotificationManager.error(response.data.title)
        props.handleClick('delete');
      } else {
        NotificationManager.success(response.data.title)
        props.handleClick('delete');
        props.callMount();
      }
    }
  }

  return (
    <React.Fragment>
      <Dialog open={props.delete} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          {props.hideFor === 'inventory' ? props.editData ? props.editData.isHidden ? 'Unhide item?' : 'Hide item?' : '' : ''}
          {props.deleteFor === 'inventory' ? 'Remove item?' : ''}
          {props.deleteFor === 'staff' ? 'Remove Staff?' : ''}
          {props.deleteFor === 'group' ? 'Remove Group?' : ''}
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            {
              props.deleteFor === 'inventory' ?
                <DialogContentText className='mt-3'>
                  This item will be removed from your inventory
                </DialogContentText>
                : ''
            }
            {
              props.deleteFor === 'staff' ?
                <DialogContentText className='mt-3'>
                  Are you sure, you want to remove this staff member.
                </DialogContentText>
                : ''
            }
            {
              props.deleteFor === 'group' ?
                <DialogContentText className='mt-3'>
                  Are you sure, you want to remove this Group.
                </DialogContentText>
                : ''
            }
            {
              props.hideFor === 'inventory' && props.editData &&
              <DialogContentText className='mt-3'>
                {`Are you sure, you want to ${!props.editData.isHidden ? 'hide' : 'unhide'} this item ?`}
              </DialogContentText>
            }
          </form>
        </DialogContent>
        <DialogActions className="pr-4">
          <Button onClick={handleRequestClose} color='secondary' >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            {props.hideFor === 'inventory' ? 'Confirm' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = () => {
  return {}
};

export default connect(mapStateToProps, { deleteGroup, deleteStaff, getInventoryList })(DeleteInventory)