import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Avatar from '@mui/material/Avatar';
import { Col, Row } from 'reactstrap';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'constants/axios';
import { Field, Form } from 'react-final-form'
import moment from 'moment';
import { connect } from 'react-redux';
import { getProductDetails, getVisitedCategory, bulkRequest, addToCart, getCartDetails } from 'actions/buyer';
import { NotificationManager } from 'react-notifications';
import AxiosRequest from 'sagas/axiosRequest'
import { FormGroup, Label, Input } from 'reactstrap';
import helpertFn from 'constants/helperFunction';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { required, number, number0, maxLength4 } from 'constants/validations';
import { Helmet } from "react-helmet";
import ReactStrapTextField from 'components/ReactStrapTextField';
import { CircularProgress } from '@mui/material'

const ProductDetail = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const [invalid, setInvalid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [bulkQuantity, setBulkQuantity] = useState(0);
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState('');
  const [updatedCount, setUpdatedCount] = useState(0);
  const [bulkReqEptr, setBulkReqEptr] = useState(0);
  const [bestOffer, setBestOffer] = useState('');

  const handleClick = () => {
    navigate('/view-seller')
  }

  useEffect(() => {
    props.getProductDetails({ inventory_id: params.id })
  }, [])

  const handleOpenModal = async () => {
    let { productData } = props;
    let data1 = { Id: productData && productData[0] && productData[0].Product._id }
    let response1 = await AxiosRequest.axiosBuyerHelperFunc('post', 'product/bestOffer', '', data1)
    let offer = response1 && response1.data && response1.data.data.slice(0, 1);
    let ePtr = productData && productData.length > 0 && productData[0].ePTR;
    setOpenModal(true)
    setDiscount('')
    setBulkReqEptr(offer.length > 0 ? Number((offer[0].price).toFixed(2)) : ePtr)
    setBestOffer(offer[0])
  }

  const handleClose = () => {
    setOpenModal(!openModal)
    setBulkQuantity('')
    setDiscount('')
    setBulkReqEptr(0)
    setAmount(0)
  }

  const handleBulkRequest = async () => {
    try {
      let { productData } = props;
      let data = {
        product_name: productData && productData.length > 0 && productData[0].Product.name,
        quantity: Number(bulkQuantity),
        discount: discount ? discount : 0,
        amount: Number(amount),
        ptr: Number(bulkReqEptr),
        Id: productData && productData.length > 0 && productData[0].Product._id
      }
      const result = await axios.post('/bulkRequest/createBulkRequest', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('buyer_token')
        }
      })
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
      }
      handleClose();
    } catch (error) {
      NotificationManager.error('Something went wrong, Please try again')
    }
  }

  const handleIncremented = (key, event) => {
    if ((Number(updatedCount) + Number(props.productData[0].min_order_quantity)) <= Number(props.productData[0].max_order_quantity)) {
      setUpdatedCount(Number(updatedCount) + Number(props.productData[0].min_order_quantity))
    }
  }

  const handleDecremented = (key, event) => {
    if (updatedCount !== props.productData[0].min_order_quantity && updatedCount - props.productData[0].min_order_quantity >= props.productData[0].min_order_quantity) {
      setUpdatedCount(Number(updatedCount) - Number(props.productData[0].min_order_quantity))
    }
  }

  const handleChanged = (e, key) => {
    if (key === 'discount') {
      setDiscount(e.target.value)
    } else {
      setBulkQuantity(e.target.value)
    }
    let { productData } = props;
    let minOrder = productData && productData.length > 0 && productData[0].Product.minOrder;
    if (key === 'bulkQuantity') {
      let value = Number(e.target.value) * Number(bulkReqEptr) - ((Number(e.target.value) * Number(bulkReqEptr)) * Number(discount) / 100);
      if (value < minOrder) {
        setError(true)
        setAmount(value)
      } else {
        setError(false)
        setAmount(value)
      }
    }
    if (key === 'discount') {
      let value = Number(bulkQuantity) * Number(bulkReqEptr) - ((Number(bulkQuantity) * Number(bulkReqEptr)) * Number(e.target.value) / 100);
      if (value < minOrder) {
        setError(true)
        setAmount(value)
      } else {
        setError(false)
        setAmount(value)
      }
    }
  }

  const handleQuantityChanged = () => {
    NotificationManager.warning('Please use buttons for increment and decrement')
  };

  const onSubmit = async (e) => {
    setDisabled(true)
    let data
    if (props.productData[0].Discount) {
      if (updatedCount >= props.productData[0].min_order_quantity && updatedCount <= props.productData[0].max_order_quantity && Number.isInteger(updatedCount) === true) {
        data = {
          cart_details: [{
            inventory_id: props.productData[0].inventory_id,
            quantity: updatedCount
          }]
        }
        let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'cart/addtoCart', '', data)
        if (response.data.error) {
          // NotificationManager.error("Please Sign In.")
          NotificationManager.error(response.data.title)
        } else {
          NotificationManager.success(response.data.title)
          const isLogin = localStorage.getItem("buyer_token");
          if (isLogin) {
            props.getCartDetails({})
          }
        }
      }
    } else if (quantity >= props.productData[0].min_order_quantity && quantity <= props.productData[0].max_order_quantity && Number.isInteger(quantity) === true) {
      data = {
        cart_details: [{
          inventory_id: props.productData[0].inventory_id,
          quantity: updatedCount
        }]
      }
      let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'cart/addtoCart', '', data)
      if (response.data.error) {
        // NotificationManager.error("Please Sign In.")
        NotificationManager.error(response.data.title)
      } else {
        NotificationManager.success(response.data.title)
        const isLogin = localStorage.getItem("buyer_token");
        if (isLogin) {
          props.getCartDetails({})
        }
      }
    } else {
      NotificationManager.error(!quantity ? 'Please enter quantity' : "Please check Minimum & Maximum order quantity.")
    }
  }

  const maxLengthCheck = (event) => {
    if (event.target.value > props.productData[0].max_order_quantity) {
      event.target.value = props.productData[0].min_order_quantity
      setInvalid(true)
    }
  }

  useEffect(() => {
    if (props.productData && props.productData[0]) {
      setQuantity(props.productData[0].min_order_quantity)
      setUpdatedCount(props.productData[0]?.min_order_quantity)
      setInvalid(false)
    }
  }, [props.productData])

  const user = localStorage.getItem("buyer_token")
  const { productData, users } = props;
  let title = params.keyword && params.keyword.toUpperCase().split('-').join(' ');
  const bgColor = (productData && productData.length > 0 && productData[0].medi_type === 'Ethical branded') || (productData && productData.length > 0 && productData[0].medi_type === 'Others') ? '#ff7000' :
    productData && productData.length > 0 && productData[0].medi_type === 'Cool chain' ? '#0b68a8' :
      (productData && productData.length > 0 && productData[0].medi_type === 'Surgical') || (productData && productData.length > 0 && productData[0].medi_type === 'OTC') || (productData && productData.length > 0 && productData[0].medi_type === 'Generic') ? '#038d0e' : '#072791'

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  const getError = () => !bulkQuantity ? "This field is required" : bulkQuantity && isNaN(Number(bulkQuantity)) ? 'Must be a number' : Number(bulkQuantity) <= -1 ? 'Value cannot be negative' : Number(bulkQuantity) === 0 ? "Can't be zero" : bulkQuantity && bulkQuantity.length > 4 ? `Must be ${4} characters or less` : ""

  return (
    <React.Fragment>
      {props.loading ?
        <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div>
        :
        <div className='pt-3'>
          <Helmet>
            <title>Buy {title} Online In India - Medimny</title>
            <meta name="title" content={`Buy ${title} Online In India - Medimny`} />
            <meta name="description" content={`Medimny is a B2B E-Commerce platform for Pharmaceutical Products for Distributors, Stockists and Retail Drug Stores serving entire nation. Buy ${title} Online In India.`} />
          </Helmet>
          {
            productData.map((value, key) => {
              return (
                <React.Fragment>
                  <Chip
                    label={value.medi_type === 'Others' ? 'PCD' : value.medi_type}
                    size='small'
                    style={{ backgroundColor: `${bgColor}`, color: 'white', marginBottom: '10px' }}
                  />
                  {
                    value && value.medi_attribute.includes('Jumbo Deal') ?
                      <Chip
                        label='Jumbo Deal'
                        size='small'
                        style={{ backgroundColor: `${bgColor}`, color: 'white', marginBottom: '10px', marginLeft: '10px' }}
                      />
                      : ""
                  }
                  <h1><strong>{value.Product.name}</strong></h1>
                </React.Fragment>
              )
            })
          }
          {
            productData.map((value, key) => {
              return (
                <React.Fragment>
                  <NavLink className="buyerRedirectNavlink" to={`/view-seller/${value && value.Seller && value.Seller._id}`}>
                    <Chip
                      label={value.Seller.company_name}
                      deleteIcon={
                        <Avatar className="bg-success iconAvtarColor text-white">
                          <ThumbUpIcon style={{ fontSize: '0.6rem' }} /></Avatar>
                      }
                      onClick={handleClick}
                      size='small'
                    />
                  </NavLink>
                </React.Fragment>
              )
            })
          }
          <Row>
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-5">
                    <NavLink className="buyerRedirectNavlink" to={`/view-company/${value.Company._id}`}>
                      <div className="media flex-nowrap  mb-2">
                        <div className="mr-3">
                          <i className={`zmdi zmdi-city-alt jr-fs-xlxl text-primary`} />
                        </div>
                        <div className="media-body">
                          <h5 className="mb-1 text-grey">Manufacturer</h5>
                          <p className="mb-0 text-primary">{value.Company.name}</p>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-5">
                    <div className="media flex-nowrap  mb-2">
                      {(value.expiry_status === 'Short Expiry') ?
                        <React.Fragment>
                          <div className="mr-3">
                            <i className={`zmdi zmdi-calendar-close animated infinite wobble jr-fs-xlxl text-danger`} />
                          </div>
                          <div className="media-body">
                            <h5 className="mb-1 text-danger">Expiry Date</h5>
                            <p className="mb-0 text-danger">{moment(value.expiry_date).format('ll')}</p>
                          </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div className="mr-3">
                            <i className={`zmdi zmdi-calendar-close jr-fs-xlxl text-primary`} />
                          </div>
                          <div className="media-body">
                            <h5 className="mb-1 text-grey">Expiry Date</h5>
                            <p className="mb-0">{moment(value.expiry_date).format('ll')}</p>
                          </div>
                        </React.Fragment>
                      }
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-5">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-collection-text jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Type</h5>
                        <p className="mb-0">{value.Product.product_type}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-long-arrow-down jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Minimum Order Quantity </h5>
                        <p className="mb-0">{value.min_order_quantity}</p>
                      </div>
                    </div>
                  </Col>)
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-long-arrow-up jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Maximum Order Quantity</h5>
                        <p className="mb-0">{value.max_order_quantity}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-folder jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Product Category</h5>
                        <p className="mb-0">{value.Product_Category.name}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <h1 style={{ fontSize: '37px', color: '#072791' }}>₹</h1>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">MRP</h5>
                        <p className="mb-0">₹{(value.MRP).toFixed(2)}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-shopping-basket jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">GST</h5>
                        <p className="mb-0">{value.Product.GST}%</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-pin jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Country Of Origin</h5>
                        <p className="mb-0">{value.Product.country_of_origin}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  <Col lg={value.expiry_status === 'Short Expiry' && value.batchNumber ? 4 : 6} xl={value.expiry_status === 'Short Expiry' && value.batchNumber ? 4 : 6} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                        <i className={`zmdi zmdi-library jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Chemical Combination</h5>
                        <p className="mb-0">{value.Product.chem_combination}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  value.Discount && value.Discount !== undefined ?
                    <Col lg={value.expiry_status === 'Short Expiry' && value.batchNumber ? 4 : 6} xl={value.expiry_status === 'Short Expiry' && value.batchNumber ? 4 : 6} className="pt-4">
                      <div className="media flex-nowrap  mb-2">
                        {value.Discount === undefined ?
                          <React.Fragment style={{ display: 'none' }} ></React.Fragment>
                          :
                          <React.Fragment>
                            <div className="mr-3">
                              <i className={`zmdi zmdi-label jr-fs-xlxl text-primary`} />
                            </div>
                          </React.Fragment>}
                        <div className="media-body">
                          {value.Discount && value.Discount.discount_type === "Discount" ?
                            <React.Fragment>
                              <h5 className="mb-1 text-grey">Discount on PTR</h5>
                              <p className="mb-0">{value.Discount && (value.Discount.discount_per).toFixed(2)}%</p>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {value.Discount && value.Discount.discount_type === "Same" ?
                                <React.Fragment>
                                  <h5 className="mb-1 text-grey">Offer</h5>
                                  <span className={'DiscountPrice'}>Buy {value.Discount.discount_on_product.purchase} Get {value.Discount.discount_on_product.bonus} Free</span>
                                </React.Fragment>
                                :
                                value.Discount && value.Discount.discount_type === "SameAndDiscount" ?
                                  <React.Fragment>
                                    <h5 className="mb-1 text-grey">Offer</h5>
                                    <span className={'DiscountPrice'}>Buy {value.Discount.discount_on_product.purchase} Get {value.Discount.discount_on_product.bonus} Free, and {value.Discount && (value.Discount.discount_per).toFixed(2)}% Off</span>
                                  </React.Fragment>
                                  :
                                  value.Discount && value.Discount.discount_type === "DifferentAndDiscount" ?
                                    <React.Fragment>
                                      <h5 className="mb-1 text-grey">Offer</h5>
                                      <span className={'DiscountPrice'}>Buy {value.Discount.discount_on_product.purchase} Get {value.Discount.discount_on_product.bonus} {value.OtherProducts.name} Free, and {value.Discount && (value.Discount.discount_per).toFixed(2)}% Off</span>
                                    </React.Fragment>
                                    :
                                    value.Discount && value.Discount.discount_type === "Different" ?
                                      <React.Fragment>
                                        <h5 className="mb-1 text-grey">Offer</h5>
                                        <span className={'DiscountPrice'}>Buy {value.Discount.discount_on_product.purchase} Get {value.Discount.discount_on_product.bonus} {value.OtherProducts.name} Free</span>
                                      </React.Fragment>
                                      :
                                      value.Discount && value.Discount.type === "" ? //FOR NO DISCOUNT
                                        <React.Fragment style={{ display: 'none' }}>
                                        </React.Fragment>
                                        :
                                        <React.Fragment style={{ display: 'none' }}>
                                        </React.Fragment>
                              } </React.Fragment>
                          }
                        </div>
                      </div>
                    </Col>
                    :
                    <React.Fragment></React.Fragment>
                )
              })
            }
            {
              productData.map((value, key) => {
                return (
                  (value.expiry_status === 'Short Expiry') && value.batchNumber ?
                  <Col lg={4} xl={4} className="pt-4">
                    <div className="media flex-nowrap  mb-2">
                      <div className="mr-3">
                    <i className={`zmdi zmdi-format-list-numbered jr-fs-xlxl text-primary`} />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-1 text-grey">Batch number</h5>
                        <p className="mb-0">{value.batchNumber}</p>
                      </div>
                    </div>
                  </Col> : ""
                )
              })
            }
          </Row>
          {
            productData.map((value, key) => {
              return (
                <div   >
                  {value.Discount && value.Discount.discount_type === "Discount" ?
                    <div>
                      <span className='priceColorProduct'> &#x20B9;{(value.ePTR).toFixed(2)}</span>
                      <span className='originalPriceProduct ml-2'> &#x20B9;{value.PTR.toFixed(2)}</span>
                      <br />
                      <span className='text-danger'>*Excluding GST</span>
                    </div>
                    :
                    value.Discount && value.Discount.discount_type === "Same" ?
                      <div><h5 className="mb-1 text-grey">Offered Price :</h5>&nbsp;
                        <span className={'priceColor'}>&#x20B9;{(value.ePTR).toFixed(2)}</span>&nbsp;
                        <span className={'originalPrice font'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                        <br />
                        <span className='text-danger'>*Excluding GST</span>
                      </div>
                      :
                      value.Discount && value.Discount.discount_type === "SameAndDiscount" ?
                        <div><h5 className="mb-1 text-grey">Offered Price :</h5>&nbsp;
                          <span className={'priceColor'}>&#x20B9;{(value.ePTR).toFixed(2)}</span>&nbsp;
                          <span className={'originalPrice font'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                          <br />
                          <span className='text-danger'>*Excluding GST</span>
                        </div>
                        :
                        value.Discount && value.Discount.discount_type === "DifferentAndDiscount" ?
                          <div><h5 className="mb-1 text-grey">Offered Price :</h5>&nbsp;
                            <span className={'priceColor'}>&#x20B9;{(value.ePTR).toFixed(2)}</span>&nbsp;
                            <span className={'originalPrice font'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                            <br />
                            <span className='text-danger'>*Excluding GST</span>
                          </div>
                          :
                          <span className='priceColorProduct'> &#x20B9;{(value.ePTR).toFixed(2)}</span>
                  }
                  {value.Discount && value.Discount.discount_type === "Different" ?
                    <div>
                      <span className='text-danger'>*Excluding GST</span>
                    </div> : ''
                  }
                  {value.Discount === undefined ?
                    <div>
                      <span className='text-danger'>*Excluding GST</span>
                    </div>
                    : ''
                  }
                  {
                    helpertFn.showPrepaid(value.medi_type, value.Product.isPrepaid && value.Product.isPrepaid, value.prepaidInven && value.prepaidInven) &&
                    <div>
                      <Chip
                        label={'Only Prepaid'}
                        size='small'
                        style={{ backgroundColor: 'red', color: 'white' }}
                      />
                    </div>
                  }
                  <br />
                  <div style={{ display: "flex", marginTop: "-7px" }} >
                    <span className="mt-1 text-grey" onClick={(e) => handleDecremented('minOrdntity', e)}>
                      <i class="zmdi zmdi-minus-circle-outline  zmdi-hc-2x"></i>
                    </span>
                    <Input
                      id="minOrderQuantity"
                      name="minOrderQuantity"
                      type="number"
                      updatedCount={props.productData[0].min_order_quantity}
                      value={updatedCount}
                      placeholder="Quantity"
                      max={props.productData[0].max_order_quantity}
                      min={props.productData[0].min_order_quantity}
                      onChange={(e) => handleQuantityChanged()}
                      onInput={maxLengthCheck}
                      style={{ width: "100px", fontSize: "17px", marginLeft: "5px", marginRight: "1px", textAlign: 'center', backgroundColor: '#fff' }}
                    />
                    <span className="ml-1 mt-1 text-grey" onClick={(e) => handleIncremented('minOrderQuantity', e)}>
                      <i class="zmdi zmdi-plus-circle-o  zmdi-hc-2x"></i>
                    </span>
                  </div>
                </div>
              )
            })
          }
          <Row style={{ visibility: invalid === true ? "" : "hidden" }}>
            <h5 className="mt-2 ml-3 text-danger">Quantity should be between Minimum & Maximum order quantity.</h5>
          </Row>
          {productData.map((value, key) => {
            if (value && value.Seller) {
              return <div className='ml-1'>
                <button disabled={disabled}
                  style={{ padding: '10px 15px 10px 15px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none', marginRight: "30px" }}
                  onClick={onSubmit}
                >
                  Add To Cart
                </button>
                {(user !== null && users && users.bulkRequest === true) ?
                  <button style={{ padding: '10px 15px 10px 15px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleOpenModal}>Bulk Request</button>
                  :
                  null
                }
              </div>
            }
            else {
              return <div className='row ml-1'>
                <button
                  style={{ padding: '10px 15px 10px 15px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none', marginRight: "30px", cursor: 'default' }}
                >
                  Product Not Available
                </button>
              </div>
            }
          })}
          <Dialog open={openModal}
            onClose={handleClose}
            fullWidth={true}>
            <DialogTitle id="alert-dialog-title " className='pb-0'>Bulk Request</DialogTitle>
            <Form
              onSubmit={handleBulkRequest}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <DialogContent >
                    <div className=''>
                      {
                        productData.map((value, key) => {
                          return (
                            <div className='row justify-content-center '>
                              <FormGroup className={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10 mb-0'}>
                                <Label for="exampleEmail">Product Name</Label><span className='text-danger float-right'>(All prices excluding GST)</span>
                                <Input type="text" value={value.Product.name} readonly style={{ backgroundColor: "#e1e1e1" }} />
                              </FormGroup>
                              <FormGroup className={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10 m-0 p-0 pl-3'}>
                                {bestOffer && <Label for="name" className='mt-3 text-primary'>{`${bestOffer.disc && bestOffer.disc.name === 'Same' ? 'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' free' :
                                  bestOffer.disc && bestOffer.disc.name === 'SameAndDiscount' ? 'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' free and ' + (bestOffer.disc.discount_per).toFixed(2) + '% discount' :
                                    bestOffer.disc && bestOffer.disc.name === 'Discount' ?
                                      'Best Offer: ' + bestOffer.disc.discount_per + '% discount' :
                                      bestOffer.disc && bestOffer.disc.name === 'DifferentAndDiscount' ?
                                        'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' ' + bestOffer.inven[0].productName + ' free and ' + (bestOffer.disc.discount_per).toFixed(2) + '% discount' :
                                        bestOffer.disc && bestOffer.disc.name === 'Different' ?
                                          'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' ' + bestOffer.inven[0].productName + ' free' : bestOffer.disc && bestOffer.disc.type === '' ?
                                            '' : ''}`}</Label>}
                              </FormGroup>
                            </div>
                          )
                        })
                      }
                      {
                        productData.map((value, key) => {
                          return (
                            <div className='row justify-content-center '>
                              <FormGroup className={'col-sm-5 col-lg-5 col-md-5 col-xs-5 col-xl-5 pt-2'}>
                                <Label for="exampleEmail">Best Offered Effective PTR</Label>
                                <Input type="text" value={bulkReqEptr} readonly style={{ backgroundColor: "#e1e1e1" }} />
                              </FormGroup>
                              <FormGroup className={'col-sm-5 col-lg-5 col-md-5 col-xs-5 col-xl-5 pt-2'}>
                                <Label for="exampleEmail">Estimated Effective PTR</Label>
                                <Input type="text" value={(isNaN(amount) || isNaN(bulkQuantity) || bulkQuantity === 0) ? 0 : (Number(amount) / Number(bulkQuantity)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readonly style={{ backgroundColor: "#e1e1e1" }} />
                              </FormGroup>
                            </div>
                          )
                        })
                      }
                      <div className='row justify-content-center '>
                        <FormGroup className={'col-sm-10 col-lg-10 col-md-10 col-xs-10 col-xl-10'}>
                          <Label for="exampleEmail">Additional Expected Discount %</Label>
                          <Input type="select" name='discount' onChange={(e) => handleChanged(e, 'discount')}>
                            <option value='' >0%</option>
                            {productData && productData.length > 0 && productData[0].Product && productData[0].Product.discount.map(val => <option value={val} >{val}%</option>)}
                          </Input>
                        </FormGroup>
                      </div>
                      <div className='row justify-content-center '>
                        <FormGroup className={'col-sm-5 col-lg-5 col-md-5 col-xs-5 col-xl-5'}>
                          <Label for="exampleEmail">Required Quantity</Label>
                          <Input type="text" name='bulkQuantity' id='bulkQuantity' value={bulkQuantity}
                            onChange={(e) => handleChanged(e, 'bulkQuantity')}
                            helperText={getError()}
                            validate={composeValidators(number, maxLength4, number0, required)} />
                        </FormGroup>
                        <FormGroup className={'col-sm-5 col-lg-5 col-md-5 col-xs-5 col-xl-5'}>
                          <Label for="exampleEmail">Estimated value</Label>
                          <Input type="text" name='value' readOnly value={isNaN(amount) ? 0 : (Number(amount)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} />
                          {error && <span className='text-danger'>Value can't be less than {productData && productData.length > 0 && productData[0].Product && (productData[0].Product.minOrder).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>}
                        </FormGroup>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions className="pr-4">
                    <div>
                      <Button onClick={handleClose} color='secondary' >  Cancel </Button>
                      {(error === true || bulkQuantity === '' || getError()) ?
                        <Button disabled={true}> CREATE BULK REQUEST </Button> :
                        <Button type="submit" color='primary'> CREATE BULK REQUEST </Button>
                      }
                    </div>
                  </DialogActions>
                </form>
              )}
            />
          </Dialog>

        </div>
      }
    </React.Fragment>
  );
}

const mapStateToProps = ({ auth, buyer, seller }) => {
  const { data, otherSellers, relatedProducts, productData, visitedCategory, bulkRequest, addToCart, cartDetails, loading } = buyer;
  const { user_details } = auth;
  const { userDetails } = seller;
  let users = userDetails ? userDetails : user_details
  return { data, otherSellers, relatedProducts, productData, visitedCategory, bulkRequest, addToCart, cartDetails, loading, user_details, users }
};

export default connect(mapStateToProps, { getProductDetails, getVisitedCategory, bulkRequest, addToCart, getCartDetails })(ProductDetail)