import React, { useRef } from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText style={{ color: '#f44336' }}>{touched && error}</FormHelperText>
    }
}

const FieldFileInput = (props) => {
    const { input: { onChange, value }, label, meta: { touched, error } } = props
    const inputFile = useRef(null)

    function handleFileSelect(e) {
        let document = "";
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            document = reader.result;
            onChange(document)
        };
        reader.onerror = function (error) {
        };
    }

    const removeimage = () => {
        onChange('')
    }

    return (
        <div className='text-center'>
            <div>
                <input
                    type='file'
                    accept='.jpg, .png, .jpeg, .pdf'
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileSelect(e)} ref={inputFile}
                />
                <img alt="licence" src={value !== '' ? require('assets/images/camera_3.png') : require('assets/images/camera_1.png')} className='d-block mx-auto' onClick={(e) => inputFile.current.click()} />
            </div>
            <label>{label}</label>
            {renderFromHelper({ touched, error })}
            {(label === 'GSTN Lic (Optional)' || label === "FSSAI Lic (Optional)") && value && <div className='mr-1 ml-1 d-flex justify-content-center'>
                <Tooltip title={`Remove this document`} onClick={removeimage}>
                    <Avatar className='bg-danger cursor-pointer' size={1}>
                        <i className='zmdi zmdi-close'></i>
                    </Avatar>
                </Tooltip>
            </div>}
        </div>
    )
}

export default FieldFileInput