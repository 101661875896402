import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AxiosRequest from 'sagas/axiosRequest'
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationManager } from 'react-notifications';
import { Col, Row } from 'reactstrap';
import Drop from 'components/ticketTable/Drop';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const TicketDetailsHeader = (props) => {
	const [addPopup, setAddPopup] = useState(false)
	const [newFileUpload, setNewFileUpload] = useState([])
	const [submitting, setSubmitting] = useState(false)
	const [uploadedFileArr, setUploadedFileArr] = useState([])
	const [anchorEl, setAnchorEl] = useState(null);
	const [openLightBox, setOpenLightBox] = useState(false)

	const closePopup = () => {
		setNewFileUpload([])
		setUploadedFileArr([])
		setAddPopup(false)
		setSubmitting(false)
	}

	const add = async () => {
		setSubmitting(true)
		let data = { Id: props.ticketDetails._id, supportings: newFileUpload }
		let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/updateTicket', '', data)
		if (result.data.error) {
			closePopup();
		} else {
			NotificationManager.success(result.data.title)
			closePopup();
		}
		setSubmitting(false)
	}

	const getFiles = async (data1) => {
		setUploadedFileArr([...uploadedFileArr, ...data1]);
		data1.map(data => {
			let reader;
			let file;
			file = data;
			reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async (e) => {
				let joined = [...newFileUpload].concat(e.target.result);
				setNewFileUpload(joined)
			}
		})
	}

	const remove = (file, i) => {
		let index = uploadedFileArr.findIndex(e => e.name === file.name);
		let temp = [...uploadedFileArr]
		temp.splice(index, 1)
		setUploadedFileArr(temp)
		let temp1 = [...newFileUpload]
		temp1.splice(i, 1)
		setNewFileUpload(temp1)
	}

	let { ticketDetails } = props;
	let images = (ticketDetails && ticketDetails.supportings && ticketDetails.supportings.filter((e) => !e.includes('pdf'))) || [];
	let pdfs = (ticketDetails && ticketDetails.supportings && ticketDetails.supportings.filter((e) => e.includes('pdf'))) || [];

	return (
		<div className="page-heading d-sm-flex  justify-content-sm-between align-items-sm-center" >
			<span><h2 className="title mb-3 mb-sm-0">Issue: {ticketDetails?.issue}</h2></span>
			<div style={{ display: 'flex' }} >
				<Button variant="outlined" className={'text-success mr-2'} style={{ padding: '2px 15px', display: `block` }} onClick={(e) => setOpenLightBox(true)}>Images	</Button>
				<Lightbox
					open={openLightBox}
					plugins={[Download, Fullscreen, Slideshow, Zoom]}
					styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
					close={() => setOpenLightBox(false)}
					slides={images.map((data, i) => { return { src: data } })}
				/>
				{
					pdfs.length > 1 ?
						<React.Fragment>
							<Button variant="outlined" className={'text-success mr-2'} style={{ padding: '2px 15px', display: `block` }}>View PDFs<KeyboardArrowDownIcon onClick={(e) => setAnchorEl(e.currentTarget)} /></Button>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={() => setAnchorEl(null)}
								style={{ textAlign: 'end' }}
							>
								{pdfs.map((data, i) =>
									<a href={data} target='_blank' rel="noreferrer">
										<MenuItem onClick={() => setAnchorEl(null)}>{`PDF ${i + 1}`}</MenuItem>
									</a>)
								}
							</Menu>
						</React.Fragment> : pdfs.length === 0 ? '' :
							<a href={pdfs[0]} target='_blank' rel="noreferrer"><Button variant="outlined" className={'text-success  mr-2'} style={{ padding: '2px 15px' }}>View PDF</Button></a>
				}
				{ticketDetails?.status === 'open' &&
					<Button variant="outlined" className={'text-success'} style={{ padding: '2px 15px' }} onClick={() => { setAddPopup(true) }}>
						Add files
					</Button>}
			</div>
			<Dialog open={addPopup} onClose={closePopup} fullWidth={true} maxWidth='md'>
				<DialogTitle>
					Add files
				</DialogTitle>
				<DialogContent>
					<Row>
						<Col>
							<Drop handleFiles={getFiles} accept="image/*, application/pdf" />
							{uploadedFileArr.length > 0 && <div className="dropzone-content">
								<ul className="mb-2" style={{ listStyleType: 'none' }}>
									{
										uploadedFileArr.map((file, i) => <li key={file.path}>
											<IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
										</li>
										)
									}
								</ul>
							</div>
							}
						</Col>
					</Row>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => closePopup()} color='secondary'>Cancel</Button>
					{(newFileUpload.length !== 0 && !submitting) ?
						<Button onClick={() => add()} color='primary' disabled={submitting}>Add</Button> :
						<Button disabled={true} color='primary'>Add</Button>
					}
				</DialogActions>
			</Dialog>
		</div >
	)
}

export default TicketDetailsHeader;

