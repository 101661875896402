import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FormGroup, Label } from 'reactstrap';
import { Field, Form } from 'react-final-form'
import DialogTitle from '@mui/material/DialogTitle';
import ReactstrapSelectField from 'components/reactstrapSelectField';
import ReactStrapTextField from 'components/ReactStrapTextField';
import IconButton from '@mui/material/IconButton';
import Drop from './Drop'
import axios from 'axios';
import AppConfig from 'constants/config';
import { NotificationManager } from 'react-notifications';
import { required } from 'constants/validations';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import AxiosRequest from 'sagas/axiosRequest';
import moment from 'moment';

const AddTicket = (props) => {
    const [newFileUpload, setNewFileUpload] = useState([])
    const [order_id, setOrderId] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [uploadedFileArr, setUploadedFileArr] = useState([])
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState('')

    const add = () => {
        props.handleClick();
        setTimeout(() => {
            setNewFileUpload([])
            setOrderId('')
            setSubmitting(false)
            setUploadedFileArr([])
            setLoader(false)
        }, 500)

    }

    const onSubmit = async (values, form) => {
        setSubmitting(true)
        setLoader(true)
        let data = {
            type: values.type,
            orderId: values.orderId,
            issue: values.issue,
            ogInvoiceAmt: values.ogInvoiceAmt,
            order_id: order_id,
            supportings: newFileUpload,
            issueDescription: values.type === 'order' ? values.issueDescriptionOR : values.issueDescriptionO
        }
        axios({
            method: 'post',
            url: `${AppConfig.baseUrl}ticket/addTicket`,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('buyer_token')
            },
            data: data
        }).then(result => {
            if (result.data.error) {
                NotificationManager.error(result.data.title)
                setSubmitting(false)
                setLoader(false)
            } else {
                NotificationManager.success(result.data.title)
                add();
                props.hitApi();
            }
            form.reset()
        }).catch(error => {
            NotificationManager.error(error.message || "Something went wrong!")
            setSubmitting(false)
            setLoader(false)
        });
    }

    const getFiles = async (data1) => {
        setUploadedFileArr([...uploadedFileArr, ...data1]);
        data1.map(data => {
            let reader;
            let file;
            file = data;
            reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
                let joined = newFileUpload.concat(e.target.result);
                setNewFileUpload(joined)
            }
        })
    }

    const remove = (file, i) => {
        let index = uploadedFileArr.findIndex(e => e.name === file.name);
        let temp = [...uploadedFileArr]
        temp.splice(index, 1)
        setUploadedFileArr(temp)
        let temp1 = [...newFileUpload]
        temp1.splice(i, 1)
        setNewFileUpload(temp1)
    }

    const handleFormat = async (val, form) => {
        if (val && val.length > 2) {
            let data = { orderId: val }
            let result = await AxiosRequest.axiosBuyerHelperFunc('post', 'ticket/getOrderForTicket', '', data)
            if (result.data.error) {
                setError(result.data.title);
                setOrderId('')
                setSubmitting(true)
            } else {
                setError('');
                form.change('orderDate', moment(result.data.data.createdAt).format('DD/MM/YYYY'))
                form.change('sellerName', result.data.data.sellerCompName)
                form.change('ogInvoiceAmt', result.data.data.total_amount)
                setOrderId(result.data.data._id)
                setSubmitting(false)
            }
        }
        return val
    }

    const { open } = props;

    return (
        <React.Fragment>
            <Form
                initialValues={{
                    type: 'order',
                    issue: '',
                    orderId: '',
                    orderDate: '',
                    sellerName: '',
                    ogInvoiceAmt: '',
                    issueDescriptionOR: '',
                    issueDescriptionO: '',
                }}
                onSubmit={onSubmit}
                render={({ handleSubmit, values, form, errors }) => (
                    <Dialog open={open} onClose={() => { form.reset(); add() }} fullWidth={true} maxWidth='md'>
                        <DialogTitle className='pb-0'>
                            Add Ticket
                        </DialogTitle>
                        <DialogContent >
                            <form onSubmit={handleSubmit}>
                                {values.type === 'order' ?
                                    <>
                                        <Row>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field
                                                        name="type"
                                                        component={ReactstrapSelectField}
                                                        id="type"
                                                        type="select"
                                                        label='Ticket type'
                                                    >
                                                        <option value="order">Order Related</option>
                                                        <option value="other">Other</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field
                                                        name="issue"
                                                        component={ReactstrapSelectField}
                                                        id="issue"
                                                        type="select"
                                                        label='Issue type'
                                                        validate={required}
                                                    >
                                                        <option value="">Select issue</option>
                                                        <option value="Short supply">Short supply</option>
                                                        <option value="Damaged products">Damaged products</option>
                                                        <option value="Wrong invoice">Wrong invoice</option>
                                                        <option value="Diff in Invoice and Docket">Diff in Invoice and Docket</option>
                                                        <option value="Short Expiry">Short Expiry</option>
                                                        <option value="Order by mistake">Order by mistake</option>
                                                        <option value="Extra product received">Extra product received</option>
                                                        <option value="Damaged package received">Damaged package received</option>
                                                        <option value="Rescheduling related">Rescheduling related</option>
                                                        <option value="Order not processed">Order not processed</option>
                                                        <option value="Other issue">Other issue</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup className='mb-0'>
                                                    <Field
                                                        id="orderId"
                                                        name="orderId"
                                                        type="text"
                                                        component={ReactStrapTextField}
                                                        label="Order Id"
                                                        validate={required}
                                                        format={(val) => { handleFormat(val, form) }}
                                                    />
                                                </FormGroup>
                                                {error && error.length > 0 && <span className='text-danger'>{error}</span>}
                                            </Col>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field disabled label="Order Date" component={ReactStrapTextField} type="text" name="orderDate" id="orderDate" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field disabled label="Seller Name" component={ReactStrapTextField} type="text" name="sellerName" id="sellerName" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                <FormGroup>
                                                    <Field disabled label="Original Invoice Amt" component={ReactStrapTextField} type="text" name="ogInvoiceAmt" id="ogInvoiceAmt" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup >
                                                    <Field
                                                        label='Issue Description'
                                                        type='textarea'
                                                        name='issueDescriptionOR'
                                                        rows="3"
                                                        component={ReactStrapTextField}
                                                        validate={required}
                                                        id="issueDescriptionOR"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Drop handleFiles={getFiles} accept="image/*, application/pdf" />
                                                {uploadedFileArr.length > 0 && <div className="dropzone-content">
                                                    <ul className="mb-2" style={{ listStyleType: 'none' }}>
                                                        {
                                                            uploadedFileArr.map((file, i) => <li key={file.path}>
                                                                <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
                                                            </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                    : values.type === 'other' ?
                                        <>
                                            <Row>
                                                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                    <FormGroup>
                                                        <Field
                                                            name="type"
                                                            component={ReactstrapSelectField}
                                                            id="type"
                                                            type="select"
                                                            label='Ticket type'
                                                        >
                                                            <option value="order">Order Related</option>
                                                            <option value="other">Other</option>
                                                        </Field>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                                                    <FormGroup>
                                                        <Field
                                                            name="issue"
                                                            component={ReactstrapSelectField}
                                                            id="issue"
                                                            validate={required}
                                                            type="select"
                                                            label='Issue type'
                                                        >
                                                            <option value=" ">Select issue</option>
                                                            <option value="License related">License related</option>
                                                            <option value="Address related">Address related</option>
                                                            <option value="Delivery serviceability related">Delivery serviceability related</option>
                                                            <option value="COD disabled">COD disabled</option>
                                                            <option value="Delivery charges activated">Delivery charges activated</option>
                                                            <option value="Other issue">Other issue</option>
                                                        </Field>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup >
                                                        <Field
                                                            label='Issue Description'
                                                            type='textarea'
                                                            name='issueDescriptionO'
                                                            rows="3"
                                                            component={ReactStrapTextField}
                                                            validate={required}
                                                            id="issueDescriptionO"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Drop handleFiles={getFiles} accept="image/*, application/pdf" />
                                                    {uploadedFileArr.length > 0 && <div className="dropzone-content">
                                                        <ul className="mb-2" style={{ listStyleType: 'none' }}>
                                                            {
                                                                uploadedFileArr.map((file, i) => <li key={file.path}>
                                                                    <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
                                                                </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </> :
                                        <div style={{ fontSize: 'larger' }}> Please select ticket type</div>
                                }
                                <DialogActions>
                                    <Button type="button" onClick={() => { form.reset(); add() }} color='secondary'>Cancel</Button>
                                    {
                                        loader ? <CircularProgress size={30} /> : <Button disabled={submitting} type='submit' color='primary'>Submit</Button>
                                    }
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                )}
            />
        </React.Fragment >
    )
}
export default AddTicket