import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row, FormGroup } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import { listGroup, addStaff, listPermissionModules } from 'actions/seller';
import { connect } from 'react-redux';
import ReactStrapTextField from 'components/ReactStrapTextField';
import ReactstrapSelectField from 'components/reactstrapSelectField';
import { Field, Form } from 'react-final-form'
import { required, emailField, validatePhone } from 'constants/validations';
import Axios from 'sagas/axiosRequest';
import helperFunction from 'constants/helperFunction';

const AddStaff = (props) => {
  const [permission, setPermission] = useState([])
  const [groups, setGroups] = useState([])
  const [isAllPermission, setIsAllPermission] = useState(false)
  const [red, setRed] = useState(0)
  const [blue, setBlue] = useState(0)
  const [green, setGreen] = useState(0)
  const [selectedGroup, setSelectedGroup] = useState()

  useEffect(() => {
    getGroupList()
  }, [])

  const getGroupList = async () => {
    let groupListData = await Axios.axiosHelperFunc('get', 'seller/getGroupList', '', {});
    setGroups(groupListData && groupListData.data && groupListData.data.detail ? groupListData.data.detail : []);
    let rgbValue = helperFunction.getRGB();
    setRed(rgbValue[0])
    setGreen(rgbValue[1])
    setBlue(rgbValue[2])
  }

  const handleAddGroupClick = (key) => {
    props.addGroup('add')
  }

  const handleRequestClose = () => {
    props.handleClick('add');
    setPermission([])
  };

  useEffect(() => {
    if (selectedGroup) {
      let index = groups.findIndex((e) => String(e._id) === String(selectedGroup));
      if (index > -1) {
        let tempPermission = permission;
        tempPermission = groups[index].permissions;
        let isAdminAccess = tempPermission.length === props.listpermissionModulesdata.length ? true : false;
        setPermission(tempPermission)
        setIsAllPermission(isAdminAccess);
      } else {
        setPermission([])
        setIsAllPermission(false);
      }
    } else {
      setPermission([])
    }
  }, [selectedGroup])

  const onSubmit = (values) => {
    let data = {
      first_name: values.name,
      email: values.email,
      phone: values.phone,
      groupId: values.groupId,
      isAllPermission: isAllPermission,
      red: red,
      green: green,
      blue: blue,
    }
    props.handleClick('add')
    props.addStaff({ data, listStaff: { searchText: '', page: 1, perPage: 10 } })
    setPermission([])
  }

  const { add, title, listpermissionModulesdata } = props;
  return (
    <React.Fragment>
      <Dialog open={add} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
          }}
          render={({ handleSubmit, values, submitting }) => {
            if (selectedGroup !== values.groupId) setSelectedGroup(values.groupId)
            return <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                {title}
              </DialogTitle>
              <DialogContent>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={2} lg={2} className="addStaffAvatar">
                    <Avatar className="size-100" alt="Remy Sharp" src={require('assets/images/avatar.png')} />
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={10} lg={10}>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="name" name="name" type="text"
                          component={ReactStrapTextField} label="Name"
                          validate={required}
                        />
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="email" name="email" type="text"
                          component={ReactStrapTextField} label="Email ID"
                          validate={emailField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="phone" name="phone" type="text"
                          component={ReactStrapTextField} label="Phone"
                          validate={validatePhone}
                        />
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Row>
                          <Col xs={10} md={10} sm={10} xl={10} lg={10}>
                            <Field
                              name="groupId"
                              id="groupId"
                              component={ReactstrapSelectField}
                              label="Group"
                              validate={required}
                              type="select"
                            >
                              <option value=""> Select Group </option>
                              {
                                groups && groups.length > 0 && groups.map((value, key) => {
                                  return <option key={'group_' + key} value={value._id}>{value.name}</option>
                                })
                              }
                            </Field>
                          </Col>
                          <Col xs={2} md={2} sm={2} xl={2} lg={2} className="addGroupButton cursor-pointer">
                            <FormGroup>
                              <Icon color="primary" onClick={() => handleAddGroupClick('addGroup')} >add_circle</Icon>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {
                      values.groupId ? <Row>
                        {
                          listpermissionModulesdata && listpermissionModulesdata.length === permission.length ? <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={listpermissionModulesdata && listpermissionModulesdata.length === permission.length}
                                  value={'admin_access'}
                                  color="primary"
                                  disabled={true}
                                />
                              }
                              label={'Admin Access'}
                            />
                          </Col> : ''
                        }
                        {
                          (permission && permission.length > 0) ? permission.map((value, key) => {
                            return <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={true}
                                    value={value.value}
                                    color="primary"
                                    disabled={true}
                                  />
                                }
                                label={value.name}
                              />
                            </Col>
                          }) : ''
                        }
                      </Row> : ''
                    }
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={submitting}>
                  Add
                </Button>
              </DialogActions>
            </form>
          }}
        />
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ seller }) => {
  const { listGroupData, listpermissionModulesdata } = seller;
  return { listGroupData, listpermissionModulesdata }
};

export default connect(mapStateToProps, { listGroup, listPermissionModules, addStaff })(AddStaff)