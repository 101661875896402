import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomScrollbars from 'util/CustomScrollbars';
import { getShortbook } from 'actions/buyer';
import ShortBook from './ShortBook';
import ContainerHeader from 'components/ContainerHeader';
import BuyerContainer from 'components/buyerContainer'

function TabContainer({ children, dir, index, value }) {
  return (
    <div dir={dir}>
      {value === index && children}
    </div>
  );
}

const FullWidthShortbookTab = (props) => {
  useEffect(() => {
    const element = document.getElementById("buyerShortBook")
    element.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <BuyerContainer>
      <div className="app-wrapper" id="buyerShortBook">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <ContainerHeader title="My Shortbook" />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <TabContainer dir="ltr" value={0} index={0}>
              <CustomScrollbars className="messages-list scrollbar" style={{ height: 500 }}>
                <ShortBook identifier={'shortbookList'} />
              </CustomScrollbars>
            </TabContainer>
          </div>
        </div>
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { shortbookList } = buyer;
  return { shortbookList }
};

export default connect(mapStateToProps, { getShortbook })(FullWidthShortbookTab);