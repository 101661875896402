import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  REMOVE_AUTH
} from "constants/ActionTypes";
import { NotificationManager } from 'react-notifications';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('token'),
  user_details: ''

};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      if (action.payload.user_type === 'seller') {
        NotificationManager.success(action.payload.title)
      }
      return {
        ...state,
        loader: false,
        user_details: action.payload.detail

      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      NotificationManager.success("Logged out successfully.")
      return {
        ...state,
        authUser: null,
        // initURL: '/signin',
        initURL: '',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      NotificationManager.error(action.payload)
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case REMOVE_AUTH: {
      return {
        ...state,
        user_details: '',
        authUser: ''
      }
    }
    default:
      return state;
  }
}
