import React from 'react';
import { Field, Form } from 'react-final-form'
import renderTextField from 'components/textBox';
import FieldFileInput from 'components/FieldFileInput';
import { accountNumber, ifscCode, required } from 'constants/validations';
import { Col, Row } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import axios from 'constants/axios';
import AppConfig from 'constants/config';
import { getUserDetail } from 'actions'
import { connect } from 'react-redux'

const BankDetails = (props) => {
    const onSubmit = (values) => {
        let data = values
        axios.post('/users/updateBankDetails', data, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
        }).then(result => {
            if (result.data.error) {
                NotificationManager.error(result.data.title);
            } else {
                NotificationManager.success('Bank details updated successfully.');
                props.getUserDetail({})
            }
        })
            .catch(error => {
                NotificationManager.error('Something went wrong, Please try again')
            });
    }

    const { user_bank_details } = props.userDetails.mainUser ? props.userDetails.mainUser : props.userDetails
    let isMainUser = props.userDetails.mainUser ? false : true

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                account_no: user_bank_details.account_no ? user_bank_details.account_no : '',
                ifsc_code: user_bank_details.ifsc_code ? user_bank_details.ifsc_code : '',
                recipient_name: user_bank_details.recipient_name ? user_bank_details.recipient_name : '',
                cancelledCheck: user_bank_details.cancelledCheck ? user_bank_details.cancelledCheck : ''
            }}
            render={({ handleSubmit, submitting }) =>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className='jr-card-body'>
                        <Row>
                            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                                <Field name="cancelledCheck" label={'Cancelled Check'} component={FieldFileInput} validate={required} />
                                <div className='mx-md-n5 text-center justify-content-center d-flex pt-2'>
                                    <div className='mr-1 ml-1'>
                                        <Tooltip title={`${user_bank_details.cancelledCheck ? 'View Cancelled check' : 'Cancelled check not uploaded'} `} >
                                            <Avatar className='bg-warning' color='primary' size={2}>
                                                <a rel="noreferrer" href={`${AppConfig.baseUrl}users/${user_bank_details.cancelledCheck ? user_bank_details.cancelledCheck : ''}`} style={user_bank_details.cancelledCheck ? { padding: 0 } : { pointerEvents: 'none', cursor: 'default' }} disabled={user_bank_details.cancelledCheck ? false : true} target='_blank'><i className='zmdi zmdi-eye text-white'></i></a>
                                            </Avatar>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                                <Field name="account_no" label="Account Number" component={renderTextField} validate={accountNumber} />
                            </Col>
                            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                                <Field name="ifsc_code" label="IFSC Code" component={renderTextField} validate={ifscCode} />
                            </Col>
                            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                                <Field name="recipient_name" label={'Recipient Name'} component={renderTextField} validate={required} />
                            </Col>
                        </Row>
                    </div>
                    {
                        isMainUser &&
                        <div className='jr-card-footer d-flex justify-content-end'>
                            <Button style={{ backgroundColor: '#072791' }} disabled={submitting} className='text-white' type="submit" variant="contained" color="primary">Save</Button>
                        </div>
                    }
                </form>
            }
        />
    );
}

const mapStateToProps = ({ seller, form }) => {
    const { userDetails } = seller;
    return { userDetails, form }
};

export default connect(mapStateToProps, { getUserDetail })(BankDetails)