import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import TextField from '@mui/material/TextField';
import { required, number0 } from 'constants/validations'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'constants/axios'
import { NotificationManager } from 'react-notifications';
import Autocomplete from '@mui/material/Autocomplete';

const BuyerProductRequestPopOver = (props) => {
    const [product, setProduct] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [loader, setLoader] = useState(false);
    const [otherManufacturer, setOtherManufacturer] = useState('');
    const [hidden, setHidden] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            let data = {
                product: product.toUpperCase(),
                manufacturer: manufacturer,
                quantity: Number(quantity),
                otherManufacturer: otherManufacturer
            }
            let errP = required(product);
            let errM = required(manufacturer);
            let errO = manufacturer === 'others' ? required(otherManufacturer) : null;
            let errQ = number0(quantity);
            if (errP || errM || errQ || errO) {
                setError(true)
                setErrorMessage({ product: errP, manufacturer: errM, quantity: errQ, otherManufacturer: errO })
            } else {
                setLoader(true)
                const result = await axios.post('/buyerRequest/createBuyerRequest', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('buyer_token')
                    }
                })
                if (result.data.error) {
                    NotificationManager.error(result.data.title);
                } else {
                    NotificationManager.success(result.data.title);
                }
                onClose()
            }
        } catch (error) {
            NotificationManager.error('Something went wrong, Please try again')
            onClose()
        }
    }

    useEffect(() => {
        setProduct(props.product ? props.product : '')
    }, [props.product])

    const onClose = () => {
        setProduct(props.product ? props.product : '')
        setManufacturer('')
        setQuantity(0)
        setError(false)
        setErrorMessage({})
        setLoader(false)
        setOtherManufacturer('')
        setHidden(true)
        props.toggle();
    }

    const selectChanged = (company) => {
        if (company && company._id) {
            setManufacturer(company._id)
            setHidden(true)
        } else if (company && company === 'Other') {
            setManufacturer('others')
            setHidden(false)
        } else {
            setManufacturer('')
            setHidden(true)
        }
    }

    const { companies, open } = props

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>
                    Create Product Request
                </DialogTitle>
                {loader ?
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                    :
                    <form onSubmit={onSubmit} autoComplete={false}>
                        <DialogContent>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <TextField name="product"
                                            fullWidth={true}
                                            type="text"
                                            label="Product Name"
                                            autoComplete="off"
                                            error={error && errorMessage.product ? error : false}
                                            helperText={error && errorMessage.product ? errorMessage.product : ''}
                                            value={product}
                                            onChange={(e) => setProduct(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <Autocomplete
                                            options={['Other', ...companies]}
                                            id="auto-complete"
                                            getOptionLabel={(option) => { return option && option === 'Other' ? 'OTHER' : option ? option.name : 'Select Manufacturer' }}
                                            autoComplete
                                            onChange={(e, option) => selectChanged(option)}
                                            includeInputInList
                                            renderInput={(params) => <TextField {...params}
                                                name="manufacturer"
                                                fullWidth={true}
                                                label="Manufacturer"
                                                error={error && errorMessage.manufacturer ? error : false}
                                                helperText={error && errorMessage.manufacturer ? errorMessage.manufacturer : ''} />}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <TextField name="otherManudacturer"
                                            fullWidth={true}
                                            type="text"
                                            label="Other Manufacturer"
                                            autoComplete="off"
                                            hidden={hidden}
                                            error={error && errorMessage.otherManufacturer ? error : false}
                                            helperText={error && errorMessage.otherManufacturer ? errorMessage.otherManufacturer : ''}
                                            inputMode="numeric"
                                            value={otherManufacturer}
                                            onChange={(e) => setOtherManufacturer(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                                    <FormGroup>
                                        <TextField name="quantity"
                                            fullWidth={true}
                                            type="text"
                                            label="Quantity"
                                            autoComplete="off"
                                            error={error && errorMessage.quantity ? error : false}
                                            helperText={error && errorMessage.quantity ? errorMessage.quantity : ''}
                                            inputMode="numeric"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color='secondary' >
                                Cancel
                            </Button>
                            <Button type='submit' color='primary'>
                                Request
                            </Button>
                        </DialogActions>
                    </form>
                }
            </Dialog>
        </React.Fragment>
    );
}

export default BuyerProductRequestPopOver


