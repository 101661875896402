import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'reactstrap';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom'
import ChangeStatusDailog from './changeStatus';
import CancelOrderDailog from './orderCancelDailog';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateOrder, getOrderDetails } from 'actions/order';
import { connect } from 'react-redux';
import helper from 'constants/helperFunction';

const OrderDetailsHeader = (props) => {
	const params = useParams()
	const [open, setOpen] = useState(false)
	const [productData, setProductData] = useState([])
	const [disabledcr, setDisabledcr] = useState(false)
	const [disabledyes, setDisabledyes] = useState(false)

	useEffect(() => {
		setProductData(props.updatedProduct)
		disableChangeRequest()
	}, [props.updatedProduct])

	const handleRequest = (e, title) => {
		setOpen(true)
		setDisabledcr(true)
	}

	const handleRequestClose = () => {
		setOpen(false)
		setDisabledcr(false)
		props.getOrderDetails({ data: { orderId: params.id } })
	};

	const handleSubmitRequest = (e) => {
		e.preventDefault();
		setDisabledyes(true)
		props.updateOrder({ data: { products: productData, orderId: params.id, status: 'Requested' } });
		setOpen(false)
		setDisabledcr(true)
		props.getOrderDetails({ data: { orderId: params.id } })
	}

	const disableChangeRequest = () => {
		let { status, orderDetails } = props
		if (orderDetails && orderDetails.user_id && orderDetails.user_id.user_status !== 'active') {
			return true
		} else if (!productData || productData.length < 1) {
			return true
		} else if (status === 'Accepted' || status === 'Requested' || status === 'Approved' || status === 'Processed' || status === "Cancelled" || status === "Delivered" || status === "LOST" || status === "RTO" || status === "RTO after Delivery") {
			return true
		} else {
			return false
		}
	}

	let removedItem = [];
	let { status, orderDetails } = props;

	orderDetails.products.map((val, index) => {
		if (productData && val.quantity > (productData[index] && productData[index].quantity)) {
			removedItem.push(productData[index]);
		}
	});

	return (
		<div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
			<span style={{ display: 'flex' }}>
				<span><h2 className="title mb-3 mb-sm-0">Order Detail</h2></span>&nbsp;&nbsp;
				<span className="badge text-uppercase text-white" style={{ backgroundColor: `${helper.getProdColor(orderDetails.orderType)}` }}>
					{orderDetails.orderType ? orderDetails.orderType : 'N/A'}
				</span>
			</span>

			<Breadcrumb className="mb-0" tag="nav">
				<Row>
					<Col className="text-nowrap p-0">
						<Button
							className={'jr-btn-xs mr-1'}
							variant="contained"
							disabled={disabledcr || disableChangeRequest()}
							color={(status === 'Requested' || status === 'Approved' || status === 'Processed' || status === "Cancelled" || status === "Delivered" || !productData || productData.length < 1) ? 'grey' : 'primary'}
							onClick={(e) => handleRequest(e, 'Change Request')}>
							Change Request
						</Button>
					</Col>
					<Col className="text-nowrap p-0">
						<ChangeStatusDailog orderDetails={orderDetails} status={props.status} />
					</Col>
					<Col className="text-nowrap p-0">
						<CancelOrderDailog orderDetails={orderDetails} status={props.status} cancelText={props.cancelText} />
					</Col>
				</Row>
			</Breadcrumb>

			<Dialog open={open} onClose={handleRequestClose}
				fullWidth={true}
				maxWidth={'sm'}>
				<DialogTitle className="pb-1">
					Change Request
					<DialogContentText className="mb-2">
						{props.text && props.text}
					</DialogContentText>
				</DialogTitle>
				<DialogContent className="pt-0">
					{removedItem && removedItem.map((data, index) => {
						return <h4 className='text-muted pb-1'> {++index}. {data.productName} </h4>
					})
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRequestClose} color='secondary' >
						No
					</Button>
					<Button onClick={(e) => handleSubmitRequest(e)} color='primary' disabled={disabledyes}>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	)
}

const mapStateToProps = ({ order }) => {
	const { orderDetails } = order;
	return { orderDetails }
};

export default connect(mapStateToProps, { updateOrder, getOrderDetails })(OrderDetailsHeader);

