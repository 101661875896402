import React, { useRef, useEffect } from 'react';
import logo from 'assets/images/medimny.png'
import { Helmet } from "react-helmet";
import BuyerContainer from 'components/buyerContainer';

const AboutUs = (props) => {
    const myRef = useRef(null)

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>About Us | Pharmaceutical Products Wholesale Distributors In India - Medimny</title>
                <meta name="title" content="About Us | Pharmaceutical Products Wholesale Distributors In India - Medimny" />
                <meta name="description" content={`Incorporated under corporate identity "Felicitas Digitech Private Limited" and brand name "Medimny", we aim to bring-in efficiencies in the supply chain in the sector by providing a common platform to buyers and sellers across the nation.`} />
            </Helmet>
            <BuyerContainer>
                <div className="app-wrapper" ref={myRef}>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-sm-8 col-xs-12 signinContainer mt-4 mb-5" >
                            <div className="jr-card AboutUs">
                                <div className="animated slideInUpTiny animation-duration-3 ">
                                    <h1 style={{ textAlign: 'center', fontWeight: 'bolder' }}>
                                        About Us
                                    </h1>
                                    <div className="login-header mb-0">
                                        <img src={logo} width="40%" style={{ marginBottom: "60px", marginTop: "35px" }} alt="logo" />
                                    </div>
                                    <h3 style={{ color: "#5b5b5b" }}>
                                        We are a new age technology-driven B2B<br /> ecommerce marketplace in pharma and healthcare space.
                                    </h3>
                                    <br />
                                    <h4 style={{ color: "#5b5b5b" }} className="mb-4">
                                        Incorporated under corporate identity "Felicitas Digitech Private Limited" and brand name "Medimny",
                                        we aim to bring-in efficiencies in the supply chain in the sector by providing a common platform to buyers
                                        and sellers across the nation.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BuyerContainer>
        </React.Fragment>
    );
}

export default AboutUs;