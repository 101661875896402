import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { Col, Row } from 'reactstrap';
import ContainerHeader from 'components/ContainerHeader';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form'
import { required, emailField, validatePhone, validatePincode, specailChar } from 'constants/validations';
import renderTextField from 'components/textBox';
import RenderSelectField from 'components/RenderSelectField';
import axios from 'constants/axios'
import { getUserDetail } from 'actions/seller'
import SellerContainer from "components/sellerContainer"

const EditProfile = (props) => {
  const [states, setStates] = useState([])

  useEffect(() => {
    axios.get('/admin/getStates', {}, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
      } else {
        setStates(result.data.detail)
      }
    }).catch(error => {
    });
  }, [])

  const onSubmit = async (values) => {
    let data = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      user_address: values.address,
      user_pincode: values.pincode,
      user_state: values.state,
      user_city: values.city,
      company_name: values.company_name
    }
    await axios.post('/users/editUserProfile', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
        props.getUserDetail({})
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  let { userDetails } = props;
  let isDisabled = userDetails.mainUser ? true : false
  let userData = props.userDetails.mainUser ? props.userDetails.mainUser : props.userDetails
  return (
    <SellerContainer>
      <div className="col-xl-12 col-lg-12">
        <ContainerHeader title="Edit Profile" />
        <div className="jr-card">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              first_name: userDetails ? userDetails.first_name : '',
              last_name: userDetails ? userDetails.last_name : '',
              phone: userDetails ? userDetails.phone : '',
              address: userData ? userData.user_address : '',
              email: userDetails ? userDetails.email : '',
              pincode: userData && userData.user_pincode ? userData.user_pincode : '',
              city: userData && userData.user_city ? userData.user_city : '',
              state: userData && userData.user_state && userData.user_state._id ? userData.user_state._id : '',
              company_name: userData && userData.company_name ? userData.company_name : '',
            }}
            render={({ handleSubmit, values }) =>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Row>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    <Field id="first_name" name="first_name" type="text"
                      component={renderTextField} label="First Name"
                      validate={composeValidators(required, specailChar)}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    <Field id="last_name" name="last_name" type="text"
                      component={renderTextField} label="Last Name"
                      validate={composeValidators(required, specailChar)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    <Field id="phone" name="phone" type="text"
                      component={renderTextField} label="Phone"
                      validate={validatePhone}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    {
                      isDisabled ? <Field id="address" name="address" type="text"
                        component={renderTextField} label="Address"
                        validate={required}
                        input={{ disabled: isDisabled, value: values.address }}
                        disabled={isDisabled}
                      /> : <Field id="address" name="address" type="text"
                        component={renderTextField} label="Address"
                        validate={required}
                      />
                    }
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    {
                      isDisabled ?
                        <Field id="pincode" name="pincode" type="text"
                          component={renderTextField} label="Pincode"
                          validate={composeValidators(required, validatePincode)}
                          disabled={isDisabled}
                          input={{ disabled: isDisabled, value: values.pincode }}
                        /> : <Field id="pincode" name="pincode" type="text"
                          component={renderTextField} label="Pincode"
                          validate={composeValidators(required, validatePincode)}
                        />
                    }
                  </Col>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    {
                      isDisabled ?
                        <Field id="city" name="city" type="text"
                          component={renderTextField} label="City"
                          validate={composeValidators(required, specailChar)}
                          input={{ disabled: isDisabled, value: values.city }}
                        /> :
                        <Field id="city" name="city" type="text"
                          component={renderTextField} label="City"
                          validate={composeValidators(required, specailChar)}
                        />
                    }
                  </Col>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6} className="text-left">
                    {isDisabled ?
                      <Field
                        name="state"
                        component={RenderSelectField}
                        label="State"
                        type='select'
                        fullWidth={true}
                        margin="normal"
                        input={{ disabled: isDisabled, value: values.state }}
                      >
                        <option value="" selected={!values.state} />
                        {
                          states && states.length > 0 ? states.map((stateData, key) => {
                            return <option value={stateData._id} selected={String(values.state) === String(stateData._id)}>{stateData.name}</option>
                          }) : ''
                        }
                      </Field> : <Field
                        name="state"
                        component={RenderSelectField}
                        label="State"
                        type='select'
                        validate={required}
                        fullWidth={true}
                        margin="normal"
                      >
                        <option value="" selected={!values.state} />
                        {
                          states && states.length > 0 ? states.map((stateData, key) => {
                            return <option value={stateData._id} selected={String(values.state) === String(stateData._id)}>{stateData.name}</option>
                          }) : ''
                        }
                      </Field>}
                  </Col>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    {
                      isDisabled ?
                        <Field id="company_name" name="company_name" type="text"
                          component={renderTextField} label="Company"
                          validate={composeValidators(required, specailChar)}
                          disabled={isDisabled}
                          input={{ disabled: isDisabled, value: values.company_name }}
                        /> :
                        <Field id="company_name" name="company_name" type="text"
                          component={renderTextField} label="Company"
                          validate={composeValidators(required, specailChar)}
                        />
                    }
                  </Col>
                  <Col sm={12} md={6} lg={6} xs={12} xl={6}>
                    <Field id="email" name="email" type="text"
                      component={renderTextField} label="Email"
                      validate={emailField}
                      input={{ disabled: true, value: values.email }}
                    />
                  </Col>
                </Row>
                <div className={'customButton'}>
                  <Button style={{ backgroundColor: '#072791' }} type="submit" variant="contained" color="primary">Save</Button>
                </div>
              </form>
            }
          />
        </div>
      </div >
    </SellerContainer>
  );
}

const mapStateToProps = ({ seller }) => {
  const { userDetails } = seller;
  return { userDetails }
};

export default connect(mapStateToProps, { getUserDetail })(EditProfile)