import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import Chip from '@mui/material/Chip';
import { Carousel, CarouselItem } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import CartPopOver from 'components/userProfileCard/CartPopOver'
import Tooltip from '@mui/material/Tooltip';
import helpertFn from 'constants/helperFunction';
import './index.css'
import { isMobile } from 'react-device-detect';
import { BorderBottom } from '@mui/icons-material';
import { EllipsisTooltips } from '../TooltipEllipsis/EllipsisTooltips'
import { EllipsedText } from "../TooltipEllipsis/EllipsedText";
import { Tooltip2 } from "../TooltipEllipsis/Tooltip";
const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const SellerProductCard = (props) => {
  const location = useLocation()
  const [tooltipElement, setTooltipElement] = React.useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  function next(size) {
    if (size === 1) {
      setActiveIndex(0)
    } else if (activeIndex === 0) {
      setActiveIndex(activeIndex + 1)
    }
  }

  function previous(size) {
    if (size === 1) {
      setActiveIndex(0)
    } else if (activeIndex === 1) {
      setActiveIndex(activeIndex - 1)
    }
  }

  function toggle() {
    setPopoverOpen(!popoverOpen)
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const closepop = () => {
    setPopoverOpen(false)
  }

  const { value } = props
  const productMRP = props.value && props.value.MRP
  const inventoryID = value && value && value.inventory_id;
  const productName = value && value.Product && value.Product.name && ((value.Product.name).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase();
  const bgColor = value && (value.medi_type === 'Ethical branded' || value.medi_type === 'Others') ? '#ff7000' :
    value.medi_type === 'Cool chain' ? '#0b68a8' :
      value.medi_type === 'Surgical' || value.medi_type === 'OTC' || value.medi_type === 'Generic' ? '#038d0e' : '#072791'
  const mobileStyles = {
    BorderBottom: "1px solid red"
  };
  return (
    <React.Fragment>
      <EllipsisTooltips onShowTooltip={setTooltipElement} />
      {tooltipElement && <Tooltip2 element={tooltipElement} />}
      <div className="jr-card-buyer text-left pb-2">
        <div className="jr-card-header-color card-header-style seller-card-min-height">
          <Carousel
            autoPlay={false}
            indicators={true}
            activeIndex={activeIndex}
            next={() => next(value && value.Product && value.Product.images ? value.Product.images.length : 1)}
            interval={false}
            previous={() => previous(value && value.Product && value.Product.images ? value.Product.images.length : 1)}
            className='itemImage'>
            {
              value && value.Product && value.Product.images && value.Product.images.length > 0 ?
                value.Product.images.map((image, index) => {
                  return (
                    <CarouselItem
                      key={index}
                    >
                      <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${inventoryID}`}>
                        <img onError={addDefaultSrc} src={value.Product.images.length === 0 ? logo : `${helpertFn.productImg(image)}`} alt="product" />
                      </NavLink>
                    </CarouselItem>
                  );
                })
                :
                [<CarouselItem
                  key='images'
                >
                  <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${inventoryID}`}>
                    <img src={logo} onError={addDefaultSrc} alt="product" />
                  </NavLink>
                </CarouselItem>]
            }
          </Carousel>
          <span style={{ padding: '5px 12px', borderRadius: "0 0 10px 0", fontWeight: 'bold', position: 'absolute', zIndex: 1, backgroundColor: `${bgColor}`, color: 'white', width: 'auto' }} >{value && value.medi_type === 'Others' ? 'PCD' : value.medi_type}</span>
          {
            location.pathname.includes('/view-company') ?
              null
              :
              <div>
                <Fab className="jr-badge-up" style={{ backgroundColor: `${bgColor}`, color: 'white' }} >
                  <i className="zmdi zmdi-shopping-cart"
                    style={{ float: "left" }}
                    onClick={toggle}
                    id={"upc111" + inventoryID}
                  />
                </Fab>
                <div>
                  <Popover style={{ padding: "5px", paddingBottom: "0px", textAlign: "center", backgroundColor: '#fff' }} trigger="legacy" placement="right"
                    isOpen={popoverOpen}
                    target={"upc111" + inventoryID}
                    toggle={toggle} >
                    <PopoverHeader style={{ padding: "4px 4px", textAlign: "center" }}>Select Quantity</PopoverHeader>
                    <br />
                    <CartPopOver closepop={(e) => closepop(e)}
                      dataFromParent={value}
                    />
                    <PopoverBody style={{ paddingBottom: 0, textAlign: "center" }}>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
          }
        </div>
        {
          value && value.medi_attribute.includes('Jumbo Deal') ?
            <Chip
              label={'Jumbo Deal'}
              size='small'
              style={{ backgroundColor: `${bgColor}`, padding: '17px 10px', color: 'white', float: 'left', position: 'relative', zIndex: 1, marginTop: '-28px' }}
            />
            : ''
        }
        <div className="jr-card-body pt-2" style={isMobile ? mobileStyles : {}}>
          <div className="product-details cardBodyPadding">
                <EllipsedText>
                  <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${inventoryID}`}>
                    <h2 className="card-title card-titleIntranet ellipsis w-100" style={{ minHeight: "45px", maxHeight: "45px", marginTop: "2px" }}>
                      {(value && value.Product && value.Product.name)}
                    </h2>
                  </NavLink>
                </EllipsedText>
            <EllipsedText>
              <p className="d-flex mb-0 align-items-baseline companyName ellipsis">
              By
                <NavLink className="buyerRedirectNavlink ellipsis" to={`/view-company/${value.Company._id}`}>
                  <h5 className="text-primary ml-1 NameBold ellipsis" style={{ minHeight: "30px", maxHeight: "30px", overflow: "hidden", marginBottom: "2px" }}>{value && value.Company && value.Company.name}
                  </h5>
                  </NavLink>
              </p>
            </EllipsedText>
            <div style={{ minHeight: "35px", maxHeight: "35px", marginBottom: "5px", padding: "0px" }}>
              <div className={`cardMrp`}>
                <div>
                  MRP <strong style={{ color: "black" }}>₹ {value.MRP.toFixed(2)}</strong>
                </div>
                {
                  helpertFn.showPrepaid(props.value.medi_type, props.value.Product.isPrepaid && props.value.Product.isPrepaid, props.value.prepaidInven && props.value.prepaidInven) &&
                  <Chip
                    label={'Only Prepaid'}
                    className='text-white bg-danger'
                    size='small'
                    style={{ padding: '17px 10px', float: "right" }}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className='jr-card-footer intranetFooterSeller ' style={{ overflow: 'hidden', minHeight: '90px', padding: '20px' }}>
          {
            value.Discount && value.Discount.discount_type === "Same" ?
              <React.Fragment>
                  <EllipsedText>
                    <Chip
                      className='chipBox'
                      style={{ padding: '17px 10px' }}
                      label={`Buy ${value.Discount.discount_on_product.purchase} Get ${value.Discount.discount_on_product.bonus} Free`}
                      size='small'
                    />
                  </EllipsedText>
                {/* <br /> */}
                <span className={'priceColor price'}>&#x20B9;{(value.ePTR).toFixed(2)}</span>&nbsp;
                <span className={'originalPrice font priceFont'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                <br />
                <span className='discountPrice orderQuantity' >
                  Min: {value.min_order_quantity}, Max: {value.max_order_quantity}
                </span>
              </React.Fragment>
              :
              value.Discount && value.Discount.discount_type === "SameAndDiscount" ?
                <React.Fragment>
                  <EllipsedText>
                    <Chip
                      className='chipBox'
                      style={{ padding: '17px 10px' }}
                      label={`Buy ${value.Discount.discount_on_product.purchase} Get ${value.Discount.discount_on_product.bonus} Free, and ${(value.Discount.discount_per).toFixed(2)}% Off`}
                      size='small'
                    />
                  </EllipsedText>
                  {/* <br /> */}
                  <span className={'priceColor price'}>&#x20B9;{(value.ePTR).toFixed(2)}</span>&nbsp;
                  <span className={'originalPrice font priceFont'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                  <br />
                  <span className='discountPrice orderQuantity' >
                    Min: {value.min_order_quantity}, Max: {value.max_order_quantity}
                  </span>
                </React.Fragment> :
                value.Discount && value.Discount.discount_type === "Different" ?
                  <React.Fragment>
                    <EllipsedText>
                      <Chip
                        className='chipBox'
                        style={{ padding: '17px 10px' }}
                        label={`Buy ${value.Discount.discount_on_product.purchase} Get ${value.Discount.discount_on_product.bonus} ${value.OtherProducts.name} Free`}
                        size='small'
                      />
                    </EllipsedText>
                    {/* <br /> */}
                    <span className={'priceColor price'}>₹{(value.ePTR).toFixed(2)}</span>
                    &nbsp;
                    <span className={'originalPrice font priceFont'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                    <br />
                    <span className='discountPrice orderQuantity' >
                      Min: {value.min_order_quantity}, Max: {value.max_order_quantity}
                    </span>
                  </React.Fragment>
                  :
                  value.Discount && value.Discount.discount_type === "DifferentAndDiscount" ?

                    <React.Fragment>
                      <EllipsedText>
                        <Chip
                          className='chipBox'
                          style={{ padding: '17px 10px' }}
                          label={` Buy ${value.Discount.discount_on_product.purchase} Get ${value.Discount.discount_on_product.bonus} ${value.OtherProducts.name} Free, and ${(value.Discount.discount_per).toFixed(2)}% Off`}
                          size='small'
                        />
                      </EllipsedText>
                      {/* <br /> */}
                      <span className={'priceColor price'}>₹{(value.ePTR).toFixed(2)}</span>
                      &nbsp;
                      <span className={'originalPrice font priceFont'}>&#x20B9;{value.PTR.toFixed(2)}</span>
                      <br />
                      <span className='discountPrice orderQuantity' >
                        Min: {value.min_order_quantity}, Max: {value.max_order_quantity}
                      </span>
                    </React.Fragment> :
                    value.Discount && value.Discount.discount_type === "Discount" ?
                      <React.Fragment>
                          <Chip
                            className='chipBox'
                            style={{ padding: '17px 10px' }}
                            label={`${(value.Discount.discount_per)}% off
                            `}
                            size='small'
                          />
                        <br />
                        <span className={'priceColor price'}>₹{(value.ePTR).toFixed(2)}</span>
                        &nbsp;
                        <span className={'originalPrice font priceFont'}>₹{(value.PTR).toFixed(2)}</span>
                        <br />
                        <span className='discountPrice orderQuantity' >
                          Min: {value.min_order_quantity}, Max: {value.max_order_quantity}
                        </span>
                      </React.Fragment>
                      :
                      value.Discount === undefined ?
                        <React.Fragment>
                          <span className={'priceColor price'}>&#x20B9;{(value.ePTR).toFixed(2)}</span>&nbsp;
                          <span className={'originalPrice font priceFont'}>&#x20B9;{value.MRP.toFixed(2)}
                          </span>
                          <br />
                          <span className='discountPrice orderQuantity' >
                            Min: {value.min_order_quantity}, Max: {value.max_order_quantity}
                          </span>
                        </React.Fragment>
                        :
                        ''
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default SellerProductCard;