import React from "react";

const CampanyHeader = (props) => {
  const { dataFromParent } = props;

  return (
    <div className="jr-profile-banner" style={{
      background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)),url(${require('assets/images/eco.jpg')})`, backgroundSize: 'cover',
      backgroundRepeat: 'none', backgroundPosition: 'center center'
    }}>
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left mt-4 ml-4">
            <div className="jr-profile-banner-avatar-info">
              <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                {dataFromParent && dataFromParent.results && dataFromParent.results[0] && dataFromParent.results[0].company_id && dataFromParent.results[0].company_id.name}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampanyHeader;