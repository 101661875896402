import React, { useEffect } from 'react'
import ContainerHeader from 'components/ContainerHeader';
import WithIconTimeLineItem from 'components/timeline/OrderTimeline';
import { NewReleases, CreditCard } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getOrderDetails } from 'actions/order';
import { useParams } from 'react-router-dom';
import SellerContainer from "components/sellerContainer"

const TrackOrders = (props) => {
  const params = useParams()

  useEffect(() => {
    props.getOrderDetails({ data: { orderId: params.id } })
  }, [])

  let { orderDetails } = props;

  return (
    <SellerContainer>
      <ContainerHeader title={`Order ID: ${params.id}`} />
      <div className="timeline-section timeline-center clearfix animated slideInUpTiny animation-duration-3" >
        {
          orderDetails && Object.keys(orderDetails).length > 0 && orderDetails.order_status.length > 0 ?
            orderDetails.order_status.map((value, key) => {
              return <WithIconTimeLineItem styleName={(key === 0 || key === 2 || key === 4 || key === 6 || key === 8 || key === 10) ? 'timeline-inverted' : ''} timeLine={value} color={value.status === 'Processed' ? 'blue' : value.status === 'New' ? 'red' : value.status === 'Cancelled' ? 'grey' : value.status === 'Ready For Dispatch' ? 'orange' : value.status === 'Delivered' ? 'green' : 'info'}>
                {
                  value.status === 'Processed' ? <CreditCard /> : value.status === 'New' ? <NewReleases /> : value.status === 'Cancelled' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-close"></i></div> : value.status === 'Ready For Dispatch' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-run"></i></div> : value.status === 'Delivered' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-check"></i></div> : <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-truck"></i></div>}
              </WithIconTimeLineItem>
            }) : ''
        }
      </div>
    </SellerContainer>
  );
}

const mapStateToProps = ({ order }) => {
  const { orderDetails } = order;
  return { orderDetails }
};

export default connect(mapStateToProps, { getOrderDetails })(TrackOrders);