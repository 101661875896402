import moment from "moment"

export const required = value => {
  return value ? undefined : 'This field is required'
}
export const requiredddd = value => {
  return value ? value.trim() === '' ? "This field is required" : undefined : undefined
}
export const validatePincode = value => {
  let pin = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/
  return value ? !pin.test(value) ? "Invalid pincode" : '' : 'This field is required'
}
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const maxLength15 = maxLength(15)
export const maxLength4 = maxLength(4)
export const maxNum = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxNum4 = maxNum(4)

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : undefined
export const number0 = value => value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : Number(value) === 0 ? "Can't be zero" : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue18 = minValue(18)
export const minValue4 = minValue(4)
export const minValue10 = minValue(10)
export const minLength = min => value =>
  value && value.length < min ? `Must contain at least ${min} characters.` : undefined;
export const minLength6 = minLength(6);

export const validatePhone = value => {
  return value !== undefined ? value.length !== 10 ? 'Please enter valid Mobile number' : '' : 'This field is required'
}
export const maxPurchase = (value, allValues, props) => Number(allValues.minimumOrderQty) >= Number(allValues.maximumOrderQty) ?
  '' : ''

export const minLessAvail = (value, allValues, props) => Number(allValues.minimumOrderQty) > Number(allValues.totalQuantity) ?
  'Lot size should not be greater than Total Available Quantity' : ''

export const lessThenMrp = (value, allValues, props) => Number(allValues.PTR) > Number(allValues.MRP) ?
  'PTR should not be greater than MRP' : ''

export const maxLessAvail = (value, allValues, props) => Number(allValues.maximumOrderQty) > Number(allValues.totalQuantity) ?
  'Maximum Quantity should not be greater than Total Available Quantity' : ''

export const maxGreaterMin = (value, allValues, props) => Number(allValues.maximumOrderQty) === Number(allValues.minimumOrderQty) ?
  '' : ''
export const maxLessMin = (value, allValues, props) => Number(allValues.maximumOrderQty) < Number(allValues.minimumOrderQty) ?
  'Maximum Quantity should not be less than Lot size' : ''

export const dicount100 = (value, allValues, props) => Number(allValues.percentOff) === 100 ?
  `Discount can't be 100 %` : ''

export const emailField = (value) => {
  return (value ?
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
      'Invalid email address' : '' : 'Email is required')
}
export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined
export const aol = value =>
  value && /.+@aol\.com/.test(value) ?
    'Really? You still use AOL for your email?' : undefined
export const passwordMatch = (value, allValues, props) => {
  return allValues.confirmPassword !== allValues.password
    ?
    "Passwords do not match."
    : undefined;
}

export const confirmPasswordMatch = (value, allValues, props) => {
  return allValues.confirmPassword !== allValues.newPassword
    ?
    "Passwords do not match."
    : undefined;
}
export const fassaiLicNoRequired = (value, allValues, props) => {
  return allValues.fassaiLic && !allValues.fassaiLicNo ? 'This field is required' : undefined
}

export const gstLicNoRequired = (value, allValues, props) => {
  return allValues.gstLic ?
    allValues.gstLicNo ?
      (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(allValues.gstLicNo) ?
        'Invalid GST' : '') :
      'This field is required' : undefined
}
export const gstLicNoComp = (value, allValues, props) => {
  return allValues ?
    allValues.gstLicNo ?
      (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(allValues.gstLicNo) ?
        'Invalid GST' : '') :
      'This field is required' : undefined
}

export const gstValidation = (value) => {
  return (value && value.length <= 15 ?
    !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(value) ?
      'Invalid GST' : '' : 'Please enter valid GST')
}
export const specailChar = (value) => {
  return (value === undefined ? 'This field is required' : !/^[a-zA-Z 0-9]*$/.test(value) ? 'Special characters not allowed' : ''
  )
}
export const accountNumber = (value) => {
  return (value === undefined ? 'This field is required' : isNaN(value) ? 'Please enter valid account number' : value.length < 10 ? 'Please enter valid account number' : '')
}

export const invoiceValue = (value, allValues) => {
  let newValue = Math.round(allValues.invoice2 + allValues.invoice2 * 0.1);
  let newValue1 = Math.round(allValues.invoice2 - allValues.invoice2 * 0.1);
  return value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : Number(value) < Number(newValue1) || Number(value) > Number(newValue) ?
    `Please enter  value between ${newValue1} to ${newValue}` : ''
}

export const invoiceValueOnline = (value, allValues) => {
  let newValue = Math.round(allValues.invoice2);
  let newValue1 = Math.round(allValues.invoice2 - allValues.invoice2 * 0.1);
  return value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : Number(value) < Number(newValue1) || Number(value) > Number(newValue) ?
    `Please enter  value between ${newValue1} to ${newValue}` : ''
}

export const ifscCode = (value) => {
  return (value === undefined ? 'This field is required' : !/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/.test(value) ? 'Please enter valid IFSC Code ' : '')
}

export const emailPhone = (value) => {
  return (value === undefined ? 'This field is required' : isNaN(value) ? !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter valid Email address' : '' : value.length < 10 ? 'Please enter valid Mobile number' : '')
}

export const phone = (value) => {
  return (value ?
    !/^[6-9]\d{9}$/.test(value) ?
      'Invalid Contact number' : '' : 'Please enter valid Contact number')
}

export const validatePassword = (value) => {
  return (value === undefined ? 'This field is required' : value.length < 6 ? 'Must contain at least 6 characters.' : '')
}

export const validateDate = (value) => {
  if (!value) {
    return "This field is required";
  }
  const isValidDate = moment(value, 'MM/YYYY', true).isValid();
  if(!isValidDate){
  return "Invalid Date";
  }
  const threMonth =  (moment(value, 'MM/YYYY', true).startOf("months")).isBefore(moment().add(3,"months").startOf("months"))
return threMonth ? "Invalid Date" : ""

}

export const validateData = (values) => {
  const errors = {};
  const requiredFields = [
    'email'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
}
export const noValidations = (value) => { return '' }

export const percentageValidation = value => {
  if (parseInt(value) < 0) {
    return "% off cannot be less than 0."
  } else if (parseInt(value) > 100) {
    return "% off cannot be greater than 100."
  } else {
    return ""
  }
}
export const discountValidation = value => {
  let check = value / 0.25;
  let checkNumber = Number.isInteger(check);
  if (checkNumber === false) {
    return "% should be multiple of 0.25"
  }
  else {
    return ""
  }
}

export const noDecimal = value => {

  let val = Number(value)
  let checkNumber = Number.isInteger(val);

  if (checkNumber !== true) {
    return "Quantity should be integer."
  }
  else {
    return ""
  }
}

export const twoDecimal = value => {
  let newRegex = new RegExp(/^(?:\d*\.\d{1,2}|\d+)$/)
  let val = Number(value)
  if (!newRegex.test(val)) {
    return "Should be two decimals."
  }
  else {
    return ""
  }
}

export const otpVal = (value) => {
  return (isNaN(Number(value)) ? 'Must be a number' : value && value.length === 6 ? '' : 'Please enter valid')
}



export const requiredImage = value => {
  return value ? undefined : 'Please upload image'
}