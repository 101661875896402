import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import moment from 'moment';
import { getBulkListSeller } from 'actions/seller';
import { connect } from 'react-redux';
import { Col, Row, Label, Input } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const BulkListHistory = (props) => {
  const [params] = useSearchParams()
  let navigate = useNavigate();
  const tab = params && params.get("tab") ? Number(params.get("tab")) : 0
  const perPage = 100
  const page = params && Number(params.get("pageOH")) ? Number(params.get("pageOH")) : 0
  const [expirePopup, setExpirePopup] = useState(false)
  const [fulfilledPopup, setFulfilledPopup] = useState(false)
  const [dialogData, setDialogData] = useState('')
  const [offerData, setOfferData] = useState('')

  useEffect(() => {
    let data = {
      page: (params && Number(params.get("pageOH")) ? Number(params.get("pageOH")) : 0) + 1,
      perPage,
      tab: 'orderHistory',
      close: '1'
    }
    if (tab === 1) {
      props.getBulkListSeller({ data })
    }
  }, [params])

  useEffect(() => {
    if (props.tabValue === 1) {
      navigate(`/seller/bulkrequests?tab=1`, { replace: true })
    }
  }, [props.tabValue])

  const changePage = (page) => {
    let obj = { pageOH: page, tab: 1 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/bulkrequests?${url}`, { replace: true })
  };


  const actions = (d, offers) => {
    let Id = props.sellerId && props.sellerId;
    let offer = offers.filter(e => (Id).toString() === (e.seller_id).toString())
    if (offers.length > 0 && offer.length > 0) {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setFulfilledPopup(true); setDialogData(d); setOfferData(offer[0]) }}>
        VIEW
      </span>
    } else {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => openClosedDialog(d)}>
        VIEW
      </span>
    }
  }

  const handleLogClose = () => {
    setExpirePopup(false)
    setFulfilledPopup(false)
    setOfferData('')
  }

  const openClosedDialog = (data) => {
    setExpirePopup(true)
    setDialogData(data)
  }

  const statusStyle = (data) => {
    let status = data.toLowerCase();
    return status.includes("supplied") ? "#B45F06" : status.includes("requested") ? '#2B78E4' : status.includes("confirmed") ? "#38761D" : status.includes("offered") ? "#990000" : status.includes("expired") ? "#ea9a99" : status.includes("fulfilled") ? "#b6d8a8" : status.includes("closed") ? "#9fc4f7" : '';
  }

  const text = (data1) => {
    let data = data1.toLowerCase();
    return data.includes("expired") ? "#903230" : data.includes("fulfilled") ? '#698c57' : data.includes("closed") ? "#305d8b" : '';
  }

  let { bulkListSeller } = props;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: bulkListSeller && bulkListSeller.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "product",
      label: "Product name",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "discount",
      label: "Additional expected discount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity",
      label: "Expected quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  let data = []
  bulkListSeller && bulkListSeller.data1.length > 0 && bulkListSeller.data1.map(d => {
    data.push({
      product: d.product_name,
      discount: `${d.discount}%`,
      quantity: d.quantity,
      status: <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle(d.status) }}><span style={{ color: text(d.status) }}>{d.status}</span></div>,
      date: moment(d.createdAt).format('DD/MM/YYYY'),
      actions: actions(d, d.offers),
    })
  })

  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
        style={{ borderRadius: '0px !important' }}
      />
      <Dialog open={fulfilledPopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          DETAILS
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col sm={12} lg={12} md={12} xl={12} xs={12} className="mb-2">
              <Label>Product Name</Label>
              <Input value={dialogData?.product_name} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Best Offered Effective PTR</Label>
              <Input value={dialogData && (dialogData.ptr).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Estimated Effective PTR</Label>
              <Input value={offerData && (Number(offerData.amount) / Number(offerData.quantity)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Quantity</Label>
              <Input value={offerData?.quantity} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Discount %</Label>
              <Input value={`${offerData?.discount}%`} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6}>
              <Label for="expiryDate">Product expiry</Label>
              {
                offerData && offerData.expiry_date ? <Input value={moment(offerData?.expiry_date).format('MMMM YY')} readOnly /> :
                  <Input value={''} readOnly />
              }

            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6}>
              <Label>Final Cost  (W/O GST)</Label>
              <Input type="text" name='value' readOnly value={offerData && offerData.amount && (offerData.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions className='mr-2'>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={expirePopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
        <DialogContent>
          <h3>{dialogData.closed_reason}</h3>
        </DialogContent>
        <DialogActions className='mr-2 pt-0'>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ seller }) => {
  const { bulkListSeller, sellerId, loading } = seller;
  return { bulkListSeller, sellerId, loading }
};

export default connect(mapStateToProps, { getBulkListSeller })(BulkListHistory);