import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText style={{ color: '#f44336' }}>{touched && error}</FormHelperText>
  }
}

const RenderDatePicker = ({ label, minDate, views, input: { onChange, value, name }, meta: { touched, error } }) => {
  const selectedDate = value ? moment(value) : moment().add(2, "M");

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          onChange={onChange}
          views={views || ['year', 'month', 'day']}
          name={name}
          label={label}
          value={selectedDate}
          minDate={minDate || moment().add(2, "M")}
          fullWidth
          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          format={views && views.length > 0 ? "MMMM YYYY" : "MM/DD/YYYY"}
          slotProps={{ textField: { variant: 'standard' } }}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>
      {renderFromHelper({ touched, error })}
    </React.Fragment>
  );
}

export default RenderDatePicker;