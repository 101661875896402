import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import helperFunction from 'constants/helperFunction';

const UserProfileCard = (props) => {
  const [gradient, setGradient] = useState('')

  useEffect(() => {
    setGradient(helperFunction.gradientGenerator())
  }, [])

  return (
    <React.Fragment>
      <div className="jr-card user-detail-card mb-4">
        <div className="user-img-container">
          <Avatar
            alt={''}
            src={''}
            className={`user-avatar`}
            style={{ background: gradient }}
          >
            {helperFunction.getNameInitials(`${props.DataFromParent.first_name} ${props.DataFromParent.last_name}`)}
          </Avatar>
        </div>
        <div className="jr-card-body d-flex flex-column justify-content-center">
          <h4 className="mb-0"> {`${props.DataFromParent.first_name}`} {props.DataFromParent.last_name}</h4>
          <span className="d-block jr-fs-13 mb-1">{props.DataFromParent.email}</span>
          <span className="d-block jr-fs-sm text-grey">{props.DataFromParent.phone}</span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserProfileCard;

