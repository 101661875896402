import React, { useState, useEffect } from 'react';
import { Label, Input } from 'reactstrap';
import { FormGroup, Button } from '@mui/material';
import { getOrderHistory } from 'actions/order'
import { getSettlementList } from 'actions/seller'
import { getTransactionList } from 'actions/buyer'
import { getMediWallet } from 'actions/buyer'
import { getOrderHistoryList, getOrderList, getProductRequestList, getShortbook } from 'actions/buyer'
import { getOrder } from 'actions/order';
import { connect } from 'react-redux';
import helperFunction from 'constants/helperFunction'
import { Grid } from "@mui/material";

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const CustomFilter = (props) => {
  const [yearList, setYearList] = useState([])

  useEffect(() => {
    let years = helperFunction.yearList();
    setYearList(years)
  }, [])

  const handleChange = (e, key) => {
    e.preventDefault()
    props.onFilterChange(e, key);
  }

  const handleClose = e => {
    e.preventDefault();
    props.handleResetFilter(e, props.applyFilter)
  }

  const handleDownload = e => {
    e.preventDefault();
    props.handleDownload(e, props.applyFilter)
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (props.filterFor !== 'buyerOrderHistory') {
      props.applyFilter();
    }
    if (props.filterFor === 'buyerOrderHistory') {
      props.handleApplyFilter();
      props.applyFilter();
    }
    if (props.filterFor === 'mediWallet') {
      props.click();
    }
    let data = {
      page: 1,
      perPage: 50,
      filter: '',
      searchText: props.searchText ? props.searchText : '',
      month: props.month,
      year: parseInt(props.year) ? parseInt(props.year) : ''
    }
    if (props.filterFor === 'buyerProductRequestHistory' || props.filterFor === 'shortbookFulfilled') {
      data.tab = 'history'
    }
    if (props.filterFor === 'buyerOpenProductRequest' || props.filterFor === 'shortbookList') {
      data.tab = 'open'
    }
    if (props.filterFor === 'settlement') {
      props.getSettlementList({ data })
    }
    else if (props.filterFor === 'transaction') {
      props.getTransactionList({ data })
    }
    else if (props.filterFor === 'mediWallet') {
      props.getMediWallet({ data })
    }
    else if (props.filterFor === 'buyerOrderHistory') {
      props.getOrderHistoryList({ data })
    }
    else if (props.filterFor === 'buyerOpenOrders') {
      props.getOrderList({ data })
    }
    else if (props.filterFor === 'sellerOpenOrder') {
      props.getOrder({ data })
    }
    else if (props.filterFor === 'buyerOpenProductRequest' || props.filterFor === 'buyerProductRequestHistory') {
      props.getProductRequestList({ data })
    }
    else if (props.filterFor === 'shortbookFulfilled' || props.filterFor === 'shortbookList') {
      props.getShortbook({ data })
    }
    else {
      props.getOrderHistory({ data })
      props.onFilterChange({ page: props.page, month: props.month, year: props.year })
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={5} md={6} lg={6} xs={5} xl={6}>
          <FormGroup>
            <Label for="exampleSelect">Select Month</Label>
            <Input type="select" name="month" value={props.month} onChange={(e) => handleChange(e, 'month')} id="month">
              {(props.filterFor === 'sellerOpenHistoryOrder' || props.filterFor === 'sellerOpenOrder') ? <option value=''>All</option> : ''}
              {
                monthArray.map((value, key) => {
                  return <option value={value} >{value}</option>;
                })
              }
            </Input>
          </FormGroup>
        </Grid>
        <Grid item sm={5} md={6} lg={6} xs={5} xl={6}>
          <FormGroup>
            <Label for="exampleSelect">Select Year</Label>
            <Input type="select" value={props.year} onChange={(e) => handleChange(e, 'year')} name="year" id="year">
              {(props.filterFor === 'sellerOpenHistoryOrder' || props.filterFor === 'sellerOpenOrder') ? <option value=''>All</option> : ''}
              {
                yearList.map((value, key) => {
                  return <option value={parseInt(value)} >{value}</option>
                })
              }
            </Input>
          </FormGroup>
        </Grid>
        <Grid item sm={5} md={6} lg={6} xs={5} xl={6}>
          <Button variant="contained" className='filterButton' onClick={handleSubmit} color='primary'>Apply</Button>
        </Grid>
        <Grid item sm={5} md={6} lg={6} xs={5} xl={6} className="text-nowrap">
          <Button variant="contained" onClick={handleClose} className='filterButton' color='primary'>Reset</Button>
        </Grid>
        {props.filterFor === 'mediWallet' ?
          <Grid item md={12}>
            <Button variant="contained" onClick={handleDownload} className='filterButton' color='primary'>WALLET CSV</Button>
          </Grid>
          : null}
      </Grid>
    </React.Fragment>
  );
}

export default connect(null, { getOrderHistory, getSettlementList, getTransactionList, getMediWallet, getOrderHistoryList, getOrder, getOrderList, getProductRequestList, getShortbook })(CustomFilter);