import { createMuiTheme } from '@mui/material/styles';
import AppConfig from './config';

const gradientGenerator = () => {

  var hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e"];

  function populate(a) {
    for (var i = 0; i < 6; i++) {
      var x = Math.round(Math.random() * 14);
      var y = hexValues[x];
      a += y;
    }
    return a;
  }

  var newColor1 = populate('#');
  var newColor2 = populate('#');
  var angle = Math.round(Math.random() * 360);

  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  let newRgbaColor = hexToRGB(newColor1, 0.7);
  let newRgbaColor1 = hexToRGB(newColor1, 1);

  // var gradient = "linear-gradient(" + angle + "deg, " + newColor1 + ", " + newColor2 + ")";
  var gradient = "linear-gradient(" + 150 + "deg, " + newRgbaColor + ", " + newRgbaColor1 + "50%" + ")";

  return gradient;

}

const getGradient = (rgbValues) => {
  if (rgbValues !== undefined) {
    function getColors(alpha) {
      if (alpha) {
        return "rgba(" + parseInt(rgbValues.red) + ", " + parseInt(rgbValues.green) + ", " + parseInt(rgbValues.blue) + ", " + alpha + ")";
      } else {
        return "rgb(" + parseInt(rgbValues.red) + ", " + parseInt(rgbValues.green) + ", " + parseInt(rgbValues.blue) + ")";
      }
    }

    let newRgbaColor = getColors(0.7);
    let newRgbaColor1 = getColors(1);

    var gradient = "linear-gradient(" + 150 + "deg, " + newRgbaColor + ", " + newRgbaColor1 + "50%" + ")";
    return gradient;
  }

}

const getNameInitials = (name) => {
  if (name !== undefined) {
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
}

const getRGB = () => {
  var hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e"];

  function populate(a) {
    for (var i = 0; i < 6; i++) {
      var x = Math.round(Math.random() * 14);
      var y = hexValues[x];
      a += y;
    }
    return a;
  }
  var newColor = populate('#');

  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return [r, g, b]
  }

  return hexToRGB(newColor, 0.7)
}

export function validateEmail(data) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
  if (data.match(mailformat)) {
    return true
  } else {
    return false
  }
}

const normalizeName = (first) => {
  if (first !== undefined) {
    return first.length > 2 ? first.slice(0, 1) + '' : first
  }

  /* if(first !== undefined && last !== undefined) {
    let name = first.concat(last);
    return name.length > 15 ? name.slice(0,2) + '': name
  } */
}

const getRatingValue = (value) => {
  let emoji = [':angry::skin-tone-6:', ':slighlty_frowning_face::skin-tone-6:', ':neutral_face::skin-tone-6:', ':blush::skin-tone-6:', ':heart_eyes::skin-tone-6:'];
  return value !== 0 ? emoji[value - 1] : emoji[0];
}

const yearList = (startYear) => {
  var currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 2017;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

const getRatingValueForBuyer = (value) => {
  let i = ['fas fa-angry', 'fas fa-sad-tear', 'fas fa-meh', 'fas fa-smile-beam', 'fas fa-grin-hearts'];
  return value !== 0 ? i[value - 1] : i[0];
}

const styleForDiv = {
  cursor: 'default',
  maxHeight: '50%',
  width: "60%",
}
const divFlex = { display: 'flex', flexDirection: 'column' }

const styleDivLine = (data) => {
  switch (data) {
    case 'Generic': return 'bg-success'
      break;
    case 'Ethical branded': return 'bg-warning';
      break;
    case 'Cool chain': return 'bg-primary'
      break;
    case 'Short Expiry': return 'bg-pink'
      break;
    default: return 'bg-white'
  }
}
const styleDivLine2 = (data) => {
  let data2 = data && data.toLowerCase();
  switch (data2) {
    case 'delhivery': return 'bg-danger'
      break;
    case 'shiprocket': return 'bg-dark';
      break;
    case 'bluedart': return 'bg-primary'
      break;
    default: return 'bg-white'
  }
}

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        'padding-left': '0px'
      }
    }
  }
})

const productImg = (data) => {
  return data && data.includes('amazonaws') ? data : `${AppConfig.productImageUrl}${data}`
}

const getProdColor = (data) => {
  return data && data && (data == 'Ethical branded' || data == 'Others') ? '#ff7000' : (data == 'Generic' || data == 'OTC' || data == 'Surgical') ? '#038d0e' : data == 'Cool chain' ? '#0b68a8' : ' #0f0f0a';
}

const showPrepaid = (medi, prod, inven) => {
  if (medi.toLowerCase() == 'cool chain' || prod === true || inven === true) {
    return true
  } else {
    return false
  }
}

const textCapitalize = (data) => {
  return data ? data.trim() ? data.charAt(0).toUpperCase() + data.slice(1) : data : data
}

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default {
  gradientGenerator,
  getNameInitials,
  getRGB,
  validateEmail,
  getGradient,
  normalizeName,
  getRatingValue,
  yearList,
  getRatingValueForBuyer,
  styleForDiv,
  divFlex,
  styleDivLine,
  styleDivLine2,
  getMuiTheme,
  productImg,
  getProdColor,
  showPrepaid,
  textCapitalize,
  monthArray
};