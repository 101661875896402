import React from 'react'
import { Field } from 'react-final-form'
import renderTextField from 'components/textBox';
import { accountNumber, ifscCode, required } from 'constants/validations';
import { Col, Row } from 'reactstrap';
import FieldFileInput from 'components/FieldFileInput';
import FieldCheckBox from 'components/checkBoxField'
import './style.css'

const Form3 = (props) => {
    return (
        <Row>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="account_no" label="Account Number" component={renderTextField} validate={accountNumber} />
            </Col>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="ifsc_code" label="IFSC Code" component={renderTextField} validate={ifscCode} />
            </Col>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="recipient_name" label={'Recipient Name'} component={renderTextField} validate={required} />
            </Col>
            <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                <Field name="cancelledCheck" component={FieldFileInput} label="Cancelled Check" validate={(value) => (value === undefined ? 'Please Upload Cancelled Check' : '')} />
            </Col>
            <Col sm={12} md={12} xs={12} lg={12} xl={12} className="mt-2">
                <Field name="policy" component={FieldCheckBox} label="Accept Terms and Conditions" validate={required} />
            </Col>
        </Row>
    )
}

export default Form3;