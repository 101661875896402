import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios';
import AppConfig from 'constants/config';
import './index.css';
import { NotificationManager } from 'react-notifications';

const Bnpl = (props) => {
    const [openModel, setOpenModel] = useState(false)

    useEffect(() => {
        let { data } = props;
        let show = data.bnplAccepted === true ? false : true;
        setOpenModel(show)
    }, [])

    const handleAccept = () => {
        axios({
            method: 'post',
            url: `${AppConfig.baseUrl}users/acceptBnpl`,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('buyer_token')
            }
        }).then(async (result) => {
            if (result.data.error) {
                NotificationManager.error(result.data.title);
                handleClose();
            } else {
                NotificationManager.success(result.data.title);
                setOpenModel(false)
            }
        }).catch(error => { NotificationManager.error('Error while accepting.'); handleClose(); });
    }
    const handleClose = () => {
        props.changeTab('Edit Profile')
    }

    let { data } = props;
    let token = localStorage.getItem('buyer_token');
    let userId = data.buyerId;
    let srcLink = `https://sitdev.finovatecapital.in/bnpl/?vendorId=MDMNY&userId=${userId}&sessionId=${token}`;

    return (
        <React.Fragment>
            {!openModel &&
                <div className="jr-card">
                    <iframe title="bnpl" frameborder="0" box-sizing="border-box" display='inline-block' height='550' width='100%' border='none !important' src={srcLink} />
                </div>
            }
            <Dialog open={openModel} onClose={handleClose} fullWidth={true}>
                <DialogTitle>
                    To avail BNPL kindly accept the terms and conditions.
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleAccept} color='primary'>Accept</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Bnpl;