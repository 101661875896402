import React, { useState, useEffect } from 'react';
import List from "@mui/material/List";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import './index.css'
import { Row, Col } from 'reactstrap';
import { Input } from 'reactstrap';
import moment from 'moment';
import helperFunction from 'constants/helperFunction';
import { NotificationManager } from 'react-notifications';
import AxiosRequest from 'sagas/axiosRequest';
import { DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import Drop from './Drop';
import TrackOrder from './trackTicket';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const CollapseComponent = (props) => {
  const [commentsArray, setCommentsArray] = useState('')
  const [comment, setComment] = useState('')
  const [imageArr, setImageArr] = useState([])
  const [addPopup, setAddPopup] = useState('')
  const [newFileUpload, setNewFileUpload] = useState([])
  const [submitBoolean, setSubmitBoolean] = useState(false)
  const [openTrack, setOpenTrack] = useState(false)
  const [uploadedFileArr, setUploadedFileArr] = useState([])
  const [loader, setLoader] = useState(false)
  const [value, setValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLightBox, setOpenLightBox] = useState(false)

  const handleReply = async () => {
    setSubmitBoolean(true);
    let { value } = props;
    let data = { Id: value.data._id, comment: comment }
    if (comment === '') {
      NotificationManager.error('Reply is required.');
    } else {
      if (submitBoolean) {
        let result = await AxiosRequest.axiosBuyerHelperFunc('post', 'ticket/addComment', '', data)
        if (result.data.error) {
          NotificationManager.error(result.data.title);
          setComment('')
        } else {
          NotificationManager.success(result.data.title)
          setComment('')
          setCommentsArray(result.data.data.comments)
        }
        closePopup();
      }
    }
  }

  const handleChange = (e, key) => {
    setComment(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (comment.length > 0 && e.key === 'Enter' && !submitBoolean) {
      handleReply();
    }
  }

  useEffect(() => {
    let index = props.mainOrderData.findIndex(x => x.ticketNo === props.value.ticketNo);
    let data = props.mainOrderData[index];
    setValue(data)
    setCommentsArray(data.comments)
    setImageArr(data.data.supportings)
  }, [])

  useEffect(() => {
    if (props.collapse && props.value.comments[0] !== value.comments[0]) {
      let index = props.mainOrderData.findIndex(x => x.ticketNo === props.value.ticketNo);
      let data = props.mainOrderData[index];
      setValue(data)
      setCommentsArray(data.comments)
      setImageArr(data.data.supportings)
    }
  }, [props.collapse, props.value.comments, value.comments])

  const getFiles = async (data1) => {
    setUploadedFileArr([...uploadedFileArr, ...data1]);
    data1.map(data => {
      let reader;
      let file;
      file = data;
      reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let joined = newFileUpload.concat(e.target.result);
        setNewFileUpload(joined)
      }
    })
  }

  const remove = (file, i) => {
    let index = uploadedFileArr.findIndex(e => e.name === file.name);
    let temp = [...uploadedFileArr]
    temp.splice(index, 1)
    setUploadedFileArr(temp)
    let temp1 = [...newFileUpload]
    temp1.splice(i, 1)
    setNewFileUpload(temp1)
  }

  const closePopup = () => {
    setNewFileUpload([])
    setUploadedFileArr([])
    setAddPopup(false)
    setSubmitBoolean(false)
    setOpenTrack(false)
    setLoader(false)
  }

  const add = async () => {
    setSubmitBoolean(true)
    setLoader(true);
    let data = { Id: props.value.data._id, supportings: newFileUpload }
    let result = await AxiosRequest.axiosBuyerHelperFunc('post', 'ticket/updateTicket', '', data)
    if (result.data.error) {
      closePopup();
    } else {
      NotificationManager.success(result.data.title)
      setTimeout(() => {
        setImageArr(result.data.data.supportings)
      }, 2000);
      closePopup();
    }
  }

  const { collapse } = props;
  let images = imageArr.filter((e) => !e.includes('pdf'));
  let pdfs = imageArr.filter((e) => e.includes('pdf'));

  return (
    <React.Fragment>
      {
        collapse === true &&
        <React.Fragment>
          <TableRow className='w-100 h-50'>
            <TableCell colSpan={10} align="center" className='w-100 h-50 pt-0' >
              <div className='row'>
                <div className='col-12 d-flex '>
                  <List className='col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 pt-0'>
                    {value.type1 === 'order' && <>
                      <Row style={{ background: '#ececef' }} className='pt-4 pr-4 pl-4'>
                        <Col md={8} xl={8} xs={8} sm={8} lg={8} style={{ textAlign: 'left' }} >
                          <h5 style={{ fontWeight: '500' }}>Seller: {helperFunction.textCapitalize(value.data.seller.sellerName)}</h5>
                        </Col>
                        <Col md={4} xl={4} xs={4} sm={4} lg={4} style={{ textAlign: 'right' }}>
                          <h5 onClick={() => setOpenLightBox(true)}>View Images</h5>
                          <Lightbox
                            open={openLightBox}
                            plugins={[Download, Fullscreen, Slideshow, Zoom]}
                            styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
                            close={() => setOpenLightBox(false)}
                            slides={images.map((data, i) => { return { src: data } })}
                          />
                        </Col>
                      </Row>
                      <Row style={{ background: '#ececef' }} className='pl-4 pr-4'>
                        <Col md={6} xl={6} xs={6} sm={6} lg={6} style={{ textAlign: 'left' }}>
                          <Row>
                            <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                              <h5 style={{ fontWeight: '500' }}>Original amount: <span style={{ color: 'green' }}>₹{(value.data.orderAmount).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</span></h5>
                            </Col>
                            <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                              <h5 style={{ fontWeight: '500' }}>Revised amount: <span style={{ color: 'green' }}>{`${value.data.revisedInvoiceAmt ? `₹${(value.data.revisedInvoiceAmt).toLocaleString('en-IN', { maximumFractionDigits: 2 })}` : 'N/A'}`}</span></h5>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} xl={6} xs={6} sm={6} lg={6} >
                          <Row style={{ justifyContent: 'end' }} className='pr-3'>
                            {
                              pdfs.length > 1 ?
                                <Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-0'}>
                                  <React.Fragment>
                                    <div ><h5>View PDFs<KeyboardArrowDownIcon onClick={(e) => setAnchorEl(e.currentTarget)} /></h5></div>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={() => setAnchorEl(null)}
                                      style={{ textAlign: 'end' }}
                                    >
                                      {pdfs.map((data, i) =>
                                        <a href={data} target='_blank' rel="noreferrer">
                                          <MenuItem onClick={() => setAnchorEl(null)}>{`PDF ${i + 1}`}</MenuItem>
                                        </a>)
                                      }
                                    </Menu>
                                  </React.Fragment>
                                </Col>
                                : pdfs.length > 0 ?
                                  <a href={pdfs[0]} target='_blank' rel="noreferrer"><Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-0'}><h5>View PDF</h5></Col></a> : ''
                            }
                            {value.status === 'close' &&
                              <Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-0'} onClick={() => { setOpenTrack(true) }}><h5>Track</h5></Col>
                            }
                          </Row>
                        </Col>
                      </Row>
                    </>
                    }
                    {(value.type1 === 'other') && <Row style={{ justifyContent: 'end', background: '#ececef' }} className='pr-4 pl-4 pt-4'>
                      {imageArr.length > 0 && <Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-3'}>
                        <h5 onClick={() => setOpenLightBox(true)}>View Images</h5>
                        <Lightbox
                          open={openLightBox}
                          plugins={[Download, Fullscreen, Slideshow, Zoom]}
                          styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
                          close={() => setOpenLightBox(false)}
                          slides={imageArr.map((data, i) => { return { src: data } })}
                        />
                      </Col>}
                      {
                        pdfs.length > 1 ?
                          <Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-3'}>
                            <Row>
                              <Col md={10} xl={10} xs={10} sm={10} lg={10}>
                              </Col>
                              <Col md={2} xl={2} xs={2} sm={2} lg={2}>
                                <div ><h5>View PDFs<KeyboardArrowDownIcon onClick={(e) => setAnchorEl(e.currentTarget)} /></h5></div>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={() => setAnchorEl(null)}
                                  style={{ textAlign: 'end' }}
                                >
                                  {pdfs.map((data, i) =>
                                    <a href={data} target='_blank' rel="noreferrer">
                                      <MenuItem onClick={() => setAnchorEl(null)}>{`PDF ${i + 1}`}</MenuItem>
                                    </a>)
                                  }
                                </Menu>
                              </Col>
                            </Row>
                          </Col> : pdfs.length > 0 ?
                            <a href={pdfs[0]} target='_blank' rel="noreferrer"><Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-3'}><h5>View PDF</h5></Col></a> : ''
                      }
                      {value.status === 'close' &&
                        <Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'end', cursor: 'pointer' }} className={'pr-3'} onClick={() => { setOpenTrack(true) }}><h5>Track</h5></Col>
                      }
                    </Row>}
                    {
                      commentsArray.map(comment =>
                        <Row className='mt-4 pr-4 pl-4' key={comment._id}>
                          <Col md={1} xl={1} xs={2} sm={2} lg={1}>
                            <div className="user-img-container rounded-circle avatar size-50 mr-3" style={{ background: helperFunction.getGradient(comment?.user_id?.backGroundColor) }}>
                              <span style={{ fontSize: 'x-large', color: 'white' }}>{(comment.name).includes('ADMIN') ? 'MS' : helperFunction.getNameInitials(`${comment.name}`)}</span>
                            </div>
                          </Col>
                          <Col md={11} xl={11} xs={10} sm={10} lg={11}>
                            <Row className='mb-1'>
                              <Col md={8} xl={8} xs={8} sm={8} lg={8} style={{ textAlign: 'left' }} >
                                <h5 className='pt-1 m-0 bold'>{(comment.name).includes('ADMIN') ? 'MEDIMNY STAFF' : (comment.name).toUpperCase()}</h5>
                              </Col>
                              <Col md={4} xl={4} xs={4} sm={4} lg={4} className='pt-1' style={{ textAlign: 'right' }}>
                                <h6>{` ${moment(comment.date).format("MMM Do YYYY, hh:mm A")} `}</h6>
                              </Col >
                            </Row>
                            <Row>
                              <Col md={12} xl={12} xs={12} sm={12} lg={12} style={{ textAlign: 'left' }}>
                                <h6>{comment.description}</h6>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }
                    {value.status === 'open' &&
                      <Row className='mt-3 pl-4 pr-4'>
                        <Col md={9} xl={9} xs={7} sm={7} lg={9}>
                          <Input type="text" name="issueReply" id="exampleSelect" placeholder='Reply here.' onChange={(e) => handleChange(e, 'comment')} value={comment} onKeyDown={handleKeyDown} />
                        </Col>
                        <Col md={1} xl={1} xs={1} sm={1} lg={1} className='pl-0 pr-0 pt-2'>
                          <span className="jr-btn text-uppercase text-primary" onClick={() => handleReply()} disabled={submitBoolean} style={{ cursor: 'pointer' }}>
                            Reply
                          </span>
                        </Col>
                        <Col md={1} xl={1} xs={3} sm={3} lg={1} className='pl-0 pr-0 pt-2'>
                          <span className={'text-warning text-uppercase'} style={{ padding: '2px 2px', cursor: 'pointer' }} onClick={() => { setAddPopup(true) }}>
                            Add files
                          </span>
                        </Col>
                        <Col md={1} xl={1} xs={1} sm={1} lg={1} className='pl-0 pr-0 pt-2'>
                          <span className={'text-success text-uppercase'} style={{ padding: '2px 2px', cursor: 'pointer' }} onClick={() => { setOpenTrack(true) }}>
                            Track
                          </span>
                        </Col>
                      </Row>}
                  </List>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </React.Fragment>
      }
      <Dialog open={addPopup} onClose={closePopup} fullWidth={true} maxWidth='md'>
        <DialogTitle>
          Add Images
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col>
              <Drop handleFiles={getFiles} accept="image/*, application/pdf" />
              {uploadedFileArr.length > 0 && <div className="dropzone-content">
                <ul className="mb-2" style={{ listStyleType: 'none' }}>
                  {
                    uploadedFileArr.map((file, i) => <li key={file.path}>
                      <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
                    </li>
                    )
                  }
                </ul>
              </div>
              }
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closePopup()} color='secondary'>Cancel</Button>
          {newFileUpload.length !== 0 && !submitBoolean && !loader ?
            <Button onClick={() => add()} color='primary' disabled={submitBoolean}>Add</Button> :
            loader ? <CircularProgress size={30} /> :
              <Button disabled={true} color='primary'>Add</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={openTrack} onClose={closePopup} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{"Timeline"}</DialogTitle>
        <DialogContent>
          <TrackOrder activities={value && value.data.activities} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup} color="secondary" autoFocus> Close </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}


export default CollapseComponent

