import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { getOrder } from 'actions/order';
import { Label, Input, FormGroup } from 'reactstrap';
import ButtonGroup from '@mui/material/ButtonGroup';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import ReactStrapTextField from 'components/ReactStrapTextField';
import ReactstrapSelectField from 'components/reactstrapSelectField';
import { Field, Form } from 'react-final-form'
import { required, invoiceValue, invoiceValueOnline, requiredddd } from 'constants/validations';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import InvoiceValidate from './InvoiceValidate';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import PrintManifest from './PrintManifest'
import AddImages from './imageupload'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import helper from 'constants/helperFunction';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import AppConfig from "constants/config";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const OrderList = (props) => {
  const [params] = useSearchParams()
  let navigate = useNavigate();
  const tab = params && params.get("tab") ? Number(params.get("tab")) : 0
  const perPage = 100
  const [open, setOpen] = useState(false)
  const [orderDetails, setOrderDetails] = useState('')
  const searchedText = params && params.get("searchO") ? params.get("searchO") : ''
  const page = params && Number(params.get("pageO")) ? Number(params.get("pageO")) : 0
  const [from, setFrom] = useState(params && params.get("from_dateO") ? moment(params.get("from_dateO")) : moment().subtract(1, 'months'))
  const [to, setTo] = useState(params && params.get("to_dateO") ? moment(params.get("to_dateO")) : moment())
  const [status, setStatus] = useState(params && params.get("statusO") ? params.get("statusO") : '')
  const [type, setType] = useState(params && params.get("typeO") ? params.get("typeO") : '')
  const [orderArr, setOrderArr] = useState([])
  const [order, setOrder] = useState([])
  const [openPrint, setOpenPrint] = useState(false)
  const openCheckbox = params && params.get("openCheckbox") ? true : ''
  const manifest = params && params.get("manifest") ? false : ''
  const [set, setSet] = useState(false)
  const [add, setAdd] = useState(false)
  const [order_id, setOrderId] = useState('')
  let { orderData, loading } = props;

  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("Ready For Dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-yellow" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("Requested") ? "text-white bg-magenta" : status.includes("New") ? "text-white bg-danger" : "text-white bg-info";
  }

  const openNewWindow = (data) => {
    if (data) {
      window.open(`${AppConfig.baseUrl}users/generateLabel1?orderId=${data.order_id}`, '_blank');
    }
  }

  const handleUploadImg = (data) => {
    setAdd(true)
    setOrderId(data.order_id)
  }

  const button = (orderId, status) => {
    return (
      <React.Fragment>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {
            !openCheckbox &&
            <>
              <Button variant="outlined" className={'text-warning'} >
                <NavLink to={`/seller/orders/orderDetails/${orderId.order_id}`} style={{ color: 'inherit' }}>
                  Details
                </NavLink>
              </Button>
              <Button variant="outlined" className={'text-success'}>
                <a target='_blank' rel="noreferrer" href={`/seller/orders/trackOrder/${orderId.order_id}`}
                  style={{ textDecoration: 'none', color: 'inherit', cursor: 'auto' }} > Track</a>
              </Button>
            </>
          }
          {
            status === 'Processed' ?
              <Button variant="outlined" disabled={!orderId.docket} onClick={() => openNewWindow(orderId)}>
                Docket
              </Button>
              :
              <Button variant="outlined" disabled={disableChangeRequest(orderId, status)} onClick={() => openModal(orderId)}>
                Process
              </Button>
          }
          {
            status === 'Processed' && openCheckbox && !orderId.seller_img ?
              <Button variant="outlined" className={'text-info'} onClick={(e) => handleUploadImg(orderId)}>
                Upload
              </Button>
              :
              openCheckbox &&
              <Button variant="outlined" className={'text-warning'} >
                Uploaded
              </Button>
          }
        </ButtonGroup>
      </React.Fragment>
    )
  }

  useEffect(() => {
    let data = {
      page: (params && Number(params.get("pageO")) ? Number(params.get("pageO")) : 0) + 1,
      perPage,
      filter: '',
      searchText: params && params.get("searchO") ? params.get("searchO") : '',
      from_date: moment(params && params.get("from_dateO") ? params.get("from_dateO") : moment().subtract(1, 'months')).format('YYYY-MM-DD'),
      to_date: moment(params && params.get("to_dateO") ? params.get("to_dateO") : moment()).add(1, 'days').format('YYYY-MM-DD'),
      status: (params && params.get("statusO") ? params.get("statusO") : '').split(','),
      type: params && params.get("typeO") ? params.get("typeO") : '',
      isManifested: params && params.get("manifest") ? false : '',
      tab: 'orderListing'
    }
    if (tab === 0) {
      props.getOrder({ data })
    }
  }, [params])

  useEffect(() => {
    if (props.tabValue === 0) {
      let obj = { from_dateO: from, to_dateO: to, pageO: page, searchO: searchedText, statusO: status, typeO: type, tab: 0 }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      navigate(`/seller/orders?${url}`, { replace: true })
    }
  }, [props.tabValue])

  const changePage = (page) => {
    let obj = { from_dateO: from, to_dateO: to, pageO: page, searchO: searchedText, statusO: status, typeO: type, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  };

  const openModal = (orderId) => {
    setOpen(true)
    setOrderDetails(orderId)
    setSet(false)
  }

  const handleSearch = (searchText) => {
    let obj = { from_dateO: from, to_dateO: to, pageO: 0, searchO: searchText, statusO: status, typeO: type, manifest: manifest, openCheckbox: openCheckbox, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  };

  const handleCloseSearch = () => {
    let obj = { from_dateO: from, to_dateO: to, pageO: 0, searchO: '', statusO: status, typeO: type, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const resetFilter = () => {
    let obj = { from_dateO: '', to_dateO: '', pageO: 0, searchO: searchedText, statusO: '', typeO: '', tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleClickImages = (key, value) => {
    setAdd(!add)
  }

  const handleDateChange = (date, key) => {
    if (key === "from") {
      setFrom(date)
    }
    if (key === "to") {
      setTo(date)
    }
  }


  const onSubmit = async (values) => {
    setSet(true)
    let data = {
      invoice: Number(values.invoice),
      weight: values.weight,
      invoiceNumber: values.invoiceNumber,
      selectedDate: moment().format(),
      orderId: orderDetails.order_id,
      status: 'Processed',
      length: orderDetails.length ? orderDetails.length : 25,
      breadth: orderDetails.breadth ? orderDetails.breadth : 25,
      height: orderDetails.height ? orderDetails.height : 25,
      uploadedInvoice: values.uploadedInvoice
    }
    await axios.post('/order/proccessCancelOrder', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
        setSet(false)
      } else {
        NotificationManager.success(result.data.title);
        props.getOrder({
          data: {
            page: page + 1,
            perPage: perPage,
            from_date: moment(from).format('YYYY-MM-DD'),
            to_date: moment(to).add(1, 'days').format('YYYY-MM-DD'),
            tab: 'orderListing',
            searchText: searchedText,
            status: status,
            type: type,
            isManifested: manifest
          }
        });
        if (result.data && result.data.detail && result.data.detail.docket) {
          openNewWindow(result.data.detail)
        }
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
    setOpen(false);
  }

  const disableChangeRequest = (data, status) => {
    if (data && data.user_id && data.user_id.user_status !== 'active') {
      return true
    } else if ((status === 'Requested' || status === 'Approved' || status === 'Processed' || status === "Cancelled")) {
      return true
    } else {
      return false
    }
  }

  const applyFilter = () => {
    let obj = { from_dateO: from, to_dateO: to, pageO: 0, searchO: searchedText, statusO: status, typeO: type, tab: 0, key: Math.random() * 10 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const checkBox = (data) => {
    return openCheckbox &&
      <Tooltip title={!data.seller_img ? "Please first upload the Images" : ''} placement="top-start">
        <div>
          <Checkbox
            checked={orderArr.findIndex(value => value._id === data._id) > -1 ? true : false}
            onChange={() => handleChangeOrder(data)}
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={!data.seller_img}
          /> </div>
      </Tooltip>
  }

  const handleChangeOrder = (data) => {
    let temp = [...orderArr]
    let index = temp.findIndex(val => val._id === data._id)
    if (index > -1) {
      temp.splice(index, 1)
    } else {
      temp.push(data)
    }
    setOrderArr(temp)
  }

  const handlePrint = async () => {
    const orderId = []
    setOpenPrint(true)
    orderArr.map((data) => {
      orderId.push({ _id: data._id, order_id: data.order_id, amount: data.total_amount, awb: data.shipping_awb })
    })
    setOrder(orderId)
  }

  const generateManifest = () => {
    let obj = { from_dateO: from, to_dateO: to, pageO: page, searchO: searchedText, statusO: status, typeO: type, manifest: 'false', openCheckbox: true, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const cancelManifest = () => {
    setOpenPrint(false)
    let obj = { from_dateO: from, to_dateO: to, pageO: page, searchO: searchedText, statusO: status, typeO: type, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/orders?${url}`, { replace: true })
  }

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: orderData && orderData.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    customToolbar: () => {
      return (
        <React.Fragment>
          {
            !openCheckbox ?
              <Button variant="outlined" className={'text-warning'} onClick={(e) => generateManifest(e)} >
                Generate Manifest
              </Button>
              :
              <Button variant="outlined" disableA={orderArr.length === 0} className={orderArr.length > 0 ? 'text-success' : 'text-grey'} onClick={(e) => handlePrint(e)} disabled={orderArr.length > 0 ? false : true}>Print Manifest</Button>
          }
          {
            openCheckbox &&
            <Button variant="outlined" className={'text-danger ml-2'} onClick={(e) => cancelManifest(e)} >
              Cancel
            </Button>
          }
        </React.Fragment>
      );
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderId",
      label: "Order",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'from')}
                        name="date"
                        label="FROM"
                        value={from}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'to')}
                        name="date"
                        label="TO"
                        value={to}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>
                    <Input type="select" value={status} onChange={(e) => setStatus(e.target.value)} name="status" id="status">
                      <option value="" >Select Status</option>
                      <option value="New" >New</option>
                      <option value="Processed" >Processed</option>
                      <option value="Requested" >Requested</option>
                      <option value="Manifested" >Manifested</option>
                    </Input>
                  </FormGroup>
                </Grid>
                <Grid item md={6}>
                  <FormGroup>
                    <Label for="exampleSelect">Type</Label>
                    <Input type="select" value={type} onChange={(e) => setType(e.target.value)} name="type" id="type">
                      <option value="" >Select Type</option>
                      <option value="Bulk" >Bulk Prepaid</option>
                      <option value="COD" >COD</option>
                      <option value="Online" >Prepaid</option>
                    </Input>
                  </FormGroup>
                </Grid>
              </Grid>
            </React.Fragment>
          ),
        },
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  let data = []
  orderData && orderData.detail && orderData.detail.length > 0 && orderData.detail.map((data1, index) => {
    const ShortExp = data1.products.filter(data => data.expiry_status === 'Short Expiry') 
    data.push([
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        {ShortExp.length > 0 && <Tooltip title={`Short expiry`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine('Short Expiry')}`}>&nbsp;&nbsp;</span>
        </Tooltip> }
        {data1.orderType ? <Tooltip title={`${data1.orderType}`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine(data1.orderType)}`}>&nbsp;&nbsp;</span>
        </Tooltip> : <span style={helper.styleForDiv}>&nbsp;&nbsp;</span>}
        {data1.serviceName ? <Tooltip title={`${data1.serviceName}`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine2(data1.serviceName)}`}>&nbsp;&nbsp;</span>
        </Tooltip> :
          <span style={helper.styleForDiv}>&nbsp;&nbsp;</span>
        }
      </span>,
      <span>{checkBox(data1)}</span>,
      <div>
        <h4><span className="font-weight-bolder">{data1.products.length} {data1.products.length > 1 ? 'items' : 'item'} by </span>{data1.user_id.company_name} </h4>
        <p className="small">{data1.order_id}{data1.seller_invoice ? ` | ${data1.seller_invoice}` : ''} | {data1.isBulk ? 'Bulk Prepaid' : data1.paymentType === 'Online' ? 'Prepaid' : data1.paymentType}
          {(moment().diff(moment(data1.createdAt), 'hours')) <= 48 ?
            <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'green' }}></span> :
            (moment().diff(moment(data1.createdAt), 'hours')) >= 48 && (moment().diff(moment(data1.createdAt), 'hours')) <= 72 ?
              <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'orange' }}></span> :
              (moment().diff(moment(data1.createdAt), 'hours')) >= 72 && data1.order_status[data1.order_status.length - 1].status === 'New' ?
                <span className="size-8 rounded-circle d-inline-block ml-1 blink_me" style={{ backgroundColor: 'red' }}></span> : ''
          }</p>
      </div>,
      <div><p className={'ptrPriceFont1 priceColorInventory m-0'}>
        ₹{(Number(data1.total_amount)).toFixed(2)}
      </p></div>,
      <div key={'recent'} className={` badge text-uppercase ${statusStyle(data1.requested !== "Requested" ? data1.order_status[data1.order_status.length - 1].status : 'Requested')}`}>{data1.requested === "Requested" ? 'Requested' : data1.order_status[data1.order_status.length - 1].status}</div>,
      moment(data1.createdAt).format(' D MMM, YYYY h:mm a'),
      button(data1, data1.requested), <>{data1._id}</>
    ])
  })

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  return (
    <div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {
          loading ?
            <div className="loader-view" style={{ height: 'calc(100vh - 238px)' }}>
              <CircularProgress />
            </div>
            : null
        }
        {
          loading === true ?
            null :
            <ThemeProvider theme={createTheme({ components: styles })}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
                style={{ borderRadius: '0px !important' }}
              />
            </ThemeProvider>
        }
        {openPrint && <PrintManifest open={openPrint} handleClick={cancelManifest} orderArr={order} />}
        <Dialog open={open} onClose={handleRequestClose} fullWidth={true} maxWidth={'sm'}>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              length: orderDetails.length ? orderDetails.length : 25,
              breadth: orderDetails.breadth ? orderDetails.breadth : 25,
              height: orderDetails.height ? orderDetails.height : 25,
              invoice: orderDetails.total_amount,
              invoice2: orderDetails.total_amount,
              uploadedInvoice: '',
              check1: false,
              check2: false,
              check3: false,
            }}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle className='pb-0'>
                  Process Order
                </DialogTitle>
                <DialogContent>
                  <React.Fragment>
                    <FormGroup>
                      {
                        orderDetails.paymentType === 'COD' ?
                          <Field
                            id="invoice"
                            name="invoice"
                            type="text"
                            component={ReactStrapTextField}
                            label={"Value of invoice ( 10% modification allowed )"}
                            validate={(val) => invoiceValue(val, values)}
                          />
                          :
                          <Field
                            id="invoice"
                            name="invoice"
                            type="text"
                            component={ReactStrapTextField}
                            label={"Value of invoice ( 10% modification allowed )"}
                            validate={invoiceValueOnline}
                          />
                      }
                    </FormGroup>
                    <FormGroup>
                      <Field
                        id="invoiceNumber"
                        name="invoiceNumber"
                        type="text"
                        component={ReactStrapTextField}
                        label="Invoice Number"
                        validate={composeValidators(required, requiredddd)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        id="length"
                        name="length"
                        type="text"
                        component={ReactStrapTextField}
                        label="Length (cm)"
                        validate={required}
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        id="breadth"
                        name="breadth"
                        type="text"
                        component={ReactStrapTextField}
                        label="Breadth (cm)"
                        validate={required}
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        id="height"
                        name="height"
                        type="text"
                        component={ReactStrapTextField}
                        label="Height (cm)"
                        validate={required}
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        name="weight"
                        component={ReactstrapSelectField}
                        label="Weight"
                        validate={required}
                        type="select"
                      >
                        <option value=" ">Select weight</option>
                        <option value='0.5'>0.5 kg</option>
                        <option value='1'>1.0 kg</option>
                        <option value='1.5'>1.5 kg</option>
                        <option value='2'>2.0 kg</option>
                        <option value='2.5'>2.5 kg</option>
                        <option value='3'>3.0 kg</option>
                        <option value='3.5'>3.5 kg</option>
                        <option value='4'>4.0 kg</option>
                      </Field>
                    </FormGroup>
                    {
                      orderDetails && orderDetails.orderType && orderDetails.orderType.toLowerCase() === 'cool chain' ?
                        <>
                          <FormGroup className='mb-0'>
                            <Checkbox color="primary" name="check1" onClick={(e) => form.change("check1", !values.check1)} />
                            I confirm that I have used Thermocol box for packing this order.
                          </FormGroup>
                          <FormGroup className='mb-0'>
                            <Checkbox color="primary" name="check2" onClick={(e) => form.change("check2", !values.check2)} />
                            I confirm that I have put 3 frozen chillpacks in this order.
                          </FormGroup>
                          <FormGroup className='mb-0'>
                            <Checkbox color="primary" name="check3" onClick={(e) => form.change("check3", !values.check3)} />
                            I undertake full responsibility for RTO due to non compliance.
                          </FormGroup>
                        </> : ''
                    }
                    <FormGroup>
                      <Field
                        name="uploadedInvoice"
                        component={InvoiceValidate}
                        validate={required}
                        label="UploadInvoice"
                      />
                    </FormGroup>
                  </React.Fragment>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  {
                    orderDetails && orderDetails.orderType && orderDetails.orderType.toLowerCase() === 'cool chain' && !set && values.check1 && values.check2 && values.check3 ?
                      <Button type="submit" color='primary'>
                        Process
                      </Button> :
                      ((orderDetails && orderDetails.orderType === '') || (orderDetails && orderDetails.orderType === undefined) || (orderDetails && orderDetails.orderType.toLowerCase() !== 'cool chain')) && !set ?
                        <Button type="submit" color='primary'>
                          Process
                        </Button> :
                        set ? <Button color='primary'>
                          Processing...
                        </Button> :
                          <Button color='primary' disabled={true}>
                            Process
                          </Button>
                  }
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
        {
          add && <AddImages
            getOrder={getOrder}
            page={page}
            add={add}
            menifestId={order_id}
            handleClick={handleClickImages}
          />
        }
      </div>
    </div>

  );
}

const mapStateToProps = ({ order }) => {
  const { orderData, loading } = order;
  return { orderData, loading }
};

export default connect(mapStateToProps, { getOrder })(OrderList);