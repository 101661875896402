import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables"
import Button from '@mui/material/Button';
import ContainerHeader from 'components/ContainerHeader';
import { Label } from 'reactstrap';
import AddInventory from './addInventory';
import EditInventory from './editInventory';
import DeleteInventory from './deleteInventory';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import { connect } from 'react-redux';
import { getInventoryList } from 'actions/seller'
import './index.css'
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Grid from '@mui/material/Grid';
import SellerContainer from "components/sellerContainer"
import { useLocation } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";
import axios from 'constants/axios';
import AppConfig from "constants/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Inventory = (props) => {
  const location = useLocation()
  const type = location.search.substring(1) === "activeSelling" ? "Active Selling Products" : location.search.substring(1) === "shortExpiry" ? "Short expire products" : location.search.substring(1) === "slowMoving" ? "Slow moving Products" : location.search.substring(1) === "outOfStock" ? "Out of stock Products" : 'none'
  const perPage = 50
  const [add, setAdd] = useState(false)
  const [edit, setEdit] = useState(false)
  const [hide, setHide] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(['Category'])
  const [filter, setFilter] = useState(type)
  const [page, setPage] = useState(0)
  const [searchedText, setSearchedText] = useState('')
  const [editData, setEditData] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [visible, setVisible] = useState('none')
  const [inventoryId, setInventoryId] = useState('')
  const [categoriesList ,setCategories ] = useState([])

  const button = (data) => {
    let disabled = (data.expiry_status === 'Delist' || data.expiry_status === 'Expired') ? true : false;
    let blur = data.hiddenBy && data.hiddenBy === 'admin' ? true : false;
    return blur ? <Tooltip className="d-inline-block" id="tooltip-right" title={<h6 className="text-white"
      style={{ marginTop: "10px" }}> {'Hidden by Admin'} </h6>} placement="top">
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button className={blur ? 'text-grey' : 'text-primary'} disabled={blur} onClick={() => handleClick('edit', data)} > Edit</Button>
        <Button className={blur ? 'text-grey' : 'text-warning'} disabled={blur} onClick={() => handleClick('hide', data)}> {data.isHidden ? 'Unhide' : 'Hide'}</Button>
        <Button className={blur ? 'text-grey' : 'text-danger'} disabled={blur} onClick={() => handleClick('delete', data)}> Delete</Button>
      </ButtonGroup></Tooltip> :
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button className={disabled ? 'text-grey' : 'text-primary'} disabled={disabled} onClick={() => handleClick('edit', data)} > Edit</Button>
        <Button className={disabled ? 'text-grey' : 'text-warning'} disabled={disabled} onClick={() => handleClick('hide', data)}> {data.isHidden ? 'Unhide' : 'Hide'}</Button>
        <Button className={disabled ? 'text-grey' : 'text-danger'} disabled={disabled} onClick={() => handleClick('delete', data)}> Delete</Button>
      </ButtonGroup>
  }

  const handleClick = (key, data) => {
    setEditData(data)
    if (key === 'edit') {
      setInventoryId(data ? data._id : '')
      setEdit(!edit)
    }
    if (key === 'hide') {
      setHide(!hide)
    }
    if (key === 'delete') {
      setDeleteOpen(!deleteOpen)
    }
    if (key === 'add') {
      setAdd(!add)
    }
  }

  const handleSelectChange = (e) => {
    e.preventDefault();
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'Category') > -1 ? tempSelectedCat.findIndex((e) => e === 'Category') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setSelectedCategory(tempSelectedCat);
  }

  const handleFiltersChange = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
  }
  const fetchApi = async () => {
    try {
      const result = await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}inventory/get_filters`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token')
        }
      });
      if (result.data.error) {
        console.log("error", result.data);
      } else {
        setCategories(result.data.detail.categories);
      }
    } catch (error) {
      console.log("error catch", error);
    }
  };
  useEffect(() => {
    let data = {
      page: 1,
      perPage: 50,
      filter: filter === 'none' ? '' : filter,
      searchText: '',
      selectedCategory: []
    }
    props.getInventoryList({ data })
    fetchApi();
  }, [])

  const changePage = (page) => {
    let pages = page + 1;
    let tempSelectedCat = [...selectedCategory]
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'Category') > -1 ? tempSelectedCat.findIndex((e) => e === 'Category') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    let data = {
      page: pages,
      perPage: perPage,
      searchText: searchedText,
      filter: filter === 'none' ? '' : filter,
      selectedCategory: tempSelectedCat,
      visible: visible === 'visible' ? false : visible === 'hidden' ? true : ''
    }
    props.getInventoryList({ data })
    setPage(page)
  };

  const handleSearch = (searchText) => {
    let data = {
      searchText: searchText,
      page: 1,
      perPage: perPage,
      visible: visible === 'visible' ? false : visible === 'hidden' ? true : '',
      filter: filter === 'none' ? '' : filter,
      selectedCategory: selectedCategory && selectedCategory[0] === 'Category' ? [] : selectedCategory
    }
    props.getInventoryList({ data })
    setPage(0)
    setSearchedText(searchText)
  };

  const resetFilter = () => {
    let data = {
      page: 1,
      perPage: 50,
      filter: '',
      visible: '',
      selectedCategory: [],
      searchText: searchedText ? searchedText : '',
    }
    props.getInventoryList({ data })
    setPage(0)
    setSelectedCategory(['Category'])
    setFilter('none')
    setShowFilter(false)
    setVisible('none')
  }

  const applyFilter = () => {
    let tempSelectedCat = [...selectedCategory]
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'Category') > -1 ? tempSelectedCat.findIndex((e) => e === 'Category') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);

    let data = {
      page: 1,
      perPage: perPage,
      searchText: searchedText ? searchedText : '',
      filter: filter === 'none' ? '' : filter,
      selectedCategory: tempSelectedCat,
      visible: visible === 'visible' ? false : visible === 'hidden' ? true : ''
    }
    props.getInventoryList({ data });
    setPage(0)
    setShowFilter(true)
  }

  const handleForHidden = (e, key) => {
    setVisible(e.target.value)
  }

  const apiCall = () => {
    let data = {
      page: Number(page) + 1,
      perPage: perPage,
      searchText: searchedText ? searchedText : '',
      filter: filter === 'none' ? '' : filter,
      selectedCategory: selectedCategory && selectedCategory[0] === 'Category' ? [] : selectedCategory,
      visible: visible === 'visible' ? false : visible === 'hidden' ? true : ''
    }
    props.getInventoryList({ data });
  }

  const { listInventory, loading } = props;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: listInventory.length > 0 && listInventory[0].metadata.length > 0 ? listInventory[0].metadata[0].total : 0,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: false,
        reset: false,
      },
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid className="mt-2" container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const statusStyle = (status) => {
    return status.includes("Out of Stock") ? "text-white bg-danger" : status.includes("In stock") ? "text-white bg-success" : status.includes("Expired") ? "text-white bg-grey" : status.includes("Active") ? "text-white bg-info" : "text-white bg-grey";
  }

  let data = [];
  listInventory.length > 0 && listInventory[0].data.length > 0 && listInventory[0].data.map((data1, index) => {
    data.push([
      data1.productName ? data1.productName : 'N.A.', "₹" + (Number(data1.MRP)).toFixed(2),
      data1.Discounts ? data1.Discounts.type === 'Discount'
        ? <div>
          <span className={'originalPrice '}>₹{(Number(data1.PTR).toFixed(2))}</span>
          <span >₹{((Number(data1.PTR) - (Number(data1.PTR) / 100 * Number(data1.Discounts.discount_per)))).toFixed(2)}</span></div>
        : <div>
          <span >₹{(Number(data1.PTR)).toFixed(2)}</span>
        </div>
        : "₹" + (Number(data1.PTR)).toFixed(2),
      <div>{(data1.quantity < data1.min_order_quantity) ? <span className={` badge text-uppercase ${statusStyle("Out of Stock")}`}>Out of Stock</span> : data1.quantity}</div>,
      <div key={'recent'} >
        {(data1.expiry_status === 'Usable') ?
          <span className={` badge text-uppercase ${statusStyle("In stock")}`}>{data1.expiry_status}</span> :
          (data1.expiry_status === 'Short Expiry') ?
            <span className={` badge text-uppercase ${statusStyle("Out of Stock")}`}>{data1.expiry_status}</span> :
            (data1.expiry_status === 'Delist' || data1.expiry_status === 'Expired') ?
              <span className={` badge text-uppercase ${statusStyle("Expired")}`}>{data1.expiry_status}</span> : ''
        }
      </div>,
      button(data1)])
  })

  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "MRP",
      label: "MRP",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "PTR",
      label: "PTR",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className="w-100 categoryFilter">
                  <Label className="mb-0" for="Filters">Status</Label>
                  <Select
                    value={filter}
                    onChange={(e) => handleFiltersChange(e)}
                    input={<Input disableUnderline={true} className="form-control" id="Filters" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 200
                        },
                      },
                    }}
                  >
                    <MenuItem value="none">
                      All
                    </MenuItem>
                    <MenuItem value='Active Selling Products'>Active Selling </MenuItem>
                    <MenuItem value='Slow moving Products'>Slow Moving </MenuItem>
                    <MenuItem value='Short expire products'>Short Expire </MenuItem>
                    <MenuItem value='Out of stock Products'>Out Of Stock </MenuItem>
                    <MenuItem value='Expired'>Expired </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="w-100 categoryFilter">
                  <Label className="mb-0" for="category">Category</Label>
                  <Select
                    multiple
                    id="selectCategory"
                    value={selectedCategory}
                    onChange={(e) => handleSelectChange(e)}
                    input={<Input disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 200
                        },
                      },
                    }}
                  >
                    <MenuItem
                      key={'select Category'}
                      value={'Category'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select Category
                    </MenuItem>
                    {categoriesList && categoriesList.map(name => (
                      <MenuItem
                        key={name._id}
                        value={name._id}
                        className='putIconAfter '
                        style={{
                          fontWeight: selectedCategory.indexOf(name.name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className="w-100 categoryFilter">
                  <Label className="mb-0" for="Filters">Hidden</Label>
                  <Select
                    value={visible}
                    onChange={(e) => handleForHidden(e, 'visible')}
                    input={<Input disableUnderline={true} className="form-control" id="Filters" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 200
                        },
                      },
                    }}
                  >
                    <MenuItem value="none">
                      All
                    </MenuItem>
                    <MenuItem value='visible'>Visible </MenuItem>
                    <MenuItem value='hidden'>Hidden </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ),
        },
      },
    }
  ];

  const catNameArr = []
  if (Array.isArray(categoriesList)) {
    for (const categoryId of selectedCategory) {
      const category = categoriesList.find(cat => cat._id == categoryId);
      if (category) {
        catNameArr.push(category.name);
      }
    }
  }
  
  return (
    <SellerContainer>
      <ContainerHeader
        title={showFilter ? (catNameArr.findIndex((e) => e === 'Category') > -1 && filter === 'none') ? "Inventory" : (catNameArr.findIndex((e) => e === 'Category') > -1 && filter !== 'none') ? `Inventory (Showing ${filter.toLowerCase()})` : (catNameArr.findIndex((e) => e === 'Category') < 0 && filter === 'none') ? `Inventory (Showing results for ${catNameArr.join(', ').toLowerCase()})` :
          (catNameArr.length === 0 && filter) ?
            `Inventory (Showing ${filter.toLowerCase()}) ` :
            `Inventory (Showing ${filter.toLowerCase()} for ${catNameArr.join(', ').toLowerCase()})` : 'Inventory'}
        title1="inventory"
      />
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              title={<Button className={'text-primary'} onClick={() => handleClick('add')}> Add Inventory +</Button>}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
      }
      {
        add &&
        <AddInventory page={page} perPage={perPage} buttonType={'add'} add={add} title={'Inventory'} handleClick={handleClick} callMount={apiCall} />
      }
      {
        edit &&
        <EditInventory Inventory_id={inventoryId} editData={editData} page={page} perPage={perPage} buttonType={'edit'} edit={edit} title={'Edit Inventory'} handleClick={handleClick} callMount={apiCall} />
      }
      {
        deleteOpen &&
        <DeleteInventory page={page} perPage={perPage} delete={deleteOpen} editData={editData} deleteFor={'inventory'} handleClick={handleClick} callMount={apiCall} />
      }
      {
        hide &&
        <DeleteInventory page={page} perPage={perPage} delete={hide} editData={editData} hideFor={'inventory'} handleClick={handleClick} callMount={apiCall} />
      }
    </SellerContainer>
  );
}

const mapStateToProps = ({ seller }) => {
  const { listInventory, productCategories, loading } = seller;
  return { listInventory, productCategories, loading }
};

export default connect(mapStateToProps, { getInventoryList })(Inventory);