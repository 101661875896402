import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from 'components/Header';
import Sidebar from 'containers/SideNav';
import { getSidebar } from 'actions/seller'
import { isIOS, isMobile } from 'react-device-detect';
import { useNavigate, useLocation } from "react-router-dom"

const SellerContainer = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(true)
    useEffect(() => {
            props.getSidebar({ navigate, location })
    }, [])

    const drawerStyle = 'collapsible-drawer'

    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height')
    }

    return (
        <div className={`app-container ${drawerStyle}`}>
            <Sidebar open={open} setOpen={setOpen}/>
            <div className="app-main-container">
                <div className={`app-header `}>
                    <Header open={open} setOpen={setOpen}/>
                </div>
                <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                        <div className="app-wrapper">
                            {props.children}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

const mapStateToProps = ({ settings, seller }) => {
    const { sidebar } = seller;
    return { sidebar }
};
export default connect(mapStateToProps, { getSidebar })(SellerContainer);