import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getUserDetail } from 'actions'
import { getCartDetails, getMediWallet, removeFromCart } from 'actions/buyer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Popover, PopoverBody, PopoverHeader, Col, Row } from 'reactstrap';
import PopOverInCart from './PopOverInCart'
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import helpertFn from 'constants/helperFunction';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';

const sellerTextStyle = {
  fontSize: 12,
}

const CartItems = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [index, setIndex] = useState('')
  const [removeOpen, setRemoveOpen] = useState(false)
  const [removedProd, setRemovedProd] = useState('')

  const handleRemoveFromCart = (data) => {
    setIndex(data.inventory_id)
    setRemoveOpen(true)
    setRemovedProd(data)
  }

  const handleConfirmRemove = () => {
    add(removedProd);
    props.handleRem(index)
    setRemoveOpen(false)
    setIndex('')
  }

  const onCloseRemove = () => {
    props.handleRem(index)
    setRemoveOpen(false)
    setIndex('')
  }

  const onCloseRemove1 = () => {
    setRemoveOpen(false)
    setIndex('')
    setRemovedProd('')
  }

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const closepop = () => {
    setPopoverOpen(false)
  }

  const add = async (val) => {
    try {
      let data = {
        product: val.inventory.productName.toUpperCase(),
        id: val.inventory.product_id._id,
        quantity: Number(val.quantity)
      }
      const result = await axios.post('shortBook/addShortBook', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('buyer_token')
        }
      })
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
      }
      setRemovedProd('')
    } catch (error) {
      NotificationManager.error(error.message || "Something went wrong, please try again!")
    }
  }

  const { data, index: propIndex, AllItemsTotalPrice, AllItemsTotalGST } = props;
  let gstValue = data.inventory.ePTR / 100 * data.gstPercentage;
  let gst = data.inventory.ePTR / 100 * data.gstPercentage;
  let totalPtr = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? ((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity) * data.inventory.ePTR) : data.inventory.ePTR * data.quantity;
  let payable = (data.discount.name === 'Same' || data.discount.name === 'SameAndDiscount') ? Math.round((data.quantity / data.discount.discount_on_product.purchase * (data.discount.discount_on_product.bonus) + data.quantity) * (data.inventory.ePTR + gstValue)) : Math.round((data.inventory.ePTR + gstValue) * data.quantity)
  let strikePrice = (data.inventory.PTR * data.quantity).toFixed(2)
  let finalUnitPrice = (data.inventory.ePTR + gstValue);
  const productName = data && data.title && ((data.title).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()

  return (<React.Fragment>
    {isMobile === false ?
      <div className={`user-list d-flex flex-row card-strip pb-2 pt-2`}>
        <div>
          <img
            alt='User'
            src={`${helpertFn.productImg(data.image)}`}
            className="productImageStyle myCartUserAvatar mr-3"
            onError={addDefaultSrc}
          />
          {
            data.inventory && data.inventory.isJumboDeal && data.inventory.isJumboDeal === true &&
            <div className='myCartUserAvatar text-white' style={{ margin: "5px 0px", padding: "0px 5px", backgroundColor: props.color, width: '90%', textAlign: 'center' }}>Jumbo Deal</div>
          }</div>
        <div className="description w-100">
          <Row>
            <Col md={6} xl={6} lg={6} sm={6} xs={6}>
              <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${data.inventory_id}`}>
                <p className="text-primary h3 mb-2">{data.title}</p>
              </NavLink>
            </Col>
            <Col md={6} xl={6} lg={6} sm={6} xs={6} className={'pull-right'}>
              {
                data.discount && (data.discount.type === "Discount" || data.discount.type === "SameAndDiscount" || data.discount.type === "DifferentAndDiscount") ?
                  <div style={{ display: "flex" }} className={'pull-right'}>
                    <h3 className="font-weight-bold">Total: </h3>
                    &nbsp;<h3><strike>₹{strikePrice}</strike></h3>
                    &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-2">₹{(totalPtr).toFixed(2)}</h3>
                  </div>
                  :
                  <div style={{ display: "flex" }} className={'pull-right'}>
                    <h3 className="font-weight-bold">Total: </h3>
                    &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-2">₹{(totalPtr).toFixed(2)}</h3>
                  </div>
              }
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={6} lg={6} sm={6} xs={6}>
              <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.sellerID}`}>
                <p style={sellerTextStyle} className='mb-2'>Sold by <span className={'text-primary mb-2'}>{data.seller_id}</span></p>
              </NavLink>
            </Col>
            <Col md={6} xl={6} lg={6} sm={6} xs={6} style={{ textAlign: 'end' }}>
              <div>
                <button style={{ padding: '6px', width: '100px', backgroundColor: '#e5e5e5', color: 'black', borderRadius: '5px', border: 'none', fontSize: "14px" }} className="text-" variant="contained" color="primary" onClick={toggle} id={`mycart${data.inventory_id}`}>
                  Quantity: {data.quantity}
                </button>
                <div>
                  <Popover style={{ padding: "5px", paddingBottom: "0px", border: "none", textAlign: "center", }} trigger="legacy" placement="right" isOpen={popoverOpen} target={`mycart${data.inventory_id}`} toggle={toggle} >
                    <PopoverHeader style={{ padding: "4px 4px", textAlign: "center" }}>Select Quantity </PopoverHeader>
                    <br />
                    <PopOverInCart closepop={(e) => closepop(e)}
                      dataFromParent={data}
                    />
                    <PopoverBody style={{ paddingBottom: 0, textAlign: "center" }}>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={6} lg={6} sm={6} xs={6}>
              <h5 className="mb-2">PTR: ₹{(data.price).toFixed(2)}</h5>
            </Col>
            <Col md={6} xl={6} lg={6} sm={6} xs={6} style={{ textAlign: 'end' }} className='mt-1'>
              {
                helpertFn.showPrepaid(data.inventory && data.inventory.medicineTypeName, data.isPrepaid, data.inventory && data.inventory.prepaidInven) ?
                  <div><span className='text-white bg-danger' style={{ margin: "0px", padding: "0px 5px", float: "right" }}>Only Prepaid</span></div> : ''
              }
            </Col>
          </Row>
          <div style={{ display: "flex", color: "green" }}>
            {data.discount.type ? <h5 className="text-success mb-2">Offer: &nbsp;</h5>
              :
              null}
            {
              data.discount && data.discount.type === "Same" ?
                <React.Fragment>
                  <h5 className="text-success mb-2">
                    Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} Free
                  </h5>
                </React.Fragment>
                :
                data.discount && data.discount.type === "SameAndDiscount" ?
                  <React.Fragment>
                    <h5 className="text-success mb-2">
                      Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} Free, and {(Number(data.discount.discount_per)).toFixed(2)}% discount
                    </h5>
                  </React.Fragment>
                  :
                  data.discount && data.discount.type === "Different" ?
                    <React.Fragment>
                      <h5 className="text-success mb-2">
                        Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} {data.discountProduct[0].name} Free
                      </h5>
                    </React.Fragment>
                    :
                    data.discount && data.discount.type === "DifferentAndDiscount" ?
                      <React.Fragment>
                        <h5 className="text-success mb-2">
                          Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} {data.discountProduct[0].name} Free, and {(Number(data.discount.discount_per)).toFixed(2)}% discount
                        </h5>
                      </React.Fragment>
                      :
                      data.discount && data.discount.type === "Discount" ?
                        <React.Fragment>
                          <h5 className="text-success mb-2">{" " + (Number(data.discount.discount_per)).toFixed(2)}% discount on PTR</h5>
                        </React.Fragment>
                        :
                        data.discount && data.discount.type === "" ?
                          <React.Fragment>
                          </React.Fragment>
                          :
                          <React.Fragment>
                          </React.Fragment>
            }
          </div>
          <div style={{ display: "flex" }}>
            <h5 className="mb-0"> GST({data.gstPercentage}%): &nbsp;</h5>
            {
              data.discount && data.discount.type === "Discount" ?
                <div style={{ display: "flex" }} >
                  <h5 className="mb-0">₹{(gst).toFixed(2)}</h5>
                </div>
                :
                <h5 className="mb-0">₹{(gst).toFixed(2)}</h5>
            }
          </div>
          <Row>
            <Col md={6} xl={6} lg={6} sm={6} xs={6}>
              <div >
                <h5 className="pt-2 mb-1">Expiry Date: &nbsp;{moment(data.inventory.expiry_date).format('MM/YYYY')}</h5>
              </div>
            </Col>
            <Col md={6} xl={6} lg={6} sm={6} xs={6} style={{ textAlign: 'end' }}>
              <button style={{ padding: '6px', width: '100px', backgroundColor: '#e5e5e5', color: 'black', borderRadius: '5px', border: 'none', fontSize: "14px" }} variant="contained" color="primary" onClick={(e) => handleRemoveFromCart(data)}>
                Remove
              </button>
            </Col>
          </Row>
          {
            data.discount && (data.discount.type === "Same" || data.discount.type === "SameAndDiscount" || data.discount.type === "DifferentAndDiscount") ?
              <div >
                <h5 className="mb-1">Offer Price: &nbsp;₹ {(data.inventory.ePTR).toFixed(2)}</h5>
              </div>
              :
              data.discount && data.discount.type === "Discount" ?
                <div >
                  <h5 className="mb-1">Offer Price : &nbsp;₹{(data.inventory.ePTR).toFixed(2)}</h5>
                </div> : ''
          }
          <Row>
            <Col md={6} xl={6} lg={6} sm={6} xs={6} className='pt-1'>
              <h5>Final Unit Price: &nbsp;
                {
                  data.discount && (data.discount.type === "Same" || data.discount.type === "SameAndDiscount") ?
                    <>₹{(finalUnitPrice).toFixed(2)}</>
                    :
                    <>₹{(finalUnitPrice).toFixed(2)}</>
                }</h5>
            </Col>
            <Col md={6} xl={6} lg={6} sm={6} xs={6} style={{ textAlign: 'end' }}>
              {
                data.discount && data.discount.type === "Discount" ?
                  <div style={{ display: "flex" }} className={'pull-right'}>
                    <h3 className="font-weight-bold">Payable: </h3>
                    &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">
                      ₹{(payable).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h3>
                  </div>
                  :
                  data.discount && data.discount.type === "SameAndDiscount" ?
                    <div style={{ display: "flex" }} className={'pull-right'}>
                      <h3 className="font-weight-bold">Payable: </h3>
                      &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">
                        ₹{(payable).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </h3>
                    </div>
                    :
                    <div style={{ display: "flex" }} className={'pull-right'}>
                      <h3 className="font-weight-bold">Payable: </h3>
                      &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">
                        ₹{(payable).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </h3>
                    </div>
              }
            </Col>
          </Row>
        </div>
      </div>
      :
      <div className={`user-list card-strip pb-4`}>
        <img
          alt='User'
          src={`${helpertFn.productImg(data.image)}`}
          className="productImageStyle myCartUserAvatar mr-3"
          onError={addDefaultSrc}
        />
        <div className="description w-100">
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${data.inventory_id}`}>
                <p className="text-primary h3 mb-1">{data.title}</p>
              </NavLink>
            </Col>
            <Col md={12} xl={12} lg={12} sm={12} xs={12} >
              {
                data.discount && (data.discount.type === "Discount" || data.discount.type === "SameAndDiscount" || data.discount.type === "DifferentAndDiscount") ?
                  <div style={{ display: "flex" }} >
                    <h3 className="font-weight-bold">Total: </h3>
                    &nbsp;<h3><strike>₹{strikePrice}</strike></h3>
                    &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">₹{(totalPtr).toFixed(2)}</h3>
                  </div>
                  :
                  <div style={{ display: "flex" }} >
                    <h3 className="font-weight-bold">Total: </h3>
                    &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">₹{(totalPtr).toFixed(2)}</h3>
                  </div>
              }
            </Col>
          </Row>
          <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.sellerID}`}>
            <p style={sellerTextStyle}>Sold by <span className={'text-primary'}>{data.seller_id}</span></p>
          </NavLink>
          {
            helpertFn.showPrepaid(data.inventory && data.inventory.medicineTypeName, data.isPrepaid, data.inventory && data.inventory.prepaidInven) ?
              <div><span className='text-white bg-danger' style={{ margin: "0px", padding: "0px 5px", float: "right" }}>Only Prepaid</span></div>
              : ''
          }
          <h5>PTR: ₹{(data.price).toFixed(2)}</h5>
          <div style={{ display: "flex", color: "green" }}>
            {data.discount.type ? <h5 className="text-success">Offer: &nbsp;</h5>
              :
              null}
            {
              data.discount && data.discount.type === "Same" ?
                <React.Fragment>
                  <h5 className="text-success">
                    Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} Free
                  </h5>
                </React.Fragment>
                :
                data.discount && data.discount.type === "SameAndDiscount" ?
                  <React.Fragment>
                    <h5 className="text-success">
                      Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} Free, and {(Number(data.discount.discount_per)).toFixed(2)}% discount
                    </h5>
                  </React.Fragment>
                  :
                  data.discount && data.discount.type === "Different" ? //REMAINING FOR DIFFERENT (API NOT COMPLETED AT THE MOMENT)
                    <React.Fragment>
                      <h5 className="text-success">
                        Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} {data.discountProduct[0].name} Free
                      </h5>
                    </React.Fragment>
                    :
                    data.discount && data.discount.type === "DifferentAndDiscount" ? //REMAINING FOR DIFFERENT (API NOT COMPLETED AT THE MOMENT)
                      <React.Fragment>
                        <h5 className="text-success">
                          Buy {data.discount.discount_on_product.purchase} Get {data.discount.discount_on_product.bonus} {data.discountProduct[0].name} Free, and {(Number(data.discount.discount_per)).toFixed(2)}% discount
                        </h5>
                      </React.Fragment>
                      :
                      data.discount && data.discount.type === "Discount" ?
                        <React.Fragment>
                          <h5 className="text-success">{" " + (Number(data.discount.discount_per)).toFixed(2)}% discount on PTR</h5>
                        </React.Fragment>
                        :
                        data.discount && data.discount.type === "" ? //FOR DEALS ARR
                          <React.Fragment>
                          </React.Fragment>
                          :
                          <React.Fragment>
                          </React.Fragment>
            }
          </div>
          {
            data.discount && data.discount.type === "Discount" ?
              <div style={{ display: "flex", color: "green" }}>
                <h5 className="">Offer Price : &nbsp;</h5>
                <div style={{ display: "flex" }} >
                  <h5 className="">₹{(data.inventory.ePTR).toFixed(2)}</h5>
                </div>
              </div>
              :
              null
          }
          <div style={{ display: "flex" }}>
            <h5> GST({data.gstPercentage}%): &nbsp;</h5>
            {
              data.discount && data.discount.type === "Discount" ?
                <div style={{ display: "flex" }} >
                  <h5 className="">₹{(gst).toFixed(2)}</h5>
                </div>
                :
                <h5 className="">₹{(gst).toFixed(2)}</h5>
            }
          </div>
          <div style={{ display: "flex" }}>
            <h5>Expiry Date: &nbsp;</h5>
            <div style={{ display: "flex" }} >
              <h5 className="">{moment(data.inventory.expiry_date).format('MM/YYYY')}</h5>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <h5>Final Unit Price: &nbsp;</h5>
            {
              data.discount && (data.discount.type === "Same" || data.discount.type === "SameAndDiscount") ?
                <div style={{ display: "flex" }} >
                  <h5 className="">₹{(finalUnitPrice).toFixed(2)}</h5>
                </div>
                :
                <h5 className="">₹{(finalUnitPrice).toFixed(2)}</h5>
            }
          </div>
          {
            data.discount && (data.discount.type === "Same" || data.discount.type === "SameAndDiscount" || data.discount.type === "DifferentAndDiscount") ?
              <div style={{ display: "flex" }}>
                <h5>Offer Price: &nbsp;</h5>
                <div style={{ display: "flex" }} >
                  <h5 className="">₹
                    {(data.inventory.ePTR).toFixed(2)}
                  </h5>
                </div>
              </div>
              :
              null
          }
          <Row>
            <Col md={3} xl={3} lg={3} sm={3} xs={3}>
              <div>
                <button style={{ padding: '6px', width: '100px', backgroundColor: '#e5e5e5', color: 'black', borderRadius: '5px', border: 'none', fontSize: "14px" }} className="text-" variant="contained" color="primary" onClick={toggle} id={`mycart${data.inventory_id}`}>
                  Quantity: {data.quantity}
                </button>
                <div>
                  <Popover style={{ padding: "5px", paddingBottom: "0px", border: "none", textAlign: "center", }} trigger="legacy" placement="right" isOpen={popoverOpen} target={`mycart${data.inventory_id}`} toggle={toggle} >
                    <PopoverHeader style={{ padding: "4px 4px", textAlign: "center" }}>Select Quantity </PopoverHeader>
                    <br />
                    <PopOverInCart closepop={(e) => closepop(e)}
                      dataFromParent={data}
                    />
                    <PopoverBody style={{ paddingBottom: 0, textAlign: "center" }}>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
            </Col>
          </Row>
          <button style={{ padding: '6px', width: '100px', backgroundColor: '#e5e5e5', color: 'black', borderRadius: '5px', border: 'none', fontSize: "14px" }} className="mt-2" variant="contained" color="primary" onClick={(e) => handleRemoveFromCart(data)}>
            Remove
          </button>
          {
            data.discount && data.discount.type === "Discount" ?
              <div style={{ display: "flex", marginTop: '10px' }} >
                <h3 className="font-weight-bold">Payable: </h3>
                &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">
                  ₹{(payable).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h3>
              </div>
              :
              <div style={{ display: "flex", marginTop: '10px' }} >
                <h3 className="font-weight-bold">Payable: </h3>
                &nbsp;<h3 className="text-danger text-right font-weight-bold h3 mb-1">
                  ₹{(payable).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h3>
              </div>
          }
        </div>
      </div>}
    <span>
      {
        (propIndex === data.length - 1) ?
          <div className="text-right pr-3">
            <span style={{ fontSize: 17 }} className="font-weight-bold text-black">Subtotal ({data.length} items):</span>
            <span style={{ fontSize: 17 }} className="font-weight-bold text-red"> &#8377;{AllItemsTotalPrice.toFixed(2)}</span>
          </div> : ''
      }
      {
        (propIndex === data.length - 1) ?
          <div className="text-right pr-3">
            <span style={{ fontSize: 17 }} className="font-weight-bold text-black">Total GST:</span>
            <span style={{ fontSize: 17 }} className="font-weight-bold text-red"> &#8377;{AllItemsTotalGST.toFixed(2)}</span>
          </div> : ''
      }
    </span>

    {/* ===========================FOR Remove======================================= */}
    <Dialog open={removeOpen} onClose={onCloseRemove1} fullWidth={true}
      maxWidth={'sm'}>
      <DialogTitle className='pb-0'>
        Add to Shortbook
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {'Do you want to add this product to your shortbook ?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseRemove} color='secondary' >
          No
        </Button>
        <Button color='primary' onClick={handleConfirmRemove} >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
  );
}

const mapStateToProps = ({ auth, seller, buyer }) => {
  const { userDetails } = seller;
  const { user_details } = auth;
  const { cartDetails, mediWallet, removeFromCart } = buyer;
  let users = userDetails ? userDetails : user_details
  return { user_details, users, cartDetails, mediWallet, removeFromCart }
};

export default connect(mapStateToProps, { getUserDetail, getCartDetails, getMediWallet, removeFromCart })(CartItems);