import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';

const EditOrder = (props) => {
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(props.qty)
  const [refQuantity, setRefQuantity] = useState(props.qty)
  const [removeProd, setRemoveProd] = useState(false)
  const { refCost, cost, index, purchase } = props

  const handleRequestClose = (e) => {
    setOpen(false)
    setQuantity(props.qty);
    props.handleClick(e, true, '', '')
  };

  const handleRequestCloseProd = (e) => {
    setRemoveProd(false)
    setOpen(false)
    setQuantity(props.qty)
  };

  const handleRequest = (e) => {
    setRemoveProd(false)
    setOpen(false)
    if ((cost !== refCost || quantity !== refQuantity) && (props.status === "New")) {
      let isDisabled = true;
      if (props.status === "New" && (cost !== refCost || quantity !== refQuantity)) {
        isDisabled = false;
      }
      props.handleClick(e, isDisabled, cost, quantity, index, props.status)
    }
  };

  useEffect(() => {
    setQuantity(props.qty)
    setRefQuantity(props.qty)
  }, [props.qty])

  const handleCartValue = async (e, type) => {
    let tempQuantity = quantity;
    if (purchase !== 0 && purchase !== undefined) {
      if (type === 'decrement' && tempQuantity >= 1) {
        tempQuantity -= purchase;
        setQuantity(tempQuantity)
      } else if (type === 'increment') {
        tempQuantity += purchase;
        setQuantity(tempQuantity)
      }
    } else {
      if (type === 'decrement' && tempQuantity > 0) {
        tempQuantity -= 1;
        setQuantity(tempQuantity)
      } else if (type === 'increment') {
        tempQuantity += 1;
        setQuantity(tempQuantity)
      }
    }
    if (quantity === 0) {
      setRemoveProd(true)
    }
  }

  let { title } = props;
  return (
    <div>
      <Button variant="outlined" color="primary" className={'button orderListButton'} onClick={() => setOpen(true)}>Edit</Button>
      <Dialog open={open} onClose={handleRequestClose} fullWidth={false} className="EditorderPopup" >
        <DialogTitle>
          {title}
          <DialogContentText className='mt-3'>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <div className="justify-content-center d-flex mb-4">
              <span className="mr-3  editOrderIcon align-self-center text-primary" onClick={(e) => handleCartValue(e, 'decrement')}><i class="zmdi zmdi-minus-circle-outline"></i></span>
              <Avatar className="text-white bg-primary showQty">{quantity}</Avatar>
              <span className={`ml-3 editOrderIcon align-self-center ${quantity >= refQuantity ? 'text-muted' : 'text-primary'}`} onClick={(e) => quantity >= refQuantity ? false : handleCartValue(e, 'increment')}><i class="zmdi zmdi-plus-circle-o"></i></span>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleRequestClose(e)} color="secondary" >
            Cancel
          </Button>
          <Button onClick={(e) => handleRequest(e)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/*----------------------- Remove prod ---------------------- */}
      <Dialog open={removeProd} onClose={handleRequestCloseProd} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          Remove Product
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Are you sure you want to remove this product ? This product will be removed from your inventory as well. Please click to continue.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleRequestCloseProd(e)} color='secondary' >
            No
          </Button>
          <Button color='primary' onClick={(e) => handleRequest(e)} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default EditOrder;