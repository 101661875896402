import React, { useState } from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import CartPopOver from 'components/userProfileCard/CartPopOver'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import { NavLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import helpertFn from 'constants/helperFunction';
const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const OtherSellerInfo = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  }

  const closepop = () => {
    setPopoverOpen(false)
  }

  const { data } = props
  const sellerID = data && data && data.Seller && data.Seller._id;
  const inventoryID = data && data && data.inventory_id;
  let effects = Number(data.PTR) * Number(data && data.Discount && data.Discount.discount_on_product && data.Discount.discount_on_product.purchase);
  let effects1 = Number(effects) / (Number(data && data.Discount && data.Discount.discount_on_product && data.Discount.discount_on_product.purchase) + Number(data && data.Discount && data.Discount.discount_on_product && data.Discount.discount_on_product.bonus));
  const bgColor = (data && data.medi_type === 'Ethical branded') || (data && data.medi_type === 'Others') ? '#ff7000' :
    data && data.medi_type === 'Cool chain' ? '#0b68a8' :
      (data && data.medi_type === 'Surgical') || (data && data.medi_type === 'OTC') || (data && data.medi_type === 'Generic') ? '#038d0e' : '#072791'

  return (
    <div>
      <div className="media media-list pl-0 d-flex w-100">
        <Col xs={4} sm={4} md={2} lg={2} xl={2} className="pr-3">
          <img title="" alt="" className="rounded-circle avatar size-60 mr-3" src={data.Product.images.length > 0 ? `${helpertFn.productImg(data.Product.images[0])}` : logo} />
        </Col>
        <Col xs={8} sm={8} md={10} lg={10} xl={10}>
          <div className="media-body">
            <Row>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <NavLink className="buyerRedirectNavlink" to={`/view-seller/${data.Seller._id}`} >
                  <span><h4 className="mt-0 nowrap"><strong>{data.Seller.company_name}</strong></h4></span>
                </NavLink>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <span style={{ padding: '2px 5px', fontWeight: 'bold', backgroundColor: `${bgColor}`, color: 'white' }}>{data.medi_type === 'Others' ? 'PCD' : data.medi_type}</span>
                {
                  helpertFn.showPrepaid(data.medi_type, data.Product.isPrepaid && data.Product.isPrepaid, data.prepaidInven && data.prepaidInven) &&
                  <span className='text-white bg-danger ml-3' style={{ padding: "2px 5px" }}>Only Prepaid</span>
                }
              </Col>
              {data && data.Discount && data.Discount.discount_type ?
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="align-self-center" style={{ margin: '0px 0px 8px', fontSize: '15px' }} >
                  <span className="text-grey ">
                    Offer :
                    {
                      data.Discount.discount_type === "Same" ?
                        <React.Fragment>
                          <span style={{ color: 'black' }}> Buy {data.Discount.discount_on_product.purchase} Get {data.Discount.discount_on_product.bonus} Free </span>
                        </React.Fragment>
                        :
                        data.Discount.discount_type === "SameAndDiscount" ?
                          <React.Fragment>
                            <span style={{ color: 'black' }}> Buy {data.Discount.discount_on_product.purchase} Get {data.Discount.discount_on_product.bonus} Free, and {(data.Discount.discount_per).toFixed(2)}% Off </span>
                          </React.Fragment>
                          :
                          data.Discount.discount_type === "DifferentAndDiscount" ?
                            <React.Fragment>
                              <Tooltip
                                className="d-inline-block"
                                id="tooltip-right"
                                title={
                                  <span className="text-white" style={{ marginTop: "13px" }}>
                                    <strong> Buy {data.Discount.discount_on_product.purchase} Get {data.Discount.discount_on_product.bonus} {data && data.OtherProducts && data.OtherProducts.name} Free, and {(data.Discount.discount_per).toFixed(2)}% Off</strong>
                                  </span>
                                }
                                placement="right"
                              >
                                <span className="ellipsis w-100" style={{ color: 'black' }}> {"Buy " + data.Discount.discount_on_product.purchase + " Get " + data.Discount.discount_on_product.bonus + " Different Free, with" + (data.Discount.discount_per).toFixed(2) + "% off"}  </span>
                              </Tooltip>
                            </React.Fragment> :
                            data.Discount.discount_type === "Discount" ?
                              <React.Fragment>
                                <span style={{ color: 'black' }}> Get {(data.Discount.discount_per).toFixed(2)}% discount </span>
                              </React.Fragment>
                              :
                              data.Discount.discount_type === "Different" ?
                                <React.Fragment>
                                  <Tooltip
                                    className="d-inline-block"
                                    id="tooltip-right"
                                    title={
                                      <span className="text-white" style={{ marginTop: "13px" }}>
                                        <strong>  Buy {data.Discount.discount_on_product.purchase} Get {data.Discount.discount_on_product.bonus} {data && data.OtherProducts && data.OtherProducts.name} Free</strong>
                                      </span>
                                    }
                                    placement="right"
                                  >
                                    <span className="ellipsis w-100" style={{ color: 'black' }}> {"Buy " + data.Discount.discount_on_product.purchase + " Get " + data.Discount.discount_on_product.bonus + " Different Free"}  </span>
                                  </Tooltip>
                                </React.Fragment>
                                :
                                ''
                    }
                  </span>
                </Col> : ''}
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="align-self-center" >
                <span className="text-grey ">
                  <h5>Min Order Quantity :<span style={{ color: 'black' }}>{data.min_order_quantity}</span> </h5>
                </span>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="align-self-center" >
                <span className="text-grey ">
                  <h5>Max Order Quantity :<span style={{ color: 'black' }}>{data.max_order_quantity}</span> </h5>
                </span>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="align-self-center" >
                <span className="text-grey ">
                  <h5>GST:<span style={{ color: 'black' }}>{data.Product.GST}%</span> </h5>
                </span>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="align-self-center" >
                <span className="text-grey ">
                  <h5>Expiry date:<span style={{ color: 'black' }}>{moment(data.expiry_date).format('ll')}</span> </h5>
                </span>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="align-self-center" >
                <span className="text-green">
                  {
                    data && data.Discount && data.Discount.discount_type === "Same" ?
                      <React.Fragment>
                        <h4 >PTR:<strong>&#x20B9;{effects1.toFixed(2)}</strong></h4>
                      </React.Fragment>
                      :
                      data && data.Discount && data.Discount.discount_type === "Discount" ?
                        <React.Fragment>
                          <h4 >PTR:<strong>&#x20B9;{(data.PTR - (data.PTR / 100 * data.Discount.discount_per)).toFixed(2)}
                          </strong></h4>
                        </React.Fragment>
                        :
                        data && data.Discount && data.Discount.discount_type === "Different" ?
                          <React.Fragment>
                            <h4 >PTR:<strong>&#x20B9;{(data.PTR).toFixed(2)}</strong></h4>
                          </React.Fragment>
                          :
                          data && data.Discount && data.Discount.discount_type === "" ?
                            <React.Fragment>
                              <h4 >PTR:<strong>&#x20B9;{(data.PTR).toFixed(2)}</strong></h4>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <h4 >PTR:<strong>&#x20B9;{(data.PTR).toFixed(2)}</strong></h4>
                            </React.Fragment>

                  }
                </span>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="align-self-center" >
                <span className="text-grey ">
                  <h4>MRP:<span style={{ color: 'black' }}>&#x20B9;{data.MRP.toFixed(2)}</span> </h4>
                </span>
              </Col>
            </Row>
            <div className="comment-footer">
              <button style={{ padding: '10px 15px 10px 15px', backgroundColor: '#072791', color: 'white', borderRadius: '5px', border: 'none' }} onClick={toggle} id={`other${sellerID + inventoryID}`}>Add To Cart</button>
            </div>
            <div>
              <Popover style={{ padding: "5px", paddingBottom: "0px", textAlign: "center", }} placement="right" isOpen={popoverOpen} target={`other${sellerID + inventoryID}`} toggle={toggle} trigger="legacy">
                <PopoverHeader style={{ padding: "4px 4px", textAlign: "center" }}>Select Quantity</PopoverHeader>
                <br />
                <CartPopOver
                  closepop={(e) => closepop(e)}
                  dataFromParent={data}
                />
                <PopoverBody style={{ paddingBottom: 0, textAlign: "center" }}>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
}

export default OtherSellerInfo;