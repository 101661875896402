import React from 'react'
import { Col, Row } from 'reactstrap';
import { getUserDetail } from 'actions/seller'
import { connect } from 'react-redux'
import ComplianceFormComponent from './complianceFormComponent';
import ComplianceFormComponentForGst from './complainceFormComponentForGst';

const ComplainceForm = (props) => {
    return (
        <div className="col-xl-12 col-lg-12">
            <div className="jr-card">
                <Row style={{}}>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                        <ComplianceFormComponent fileKey={'drugLic20B'} label2='drugLic20BLicNo' label="Drug Lic 20B" name="drugLic20BExpiry" />
                    </Col>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-2">
                        <ComplianceFormComponent fileKey={'drugLic21B'} label2='drugLic21BLicNo' label="Drug Lic 21B" name="drugLic21BExpiry" />
                    </Col>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-4">
                        <ComplianceFormComponent fileKey={'fassaiLic'} label2='fassaiLicNo' label="FSSAI Lic (Optional)" name="fassaiLicExpiry" />
                    </Col>
                    <Col sm={12} md={6} lg={6} xs={12} xl={6} className="mt-4">
                        <ComplianceFormComponentForGst fileKey={'gstLic'} label2='gstLicNo' label="GSTN Lic (Optional)" name="gstLic" />
                    </Col>
                </Row>
            </div>
        </div >
    )
}

const mapStateToProps = ({ seller }) => {
    const { userDetails } = seller;
    return { userDetails }
};

export default connect(mapStateToProps, { getUserDetail })(ComplainceForm);
