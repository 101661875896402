import React, { useState, useEffect } from "react";
import Widget from "components/Widget";
import PropTypes from 'prop-types';
import CompanyProductCard from '../companyProductCard';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import 'components/profile/About/index.css'
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { getProductsByCompany, getCategories } from 'actions/buyer';
import { useParams } from "react-router-dom"

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const CompanyFrontBody = (props) => {
  const params = useParams()
  const [value, setValue] = useState('');
  const [perPage, setPerPage] = useState(16);
  const [hidden, setHidden] = useState(false);
  const page = 1
  const thisPagecount = 16

  const handleChange = (event) => {
    setValue(event.target.value)
    props.getProductsByCompany({ company_id: params.id, category_id: event.target.value })
  };

  useEffect(() => {
    props.getProductsByCompany({ company_id: params.id })
    props.getCategories({ company_id: params._id, page: page, perPage: perPage })
  }, [])

  const thisPagecountFn = async () => {
    setHidden(true)
    props.getProductsByCompany({
      page: page,
      perPage: perPage + 16,
      company_id: params.id,
      category_id: value ? value : ''
    })
    setTimeout(() => {
      setPerPage(perPage + 16)
      setHidden(false)
    }, 2000)
  }

  const { dataFromParent, productsByCompany, categories } = props;
  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile "  >
      <div position="static" color="default" >
        <FormGroup style={{ marginRight: '15px', float: 'right', width: '25%' }} >
          <Label for="exampleSelect"></Label>
          <Input type="select" name="select"
            onChange={handleChange}
            value={value}
          >
            <option value={''}>All</option>
            {
              categories && categories.detail && categories.detail.map((val, i) =>
                <option value={val._id} key={i}>{val.name}</option>
              )
            }
          </Input>
        </FormGroup>
      </div>
      <div className="jr-tabs-classic">
        <div className="jr-tabs-content jr-task-list">
          <TabContainer>
            <div className="row">
              {productsByCompany && productsByCompany.results ? null :
                <React.Fragment>
                  <div className="loader-view" style={{ margin: 'auto' }}    >
                    <CircularProgress />
                  </div>
                </React.Fragment>
              }
              {productsByCompany && productsByCompany.results.length > 0 ?
                <React.Fragment>
                  {productsByCompany && productsByCompany.results.map((product, index) =>
                    <div
                      className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12" key={product._id}>
                      <CompanyProductCard
                        dataFromParentTwo={dataFromParent}
                        styleName="pb-4" headerStyle="bg-gradient-primary" product={product} />
                    </div>)}
                </React.Fragment>
                :
                productsByCompany && productsByCompany.results.length < 1 ?
                  <React.Fragment>
                    <div style={{ margin: 'auto', width: '50%', textAlign: ' center' }}>
                      <span className="text-danger" >
                        <i class="zmdi zmdi-alert-circle animated wobble zmdi-hc-5x" style={{ fontSize: '90px' }} ></i>
                      </span><br /><br />
                      <h1>Sorry, no results found !</h1>
                    </div>
                  </React.Fragment>
                  : null
              }
            </div>
            {!hidden ?
              <div style={{ textAlign: "center" }}>
                {(productsByCompany && productsByCompany.count > thisPagecount) &&
                  <Button color="primary" className="jr-btn jr-btn-lg btnPrimary"
                    onClick={thisPagecountFn}>
                    See More
                  </Button>
                }</div> :
              <div className="loader-view">
                <CircularProgress />
              </div>
            }
          </TabContainer>
        </div>
      </div>
    </Widget>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { productsByCompany, categories } = buyer;
  return { productsByCompany, categories }
};
export default connect(mapStateToProps, { getProductsByCompany, getCategories })(CompanyFrontBody)