import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar'
import { connect } from 'react-redux'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { userSignOut } from 'actions/Auth';
import { NavLink, useNavigate } from 'react-router-dom';
import helperFunction from 'constants/helperFunction';

const UserInfo = (props) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleClick = event => {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  let { userDetails } = props;

  return (
    <React.Fragment>
      {
        userDetails !== undefined ? <div className="user-profile d-flex flex-row align-items-center">
          <Avatar
            alt='Andrew'
            style={{ background: helperFunction.getGradient(userDetails.backGroundColor) }}
            className="user-avatar "
          >
            {
              userDetails !== undefined ? helperFunction.getNameInitials(`${userDetails.first_name ? userDetails.first_name : ''} ${userDetails.last_name ? userDetails.last_name : ''}`) : ''
            }
          </Avatar>
          <div className="user-detail">
            <h4 className="user-name" onClick={handleClick}>{userDetails.first_name} {helperFunction.getNameInitials(userDetails.last_name)} <i
              className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </h4>
          </div>
          <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
          >
            <MenuItem onClick={handleRequestClose}>
              <NavLink to="/seller/edit-Profile" className={'text-black NavLink'}>
                <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                Edit Profile
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleRequestClose}>
              <NavLink to="/seller/change-Password" className={'text-black NavLink'}>
                <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
                Change Password
              </NavLink>
            </MenuItem>
            <MenuItem onClick={() => {
              handleRequestClose();
              localStorage.clear();
              props.userSignOut({ navigate })
              navigate('/signin')
            }}>
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
              Logout
            </MenuItem>
          </Menu>
        </div> : ''
      }
    </React.Fragment>
  );
}

const mapStateToProps = ({ settings, seller }) => {
  const { userDetails } = seller;
  return { userDetails }
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);


