import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AxiosRequest from 'sagas/axiosRequest'
import ReactStrapTextField from 'components/ReactStrapTextField';
import { NotificationManager } from 'react-notifications';
import { Field, Form } from 'react-final-form'
import { getInventoryList } from 'actions'
import { connect } from 'react-redux'
import { required, dicount100, number, maxPurchase, percentageValidation, discountValidation, maxLength4, noDecimal, minLessAvail, maxLessAvail, number0, maxGreaterMin, twoDecimal, maxLessMin, validateDate } from 'constants/validations';
import AutoCompleteSearch from 'components/asyncAutocmplete';
import TextBox from 'components/textBox';
import ReactStrapSelectField from 'components/reactstrapSelectField';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const AddInventory = (props) => {
  const [options, setOptions] = useState([])
  const [selectedData, setSelectedData] = useState('')
  const [options2, setOptions2] = useState([])
  const [openDailog, setOpenDailog] = useState(false)
  const [showButton, setShowButton] = useState('')
  const [bestOffer, setBestOffer] = useState('')
  const [formValues, setFormValues] = useState({})
  const [otherSelectedProduct, setOtherSelectedProduct] = useState('')
  const [errorShow, setErrorShow] = useState(false);
  const [discountCap,setDiscountCap] = useState()

  const handleRequestClose = () => {
    handleClose(props.editData ? 'edit' : 'add');
  };

  const closeDialog = () => {
    setOpenDailog(false);
    setFormValues({})
    handleClose()
  }

  const addAnotherProduct = (form, values) => {
    const { MRP, PTR, maximumOrderQty, minimumOrderQty, totalQuantity } = formValues
    form.initialize({
      MRP: MRP,
      PTR: PTR,
      totalQuantity: totalQuantity,
      minimumOrderQty: minimumOrderQty,
      maximumOrderQty: maximumOrderQty,
      discount: 'nodiscount',
      discount_name: '',
      discount_per: '',
      purchase: '',
      bonus: '',
      otherInventoryId: '',
      discountId: '',
      percentOff: 1,
      buy: '',
      get: '',
      getProduct: '',
      batchNumber: ''
    })
    setFormValues({})
  }

  const onSubmit = async (values) => {
    const { MRP, PTR, maximumOrderQty, minimumOrderQty, totalQuantity, chemicalCombination, expiryDate, discount, percentOff, buy, get, batchNumber } = values
    let data = {
      Id: props.editData ? props.editData._id : '',
      product_id: selectedData ? selectedData._id : '',
      company_id: selectedData && selectedData.company_id ? selectedData.company_id._id : '',
      chemicalCombination: chemicalCombination,
      MRP: MRP,
      PTR: PTR,
      quantity: totalQuantity,
      min_order_quantity: minimumOrderQty,
      max_order_quantity: maximumOrderQty,
      expiry_date: moment(expiryDate).endOf('month').format(),
      discount_name: discount ? discount !== 'nodiscount' ? discount : "" : "",
      discount_per: percentOff,
      purchase: (discount === 'Same' || discount === 'SameAndDiscount' || discount === 'Different' || discount === 'DifferentAndDiscount') ? Number(minimumOrderQty) : Number(buy),
      bonus: Number(get),
      otherInventoryId: otherSelectedProduct ? otherSelectedProduct._id : '',
      discountId: props.editData ? props.editData.Discounts ? props.editData.Discounts._id : '' : '',
      batchNumber: batchNumber
    }
    if ((discount === 'Different' || discount === 'DifferentAndDiscount') && !otherSelectedProduct) {
      return NotificationManager.error("Please add bonus discount product")
    }
    let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/addInventoryItem', '', data)
    if (response.data.error) {
      NotificationManager.error(response.data.title)
    } else {
      NotificationManager.success(response.data.title)
      bestOfferApi(response.data.detail.product_id)
      setFormValues(data)
      setOpenDailog(true)
      props.callMount();
    }
  }

  const handleSearchAnotherProduct = async (val, form) => {
    if (val) {
      let body = { name: val }
      form.change("otherName", val)
      let data = await AxiosRequest.axiosHelperFunc('post', 'inventory/searchInventory', '', body)
      let temp = await data.data.detail
      setOptions2(temp)
    } else {
      setOptions2([])
      form.change("otherName", '')
    }
  }

  const selectAnother = (data, form) => {
    form.change("otherName", data ? data.name : "")
    setOtherSelectedProduct(data)
  }

  const throttleHandleChange = async (val) => {
    let body = { name: val }
    let data = await AxiosRequest.axiosHelperFunc('post', 'product/searchProduct', '', body)
    let temp = []
    temp = data.data.detail
    setOptions(temp)
  }

  const handleSearchChange = async (val, form) => {
    if (val) {
      throttleHandleChange(val)
      form.change("name", val)
    } else {
      setOptions([])
      setSelectedData('')
      form.change("name", '')
    }
  }

  const selectData = async (data, form) => {
    if (data) {
      let Id = props.userDetails._id;
      let permission = data.sellerList && data.sellerList.length === 0 ? ['1'] : data.sellerList.filter(e => e._id === Id);
      form.batch(() => {
        form.change("name", data.name)
        form.change("chemicalCombination", data.chem_combination)
        form.change("companyName", data.company_id.name)
        form.change("GST", data.GST.name)
        form.change("productCategory", data.product_cat_id.name)
        form.change("type", data.Type.name)
        form.change("GSTValue", data.GST.value)
        form.change("countryOfO", data.country_of_origin)
        form.change("medicineType", data.medicine_type_id.name)
        form.change("mediTypeCommision", data.medicine_type_id.commission)
        form.change("GSTValue", data.GST.value)
        form.change("isPrepaid", data.isPrepaid)
        form.change("surCharge", data.surcharge)
        form.change("batchNumber", data.batchNumber)
      })
      setDiscountCap(data && data.discountCap)
      setShowButton(permission.length > 0 ? true : false)
      setSelectedData(data)
    } else {
      form.reset()
    }
    bestOfferApi(data && data._id)
  }

  const bestOfferApi = async (data) => {
    let data1 = { Id: data && data }
    let response1 = await AxiosRequest.axiosHelperFunc('post', 'product/bestOffer', '', data1)
    let offer = response1 && response1.data && response1.data.data.slice(0, 3);
    setBestOffer(offer)
  }

  const handleClose = () => {
    props.handleClick(props.editData ? 'edit' : 'add')
    // props.reset()
  }

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const theme2 = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '0.375rem 0.75rem !important'
          }
        }
      },
    }
  });

  const getValues = (values, type) => {
    let samePrice = (values.discount === 'Same' && values.PTR !== "") ? Number((Number(values.PTR) * Number(values.minimumOrderQty)) / (Number(values.minimumOrderQty) + (Number(values.get) ? Number(values.get) : ''))) : 0;
    let discPrice = (values.discount === 'Discount' && values.PTR !== "") ? values.percentOff !== '' ?
      (Number(values.PTR) - ((Number(values.PTR) / 100) * Number(values.percentOff))) : Number(values.PTR) : 0;
    let noDiscPrice = ((values.discount === 'nodiscount' || values.discount === 'Different') && values.PTR !== "") ? Number(values.PTR) : Number(values.PTR);
    let sameAndD = (values.discount === 'SameAndDiscount' && values.PTR !== "") ? Number((Number(values.PTR) * Number(values.minimumOrderQty)) / (Number(values.minimumOrderQty) + (Number(values.get) ? Number(values.get) : ''))).toFixed(2) : 0;
    let sameAndDiscount = (Number(sameAndD) - (Number(sameAndD) / 100 * Number(values.percentOff))).toFixed(2);
    let diffrentAndDiscount = (Number(noDiscPrice) - (Number(noDiscPrice) / 100 * Number(values.percentOff))).toFixed(2);
    let samePriceGst = Number(samePrice) / 100 * Number(values.GSTValue);
    let discPriceGst = Number(discPrice) / 100 * Number(values.GSTValue);
    let noDiscPriceGst = Number(noDiscPrice) / 100 * Number(values.GSTValue ? values.GSTValue : 0);
    let sameAndDiscountGst = Number(sameAndDiscount) / 100 * Number(values.GSTValue);
    let diffrentAndDiscountGst = Number(diffrentAndDiscount) / 100 * Number(values.GSTValue);
    let totalSamePrice = Number(samePrice + samePriceGst);
    let totalDiscPrice = Number(discPrice + discPriceGst);
    let totalNoDiscPrice = Number(noDiscPrice + noDiscPriceGst) > 0 ? Number(noDiscPrice + noDiscPriceGst) : 0;
    let totalSameAndDiscount = Number(Number(sameAndDiscount) + sameAndDiscountGst);
    let totalDiffrentAndDiscount = Number(Number(diffrentAndDiscount) + diffrentAndDiscountGst);
    if (type === 'Discount') {
      return totalDiscPrice
    }
    if (type === "nodiscount") {
      return totalNoDiscPrice
    }
    if (type === "Same") {
      return totalSamePrice
    }
    if (type === "SameAndDiscountTotal") {
      return totalSameAndDiscount
    }
    if (type === "SameAndDiscount") {
      return sameAndDiscount
    }
    if (type === "DifferentAndDiscountTotal") {
      return totalDiffrentAndDiscount
    }
    if (type === "DifferentAndDiscount") {
      return diffrentAndDiscount
    }
  }

  function calculateDiscountPercentage(values) {
    const { discount } = values;
    const PTR = Number(values.PTR || 0)
    const get = Number(values.get || 0)
    const minimumOrderQty = Number(values.minimumOrderQty || 0)
    const percentOff = Number(values.percentOff || 0)
    if (!discountCap) {
      setErrorShow(false)
      return;
    }
    switch (discount) {
      case "Discount":
        (discountCap < percentOff) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "Same":
        const totalAmtSame = (minimumOrderQty + get) * PTR;
        const costofFreeSame = get * PTR;
        const discountPerSame = (costofFreeSame / totalAmtSame) * 100;
        (discountCap < discountPerSame) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "Different":
        const priceOfOtherProductDiff = otherSelectedProduct && otherSelectedProduct.PTR ? otherSelectedProduct.PTR : 0;
        const totalAmtDiff = (minimumOrderQty * PTR) + (get * priceOfOtherProductDiff);
        const constofFreeProdDiff = get * priceOfOtherProductDiff;
        const discountPerDiff = (constofFreeProdDiff / totalAmtDiff) * 100;
        (discountCap < discountPerDiff) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "SameAndDiscount":
        const totalAmtSameDiscount = (minimumOrderQty + get) * PTR;
        const calDisc = (minimumOrderQty * PTR * percentOff) / 100
        const costofFreeSameDiscount = get * PTR;
        const totalDiscountCost = calDisc + costofFreeSameDiscount;
        const discount1SameDiscount = (totalDiscountCost / totalAmtSameDiscount) * 100;
        (discountCap < discount1SameDiscount) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "DifferentAndDiscount":
        const otherProductPTR = otherSelectedProduct && otherSelectedProduct.PTR ? otherSelectedProduct.PTR : 0;
        const totalAmt = (minimumOrderQty * PTR) + (get * otherProductPTR);
        const freeAmt= get * otherProductPTR;
        const calDisc1 = (minimumOrderQty * PTR * percentOff) / 100
        const discount1DiffDiscount = ((freeAmt + calDisc1) / totalAmt) * 100;
        (discountCap < discount1DiffDiscount) ? setErrorShow(true) : setErrorShow(false);
        break;

      default:
        setErrorShow(false);
    }
  }
  return (
    <React.Fragment>
      <Dialog open={props.buttonType === 'edit' ? props.edit : props.add} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}>
        <Form
          initialValues={{
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, values, form, errors }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              {calculateDiscountPercentage(values)}
              <DialogTitle className='pb-0'>
                {props.title}
              </DialogTitle>
              <DialogContent>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                    <FormGroup>
                      {bestOffer && bestOffer.map((data, index) => {
                        return <React.Fragment> {data ? <Label for="name">{data ? `${index + 1}. ( Best Offered Price: Rs. ${(data.price).toFixed(2)}
              ${data.disc && data.disc.name === 'Same' ? ', Offer: Buy ' + data.disc.discount_on_product.purchase + ' and get ' + data.disc.discount_on_product.bonus + ' free' :
                            data.disc && data.disc.name === 'SameAndDiscount' ? ', Offer: Buy ' + data.disc.discount_on_product.purchase + ' and get ' + data.disc.discount_on_product.bonus + ' free and ' + (data.disc.discount_per).toFixed(2) + '% discount' :
                              data.disc && data.disc.name === 'Discount' ?
                                ', Offer: ' + data.disc.discount_per + '% discount' :
                                data.disc && data.disc.name === 'DifferentAndDiscount' ?
                                  ', Offer: Buy ' + data.disc.discount_on_product.purchase + ' and get ' + data.disc.discount_on_product.bonus + ' ' + data.inven[0].productName + ' free and ' + (data.disc.discount_per).toFixed(2) + '% discount' :
                                  data.disc && data.disc.name === 'Different' ?
                                    ', Offer: Buy ' + data.disc.discount_on_product.purchase + ' and get ' + data.disc.discount_on_product.bonus + ' ' + data.inven[0].productName + ' free' : data.disc && data.disc.type === '' ?
                                      '' : ''
                          }, Min Quantity: ${data.minQ} , Expiry Date :${moment(data.expiryD).format("MM-YYYY")} )` : ''}</Label> : ''}<br /></React.Fragment>
                      })}
                      <div className='mt-2'></div>
                      <AutoCompleteSearch value={values.name} options={options} handleDataChange={(v) => selectData(v, form)} handleChange={(e, v) => handleSearchChange(v, form)} key='name' label={'Name'} />
                      {showButton === false && <Label className='text-danger'>Please contact admin to add this product!</Label>}
                      {values.medicineType && values.medicineType === "Others" ? <Label className='text-danger'>This Product will attract {values.mediTypeCommision}% charge on purchase.</Label> : ""}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    {
                      <FormGroup>
                        <Label for="chemicalCombination">Chemical Combination</Label>
                        <Input disabled type="text" name="chemicalCombination" value={values.chemicalCombination} id="chemicalCombination" />
                      </FormGroup>
                    }
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    {
                      <FormGroup>
                        <Label for="companyName">Company Name</Label>
                        <Input disabled type="text" name="companyName" id="companyName" value={values.companyName} />
                      </FormGroup>
                    }
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={4} lg={4}>
                    <Field id="totalQuantity" name="totalQuantity" type="text"
                      component={ReactStrapTextField} label={"Total Available Quantity"}
                      validate={composeValidators(required, number, number0)}
                    />
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    <FormGroup>
                      <Field id="expiryDate" name="expiryDate"
                        label='Expiry Date'
                        validate={composeValidators(required,validateDate)}
                      >
                        {
                          props =>
                            <>
                              <Label>Expiry Date</Label>
                              <ThemeProvider theme={theme2}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DatePicker
                                    views={['year', 'month']}
                                    name="expiryDate"
                                    sx={{ width: "100%" }}
                                    leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                    rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                    minDate={moment().add(3, 'months')}
                                    slotProps={{ textField: { variant: 'outlined', error: props.meta.error, helperText:props.meta.error }}}
                                    onChange={(e) => {
                                      props.input.onChange(e); 
                                      form.change("batchNumber", '')
                                    }}
                                    value={props.input.value||null}
                                  />
                                </LocalizationProvider>
                              </ThemeProvider>
                            </>
                        }
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={4} lg={4}>
                    <FormGroup>
                      <Label for="countryOfO">Country Of Origin</Label>
                      <Input disabled type="text" name="countryOfO" value={values.countryOfO} id="countryOfO" />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} sm={12} xl={4} lg={4}>
                    {
                      <FormGroup>
                        <Label for="GST">GST</Label>
                        <Input disabled type="text" name="GST" id="GST" value={values.GST}
                        />
                      </FormGroup>
                    }
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    {
                      <FormGroup>
                        <Label for="type">Type</Label>
                        <Input disabled type="text" name="type" id="text" value={values.type}
                        />
                      </FormGroup>
                    }
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <FormGroup>
                      <Label for="type">Medicine type</Label>
                      <Input disabled type="text" name="Medicine type" id="text" value={values.medicineType}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <Field id="MRP" name="MRP" type="text"
                      component={ReactStrapTextField} label={"MRP"}
                      validate={composeValidators(required, number, twoDecimal)}
                    />
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <Field id="PTR" name="PTR" type="number"
                      component={ReactStrapTextField} label={"PTR"}
                      validate={composeValidators(required, number, twoDecimal)}
                    />
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <FormGroup>
                      <Label for="type">Prepaid Inventory</Label>
                      <Input disabled type="text" name="type" id="text" value={'FALSE'} />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <Field id="minimumOrderQty" name="minimumOrderQty" type="number"
                      component={ReactStrapTextField} label={"Lot size"}
                      validate={composeValidators(required, number, number0, minLessAvail, noDecimal)}
                    />
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <Field id="maximumOrderQty" name="maximumOrderQty" type="number"
                      component={ReactStrapTextField} label={"Maximum order quantity"}
                      validate={composeValidators(required, number, maxLength4, noDecimal, maxPurchase, maxLessAvail, maxGreaterMin, maxLessMin)}
                      pattern="[0-9]"
                    />
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <FormGroup>
                      <Label for="Surcharge">Product Surcharge</Label>
                      <Input disabled type="text" name="Surcharge" value={values.surCharge && values.surCharge ? `${values.surCharge} %` : '0 %'} id="Surcharge" />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <FormGroup>
                      <Label for="isPrepaid">Prepaid Product</Label>
                      <Input disabled type="text" name="isPrepaid" id="isPrepaid" value={values.isPrepaid === true ? 'TRUE' : values.isPrepaid === false ? 'FALSE' : ''}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    {
                      <FormGroup>
                        <Label for="productCategory">Product Category</Label>
                        <Input disabled type="text" name="productCategory" id="productCategory" value={values.productCategory}
                        />
                      </FormGroup>
                    }
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <FormGroup>
                      <Field id="discount" name="discount" type="select"
                        component={ReactStrapSelectField}
                        label='Discount'
                      >
                        <option selected={values.discount === 'nodiscount'} value="nodiscount">Select discount</option>
                        <option selected={values.discount === 'Discount'} value='Discount'>Discount on PTR</option>
                        <option selected={values.discount === 'Same'} value='Same'>Same product bonus</option>
                        <option selected={values.discount === 'Different'} value='Different'>Different product bonus</option>
                        <option selected={values.discount === 'SameAndDiscount'} value='SameAndDiscount'>Same product bonus and Discount</option>
                        <option selected={values.discount === 'DifferentAndDiscount'} value='DifferentAndDiscount'>Different product bonus and Discount</option>
                      </Field>
                    </FormGroup>
                  </Col>
                  {moment(moment(values.expiryDate).endOf('month').format('YYYY-MM-DD')).diff(moment(moment().startOf('month').format('YYYY-MM-DD')), 'months', true) < (values ? values.shortExpiry: 9) && 
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <Field id="batchNumber" name="batchNumber" type="text"
                      component={ReactStrapTextField} label={"Batch number"}
                    />
                  </Col>}
                </Row>
                {
                  values.discount === 'Discount' ?
                    <React.Fragment>
                      <Row>
                        <Col sm={12} md={12} xs={12} lg={12} xl={12}>
                          <FormGroup>
                            <span className='d-flex'>
                              <span className='ml-3 mr-3 mt-3'>Get flat  </span>
                              <Field id="percentOff" name="percentOff" type="number"
                                component={TextBox} label={""}
                                validate={composeValidators(required, number, number0, percentageValidation, discountValidation, dicount100)}
                                fullWidth={false}
                                width={35}
                                margin='dense'
                              />
                              <span className='ml-3 mr-3 mt-3'>% Off </span>
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </React.Fragment>
                    : values.discount === 'Different' ? <React.Fragment>
                      <Col sm={12} md={12} xs={12} lg={12} xl={12} className='d-flex'>
                        <FormGroup className='sm-7 md-7 lg-7 xl-7'>
                          <div className='d-flex pr-4'>
                            <p className='mt-3 mr-2'>Buy </p>
                            <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                            <p className='mt-3 ml-2 mr-2'> {`${values.name} and get`}</p>
                            <Field id="get" name="get" type="number"
                              component={TextBox} label={''}
                              validate={composeValidators(required, number, number0, noDecimal)}
                              width={(values.buy < 0) ? 100 : 25}
                              margin='dense'
                              min={1}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className=' mt-2 sm-4 md-4 lg-4 xl-4' width={100} >
                          <AutoCompleteSearch searchType="other" value={values.otherName} standard={true} placeholder={'Select product'} options={options2} handleDataChange={(v) => selectAnother(v, form)} handleChange={(e, v) => handleSearchAnotherProduct(v, form)} key='getProduct' />
                            <br />
                          {!otherSelectedProduct ? <Label className='text-danger'>Please add discount product</Label> : ''}
                        </FormGroup>
                        <FormGroup className=' ml-2 sm-1 md-1 lg-1 xl-1 pt-2' >
                          <p>Free</p>
                        </FormGroup>
                      </Col>
                    </React.Fragment> : values.discount === 'Same' ? <React.Fragment>
                      <Col sm={12} md={12} xs={12} lg={12} xl={12}>
                        <FormGroup>
                          <div className='d-flex  pr-4'>
                            <p className='mt-3 mr-2'>Buy </p>
                            <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                            <p className='mt-3 ml-2 mr-2'> {`${values.name} and get`}</p>
                            <Field id="get" name="get" type="number"
                              component={TextBox} label={''}
                              validate={composeValidators(required, number, number0, noDecimal)}
                              width={35}
                              margin='dense'
                            />
                            <p className='mt-3 ml-2'> {`free`}</p>
                          </div>
                        </FormGroup>
                      </Col>
                    </React.Fragment> :
                      values.discount === 'SameAndDiscount' ? <React.Fragment>
                        <Col sm={12} md={12} xs={12} lg={12} xl={12}>
                          <FormGroup>
                            <div className='d-flex'>
                              <p className='mt-3 mr-2'>Buy </p>
                              <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                              <p className='mt-3 ml-2 mr-2'> {`${values.name} and get`}</p>
                              <Field id="get" name="get" type="number"
                                component={TextBox} label={''}
                                validate={composeValidators(required, number, number0, noDecimal)}
                                width={35}
                                margin='dense'
                              />
                              <p className='mt-3 ml-2'> {`free`}</p>
                              <span className='ml-1 mr-1 mt-3'>and add-on </span>
                              <Field id="percentOff" name="percentOff" type="number"
                                component={TextBox} label={""}
                                validate={composeValidators(required, number, number0, percentageValidation, discountValidation, dicount100)}
                                fullWidth={false}
                                width={35}
                                margin='dense'
                              />
                              <span className='ml-1 mr-1 mt-3'>% Off </span>
                            </div>
                          </FormGroup>
                        </Col>
                      </React.Fragment> :
                        values.discount === 'DifferentAndDiscount' ? <React.Fragment>
                          <Row>
                            <Col sm={12} md={12} xs={12} lg={12} xl={12} className='d-flex ml-1'>
                              <FormGroup className='sm-7 md-7 lg-7 xl-7'>
                                <div className='d-flex  pr-4'>
                                  <p className='mt-3 mr-2'>Buy </p>
                                  <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                                  <p className='mt-3 ml-2 mr-2'> {`${values.name} and get`}</p>
                                  <Field id="get" name="get" type="number"
                                    component={TextBox} label={''}
                                    validate={composeValidators(required, number, number0, noDecimal)}
                                    width={35}
                                    margin='dense'
                                    min={1}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} md={4} lg={4} xl={4} className='pr-0'>
                              <FormGroup className=' mt-2 ' width={100} >
                                <AutoCompleteSearch searchType="other" value={values.otherName} standard={true} placeholder={'Select product'} options={options2} handleDataChange={(v) => selectAnother(v, form)} handleChange={(e, v) => handleSearchAnotherProduct(v, form)} key='getProduct' />
                              </FormGroup>
                                {!otherSelectedProduct ? <Label className='text-danger'>Please add discount product</Label> : ''}
                            </Col>
                            <Col sm={8} md={8} lg={8} xl={8} className='pl-0'>
                              <FormGroup  >
                                <span className='d-flex'>
                                  <span className='ml-3 mr-3 mt-2'>Free and add-on </span>
                                  <Field id="percentOff" name="percentOff" type="number"
                                    component={TextBox} label={""}
                                    validate={composeValidators(required, number, number0, percentageValidation, discountValidation, dicount100)}
                                    fullWidth={false}
                                    width={35}
                                    margin='dense'
                                  />
                                  <span className='ml-3 mr-3 mt-3'>% Off </span>
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                        </React.Fragment> : ''
                }
                {errorShow ? <Label className='text-danger'>Discount should not be greater than {discountCap}%</Label> : ''}
                <div>
                  <span className="text-success float-left pt-2" style={{ fontSize: 15 }}>Est. retailer margin:&nbsp;
                    {<span>
                      {
                        values.discount === 'Same' && values.PTR !== "" && values.MRP !== "" ?
                          Number(values.MRP) > Number(getValues(values, "Same")) ?
                            (((Number(values.MRP) - Number(getValues(values, "Same"))) / Number(values.MRP)) * 100).toFixed(2) :
                            (((Number(getValues(values, "Same")) - Number(values.MRP)) / Number(values.MRP)) * 100).toFixed(2) :

                          (values.discount === 'SameAndDiscount' && values.PTR !== "" && values.MRP !== "" && values.percentOff !== "") ?
                            Number(values.MRP) > Number(getValues(values, "SameAndDiscountTotal")) ?
                              (((Number(values.MRP) - Number(getValues(values, "SameAndDiscountTotal"))) / Number(values.MRP)) * 100).toFixed(2) :
                              (((Number(getValues(values, "SameAndDiscountTotal")) - Number(values.MRP)) / Number(values.MRP)) * 100).toFixed(2) :

                            (values.discount === 'DifferentAndDiscount' && values.PTR !== "" && values.MRP !== "" && values.percentOff !== "") ?
                              Number(values.MRP) > Number(getValues(values, "DifferentAndDiscountTotal")) ?
                                (((Number(values.MRP) - Number(getValues(values, "DifferentAndDiscountTotal"))) / Number(values.MRP)) * 100).toFixed(2) :
                                (((Number(getValues(values, "DifferentAndDiscountTotal")) - Number(values.MRP)) / Number(values.MRP)) * 100).toFixed(2) :

                              (values.discount === 'Discount' && values.PTR !== "" && values.MRP !== "") ?
                                Number(values.MRP) > Number(getValues(values, "Discount")) ?
                                  (((Number(values.MRP) - Number(getValues(values, "Discount"))) / Number(values.MRP)) * 100).toFixed(2) :
                                  (((Number(getValues(values, "Discount")) - Number(values.MRP)) / Number(values.MRP)) * 100).toFixed(2) :

                                ((values.discount === 'nodiscount' || values.discount === 'Different') && values.PTR !== "" && values.MRP !== "") ?
                                  Number(values.MRP) > Number(getValues(values, "nodiscount")) ?
                                    (((Number(values.MRP) - Number(getValues(values, "nodiscount"))) / Number(values.MRP)) * 100).toFixed(2) :
                                    (((Number(getValues(values, "nodiscount")) - Number(values.MRP ? values.MRP : 0)) / Number(values.MRP ? values.MRP : 0)) * 100).toFixed(2) :
                                  values.PTR ? Number(values.PTR)

                                    : 0
                      }
                      %</span>
                    }
                  </span>
                  <span className="text-success float-right pr-4 pt-2" style={{ fontSize: 15 }}>Effective PTR price is
                    {
                      <span>
                        &#8377;{
                          (values.discount === 'SameAndDiscount' && values.PTR !== "") ? getValues(values, "SameAndDiscountTotal") :
                            (values.discount === 'DifferentAndDiscount' && values.PTR !== '') ? getValues(values, "DifferentAndDiscount") :
                              (values.discount === 'Same' && values.PTR !== "") ? ((Number(values.PTR) * Number(values.minimumOrderQty)) / (Number(values.minimumOrderQty) + (Number(values.get) ? Number(values.get) : ''))).toFixed(2) :
                                (values.discount === 'Discount' && values.PTR !== "") ? values.percentOff !== '' ? (Number(values.PTR) - (((values.percentOff !== '' ? Number(values.percentOff) : 100) / 100) * Number(values.PTR))).toFixed(2) < 0 ? 0 : (Number(values.PTR) - (((values.percentOff !== '' ? Number(values.percentOff) : 100) / 100) * Number(values.PTR))).toFixed(2) : (((values.percentOff !== '' ? Number(values.percentOff) : 100) / 100) * Number(values.PTR)).toFixed(2) : values.PTR !== "" ? Number(values.PTR).toFixed(2) : values.PTR ? Number(values.PTR)
                                  : 0}
                      </span>
                    }
                  </span>
                </div>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                {
                  showButton ?
                    <Button type='submit' color='primary' disabled={errorShow}>
                      Add
                    </Button> :
                    <Button disabled={true}>
                      Add
                    </Button>
                }
              </DialogActions>
            </form>
          )}
        />
      </Dialog>

      {/* --------------------------=-=Add same prod again-=-=-------------------- */}
      <Dialog fullWidth={true} maxWidth={'sm'} open={openDailog} onClose={closeDialog}>
        <DialogTitle>
          {'Recreate another scheme ?'}
        </DialogTitle>
        <DialogActions className="pr-4">
          <Button onClick={closeDialog} color='secondary' >
            No
          </Button>
          <Button onClick={addAnotherProduct} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateProps = ({ seller }) => {
  const { userDetails } = seller;
  return { userDetails }
}
export default connect(mapStateProps, { getInventoryList })(AddInventory)
