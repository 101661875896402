import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Button } from '@mui/material'
import TableComponent from './TableComponent';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableToolbarOpen from './EnhancedTableToolbarOpen'
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import axios from 'constants/axios';
import { getShortbook } from 'actions/buyer';
import ShortBookPopOver from 'components/bSearchResults/ShortBookPopOver'
import { NotificationManager } from 'react-notifications';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ShortBook = (props) => {
  const [page, setPage] = useState(0)
  const perPage = 50
  const [month, setMonth] = useState(moment().format("MMMM"))
  const [year, setYear] = useState(moment().format("GGGG"))
  const [popOverOpen, setPopOverOpen] = useState(false)
  const [cancelPopOver, setCancelPopOver] = useState(false)
  const [cancelData, setCancelData] = useState({})
  const tabs = props.identifier === 'shortbookFulfilled' ? 'history' : 'open';

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    let data = { page: newPage, perPage: 50, month, year, tab: tabs };
    props.getShortbook({ data });
  };

  const addClicked = () => {
    setPopOverOpen(!popOverOpen)
  }

  const toggle = () => {
    setPopOverOpen(!popOverOpen)
  }

  const onPopOverClosed = () => {
    let data = {
      page: 1,
      perPage: 50,
      tab: tabs,
      month: moment().format("MMMM"),
      year: moment().format("GGGG"),
    }
    props.getShortbook({ data })
  }

  const cancelClicked = (data) => {
    setCancelPopOver(true)
    setCancelData(data)
  }

  const closeCancel = () => {
    setCancelPopOver(false)
    setCancelData({})
  }

  useEffect(() => {
    let data = { page: 1, perPage, month, year, tab: tabs };
    props.getShortbook({ data });

  }, [])

  const confirmCancel = async () => {
    try {
      let token = localStorage.getItem('buyer_token');
      let changedData = {
        id: cancelData._id,
      }
      const result = await axios({
        method: 'POST',
        url: 'shortBook/removeShortBook',
        data: changedData,
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      })
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
        let data = {
          page: page === 0 ? 1 : page,
          perPage: 50,
          tab: 'open',
          month: month,
          year: year,
        }
        props.getShortbook({ data })
      }
      setCancelPopOver(false)
      setCancelData({})
    } catch (err) {
      NotificationManager.error('Something Went Wrong !')
    }
  }

  const filter = async (data) => {
    setPage(0)
    setMonth(data.month)
    setYear(data.year)
    props.getShortbook({ data })
  }

  const statusStyle = (status) => {
    return status.includes('OUT OF STOCK') ? "text-white bg-danger" : status.includes("IN-STOCK") ? "text-white bg-primary" : status.includes("FULFILLED") ? "text-white bg-success" : "text-white bg-primary";
  }

  const { identifier, shortbookList } = props;
  let counts = shortbookList && shortbookList.data.metadata[0] && shortbookList.data.metadata[0].total ? shortbookList.data.metadata[0].total : 0;
  let rows = []
  shortbookList && shortbookList.data && shortbookList.data.result && shortbookList.data.result.map((dataOne, index) => {
    rows.push({
      productName: dataOne.product_name,
      manufacturer: dataOne.manufacturer ? dataOne.manufacturer.name : 'N/A',
      quantity: Number(dataOne.quantity),
      status: <div key={'recent'}
        className={` badge text-uppercase 
                     ${statusStyle(
          dataOne.isFulfilled ? "FULFILLED"
            :
            dataOne.inStock ?
              "IN-STOCK"
              :
              'OUT OF STOCK'
        )}`}>
        {
          dataOne.isFulfilled ? "FULFILLED" : dataOne.inStock ? "ADD TO CART" : 'OUT OF STOCK'
        }
      </div>,
      requestedDate: (dataOne.createdAt),
      action: <div>{identifier === "shortbookList" ? <Button variant="outlined" className="text-danger" onClick={() => cancelClicked(dataOne)}>REMOVE</Button> : ''}</div>
    })
  })
  let productData = shortbookList ? shortbookList.data ? shortbookList.data : [] : []

  return (
    <React.Fragment>
      <div>
        <Paper>
          {identifier === "shortbookFulfilled" ?
            <EnhancedTableToolbar identifier={identifier} filter={(e) => filter(e)} />
            :
            identifier === "shortbookList" ?
              <div>
                <ShortBookPopOver
                  open={popOverOpen}
                  toggle={toggle}
                  onAdd={onPopOverClosed}
                />
                <EnhancedTableToolbarOpen identifier={identifier} addClicked={addClicked} filter={(e) => filter(e)} />
              </div>
              :
              null
          }
          {
            props.loading ?
              <div className="loader-view">
                <CircularProgress />
              </div>
              :
              <div style={{ overflowX: 'auto' }}>
                <Table
                  sx={{ minWidth: 400 }}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    identifier={identifier}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows.length < 1 ? <TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Sorry, no matching records found !</TableCell></TableRow> :
                      rows.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableComponent
                            row={row}
                            labelId={labelId}
                            identifier={identifier}
                            itemListFromParent={productData} />
                        )
                      })}
                  </TableBody>
                </Table>
              </div>
          }
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={counts}
            rowsPerPage={perPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
          />
        </Paper>
      </div>
      {/*------------------------- Confirm Cancel Dialog ------------------------------------------ */}
      <Dialog open={cancelPopOver} onClose={closeCancel} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          Remove Shortbook Product
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Are you sure you want to remove this product from shortbook ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeCancel()} color='secondary' >
            No
          </Button>
          <Button color='primary' onClick={() => confirmCancel()} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { loading, shortbookList } = buyer;
  return { loading, shortbookList }
};

export default connect(mapStateToProps, { getShortbook })(ShortBook)