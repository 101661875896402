import React from 'react';

const DeliveryAddress = (props) => {
  const { userDataFromParent } = props;
  const state_name = userDataFromParent ? userDataFromParent.user_state ? userDataFromParent.user_state.name : "" : "";

  return (
    <div className="shadow border-0 bg-white text-black card mt-3">
      <div className="card-body ">
        <div className="">
          <h3 className="mb-md-4 font-weight-bold ">Delivery Address</h3>
          <h4 className="card-title">{userDataFromParent.first_name} {userDataFromParent.last_name}</h4>
          <div className="text-black card-subtitle">{userDataFromParent.user_address},</div>
          <div className="text-black card-subtitle">{userDataFromParent.user_city}, {state_name}, {userDataFromParent.user_pincode},</div>
          <div className="text-black card-subtitle">{userDataFromParent.user_country}</div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryAddress;