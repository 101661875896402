import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import seller from './seller'
import notification from './notification'
import order from './order'
import buyer from './buyer'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    seller(),
    notification(),
    order(),
    buyer()
  ]);
}
