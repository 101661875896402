import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import Chip from '@mui/material/Chip';
import { Carousel, CarouselItem, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AppConfig from 'constants/config'
import { getProductDetails } from 'actions/buyer';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import CartPopOver from './CartPopOver'
import helpertFn from 'constants/helperFunction';
import { isMobile } from 'react-device-detect';
import { EllipsisTooltips } from '../TooltipEllipsis/EllipsisTooltips'
import { EllipsedText } from "../TooltipEllipsis/EllipsedText";
import { Tooltip2 } from "../TooltipEllipsis/Tooltip";
const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const UserProfileCard = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [tooltipElement, setTooltipElement] = React.useState();
  const [popoverOpen, setPopoverOpen] = useState(false)

  function toggle() {
    setPopoverOpen(!popoverOpen);
  }

  function next(size) {
    if (size === 1) {
      setActiveIndex(0);
    } else if (activeIndex === 0) {
      setActiveIndex(activeIndex + 1);
    }
  }

  function previous(size) {
    if (size === 1) {
      setActiveIndex(0);
    } else if (activeIndex === 1) {
      setActiveIndex(activeIndex - 1);
    }
  }

  const handleNavClick = (e, link) => {
    navigate(link)
    props.getProductDetails({ inventory_id: props.product.inventory_id })
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const closepop = () => {
    setPopoverOpen(false)
  }

  const { product, identifier } = props
  const discount = product && product.Discount && product.Discount;
  const otherProduct = product ? product.OtherProducts ? product.OtherProducts : '' : '';
  const inventoryID = product && product && product.inventory_id;
  const chemCombination = product && product.Product && product.Product.chem_combination
  const productMRP = product && product.MRP
  const productName = product && product.Product && product.Product.name && ((product.Product.name).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()
  const bgColor = product && (product.medi_type === 'Ethical branded' || product.medi_type === 'Others') ? '#ff7000' :
    product.medi_type === 'Cool chain' ? '#0b68a8' :
      product.medi_type === 'Surgical' || product.medi_type === 'OTC' || product.medi_type === 'Generic' ? '#038d0e' : '#072791'

  const slides = product ? product ? product.Product ? product.Product.images.length > 0 ? product.Product.images.map((image, index) => {
    return (
      <CarouselItem key={index} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`}
          onClick={(e) => handleNavClick(e, `/product-details/${productName}/${product.inventory_id}`)}>
          <img src={product.Product.images.length === 0 ? logo : `${helpertFn.productImg(image)}`} onError={addDefaultSrc} alt="product" />
        </NavLink>
      </CarouselItem>
    )
  })
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={(e) => handleNavClick(e, `/product-details/${productName}/${product.inventory_id}`)}>
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="product" />
        </NavLink>
      </CarouselItem>
    ]
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={(e) => handleNavClick(e, `/product-details/${productName}/${product.inventory_id}`)}>
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="product" />
        </NavLink>
      </CarouselItem>
    ]
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={(e) => handleNavClick(e, `/product-details/${productName}/${product.inventory_id}`)}>
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="product" />
        </NavLink>
      </CarouselItem>
    ]
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={(e) => handleNavClick(e, `/product-details/${productName}/${product.inventory_id}`)}>
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="product" />
        </NavLink>
      </CarouselItem>
    ]

  return (
    <React.Fragment>
      <EllipsisTooltips onShowTooltip={setTooltipElement} />
      {tooltipElement && <Tooltip2 element={tooltipElement} />}
      <div className="jr-card-buyer text-left pb-2">
        <div className="jr-card-header-color card-header-style container" style={{ minHeight: `${isMobile ? "293px" : "260px"}` }}>
          <Carousel
            autoPlay={false}
            indicators={true}
            activeIndex={activeIndex}
            next={() => next(slides.length)}
            interval={false}
            previous={() => previous(slides.length)}
            className='itemImage box'>
            {slides}
          </Carousel>
          <span style={{ padding: '5px 12px', borderRadius: "0 0 10px 0", fontWeight: 'bold', position: 'absolute', zIndex: 1, backgroundColor: `${bgColor}`, color: 'white', width: 'auto' }} >{product && product.medi_type === 'Others' ? 'PCD' : product.medi_type}</span>
          {
            props.match && props.match.url === '/view-company' ?
              null
              :
              <div>
                <Fab className="jr-badge-up" style={{ backgroundColor: `${bgColor}`, color: 'white' }} >
                  <i class="zmdi zmdi-shopping-cart"
                    style={{ float: "left" }}
                    onClick={toggle}
                    id={"upc1" + inventoryID + identifier}
                  />
                </Fab>
                <div>
                  <Popover style={{ padding: "5px", paddingBottom: "0px", textAlign: "center", backgroundColor: '#fff' }} trigger="legacy" placement="right"
                    isOpen={popoverOpen}
                    target={"upc1" + inventoryID + identifier}
                    toggle={toggle} >
                    <PopoverHeader style={{ padding: "4px 4px", textAlign: "center" }}>Select Quantity</PopoverHeader>
                    <br />
                    <CartPopOver closepop={(e) => closepop(e)}
                      dataFromParent={product}
                    />
                    <PopoverBody style={{ paddingBottom: 0, textAlign: "center" }}>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
          }
        </div>
        {
          product && product.medi_attribute.includes('Jumbo Deal') ?
            <Chip
              label={'Jumbo Deal'}
              size='small'
              style={{ backgroundColor: `${bgColor}`, padding: '17px 10px', color: 'white', float: 'left', position: 'relative', zIndex: 1, marginTop: '-22px', marginLeft: "20px" }}
            />
            : ''
        }
        <div className="jr-card-body py-4 cardBodyPadding" >
          <div className="cardMainDiv">
            {
              product && product.Product && product.Product.name &&
                <EllipsedText>
                  <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${inventoryID}`}
                    onClick={(e) => handleNavClick(e, `/product-details/${productName}/${inventoryID}`)}>
                    <h2 className="card-title card-titleIntranet ellipsis cardHeading" style={{ minHeight: "45px", maxHeight: "45px", overflow: "hidden", marginTop: "2px" }}>
                      {product && product.Product && product.Product.name}
                    </h2>
                  </NavLink>
                  </EllipsedText>
            }
            <EllipsedText>
              <NavLink className="buyerRedirectNavlink" to={`/view-seller/${product && product.Seller && product.Seller._id}`}
                style={{ minHeight: "43px" }}>
                <Chip
                  label={product && product.Seller && product.Seller.company_name}
                  size='small'
                  style={{ padding: '17px 10px' }}
                />
              </NavLink>
            </EllipsedText>
            <div className={`${location.pathname.includes('/view-seller') ? 'pt-1' : 'pt-1'} cardMrp`} style={{
              display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "34px"
            }}>
              <div>MRP <strong style={{ color: "black", marginBottom: "10px", marginTop: "10px" }}>₹ {productMRP.toFixed(2)}</strong></div>
              {
                helpertFn.showPrepaid(product.medi_type, product.Product.isPrepaid && product.Product.isPrepaid, product.prepaidInven && product.prepaidInven) &&
                <Chip
                  label={'Only Prepaid'}
                  className='text-white bg-danger'
                  size='small'
                  style={{ padding: '17px 10px', float: "right" }}
                />}
            </div>
                <React.Fragment>
                  <EllipsedText>
                    <p className={`ellipsis w-100 pt-1 chemCombination`}>
                      {chemCombination}
                    </p>
                  </EllipsedText>
                </React.Fragment>
            {
              props.match && props.match.url === `/view-company` ? null : <p className="d-flex mb-0 align-items-baseline companyName">
                By
                {product && product.Company && product.Company.name &&
                  <EllipsedText>
                    <NavLink className="buyerRedirectNavlink ellipsis" to={`/view-company/${product.Company._id}`}>
                      <h5 className="text-primary ml-1 NameBold ellipsis" style={{ minHeight: "30px", maxHeight: "30px", overflow: "hidden", marginBottom: "2px" }}>{product && product.Company && product.Company.name}
                      </h5>
                    </NavLink>
                </EllipsedText>
                }
              </p>
            }
          </div>
        </div>
        <div className='jr-card-footer intranetFooter ellipsis' >
          {
            discount && discount.discount_type === "Same" ?
              <React.Fragment>
                {`Buy ${discount.discount_on_product.purchase} Get ${discount.discount_on_product.bonus} Free`.length &&
                  <EllipsedText>
                    <Chip
                      className='chipBox'
                      style={{ padding: '17px 10px' }}
                      label={`Buy ${discount.discount_on_product.purchase} Get ${discount.discount_on_product.bonus} Free`.toUpperCase()}
                      size='small'
                    />
                  </EllipsedText>
                }
                <div>
                  <span className='priceColor price'>&#x20B9; {(product.ePTR).toFixed(2)}</span>
                  <span style={{ marginTop: '2px' }}>
                    <span className={'originalPrice font priceFont'}>&#x20B9; {product.PTR.toFixed(2)}</span>
                    &nbsp;
                  </span>
                  <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                </div>
              </React.Fragment>
              :
              discount && discount.discount_type === "SameAndDiscount" ?
                <React.Fragment>
                    <EllipsedText>
                      <Chip
                        className='chipBox'
                        style={{ padding: '17px 10px' }}
                        label={`Buy ${discount.discount_on_product.purchase} Get ${discount.discount_on_product.bonus} Free, and ${(product.Discount.discount_per)}% Off`.toUpperCase()}
                        size='small'
                      />
                    </EllipsedText>
                  <div>
                    <span className={'priceColor price'}>&#x20B9; {(product.ePTR).toFixed(2)}</span>&nbsp;
                    <span style={{ marginTop: '2px' }}>
                      <span className={'originalPrice font priceFont'}>&#x20B9; {product.PTR.toFixed(2)}</span>&nbsp;
                    </span>
                    <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                  </div>
                </React.Fragment> :
                discount && discount.discount_type === "Discount" ?
                  <React.Fragment>
                      <Chip
                        className='chipBox'
                        style={{ padding: '17px 10px' }}
                        label={`${(product.Discount.discount_per)}% off`.toUpperCase()}
                        size='small'
                      />
                    <div>
                      <span className={'priceColor price'}>₹ {(product.ePTR).toFixed(2)}</span>&nbsp;
                      <span style={{ marginTop: '2px' }}>
                        <span className={'originalPrice font priceFont'}>₹  {(product.PTR).toFixed(2)}</span>&nbsp;
                      </span>
                      <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                    </div>
                  </React.Fragment>
                  :
                  discount && discount.discount_type === "Different" ?
                    <React.Fragment>
                      <EllipsedText>
                          <Chip
                            className='chipBox'
                            style={{ padding: '17px 10px' }}
                            label={`Buy ${discount.discount_on_product.purchase} Get ${discount.discount_on_product.bonus} ${otherProduct.name} Free`.toUpperCase()}
                            size='small'
                          />
                      </EllipsedText>
                      <div>
                        <span className={'priceColor price'}>₹ {(product.ePTR).toFixed(2)}</span> &nbsp;
                        <span style={{ marginTop: '2px' }}>
                        </span>
                        <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                      </div>
                    </React.Fragment>
                    :
                    discount && discount.discount_type === "DifferentAndDiscount" ?
                      <React.Fragment>
                          <EllipsedText>
                            <Chip
                              className='chipBox'
                              style={{ padding: '17px 10px' }}
                              label={`Buy ${discount.discount_on_product.purchase} Get ${discount.discount_on_product.bonus} ${otherProduct.name} Free, and ${(product.Discount.discount_per).toFixed(2)}% Off`.toUpperCase()}
                              size='small'
                            />
                          </EllipsedText>
                        <div>
                          <span className={'priceColor price'}>₹ {(product.ePTR).toFixed(2)}</span> &nbsp;
                          <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                        </div>
                      </React.Fragment> :
                      discount && discount.discount_type === "" ?
                        <React.Fragment>
                          <div>
                            <span className={'priceColor price'}>₹ {(product.ePTR).toFixed(2)}</span> &nbsp;
                            <span style={{ marginTop: '2px' }}>
                              <span className={'originalPrice font priceFont'}>{product.MRP === product.PTR ? null : "₹" + (product.MRP).toFixed(2)}</span>&nbsp;
                            </span>
                            <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                          </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div>
                            <span className={'priceColor price'}>₹ {(product.ePTR).toFixed(2)}</span> &nbsp;
                            <span style={{ marginTop: '2px' }}>
                              &nbsp;
                              <span className='discountPrice orderQuantity'>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                            </span>
                          </div>
                        </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { data, otherSellers, relatedProducts, productData, } = buyer;
  return { data, otherSellers, relatedProducts, productData }
};

export default connect(mapStateToProps, { getProductDetails })(UserProfileCard);