import React, { useState, useEffect } from 'react';
import Widget from "components/Widget";
import { connect } from 'react-redux';
import { getDashboardCard, getUserDetail } from 'actions'
import ContainerHeader from 'components/ContainerHeader';
import SalesStatistic from './SalesStatistic';
import axios from 'constants/axios';
import { NavLink } from 'react-router-dom';
import SellerContainer from "components/sellerContainer"

const Dashboard = (props) => {
  const [rating, setRating] = useState([])

  useEffect(() => {
    getRatings()
  }, [])

  const getRatings = async () => {
    await axios.get('/users/getAverageRating', {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
      } else {
        setRating(result.data.detail)
      }
    }).catch(error => { });
    props.getDashboardCard()
  }

  const { userDetails, sellerStats } = props
  return (
    <SellerContainer>
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader rating={rating} title={userDetails ? userDetails.first_name ? `Hi ${userDetails.first_name}` : '' : ''} />
        <div className="row " >
          <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12`}>
            <NavLink to={{ pathname: "/seller/inventory/", search: 'activeSelling' }} style={{ cursor: "pointer" }}>
              <Widget styleName={`p-3 bg-primary text-white widgetCard`}>
                <div className="media align-items-center flex-nowrap py-lg-2">
                  <div className="mr-3 text-white ">
                    <img src={require('assets/images/deals.png')} alt={require('assets/images/deals.png')} />
                  </div>
                  <div className="media-body">
                    <h3 style={{ fontSize: '20px' }} className="dashboardCardHead mb-1 text-white">{'Active Selling Products'}</h3>
                    <p style={{ fontSize: '30px' }} className="dashboardCardText mb-0">{sellerStats.activeSelling ? sellerStats.activeSelling : 0}</p>
                  </div>
                </div>
              </Widget>
            </NavLink>
          </div>
          <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12`}>
            <NavLink to={{ pathname: "/seller/inventory/", search: 'shortExpiry' }} style={{ cursor: "pointer" }}>
              <Widget styleName={`p-3 bg-warning text-white widgetCard`}>
                <div className="media align-items-center flex-nowrap py-lg-2">
                  <div className="mr-3 text-white ">
                    <img src={require('assets/images/expiry.png')} alt={require('assets/images/expiry.png')} />
                  </div>
                  <div className="media-body">
                    <h3 style={{ fontSize: '20px' }} className="dashboardCardHead mb-1 text-white">{'Short Expire Products'}</h3>
                    <p style={{ fontSize: '30px' }} className="dashboardCardText mb-0">{sellerStats && sellerStats.shortExpireProduct ? sellerStats.shortExpireProduct : 0}</p>
                  </div>
                </div>
              </Widget>
            </NavLink>
          </div>
          <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 ${'className'}`}>
            <NavLink to={{ pathname: "/seller/inventory/", search: 'slowMoving' }} style={{ cursor: "pointer" }}>
              <Widget styleName={`p-3 bg-grey text-white widgetCard`}>
                <div className="media align-items-center flex-nowrap py-lg-2">
                  <div className="mr-3 text-white ">
                    <img src={require('assets/images/slow.png')} alt={require('assets/images/slow.png')} />
                  </div>
                  <div className="media-body">
                    <h3 style={{ fontSize: '20px' }} className="dashboardCardHead mb-1 text-white">{'Slow Moving Products'}</h3>
                    <p style={{ fontSize: '30px' }} className="dashboardCardText mb-0">{sellerStats && sellerStats.slowMoving ? sellerStats.slowMoving : 0}</p>
                  </div>
                </div>
              </Widget>
            </NavLink>
          </div>
          <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12`}>
            <NavLink to={{ pathname: "/seller/inventory/", search: 'outOfStock' }} style={{ cursor: "pointer" }}>
              <Widget styleName={`p-3 bg-red text-white widgetCard`}>
                <div className="media align-items-center flex-nowrap py-lg-2">
                  <div className="mr-3 text-white ">
                    <img src={require('assets/images/block.png')} alt={require('assets/images/block.png')} />
                  </div>
                  <div className="media-body">
                    <h3 style={{ fontSize: '20px' }} className="dashboardCardHead mb-1 text-white">{'Out Of Stock Products'}</h3>
                    <p style={{ fontSize: '30px' }} className="dashboardCardText mb-0">{sellerStats && sellerStats.outOfStock ? sellerStats.outOfStock : 0}</p>
                  </div>
                </div>
              </Widget>
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SalesStatistic />
          </div>
        </div>
        <div className="row" style={{ paddingBottom: 25 }}>
          <div className="col-xl-12 col-12">
          </div>
        </div>
      </div>
    </SellerContainer>
  );
}

const mspStateToProps = ({ seller }) => {
  const { userDetails, sellerStats } = seller;
  return { userDetails, sellerStats }
}
export default connect(mspStateToProps, { getDashboardCard, getUserDetail })(Dashboard);