import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import renderTextField from '../components/textBox';
import { Field, Form } from 'react-final-form'
import axios from '../constants/axios';
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from 'actions/Auth';
import { emailPhone } from '../constants/validations'
import { useNavigate } from 'react-router';

const ForgotPassword = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
    if (props.authUser !== null) {
      navigate('/');
    }
  }, [])

  const onSubmit = async (values) => {
    await axios.post('/users/forgotPassword', { email: values.email }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        localStorage.setItem('forgotPassword', true)
        localStorage.setItem('forgotPasswordToken', result.data.token)
        NotificationManager.success(result.data.title);
        setTimeout(() => {
          return navigate('/verifyToken')
        }, 2000)
      }
    })
      .catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
  }

  return (
    <div className="col-xl-12 col-lg-12 forgotPasswordContainer" >
      <div className="jr-card forgotPasswordCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="mb-4">
            <h3>Forgot Password</h3>
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="email" type="text"
                  component={renderTextField} label="Email/Mobile"
                  validate={emailPhone}
                />
                <div className="mb-2">
                  <Button type='submit' variant='contained' color='primary' style={{ backgroundColor: '#072791' }} className='text-white' > Submit </Button>
                </div>
              </form>
            )}
          />
          <div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, { userSignIn, hideMessage, showAuthLoader, })(ForgotPassword);
