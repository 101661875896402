import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './index.css'
import CollapseComponent from './CollapseComponent';

const TableComponent = (props) => {
    const [collapse, setCollapse] = useState(false)

    const OnCollapseProject = () => {
        setCollapse(!collapse)
    }

    const { itemListFromParent, row } = props;

    return (
        <React.Fragment>
            <TableRow
                hover
                className='cursor-pointer'
                key={row.name}
                align={'left'}
                onClick={OnCollapseProject}
            >
                <TableCell align={'left'} component="th" id={props.labelId} scope="row" >
                    <div className="d-contents">
                        {
                            collapse ?
                                <span className='mr-1 cursor-pointer' onClick={() => OnCollapseProject()}>
                                    <i className="zmdi zmdi-chevron-down"></i>
                                </span>
                                :
                                <span className='mr-1 cursor-pointer' onClick={() => OnCollapseProject()}>
                                    <i className="zmdi zmdi-chevron-right"></i>
                                </span>
                        }
                        {row.ticketNo}
                    </div>
                </TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">{row.createdAt}</TableCell>
                <TableCell align="left">{row.orderId}</TableCell>
                <TableCell align="left">{row.issue}</TableCell>
            </TableRow>
            <CollapseComponent
                collapse={collapse}
                value={row}
                stateValue={props.value}
                show={props.show}
                mainOrderData={itemListFromParent}
                filterObj={props.filterObj}
            />
        </React.Fragment>
    )
}

export default TableComponent;