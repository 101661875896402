import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { Carousel, CarouselItem, Col, Row } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';
import AppConfig from 'constants/config'
import './index.css'
import moment from 'moment'
import { getProductDetails } from 'actions/buyer';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import CartPopOver from './CartPopOver'
import helpertFn from 'constants/helperFunction';
import Fab from '@mui/material/Fab';
const logo = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG';

const ListCard = (props) => {
  const location = useLocation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [popoverOpen, setPopoverOpen] = useState(false)

  function toggle() {
    setPopoverOpen(!popoverOpen)
  }

  function next(size) {
    if (size === 1) {
      setActiveIndex(0);
    } else if (activeIndex === 0) {
      setActiveIndex(activeIndex + 1);
    }
  }

  function previous(size) {
    if (size === 1) {
      setActiveIndex(0);
    } else if (activeIndex === 0) {
      setActiveIndex(activeIndex - 1);
    }
  }

  const handleNavClick = () => {
    props.getProductDetails({ inventory_id: props.product.inventory_id })
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://s3.ap-south-1.amazonaws.com/medideals.assets/PLACEHOLER.JPG'
  }

  const closepop = () => {
    setPopoverOpen(false)
  }

  const { product, identifier } = props
  const discount = product && product.Discount && product.Discount;
  const otherProduct = product ? product.OtherProducts ? product.OtherProducts : '' : '';
  const inventoryID = product && product && product.inventory_id;
  const chemCombination = product && product.Product && product.Product.chem_combination
  const productMRP = product && product.MRP
  const productName = product && product.Product && product.Product.name && ((product.Product.name).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase()
  const bgColor = product && (product.medi_type === 'Ethical branded' || product.medi_type === 'Others') ? '#ff7000' : product.medi_type === 'Cool chain' ? '#0b68a8' :
    product.medi_type === 'Surgical' || product.medi_type === 'OTC' || product.medi_type === 'Generic' ? '#038d0e' : '#072791'

  const slides = product ? product ? product.Product ? product.Product.images.length > 0 ? product.Product.images.map((image, index) => {
    return (
      <CarouselItem key={index}>
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={handleNavClick} >
          <img src={product.Product.images.length === 0 ? logo : `${helpertFn.productImg(image)}`} onError={addDefaultSrc} alt="logo" />
        </NavLink>
      </CarouselItem>
    )
  })
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={handleNavClick} >
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="logo" />
        </NavLink>
      </CarouselItem>
    ]
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={handleNavClick} >
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="logo" />
        </NavLink>
      </CarouselItem>
    ]
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={handleNavClick} >
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="logo" />
        </NavLink>
      </CarouselItem>
    ]
    :
    [
      <CarouselItem key={"index"} >
        <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${product.inventory_id}`} onClick={handleNavClick} >
          <img src={product.Product.images.length === 0 ? logo : `${AppConfig.productImageUrl}/}`} alt="logo" />
        </NavLink>
      </CarouselItem>
    ]

  return (
    <React.Fragment>
      <div class='flex-con jr-card p-0' style={{ maxHeight: '170px' }}>
        <div style={{ maxWidth: '20%' }}>
          <div style={{ position: 'relative', display: 'inline-flex', overflow: 'hidden', maxHeight: '100%' }}>
            <Carousel
              autoPlay={false}
              indicators={true}
              activeIndex={activeIndex}
              next={() => next(slides.length)}
              interval={false}
              previous={() => previous(slides.length)}
              className='itemImage'
            >
              {slides}
            </Carousel>
            <span style={{ padding: '2px', fontWeight: 'bold', position: 'absolute', zIndex: 1, backgroundColor: `${bgColor}`, color: 'white', width: 'auto', maxHeight: '40px' }} className='itemImage' >{product && product.medi_type === 'Others' ? 'PCD' : product.medi_type}</span>
          </div>
        </div>
        <div style={{ width: '80%' }} className='pl-4'>
          <Row>
            <Col md={6} xl={6} lg={6} sm={12} xs={6}>
              <div className='pt-2'>
                {
                  product && product.Product && product.Product.name.length > 40 ?
                    <Tooltip
                      className="d-inline-block"
                      id="tooltip-right"
                      title={
                        <h6 className="text-white"
                          style={{ marginTop: "5px" }}>
                          {product && product.Product && product.Product.name}
                        </h6>
                      }
                      placement="right"
                    >
                      <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${inventoryID}`} onClick={handleNavClick}>
                        <h2 className="card-title card-titleIntranet ellipsis w-100" style={{ minHeight: "20px", maxHeight: "20px", overflow: "hidden", marginTop: "5px" }}>
                          {product && product.Product && product.Product.name}
                        </h2>
                      </NavLink>
                    </Tooltip>
                    :
                    <NavLink className="buyerRedirectNavlink" to={`/product-details/${productName}/${inventoryID}`} onClick={handleNavClick}>
                      <h2 className="card-title card-titleIntranet ellipsis w-100" style={{ minHeight: "20px", maxHeight: "20px", overflow: "hidden", marginTop: "5px" }}>
                        {product && product.Product && product.Product.name}
                      </h2>
                    </NavLink>
                }
              </div>
            </Col>
            <Col md={6} xl={6} lg={6} sm={12} xs={6}>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={6} lg={6} sm={12} xs={6}>
              <div className='pb-2'>
                <NavLink className="buyerRedirectNavlink" to={`/view-seller/${product && product.Seller && product.Seller._id}`}
                  style={{ minHeight: "43px" }}>
                  <Chip
                    label={(product && product.Seller && product.Seller.company_name.length) > 25 ?
                      (product && product.Seller && product.Seller.company_name).slice(0, 24) + "..." : product && product.Seller && product.Seller.company_name}
                    size='small'
                  />
                </NavLink>
              </div>
            </Col>
            <Col md={6} xl={6} lg={6} sm={12} xs={6} className='pl-0 pt-1 pr-0'>
              {
                product && product.medi_attribute.includes('Jumbo Deal') &&
                <span size='small' className='text-white'
                  style={{ backgroundColor: `${bgColor}`, margin: "0px", padding: "3px 5px" }}>
                  Jumbo Deal
                </span>
              }&nbsp;
              {
                helpertFn.showPrepaid(product.medi_type, product.Product.isPrepaid && product.Product.isPrepaid, product.prepaidInven && product.prepaidInven) &&
                <span className='text-white bg-danger' style={{ margin: "0px", padding: "3px 5px" }}>Only Prepaid</span>
              }
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={6} lg={6} sm={12} xs={6}>
              <div>
                <p className={location.pathname.includes('/view-seller') ? 'pt-1' : ''} style={{ minHeight: "10px", marginBottom: "0px" }}>
                  MRP: ₹{productMRP.toFixed(2)}
                </p>
              </div>
            </Col>
            <Col md={6} xl={6} lg={6} sm={12} xs={6} className='pl-0'>
              <div className='pl-3'>
                Expiry Date: {product && product.expiry_date && moment(product.expiry_date).format('D MMM, YYYY')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={6} lg={6} sm={12} xs={6}>
              <div>
                {
                  chemCombination.length > 35 ?
                    <React.Fragment>
                      <Tooltip
                        className="d-inline-block"
                        id="tooltip-right"
                        title={
                          <h6 className="text-white"
                            style={{ marginTop: "5px" }}>
                            {product && product.Product && product.Product.chem_combination}
                          </h6>
                        }
                        placement="right"
                      >
                        <p className={`ellipsis w-100 ${location.pathname.includes('/view-seller') ? 'pt-1' : 'pt-1'}`} style={{ minHeight: "25px", marginBottom: "5px" }}>
                          {chemCombination}
                        </p>
                      </Tooltip>
                    </React.Fragment>
                    :
                    <p className={location.pathname.includes('/view-seller') ? 'pt-1' : 'pt-1'} style={{ minHeight: "46px", marginBottom: "5px" }}>
                      {chemCombination}
                    </p>
                }
              </div>
            </Col>
            <Col md={6} xl={6} lg={6} sm={12} xs={6} className='pl-0'>
              {
                discount && discount.discount_type === "Same" ?
                  <React.Fragment>
                    <div className='pl-3'>
                      <span className={'discountPrice'}> Buy {discount.discount_on_product.purchase} Get {discount.discount_on_product.bonus} Free</span>
                    </div>
                    <div className='pl-3'>
                      <span className={'priceColor'}>&#x20B9;{(product.ePTR).toFixed(2)}</span>
                      <span style={{ marginTop: '2px' }}>&nbsp;
                        <span className={'originalPrice font'}>&#x20B9;{product.PTR.toFixed(2)}</span>
                        &nbsp;
                        <span className={'discountPrice'} >Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                      </span>
                    </div>
                  </React.Fragment>
                  :
                  discount && discount.discount_type === "SameAndDiscount" ?
                    <React.Fragment>
                      <div className='pl-3'>
                        <Tooltip
                          className="d-inline-block"
                          id="tooltip-right"
                          title={
                            <span style={{ marginTop: "13px" }}>
                              Buy {discount.discount_on_product.purchase} Get {discount.discount_on_product.bonus} Free, and {(product.Discount.discount_per).toFixed(2)}% Off
                            </span>
                          }
                          placement="right"
                        >
                          <span className={'discountPrice ellipsis w-100'}> Buy {discount.discount_on_product.purchase} Get {discount.discount_on_product.bonus} Free, and {(product.Discount.discount_per).toFixed(2)}% Off</span>
                        </Tooltip>
                      </div>
                      <div className='pl-3'>
                        <span className={'priceColor'}>&#x20B9;{(product.ePTR).toFixed(2)}</span>&nbsp;
                        <span style={{ marginTop: '2px' }}>&nbsp;
                          <span className={'originalPrice font'}>&#x20B9;{product.PTR.toFixed(2)}</span>&nbsp;
                          <span className={'discountPrice'}>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                        </span>
                      </div>
                    </React.Fragment> :
                    discount && discount.discount_type === "Discount" ?
                      <React.Fragment>
                        <div className='pl-3'>
                          <span>Get {(product.Discount.discount_per).toFixed(2)}% discount</span>
                        </div>
                        <div className='pl-3'>
                          <span className={'priceColor'}>₹{(product.ePTR).toFixed(2)}</span>&nbsp;
                          <span style={{ marginTop: '2px' }}>&nbsp;
                            <span className={'originalPrice font'}>₹{(product.PTR).toFixed(2)}</span>&nbsp;
                            <span className={'discountPrice'}>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                          </span>
                        </div>
                      </React.Fragment>
                      :
                      discount && discount.discount_type === "Different" ?
                        <React.Fragment>
                          <div className='pl-3'>
                            <Tooltip
                              className="d-inline-block"
                              id="tooltip-right"
                              title={
                                <h6 className="text-white" style={{ marginTop: "13px" }}>
                                  Buy {discount.discount_on_product.purchase} Get {discount.discount_on_product.bonus} {otherProduct.name} Free
                                </h6>
                              }
                              placement="right"
                            >
                              <span className={'discountPrice ellipsis w-100'}>
                                {("Buy " + discount.discount_on_product.purchase + " Get " + discount.discount_on_product.bonus + " " + otherProduct.name + " Free").slice(0, 25) + "..."}
                              </span>
                            </Tooltip>
                          </div>
                          <div className='pl-3'>
                            <span className={'priceColor'}>₹{(product.ePTR).toFixed(2)}</span> &nbsp;
                            <span style={{ marginTop: '2px' }}>
                              <span className={'discountPrice'}>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                            </span>
                          </div>
                        </React.Fragment>
                        :
                        discount && discount.discount_type === "DifferentAndDiscount" ?
                          <React.Fragment>
                            <div className='pl-3'>
                              <Tooltip
                                className="d-inline-block"
                                id="tooltip-right"
                                title={
                                  <h6 className="text-white" style={{ marginTop: "13px" }}>
                                    Buy {discount.discount_on_product.purchase} Get {discount.discount_on_product.bonus} {otherProduct.name} Free, and {(product.Discount.discount_per).toFixed(2)}% Off
                                  </h6>
                                }
                                placement="right"
                              >
                                <span className={'discountPrice ellipsis w-100'}>
                                  {("Buy " + discount.discount_on_product.purchase + " Get " + discount.discount_on_product.bonus + " " + otherProduct.name + " Free, and " + (product.Discount.discount_per).toFixed(2) + "% Off").slice(0, 25) + "..."}
                                </span>
                              </Tooltip>
                            </div>
                            <div className='pl-3'>
                              <span className={'priceColor'}>₹{(product.ePTR).toFixed(2)}</span> &nbsp;
                              <span className={'discountPrice'} style={{ marginTop: '2px' }}>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                            </div>
                          </React.Fragment> :
                          discount && discount.discount_type === "" ?
                            <React.Fragment>
                              <div className='pl-3 mt-3'></div>
                              <div className='pl-3'>
                                <span className={'priceColor'}>₹{(product.ePTR).toFixed(2)}</span> &nbsp;
                                <span style={{ marginTop: '2px' }}>&nbsp;
                                  <span className={'originalPrice font'}>{product.MRP === product.PTR ? null : "₹" + (product.MRP).toFixed(2)}</span>&nbsp;
                                  <span className={'discountPrice'}>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                                </span>
                              </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <div className='pl-3 mt-3'></div>
                              <div className='pl-3'>
                                <span className={'priceColor'}>₹{(product.ePTR).toFixed(2)}</span> &nbsp;
                                <span style={{ marginTop: '2px' }}>
                                  &nbsp;
                                  <span className={'discountPrice'}>Min: {product.min_order_quantity}, Max: {product.max_order_quantity}</span>
                                </span>
                              </div>
                            </React.Fragment>
              }
            </Col>
          </Row>
          <Row>
            <Col md={8} xl={8} lg={8} sm={12} xs={8} >
              {
                location.pathname.includes('/view-company') ? null : <div><p className="d-flex mb-0 align-items-baseline">
                  By
                  <NavLink className="buyerRedirectNavlink " to={`/view-company/${product.Company._id}`}>
                    <h5 className="text-primary ml-1" style={{ minHeight: "25px", maxHeight: "25px", overflow: "hidden", marginBottom: "5px" }}>{product && product.Company && product.Company.name}</h5>
                  </NavLink>
                </p></div>
              }
            </Col>
            <Col md={4} xl={4} lg={4} sm={12} xs={4} style={{ textAlign: 'end', marginTop: '-15px' }}>
              <div className='pr-3 pb-2'>
                <Fab className="jr-badge-up" style={{ backgroundColor: `${bgColor}`, color: 'white', width: '40px', height: '40px' }} >
                  <i class="zmdi zmdi-shopping-cart"
                    style={{ float: "right", fontSize: '20px' }}
                    onClick={toggle}
                    id={"lc1" + inventoryID + identifier}
                  />
                </Fab>
                <Popover placement="left" isOpen={popoverOpen} target={"lc1" + inventoryID + identifier} toggle={toggle} style={{ padding: "5px", paddingBottom: "0px", textAlign: "center", backgroundColor: '#fff' }}>
                  <PopoverHeader style={{ padding: "4px 4px", textAlign: "center" }}>
                    Select Quantity</PopoverHeader>
                  <CartPopOver closepop={closepop}
                    dataFromParent={product}
                  />
                  <PopoverBody style={{ paddingBottom: 0, textAlign: "center" }}>
                  </PopoverBody>
                </Popover></div>
            </Col>
          </Row>
        </div></div>
    </React.Fragment>
  );
}


const mapStateToProps = ({ buyer }) => {
  const { data, otherSellers, relatedProducts, productData, } = buyer;
  return { data, otherSellers, relatedProducts, productData }
};

export default connect(mapStateToProps, { getProductDetails })(ListCard);