import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { connect } from 'react-redux';
import { getListGroupSettlement } from 'actions/seller'
import AppConfig from 'constants/config'
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'components/PaginationCount';

const SettlementsReports = (props) => {
  const perPage = 50
  const [page, setPage] = useState(0)
  const [to, setTo] = useState(moment())
  const [from, setFrom] = useState(moment().subtract(30, 'day'))

  useEffect(() => {
    let data = {
      page: 1,
      perPage: 50,
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
    }
    props.getListGroupSettlement({ data })
  }, [])

  const handleDateChange = (date, key) => {
    if (key === 'from') {
      setFrom(moment(date))
    } else {
      setTo(moment(date))
    }
  }

  const applyFilter = () => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
    }
    setPage(0)
    props.getListGroupSettlement({ data })
  }

  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().add(1, 'days').format('YYYY-MM-DD')
    }
    props.getListGroupSettlement({ data })
    setTo(moment())
    setFrom(moment())
  }

  const changePage = (page) => {
    let pages = page + 1
    let data = {
      page: pages,
      perPage: perPage,
      filter: '',
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    }
    setPage(page)
    props.getListGroupSettlement({ data })
  };

  const report = (data) => {
    return <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button variant="outlined" className={'text-primary'} onClick={(e) => redirect(data)}>Report</Button>
    </ButtonGroup>
  }

  const redirect = (data) => {
    window.open(`${AppConfig.baseUrl}groupSettlements/generateRemittance?Id=${data._id}`, '_blank');
  }

  const { listGroupSettlement } = props

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: listGroupSettlement && listGroupSettlement.settlements && listGroupSettlement.settlements[0] && listGroupSettlement.settlements[0].metadata && listGroupSettlement.settlements[0].metadata[0] && listGroupSettlement.settlements[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton mt-4' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton mt-4'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "utr",
      label: "UTR",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderValue",
      label: "Order Value",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "netAmt",
      label: "Net Amt",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "paymentDate",
      label: "Payment Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "report",
      label: "Report",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment >
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'from')}
                        name="date"
                        label="FROM"
                        value={from}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        onChange={(date) => handleDateChange(date, 'to')}
                        name="date"
                        label="TO"
                        value={to}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { variant: 'standard' } }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </React.Fragment>
          ),
        },
      },
    },
  ];
  let data1 = []
  listGroupSettlement && listGroupSettlement.data[0] && listGroupSettlement.data[0].data.map((dataOne, index) => {
    data1.push({
      utr: dataOne.utrNo ? dataOne.utrNo : 'N/A',
      orderValue: <div>₹{(dataOne.order_value).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</div>,
      netAmt: <div>₹{(dataOne.net_amt).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</div>,
      paymentDate: moment(dataOne.payment_date).format('DD/MM/YYYY'),
      report: report(dataOne)
    })
  })

  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      {
        props.loading === false ?
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              data={data1}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          :
          <div className="loader-view" style={{ textAlign: "center", marginTop: "150px" }}>
            <CircularProgress />
          </div>
      }
    </div>
  );
}

const mapStateToProps = ({ seller }) => {
  const { listGroupSettlement, loading } = seller
  return { listGroupSettlement, loading }
}
export default connect(mapStateToProps, { getListGroupSettlement })(SettlementsReports)