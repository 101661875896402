import React, { useEffect } from "react";
import CompanyFrontBody from "./companyFrontBody";
import CompanyeHeader from "./companyHeader";
import { getProductsByCompany } from "actions/buyer";
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import BuyerContainer from 'components/buyerContainer';

const BCompanyFront = (props) => {
  const params = useParams()

  useEffect(() => {
    handleScroll()
    props.getProductsByCompany({ company_id: params.id })
  }, [])

  const handleScroll = () => {
    const { index, selected } = props
    if (index === selected) {
      const element = document.getElementById("companyProducts")
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const { productsByCompany } = props;
  return (
    <BuyerContainer>
      <div className="app-wrapper" id="companyProducts">
        <CompanyeHeader dataFromParent={productsByCompany} />
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <CompanyFrontBody />
            </div>
          </div>
        </div>
      </div>
    </BuyerContainer>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { productsByCompany } = buyer
  return { productsByCompany }
};
export default connect(mapStateToProps, { getProductsByCompany })(BCompanyFront);