export const styles = {
    MUIDataTableToolbar: {
        styleOverrides: {
            titleText: {
                fontWeight: "400",
                fontSize: '1.125rem',
                marginBottom: "0.75rem"
            },
            filterCloseIcon: {
                display: "none"
            },
            filterPaper: {
                maxWidth: "300px",
                right: "20px"
            }
        }
    },
    MUIDataTableHeadCell: {
        styleOverrides: {
            root: {
                fontSize: '1rem'
            }
        }
    },
    MUIDataTableFilter: {
        styleOverrides: {
            resetLink: {
                display: "none"
            }
        }
    }
}