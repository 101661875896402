// 2.] Import the ActionTypes in Actions


import {
    API_FAILED,

    GET_FEATURED_PRODUCT_LIST,
    GET_FEATURED_PRODUCT_LIST_SUCCESS,

    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_SUCCESS,

    GET_TRANSACTION_LIST,
    GET_TRANSACTION_LIST_SUCCESS,

    GET_MEDIWALLET,
    GET_MEDIWALLET_SUCCESS,

    GET_PRODUCTS_FOR_YOU,
    GET_PRODUCTS_FOR_YOU_SUCCESS,

    GET_ORDER_LIST,
    GET_ORDER_LIST_SUCCESS,

    GET_REVIEW_LIST,
    GET_REVIEW_LIST_SUCCESS,

    GET_ORDER_HISTORY_LIST,
    GET_ORDER_HISTORY_LIST_SUCCESS,

    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,

    GET_CART_DETAILS,
    GET_CART_DETAILS_SUCCESS,

    REMOVE_FROM_CART,
    REMOVE_FROM_CART_SUCCESS,

    GET_VISITED_CATEGORY,
    GET_VISITED_CATEGORY_SUCCESS,

    GET_PRODUCTS_BY_COMPANY,
    GET_PRODUCTS_BY_COMPANY_SUCCESS,

    BULK_REQUEST,
    BULK_REQUEST_SUCCESS,

    GET_SEARCH_PRODUCT,
    GET_SEARCH_PRODUCT_SUCCESS,

    GET_PRODUCTS_BY_SELLER,
    GET_PRODUCTS_BY_SELLER_SUCCESS,

    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,

    GET_AUTO_FILL,
    GET_AUTO_FILL_SUCCESS,

    GET_FILTER,
    GET_FILTER_SUCCESS,

    EMPTY_CART,
    EMPTY_CART_SUCCESS,

    GET_UPDATE_ORDER,
    GET_UPDATE_ORDER_SUCCESS,

    GET_PROMOTIONS_LISTING,
    GET_PROMOTIONS_LISTING_SUCCESS,

    GET_MARK_READ_LIST_FOR_BUYER,
    GET_MARK_READ_LIST_FOR_BUYER_SUCCESS,
    LOGOUT_USER,

    GET_PRODUCT_REQUEST_LIST,
    GET_PRODUCT_REQUEST_LIST_SUCCESS,

    GET_SHORTBOOK,
    GET_SHORTBOOK_SUCCESS,

    GET_SHORT_PRODUCTS,
    GET_SHORT_PRODUCTS_SUCCESS,

    GET_TICKET_LIST,
    GET_TICKET_LIST_SUCCESS,

    GET_TICKET_HISTORY_LIST,
    GET_TICKET_HISTORY_LIST_SUCCESS,

    GET_BULK_LIST,
    GET_BULK_LIST_SUCCESS,

    GET_BULK_HISTORY_LIST,
    GET_BULK_HISTORY_LIST_SUCCESS,
    
  } from 'constants/ActionTypes'



  // export const apiFailed = (error) => {
  //   return {
  //     type: API_FAILED,
  //     payload: error
  //   };
  // };
  
export const logoutUser = (data,history)=>{
  return{
    type:LOGOUT_USER,
    payload:{data,history}
  }
}

  export const getFeaturedProductList = (data) => {
    return {
      type: GET_FEATURED_PRODUCT_LIST,
      payload: data
    };
  };
  
  export const getFeaturedProductListSuccess = (data) => {
    return {
      type: GET_FEATURED_PRODUCT_LIST_SUCCESS,
      payload: data
    };
  };


  //------------------------//

  export const getProductDetails = (data) => {
    return {
      type: GET_PRODUCT_DETAILS,
      payload: data
    };
  };
  
  export const getProductDetailsSuccess = (data) => {
    return {
      type: GET_PRODUCT_DETAILS_SUCCESS,
      payload: data
    };
  };

  // --------------------------------- //

  export const getTransactionList = (data) =>{
    return {
        type: GET_TRANSACTION_LIST,
        payload: data
    };
  };

  export const getTransactionListSuccess = (data) => {
    return {
        type: GET_TRANSACTION_LIST_SUCCESS,
        payload: data
    };
  };


  // --------------------------------- //


  export const getMediWallet = (data) =>{
    return {
        type: GET_MEDIWALLET,
        payload: data
    };
  };

  export const getMediWalletSuccess = (data) => {
    return {
        type: GET_MEDIWALLET_SUCCESS,
        payload: data
    };
  };
  

  // --------------------------------- //


  export const getProductsForYou = (data) =>{
    return {
        type: GET_PRODUCTS_FOR_YOU,
        payload: data
    };
  };

  export const getProductsForYouSuccess = (data) => {
    return {
        type: GET_PRODUCTS_FOR_YOU_SUCCESS,
        payload: data
    };
  };

  // --------------------------------- //


  export const getOrderList = (data) =>{
    return {
        type: GET_ORDER_LIST,
        payload: data
    };
  };

  export const getOrderListSuccess = (data) => {
    return {
        type: GET_ORDER_LIST_SUCCESS,
        payload: data
    };
  };


    // --------------------------------- //


    export const getOrderHistoryList = (data) =>{
      return {
          type: GET_ORDER_HISTORY_LIST,
          payload: data
      };
    };

    export const getOrderHistoryListSuccess = (data) => {
      return {
          type: GET_ORDER_HISTORY_LIST_SUCCESS,
          payload: data
      };
    };  
  

  // --------------------------------- //


  export const getReviewList = (data) =>{
    return {
        type: GET_REVIEW_LIST,
        payload: data
    };
  };
  export const getReviewListSuccess = (data) => {
    return {
        type: GET_REVIEW_LIST_SUCCESS,
        payload: data
    };
  };


  //--------------------------------------------//


  export const addToCart = (data) =>{
    return {
        type: ADD_TO_CART,
        payload: data
    };
  };
  export const addToCartSuccess = (data) => {
    return {
        type: ADD_TO_CART_SUCCESS,
        payload: data
    };
  };


  //--------------------------------------------//


  export const getCartDetails = (data) =>{
    return {
        type: GET_CART_DETAILS,
        payload: data
    };
  };
  export const getCartDetailsSuccess = (data) => {
    return {
        type: GET_CART_DETAILS_SUCCESS,
        payload: data
    };
  };

  //--------------------------------------------//


  export const removeFromCart = (data) =>{
    return {
        type: REMOVE_FROM_CART,
        payload: data
    };
  };
  
  export const removeFromCartSuccess = (data) => {
    return {
        type: REMOVE_FROM_CART_SUCCESS,
        payload: data
    };
  };

  //--------------------------------------------//


  export const getVisitedCategory = (data) =>{
    return {
        type: GET_VISITED_CATEGORY,
        payload: data
    };
  };
  export const getVisitedCategorySuccess = (data) => {
    return {
        type: GET_VISITED_CATEGORY_SUCCESS,
        payload: data
    };
  };

  //--------------------------------------------//

   export const getProductsByCompany = (data) =>{
    return {
        type: GET_PRODUCTS_BY_COMPANY,
        payload: data
    };
  };
  export const getProductsByCompanySuccess = (data) => {
    return {
        type: GET_PRODUCTS_BY_COMPANY_SUCCESS,
        payload: data
    };
  };

    //--------------------------------------------//

    export const bulkRequest = (data) =>{
      return {
          type: BULK_REQUEST,
          payload: data
      };
    };
    export const bulkRequestSuccess = (data) => {
      return {
          type: BULK_REQUEST_SUCCESS,
          payload: data
      };
    };


    //--------------------------------------------//

    export const getSearchProduct = (data) =>{
      return {
          type: GET_SEARCH_PRODUCT,
          payload: data
      };
    };
    export const getSearchProductSuccess = (data) => {
      return {
          type: GET_SEARCH_PRODUCT_SUCCESS,
          payload: data
      };
    };

     //--------------------------------------------//

   export const getProductsBySeller = (data) =>{
    return {
        type: GET_PRODUCTS_BY_SELLER,
        payload: data
    };
  };
  export const getProductsBySellerSuccess = (data) => {
    return {
        type: GET_PRODUCTS_BY_SELLER_SUCCESS,
        payload: data
    };
  };

   //--------------------------------------------//

   export const getCategories = (data) =>{
    return {
        type: GET_CATEGORIES,
        payload: data
    };
  };
  export const getCategoriesSuccess = (data) => {
    return {
        type: GET_CATEGORIES_SUCCESS,
        payload: data
    };
  };

     //--------------------------------------------//

     export const getAutoFill = (data) =>{
      return {
          type: GET_AUTO_FILL,
          payload: data
      };
    };
    export const getAutoFillSuccess = (data) => {
      return {
          type: GET_AUTO_FILL_SUCCESS,
          payload: data
      };
    };

      //--------------------------------------------//

      export const getFilter = (data) =>{
      return {
          type: GET_FILTER,
          payload: data
      };
    };
    export const getFilterSuccess = (data) => {
      return {
          type: GET_FILTER_SUCCESS,
          payload: data
      };
    };

      //--------------------------------------------//

    export const emptyCart = (data)=>({
      type:EMPTY_CART,
      payload:data
    })
    export const emptyCartSuccess = (data)=>({
      type:EMPTY_CART_SUCCESS,
      payload:data
    })

    //--------------------------------------------//

    export const getUpdateOrder = (data) =>{
      return {
          type: GET_UPDATE_ORDER,
          payload: data
      };
    };
    
    export const getUpdateOrderSuccess = (data) => {
      return {
          type: GET_UPDATE_ORDER_SUCCESS,
          payload: data
      };
    };

//--------------------------------------------//

export const getPromotionsListing = (data) =>{
  return {
      type: GET_PROMOTIONS_LISTING,
      payload: data
  };
};

export const getPromotionsListingSuccess = (data) => {
  return {
      type: GET_PROMOTIONS_LISTING_SUCCESS,
      payload: data
  };
};

// ==============================================

export const getMarkReadForBuyer = (data) =>{
  return {
      type: GET_MARK_READ_LIST_FOR_BUYER,
      payload: data
  };
};

export const getMarkReadForBuyerSuccess = (data) => {
  return {
      type: GET_MARK_READ_LIST_FOR_BUYER_SUCCESS,
      payload: data
  };
};

  // --------------------------------- //


  export const getProductRequestList = (data) =>{
    return {
        type: GET_PRODUCT_REQUEST_LIST,
        payload: data
    };
  };

  export const getProductRequestListSuccess = (data) => {
    return {
        type: GET_PRODUCT_REQUEST_LIST_SUCCESS,
        payload: data
    };
  };

  // --------------------------------- //


  export const getShortbook = (data) =>{
    return {
        type: GET_SHORTBOOK,
        payload: data
    };
  };

  export const getShortbookSuccess = (data) => {
    return {
        type: GET_SHORTBOOK_SUCCESS,
        payload: data
    };
  };

  //----------------------------------------//
  export const getShortProducts = (data) =>{
    return {
        type: GET_SHORT_PRODUCTS,
        payload: data
    };
  };

  export const getShortProductsSuccess = (data) => {
    return {
        type: GET_SHORT_PRODUCTS_SUCCESS,
        payload: data
    };
  };

  export const getTicketList = (data) =>{
    return {
        type: GET_TICKET_LIST,
        payload: data
    };
  };

  export const getTicketListSuccess = (data) => {
    return {
        type: GET_TICKET_LIST_SUCCESS,
        payload: data
    };
  };


    // --------------------------------- //


    export const getTicketHistoryList = (data) =>{
      return {
          type: GET_TICKET_HISTORY_LIST,
          payload: data
      };
    };

    export const getTicketHistoryListSuccess = (data) => {
      return {
          type: GET_TICKET_HISTORY_LIST_SUCCESS,
          payload: data
      };
    }; 

    // --------------------------------- //

    export const getBulkList = (data) =>{
      return {
          type: GET_BULK_LIST,
          payload: data
      };
    };
  
    export const getBulkListSuccess = (data) => {
      return {
          type: GET_BULK_LIST_SUCCESS,
          payload: data
      };
    };

    export const getBulkHistoryList = (data) =>{
      return {
          type: GET_BULK_HISTORY_LIST,
          payload: data
      };
    };

    export const getBulkHistoryListSuccess = (data) => {
      return {
          type: GET_BULK_HISTORY_LIST_SUCCESS,
          payload: data
      };
    }; 