import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { getBulkListSeller } from 'actions/seller';
import { Col, Row, Label, Input } from 'reactstrap';
import moment from 'moment';
import ReactStrapTextField from 'components/ReactStrapTextField';
import { Field, Form } from 'react-final-form'
import { number, number0 } from 'constants/validations';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AxiosRequest from 'sagas/axiosRequest'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "components/PaginationCount";

const BulkList = (props) => {
  const [params] = useSearchParams()
  let navigate = useNavigate();
  const tab = params && params.get("tab") ? Number(params.get("tab")) : 0
  const perPage = 100
  const page = params && Number(params.get("pageO")) ? Number(params.get("pageO")) : 0
  const [supplyPopup, setSupplyPopup] = useState(false)
  const [viewSupplyPopup, setViewSupplyPopup] = useState(false)
  const [offerPopup, setOfferPopup] = useState(false)
  const [dialogData, setDialogData] = useState('')
  const [offerData, setOfferData] = useState('')
  const [bestOffer, setBestOffer] = useState('')
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    let data = {
      page: (params && Number(params.get("pageO")) ? Number(params.get("pageO")) : 0) + 1,
      perPage,
    }
    if (tab === 0) {
      props.getBulkListSeller({ data })
    }
  }, [params])

  useEffect(() => {
    if (props.tabValue === 0) {
      navigate(`/seller/bulkrequests?tab=0`, { replace: true })
    }
  }, [props.tabValue])

  const getAmount = (values) => {
    let ptr = dialogData && Number((dialogData.ptr).toFixed(2));
    let value = Number(values.quantity) * Number(ptr) - ((Number(values.quantity) * Number(ptr)) * Number(values.discount) / 100);
    return value
  }

  const handleLogClose = () => {
    setSupplyPopup(false)
    setViewSupplyPopup(false)
    setDialogData('')
    setOfferData('')
    setOfferPopup(false)
  }

  const actions = (d, offers) => {
    let Id = props.sellerId && props.sellerId;
    let offer = offers.filter(e => (Id).toString() === (e.seller_id).toString())
    if (offer.length > 0 && offer[0].isSupplied === true) {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setViewSupplyPopup(true); setDialogData(d); setOfferData(offer[0]) }}>
        VIEW
      </span>
    } else if (offer.length > 0 && offer[0].isConfirmed === true) {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setViewSupplyPopup(true); setSupplyPopup(true); setDialogData(d); setOfferData(offer[0]) }}>
        SUPPLY
      </span>
    } else if (offer.length > 0 && d.status === 'OFFERED') {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setViewSupplyPopup(true); setDialogData(d); setOfferData(offer[0]) }}>
        DETAILS
      </span>
    } else if (offer.length === 0) {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => openOffer(d)}>
        OFFER
      </span>
    } else {
      return <span className='text-muted' style={{ cursor: 'pointer' }} >
        N/A
      </span>
    }
  }

  const openOffer = async (d) => {
    setOfferPopup(true)
    setDialogData(d)
    let data = { Id: d.product_id }
    let response1 = await AxiosRequest.axiosHelperFunc('post', 'product/bestOffer', '', data)
    let offer = response1 && response1.data && response1.data.data.slice(0, 3);
    setBestOffer(offer[0])
  }

  const handleOffer = (values) => {
    let data = {
      Id: dialogData._id, quantity: Number(values.quantity), amount: Number(values.amount),
      discount: values.discount, expiry_date: moment(values.expiryDate).endOf('month').format()
    }
    axios.post('/bulkRequest/addOffer', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
      }
      handleLogClose();
      let data = { page: 1, perPage: 20 }
      props.getBulkListSeller({ data })
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const handleSupply = () => {
    setDisabled(true)
    let data = { requestId: dialogData._id }
    axios.post('/bulkRequest/sellerSupply', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
      }
      handleLogClose();
      let data = { page: 1, perPage: 20 }
      props.getBulkListSeller({ data })
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const status = (data) => {
    let Id = props.sellerId && props.sellerId;
    let offer = data.offers.filter(e => (Id).toString() === (e.seller_id).toString())
    if (offer.length === 0) {
      return <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle('REQUESTED') }}>REQUESTED</div>
    } else if (offer.length > 0 && offer[0].isConfirmed === true && data.status === 'CONFIRMED') {
      return <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle('CONFIRMED') }}>CONFIRMED</div>
    } else if (offer.length > 0 && offer[0].isSupplied === true && data.status === 'SUPPLIED') {
      return <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle('SUPPLIED') }}>SUPPLIED</div>
    } else if (offer.length > 0) {
      return <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle('OFFERED') }}>OFFERED</div>
    }
  }

  const statusStyle = (data) => {
    let status = data.toLowerCase();
    return status.includes("supplied") ? "#B45F06" : status.includes("requested") ? '#2B78E4' : status.includes("confirmed") ? "#38761D" : status.includes("offered") ? "#990000" : status.includes("expired") ? "#ea9a99" : status.includes("fulfilled") ? "#b6d8a8" : status.includes("closed") ? "#9fc4f7" : '';
  }

  const changePage = (page) => {
    let obj = { pageO: page, tab: 0 }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/seller/bulkrequests?${url}`, { replace: true })
  };

  let { bulkListSeller, loading } = props;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: bulkListSeller && bulkListSeller.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "product",
      label: "Product name",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "discount",
      label: " Additional expected discount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity",
      label: "Expected quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];
  let data = []
  bulkListSeller && bulkListSeller.data1.length > 0 && bulkListSeller.data1.map(d => {
    data.push({
      product: d.product_name,
      discount: `${d.discount}%`,
      quantity: d.quantity,
      status: status(d),
      date: moment(d.createdAt).format('DD/MM/YYYY'),
      actions: actions(d, d.offers),
    })
  })

  const theme2 = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '0.375rem 0.75rem !important'
          }
        }
      },
    }
  });

  return (
    <div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> :
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            style={{ borderRadius: '0px !important' }}
          />
        }
      </div>
      <Dialog open={offerPopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          OFFER DETAILS
        </DialogTitle>
        <Form
          onSubmit={handleOffer}
          initialValues={{
            quantity: dialogData.quantity ? dialogData.quantity : 0,
            discount: dialogData.discount ? dialogData.discount : 0,
            amount: dialogData.amount ? dialogData.amount : 0,
            expiryDate: moment().add(3, 'months')
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogContent>
                <Row>
                  <Col sm={12} lg={12} md={12} xl={12} xs={12} className="mb-2">
                    <Label>Product Name</Label><span className='text-danger float-right'>(All prices excluding GST)</span>
                    <Input value={dialogData.product_name} readOnly />
                    {bestOffer && <Label for="name" className='mt-3 text-primary'>{`${bestOffer.disc && bestOffer.disc.name === 'Same' ? 'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' free' :
                      bestOffer.disc && bestOffer.disc.name === 'SameAndDiscount' ? 'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' free and ' + (bestOffer.disc.discount_per).toFixed(2) + '% discount' :
                        bestOffer.disc && bestOffer.disc.name === 'Discount' ?
                          'Best Offer: ' + bestOffer.disc.discount_per + '% discount' :
                          bestOffer.disc && bestOffer.disc.name === 'DifferentAndDiscount' ?
                            'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' ' + bestOffer.inven[0].productName + ' free and ' + (bestOffer.disc.discount_per).toFixed(2) + '% discount' :
                            bestOffer.disc && bestOffer.disc.name === 'Different' ?
                              'Best Offer: Buy ' + bestOffer.disc.discount_on_product.purchase + ' and get ' + bestOffer.disc.discount_on_product.bonus + ' ' + bestOffer.inven[0].productName + ' free' : bestOffer.disc && bestOffer.disc.type === '' ?
                                '' : ''}`}</Label>
                    }
                  </Col>
                  <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
                    <Label>Best Offered Effective PTR</Label>
                    <Input value={dialogData && (dialogData.ptr).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readOnly />
                  </Col>
                  <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
                    <Label>Estimated Effective PTR</Label>
                    <Input value={(isNaN(getAmount(values)) || isNaN(values.quantity) || values.quantity === 0) ? 0 : (Number(getAmount(values)) / Number(values.quantity)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readOnly />
                  </Col>
                  <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
                    <Field type="text" name='quantity' id='quantity' label={'Quantity'} component={ReactStrapTextField} validate={number0} />
                  </Col>
                  <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
                    <Field type="text" name='discount' id='discount' label={'Discount %'} component={ReactStrapTextField} validate={number} />
                  </Col>
                  <Col sm={6} lg={6} md={6} xl={6} xs={6}>
                    <Field name="expiryDate">
                      {
                        props => <>
                          <Label>Product expiry</Label>
                          <ThemeProvider theme={theme2}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                views={['year', 'month']}
                                name="expiryDate"
                                sx={{ width: "100%" }}
                                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                minDate={moment().add(3, 'months')}
                                slotProps={{ textField: { variant: 'outlined' } }}
                                onChange={props.input.onChange}
                                value={props.input.value}
                              />
                            </LocalizationProvider>
                          </ThemeProvider>
                        </>
                      }
                    </Field>
                  </Col>
                  <Col sm={6} lg={6} md={6} xl={6} xs={6}>
                    <Label>Final Cost </Label>
                    <Input label="" type="text" name='value' readOnly value={(getAmount(values)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} />
                    {
                      (getAmount(values) < (Number(dialogData.amount) * 0.9)) && <span className='text-danger'>Value can't be less than {(Number(dialogData.amount) * 0.9).toFixed(2)}</span>
                    }
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions className='mr-2'>
                <Button onClick={handleLogClose} color='secondary' >
                  Close
                </Button>
                {((getAmount(values) < (Number(dialogData.amount) * 0.9)) || values.quantity === '') ?
                  <Button disabled={true}> MAKE OFFER </Button> :
                  <Button type="submit" color='primary'> MAKE OFFER </Button>
                }
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
      <Dialog open={viewSupplyPopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          {dialogData && dialogData.status === 'OFFERED' ? 'OFFER' : 'SUPPLY'} DETAILS
        </DialogTitle>
        <DialogContent>
          {(dialogData && offerData) && <Row>
            <Col sm={12} lg={12} md={12} xl={12} xs={12} className="mb-2">
              <Label>Product Name</Label><span className='text-danger float-right'>(All prices excluding GST)</span>
              <Input value={dialogData && dialogData.product_name} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Best Offered Effective PTR</Label>
              <Input value={dialogData && (dialogData.ptr).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Estimated Effective PTR</Label>
              <Input value={offerData && (Number(offerData.amount) / Number(offerData.quantity)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Quantity</Label>
              <Input value={offerData && offerData.quantity} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Discount %</Label>
              <Input value={offerData && `${offerData.discount}%`} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6}>
              <Label for="expiryDate">Product expiry</Label>
              {
                offerData && offerData.expiry_date ? <Input value={offerData && moment(offerData.expiry_date).format('MMMM YY')} readOnly /> :
                  <Input value={''} readOnly />
              }
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6}>
              <Label>Final Cost  </Label>
              <Input type="text" name='value' readOnly value={offerData && offerData.amount && isNaN(offerData.amount) ? 0 : (offerData.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} />
            </Col>
          </Row>}
        </DialogContent>
        <DialogActions className='mr-2'>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
          {
            supplyPopup === true &&
            <Button disabled={disabled} onClick={handleSupply} color='primary'>
              Yes
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div >
  );
}

const mapStateToProps = ({ seller }) => {
  const { bulkListSeller, sellerId, loading } = seller;
  return { bulkListSeller, sellerId, loading }
};

export default connect(mapStateToProps, { getBulkListSeller })(BulkList);