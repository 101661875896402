import React, { useRef, useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CustomScrollbars from 'util/CustomScrollbars';
import '../index.css'
import BuyerContainer from 'components/buyerContainer';

const TermsOfUse = (props) => {
    const myRef = useRef()

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <BuyerContainer>
            <div className="app-wrapper" ref={myRef}>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <ContainerHeader title="Terms Of Use" />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 signinContainer  " >
                        <div className="jr-card">
                            <div className="animated slideInUpTiny animation-duration-3 ">
                                <p style={{ color: "#5b5b5b", fontSize: '15px', textAlign: 'justify' }} >
                                    <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                            Last updated on [Feb 27th, 2020]
                                            <br />
                                            <br />
                                            THIS  DOCUMENT  IS  AN  ELECTRONIC  CONTRACT  RECOGNISED  UNDER  THE  PROVISIONS  OF  THE INFORMATION TECHNOLOGY ACT OF 2000 READ ALONG WITH ITS RULES, AND OUTLINES THE TERMS ON WHICH A USER MAY THROUGH ITS WEB APPLICATION –www.medimny.com(“WEBSITE”), OWNED AND OPERATED BY FELICITAS DIGITECH PRIVATE LIMITED.
                                            <br />
                                            <br />
                                            PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING  YOUR  LEGAL  RIGHTS,  REMEDIES  AND  OBLIGATIONS.  THESE  INCLUDE  VARIOUS LIMITATIONS  AND  EXCLUSIONS,  A  CLAUSE  THAT  GOVERNS  THE  JURISDICTION  AND  VENUE  OF DISPUTES, AND OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
                                            <br />
                                            <br />
                                            YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE WEBSITE OR SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT FROM OR ON THE WEBSITE OR THROUGH THE SERVICES, YOU ARE INDICATING THAT YOU HAVE READ, AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS(AS APPLICABLE), WHETHER OR NOT YOU HAVE REGISTERED WITH THE WEBSITE. IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE WEBSITE, SERVICES, OR COLLECTIVE CONTENT. IF YOU ACCEPT OR AGREE TO THESE TERMS ON BEHALF OF A COMPANY  OR  OTHER  LEGAL  ENTITY,  YOU  REPRESENT  AND  WARRANT  THAT  YOU  HAVE  THE AUTHORITY  TO  BIND  THAT  COMPANY  OR  OTHER  LEGAL  ENTITY  TO  THESE  TERMS  AND,  IN  SUCH EVENT, "YOU" AND "YOUR" WILL REFER AND APPLY TO THAT COMPANY OR OTHER LEGAL ENTITY.
                                            <br />
                                            <br />
                                            FELICITAS DIGITECH PRIVATE LIMITED (HEREINAFTER REFERRED TO AS “COMPANY”, “WE”, ‘’US” OR “OUR”) MAY REVISE THESE TERMS OF SERVICE INCLUDING PRIVACY POLICY, AT ITS DISCRETION. WE DO NOT HAVE ANY OBLIGATION OR RESPONSIBILITY TO INFORM OR INTIMATE YOU IN ANY MANNER, DIRECTLY OR INDIRECTLY, OF THE POSTING ONCE UPDATED OR PRIOR TO SUCH UPDATION. YOUR USE OF  THE  WEBSITE  (DEFINED  BELOW)  FOLLOWING  ANY  SUCH  MODIFICATION  CONSTITUTES  YOUR AGREEMENT TO FOLLOW AND BE BOUND BY THE TERMS OF USE AS MODIFIED. ANY ADDITIONAL TERMS AND CONDITIONS, DISCLAIMERS, PRIVACY POLICIES,REFUND POLICY AND OTHER POLICIES APPLICABLE TO GENERAL AND SPECIFIC AREAS OF THIS WEB SITE OR TO PARTICULAR CONTENT ARE ALSO CONSIDERED AS TERMS OF USE. YOU SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THE TERMS OF USE, BECAUSE THEY ARE BINDING ON YOU. THE TERMS "YOU","USER"AND “SERVICE PROVIDER” AS USED HEREIN REFER TO ALL INDIVIDUALS AND/OR ENTITIES ACCESSING THE WEB SITE FOR ANY REASON, AS APPLICABLE TO SUCH INDIVIDUALS AND/OR ENTITIES ACCESSING THE WEB SITE.
                                            <br />
                                            <br />
                                            <strong>PART A –GENERAL TERMS RELATING TO WEBSITE SERVICES</strong>
                                            <br /><br />
                                            <strong>1.Website</strong>
                                            <br /><br />
                                            You agree and acknowledge the following:
                                            <ul style={{ listStyleType: "none" }}>
                                                <li>1.1 that  we  are  not  a  Seller  of  the  products  listed  on  this  Website;  that  we  are  not  an advertiser  of  any  of  the  items  listed  on  this  Website;  that  we  are  not  a  party  to  the transactions between registered users on this Website. The Website only provides the venue  for  registered  users  to  privately  transact  for  trade  of pharmaceutical  products, medicines, supplements, equipments, and healthcare products and devices(“Items”).
                                                </li>
                                                <li>1.2 We  are  not  involved  in  the  actual  transaction  or  transfer  of  Items between  users.  We recommend  that  you  take  precautionary  measures  when  communicating  with  other users and entering into any transaction.
                                                </li>
                                                <li>1.3 We  make  no  representation  as  to  the  truthfulness  of  other  users  on  this  Website.  We recommend  that  you  take  precautionary  measures  to  determine  another  user’s  age, identity, location, and authority to enter into contracts.
                                                </li>
                                                <li>1.4 We make no representation that users on this Website shall complete the transfer of the Item  or  deliver  the  Item  to  the  buyer;  nor  that  a  user  has  truthfully  and  accurately described an Item posted on the Website; or that the transfer of an Item complies with applicable legal requirements for the transaction.
                                                </li>
                                                <li>1.5 Although we may provide references to relevant laws, nothing on this Website is meant to constitute legal advice. You should check with your country’s laws to ensure you are in full compliance with legal requirements. If you are unsure about proper compliance, seek the assistance of a licensed and qualified attorney. You shall be permitted to access the Website Services  (as  defined  below)  only  upon  creating an  Account  (as  defined below) and obtaining a registration on the Website.
                                                </li>
                                            </ul>
                                            <strong> 1.Registration<strong></strong></strong>
                                            <ul>
                                                <li>2.1 You shall be permitted to access the Website only upon creating an Account (as defined below) and obtaining a registration on the Website.
                                                </li>
                                                <li>
                                                    2.2 Your ability to continue using the Website is subject to Your continued registration on the Website.
                                                </li>
                                            </ul>
                                            <strong> 3. Services<strong></strong></strong>
                                            <br /><br />
                                            The Website provides the following services (collectively, <strong>“Website Services”)</strong>:
                                            <br /><br />
                                            3.1 To the Users:
                                            <ul>
                                                <li>
                                                    (a) It allows Users to view the profiles of all service providers registered on the Website(hereinafter referred to as <strong>“Registered Service Providers”</strong>)for the sale of Items and related services, along with their service offerings,offers, packages and prices;
                                                </li>
                                                <li>
                                                    (b) It enables Users to  locate  the  nearest  and preferred Registered  Service  Providers factoring inthe User’s location, requirement and preference;
                                                </li>
                                                <li>
                                                    (c) It facilitates regular interface of Users with their chosen Registered Service Provider to purchase the Items, and online payment for services through the Website;
                                                </li>
                                                <li>
                                                    (d) It provides Users with  the  option  to  post  feedback  and  ratings  in  relation  to  any Registered Service Provider products may have been purchased by the User;
                                                </li>
                                                <li>
                                                    (e) It facilitates periodic reminders from Registered Service Providers to the Users; and
                                                </li>
                                                <li>
                                                    (f) It serves as a medium to drive improvements in the quality of service provided by Registered Service Providers based on User feedback
                                                </li>
                                            </ul>
                                            3.2 To the Service Providers:
                                            <ul>
                                                <li>
                                                    (a) It  allows  the Users to access  to  all Items offered  by the  Service  Providers on  the Website;
                                                </li>
                                                <li>
                                                    (b) It enables the Service Providers to effectively communicate their service offerings, with  respect  to  Items  for  sale,packages, promotions, and  prices  to  the  registered customers of the Website
                                                </li>
                                                <li>
                                                    (c) It facilitates regular interface of the Service Providers with the registered customers for receiving and accepting the orders to Purchase the Items, provided the Service Provider  has  availed  of  the  facility  to  accept  bookings  and  payment  for the  Items through the Website;and
                                                </li>
                                                <li>
                                                    (d) It  facilitates  improvement  in  the  quality  of  the  services  provided  by  the  Service Providers on the basis of customer reviews and feedback
                                                </li>
                                            </ul>
                                            The Company may, at its discretion, add, modify or remove any of the Website Services listed above from time to time without notice.
                                            <br /><br />
                                            3.3 The Company is only a facilitator between You and the Registered Service Provider. Any contract  for Items  purchased between You and  the Registered  Service  Provider shall strictly be a bipartite contract between You and the Registered Service Provider and the Company shall not be a party to the same. The Company shall not and is not required to mediate or resolve any dispute or disagreement between You and the Registered Service Providers.
                                            <br /><br />
                                            3.4 The Company has not independently verified and does not guarantee the truthfulness of the  credentials  of  the Registered  Service  Provider and any  description  of  their  business, authorisations, Items offerings including the nature of services, pricing, location, contact details,  etc.provided by  the Registered  Service  Provider.  Such  information  has  been collected  from  the  concerned Registered  Service  Provider,  who  has  guaranteed  the truthfulness and completeness of the same and the Company shall not in any manner, be responsible or liable for the such information.
                                            <br /><br />
                                            3.5 The Website shall also  display  the  cost  of  the Itemsfor  sale  by  the Registered  Service Providers including the applicable taxes payable.
                                            <br /><br />
                                            3.6 Any Items once paid for purchases  on the Website, cannot be cancelled or modified by You after the goods are in dispatch state.The Company shall refund all amounts (adjusting for charges, if any) collected from You towards such Items purchased that were cancelled by You and/or the Registered Seller, in the form of MediMoney in MediWallet, within 15 business days of such cancellation.
                                            <br /><br />
                                            3.7 It shall be the responsibility of the Registered Service Provider to honour the purchase of the  Items  made  by  You.  The  Company  is  not  responsible,  and  has  no  liability  for the genuineness, the quality or completeness of such transactions provided by the Registered Service  Providers  to  You,  including  any  delay  in  services  by  the  Registered  Service Providers or cancellation of any Items purchase made with a Registered Service Provider by You through the Website, or any offers, discounts or service packages communicated by  the  Registered  Service  Providers, or  for  any  misconduct  or  fraud  committed  by a Registered Service Provider or its employees, representatives, etc.The Company cannot be  called  upon  to  provide  any  guarantee/security  with  respect  to  the Items  sold by  the Registered Service Provider/ purchased by the User.
                                            <br /><br />
                                            3.8 You may  conduct  searches  on  the Website to  look  for Registered Service  Providers  to process  Your requirements. The  results  for  searches  are  derived  on  the  basis  of an algorithm which considers various factors, including but not limited to, the proximity to location  from  where  the  service  request  has  been  made,  User  reviews  and  feedback, pricing, etc. Alternatively,the Registered Service Providers may be listed on the basis of User-selected search criteria. In either case, the Company does not have any control over the  results  of  searches  carried  out  by  You  on  the Website.The result  of  any  search  for Registered Service Providers conducted by You on the Website shall not be construed as the opinion or preference of the Company.
                                            <br /><br />
                                            3.9 As part of the Website Services provided to You, aftermaking making any purchase of any Item/s from a Registered Service Provider, You agree to provide honest feedback/review about the  concerned  Registered  Service  Provider. You agree  and  accept  that  the  ratings provided on the Website are on the basis of the feedback and comments of the Users and that  the  Company  is  no  way  responsible  or  liable for  the  same. All  views  and  opinions expressed on the Website, are those of the individual Users only, and do not in any way reflect the opinion of the Company.
                                            <br /><br />
                                            3.10 Any purchase of Items made by You through the Website maybe subject to the additional terms  and  conditions  mentioned  therein,  which  You  are  presumed  to  have  read  and accepted at the time of making the service booking.
                                            <br /><br />
                                            3.11 Currently  all Website Services  are  provided  to  you  at  no  cost.  However,  the  Company reserves  the  right  to  charge a  fee  for  any  of  the Website Services  at  any  time,  without specifically informing You of the same. Any revision of fee shall be notified on the Website and shall be applicable for Website Services availed after such notification.
                                            <br /><br />
                                            <strong> 4. Payment Services<strong></strong></strong>
                                            <br /><br />
                                            4.1 With respect to Item/s purchased by You through the Website, the amount and manner of payment will be either be on the basis of Cash on Delivery (COD) made directly to the logistics partner’s executive, appointed by the Company or may be permitted/required to be made online by You using the payment services available on the Website. In the event that full payment of any Registered Service Provider is not undertaken on the Website at the time of order booking, it shall be Your responsibility to pay the amounts due, in the manner agreed while placing the order on the Website.
                                            <br /><br />
                                            4.2 To the extent permitted by applicable law and subject to the Company’s Privacy Policy, you acknowledge and agree that the Company may use certain third-party vendors and service  providers,  including  payment  gateways,to  process  payments  and  manage payment card information.
                                            <br /><br />
                                            4.3 In order to make payments online, You undertake to use a valid payment card, to have sufficient funds or credit available to complete the payment and have an Account on the Website in  good  standing.  By  providing  payment  card  information,  You  represent, warrant,  and  covenant  that:  (1)  You  are  legally  authorized  to  provide  such  information; (2) You are legally authorized to perform payments from the payment card account; and (3) such action does not violate the terms and conditions applicable to Your use of such payment card account or applicable law. You agree that You are responsible for any fees charged  by Your  mobile  carrier  in  connection  with Your  use  of  the  payment  services through Your mobile. The Company shall use the payment card information as described in the Company’s Privacy Policy. You may add, delete, and edit the payment card account information You have provided from time to time through the Website.
                                            <br /><br />
                                            4.4 Except to the extent otherwise required by applicable law, the Company is not liable for any  payments  authorized  through  the Website using Your  payment  card  information. Particularly, the Company is not liable for any payments that do not complete because: (1) Your payment card account does not contain sufficient funds to complete the transaction or the transaction exceeds the credit limit of the payment card account; (2) You have not provided the  Company with  correct  payment  card  information;  (3)  Your  payment  card has expired; or (4) circumstances beyond the Company’s control (such as, but not limited to,  power  outages,  interruptions  of  cellular  service,  or  any  other  interferences  from  an outside force) prevent the execution of the transaction.
                                            <br /><br />
                                            <strong>PART A –GENERAL TERMS RELATING TO WEBSITE SERVICES</strong>
                                            <br /><br />
                                            <strong>1. Use of Website</strong>
                                            <br /><br />
                                            1.1 Use of and access to the Website is offered to You upon the condition of acceptance of all the  terms,  conditions  and  notices  contained  in  these  Terms  of  Use,  along  with  any amendments made by the Company at its sole discretion and posted on the Website.
                                            <br /><br />
                                            1.2 The Company shall not be required to notify You, whether as a registered User or not, of any changes made to the Terms of Use. The revised Terms of Use shall be made available on the Website. You are requested to regularly visit the homepage to view the most current Terms of Use. You can determine when the Company last modified the Terms of Use by referring to the “Last Updated” legend above. It shall be Your responsibility to check these Terms of Use periodically for changes. The Company may require You to provide Your consent to the updated Terms of Use in a specified manner prior to any further use of the Website and Website Services. If no such separate consent is sought, Your continued use of the Website, following the changes to the Terms of Use, will constitute Your acceptance of those changes. Your use of the Website and the Website Services is subject to the most current version of the Terms of Use made available on the Website at the time of such use.
                                            <br /><br />
                                            1.3 By (i) using this Website or any Website Services in any way; or (ii) merely browsing the Website,  You  agree  that  You  have  read,  understood  and  agreed  to  be  bound  by  these Terms of Use and the Website’s Privacy Policy available at [www.medimny.com].
                                            <br /><br />
                                            <strong>2. Eligibility to Use</strong>
                                            <br /><br />
                                            2.1 The Website Services are not available to minors under the age of 18 (eighteen)years or to any Users suspended or removed by the Company for any reason whatsoever. If You do not conform to the above qualification, You shall not be permitted to avail of the Website Services or use or register on the Website. You represent that You are of legal age to form a binding contract and are not a person barred from receiving Website Services under the applicable laws.
                                            <br /><br />
                                            2.2 The Company reserves the right to refuse access to the Website or Website Services to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.
                                            <br /><br />
                                            2.3 You shall not have more than one active Account (as defined hereinafter) on the Website. Additionally,  You  are  prohibited  from  selling,  trading,  or  otherwise  transferring  Your Account to another party.
                                            <br /><br />
                                            <strong>3. User Account, Password, and Security</strong>
                                            <br /><br />
                                            3.1 In order to avail the Website Services, You will have to register on the Website and create an account (<strong>"Account"</strong>). If you are using the Website on a compatible mobile or tablet, you will  have  to  install  the  application  and  then  proceed  with  registration. You  agree  to provide details about Yourself, including Your name, address, contact details, email,and KYC documents such as valid drug license, valid food license, valid GST (if applicable), and/or other documents related to your businessas necessary.
                                            <br /><br />
                                            3.2 You shall ensure and confirm that the Account information provided by You is complete, accurate  and up  to  date. If  there  is  any  change  in  the  Account  information, You  shall promptly   update Your   Account   information   on   the Website. If   You   provide   any information  that  is  untrue,  inaccurate,  not  current  or  incomplete  (or  becomes  untrue, inaccurate,  not  current  or  incomplete),  or if the Company has  reasonable  grounds  to suspect  that  such  information  is  untrue,  inaccurate,  not  current  or  incomplete, the Company has  the  right  to  suspend  or  terminate  Your  Account  and  refuse  any  and  all current or future use of the Website(or any portion thereof) at its discretion, in addition to  any  right  that  the  Company  may  have  against  You at law or in equity,  forany misrepresentation of information provided by You.
                                            <br /><br />
                                            3.3 You  will  be  responsible  for  maintaining  the  confidentiality  of  the  Account  information, and are fully responsible for all activities that occur under Your Account. You agree to (a) immediately notify the Company of any unauthorized use of Your Account information or any other breach of security, and (b) ensure that You exit from Your Account at the end of each session. The Company cannot and will not be liable for any loss or damage arising from Your failure to comply with this provision. You may be held liable for losses incurred by the Company or  any  other  user  of  or  visitor  to  the Website due  to  authorized  or unauthorized  use  of  Your  Account  as  a  result  of  Your  failure  in  keeping  Your  Account information secure and confidential.
                                            <br /><br />
                                            3.4 Use of another User’s Account information for availing the Website Services is expressly prohibited.
                                            <br /><br />
                                            <strong>4. User Representations and Obligations</strong>
                                            <br /><br />
                                            4.1  Subject to compliance with the Terms of Use, the Company grants You a non-exclusive, limited privilege to access and use this Website and the Website Services.
                                            <br /><br />
                                            4.2 You agree to use the Website Services, Website and the materials provided therein only: (a) for purposes that are permitted by the Terms of Use; and (b) in accordance with any applicable law, regulation or generally accepted practices or guidelines.
                                            <br /><br />
                                            4.3 You agree to not engage in activities that may adversely affect the use of the Website by Registered Service Providers/ Company/other Users.
                                            <br /><br />
                                            4.4 You  agree  not  to  access  (or  attempt  to  access)  the Website and  the  materials  or Website Services by any means other than through the interface that is provided by the Company. You  shall  not  use  any  deep-link,  robot,  spider  or  other  automatic  device,  program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or Content (as defined below), or in any way reproduce  or  circumvent  the  navigational  structure  or  presentation  of  the Website, materials  or  any  Content,  to  obtain  or  attempt  to obtain  any  materials,  documents  or information through any means not specifically made available through the Website.
                                            <br /><br />
                                            4.5 You acknowledge and agree that by accessing or using the Website or Website Services, You  may  be  exposed  to  content  from  others (including but  not  limited  to,  Registered Service Providers and other Users) that You may consider offensive, indecent or otherwise objectionable. The Company disclaims  all  liabilities  arising  in  relation  to  such  offensive content on the Website.
                                            <br /><br />
                                            4.6 If  the Website allows  You  to  post  andupload  any  material  on  the Website,  You  hereby undertake to ensure that such material is not offensive and is in accordance with applicable laws.  All  material  added,  created,  uploaded,  submitted,  distributed,  or  posted  to  the Website by You is Your sole responsibility.
                                            <br /><br />
                                            4.7 You  hereby  do  and  shall  grant the  Company a  worldwide,  non-exclusive,  perpetual, royalty-free, sub-licensable and transferable license to use, reproduce, disclose, distribute, translate  and  otherwise  fully  exploit  any  such  material,  in  connection  with  the Website and the Company’s(and the Company’s successors’  and  assigns’)  businesses,  including without  limitation,  for  promoting  the Website in  any  media  formats  and  through  any  media channels. You represent and warrant that You have all rights to grant such licenses to the  Company without  infringement  or  violation  of  any  third  party  rights,  including without  limitation,  any  privacy  rights,  publicity  rights,  copyrights,  trademarks, contract rights, or any other intellectual property or proprietary rights.
                                            <br /><br />
                                            4.8 Further, You undertake not to:
                                            <div className="listDots">
                                                <ul >
                                                    <li>
                                                        Defame, abuse, harass, threaten or otherwise violate the legal rights of others;
                                                    </li>
                                                    <li>
                                                        Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;
                                                    </li>
                                                    <li>
                                                        Publish,  post,  upload,  distribute  or  disseminate  any  information  that  is  harmful, harassing, blasphemous,    defamatory, obscene, pornographic, paedophilic, libellous,  hateful,  or  racially,  ethnically  objectionable,  disparaging, inappropriate, profane, infringing or otherwise unlawful in any manner whatever; or that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation;
                                                    </li>
                                                    <li>
                                                        Upload  files  that  contain  software  or  other  material  protected  by  applicable intellectual  property  laws  unless  You  own  or  control  the  rights  thereto  or  have received all necessary consents;
                                                    </li>
                                                    <li>
                                                        Upload or distribute files that contain viruses, corrupted files, or any other similar software  or  programs  that  may damage  the  operation  of  the Website or  another's computer;
                                                    </li>
                                                    <li>
                                                        Engage in any activity that interferes with or disrupts access to the Website or the Website Services (or the servers and networks which are connected to the Website);
                                                    </li>
                                                    <li>
                                                        Attempt to gain unauthorized access to any portion or feature of the Website, any other systems or networks connected to the Website, to any Company server, or to any  of  the Website Services  offered  on  or  through  the Website,  by  hacking, password mining or any other illegitimate means;
                                                    </li>
                                                    <li>
                                                        Probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network  connected  to  the Website.  You  may  not  reverse  look-up,  trace  or  seek  to traceany information on any other user (Registered Service Provider, or User), of or visitor to, the Website, to its source, or exploit the Website or Website Services or information  made  available  or  offered  by  or  through  the Website,  in  any  way whether or not the purpose is to reveal any information, including but not limited to  personal  identification  information,  other  than  Your  own  information,  as provided onthe Website;
                                                    </li>
                                                    <li>
                                                        Disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems  resources,  accounts,  passwords,  servers  or  networks  connected  to  or accessible through the Website or any affiliated or linked sites;
                                                    </li>
                                                    <li>
                                                        Collect  or  store  data  about  others  (Registered  Service  Providers  and  Users)  in connection with the prohibited conduct and activities set forth in this Section.
                                                    </li>
                                                    <li>
                                                        Use  any  device  or  software  to  interfere  or  attempt  to  interfere  with  the  proper working of the Website or any transaction being conducted on the Website, or with any other person’s use of the Website;
                                                    </li>
                                                    <li>
                                                        Use  the Website or  any  material  or  Content  for  any  purpose  that  is  unlawful  or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of the Company or other third parties;
                                                    </li>
                                                    <li>
                                                        Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;
                                                    </li>
                                                    <li>
                                                        Violate any code of conduct or other guidelines, which may be applicable for or to any particular Website Service;
                                                    </li>
                                                    <li>
                                                        Violate  any  applicable  laws  or  regulations  for  the  time  being  in  force  within  or outside India;
                                                    </li>
                                                    <li>
                                                        Violate the Terms of Use  contained herein or elsewhere; and
                                                    </li>
                                                    <li>
                                                        Reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website.
                                                    </li>
                                                </ul>
                                            </div>
                                            4.9 The Company reserves the right to review any information/data uploaded by You on the Website and delete any information/data that is inconsistent with these Terms of Use. You agree that You are solely responsible to the Company and to any third party for any breach of Your obligations under the Terms of Useand for the consequences (including any loss or damage which the Company or its affiliates may suffer) for any such breach.
                                            <br /><br />
                                            4.10 You  agree  that the Company may,  at  any  time,  modify  or  discontinue  all  or  part  of  the Website, charge, or offer opportunities to some or all Users.
                                            <br /><br />
                                            <strong>5. Use of Materials</strong>
                                            <br /><br />
                                            5.1 Except  as  expressly  indicated  herein, the  Company hereby  grants  You  a  non-exclusive, freely  revocable  (upon  notice  from the  Company),  non-transferable  access  to  view, download  and  print  any  materials  available  on  the Website,  subject  to  the  following conditions:
                                            <div className="listDots">
                                                <ul >
                                                    <li>
                                                        You  may  access  and  use  the  materials  solely for  personal,  informational,  and internal purposes, in accordance with the Terms of Use;
                                                    </li>
                                                    <li>
                                                        You may not modify or alter the materials available on the Website;
                                                    </li>
                                                    <li>
                                                        You may not distribute or sell, rent, lease, license or otherwise make the materials on the Website available to others; and
                                                    </li>
                                                    <li>
                                                        You may not remove any text, copyright or other proprietary notices contained in any materials available on the Website
                                                    </li>
                                                </ul>
                                            </div>
                                            5.2 The  rights  granted  to  You  in  the  materials  as  specified  above  are  not  applicable  to  the design, layout or look and feel of the Website. Such elements of the Website are protected by intellectual property rights and may not be copied or imitated in whole or in part.
                                            <br /><br />
                                            5.3 Any software that is available on the Website is the property of the Company. You may not  use,  download  or  install  any  software  available on the Website,  unless  otherwise expressly  permitted  by  these  Terms  of  Use  or  by  the  express  written  permission  of the Company.
                                            <br /><br />
                                            <strong>6. Usage Conduct </strong>
                                            <br /><br />
                                            6.1 You shall solely be responsible for maintaining the necessary computer equipments and internet connections that may be required to access, use and transact on the Website.
                                            <br /><br />
                                            <strong>7. Intellectual Property Rights</strong>
                                            <br /><br />
                                            7.1 Subject  to  Section  8  below,  the Website and  the  processes,  and  their  selection  and arrangement,  including  but  not  limited  to,all  text,  graphics,  user  interfaces,  visual
                                            9interfaces,  sounds  and  music  (if  any),  artwork  and  computer  code  (collectively,  the “Content”)  on  the Website is  owned  and  controlled  by the Company and  the  design, structure,  selection,  coordination,  expression,  look  and  feel  and  arrangement  of  such Content  is  protected  by  copyright,  patent  and  trademark  laws,  and  various  other intellectual property rights.
                                            <br /><br />
                                            7.2 The  trademarks,  logos  and  service  marks  displayed  on  the Website <strong>(“Marks”)</strong>  are  the property of the Company or other respective third parties, as the case may be. You are not permitted to use the Marks without the prior consent of the Company, or the third party that may own the Marks.
                                            <br /><br />
                                            7.3 Except as expressly provided herein, You acknowledge and agree that You shall not copy, republish, post, display, translate, transmit, reproduce or distribute any Content through any medium without obtaining the necessary authorization from the Company.
                                            <br /><br />
                                            <strong>8. Third Party Content</strong>
                                            <br /><br />
                                            The Website makes  available  general  third  party  information  and  other  data  from  external sources   <strong>(“Third   Party   Content”)</strong>.   The   provision   of   Third   PartyContent   is   for   general informational purposes only. You acknowledge that the Third Party Content provided to You is obtained  from  sources  believed  to  be  reliable. The Company does  not  provide  any  guarantee with respect to the truthfulness, credibility and the genuineness of any Third Party Content and the Company shall not be held liable for any loss suffered by You based on Your reliance on or use of such Third Party Content.
                                            <br /><br />
                                            <strong>9. Disclaimer Of Warranties & Liability</strong>
                                            <br /><br />
                                            YOU   EXPRESSLY   UNDERSTAND   AND   AGREE   THAT, TO   THE   MAXIMUM   EXTENT PERMITTED BY APPLICABLE LAW:
                                            <br /><br />
                                            <br /><br />
                                            9.1 THE WEBSITE, WEBSITE SERVICES,  AND  OTHER  MATERIALS  ARE  PROVIDED  BY THE  COMPANY  ON  AN  “AS  IS”  BASIS  WITHOUT  WARRANTY  OF  ANY  KIND, EXPRESS,   IMPLIED,   STATUTORY   OR   OTHERWISE,   INCLUDING   THE   IMPLIED WARRANTIES  OF  TITLE,  NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR   A   PARTICULAR PURPOSE.   WITHOUT   LIMITING   THE   FOREGOING,   THE COMPANY  MAKES  NO  WARRANTY  THAT  (I)  THE WEBSITE OR  THE WEBSITE  SERVICES WILL MEET YOUR REQUIREMENTS OR YOUR USE OF THE WEBSITE OR THE WEBSITE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE;  (II)  THERESULTS  THAT  MAY  BE  OBTAINED  FROM  THE  USE  OF  THE WEBSITE, OR WEBSITE SERVICES WILL BE EFFECTIVE, ACCURATE OR RELIABLE; (III)  THE  QUALITY  OF  THE WEBSITE OR WEBSITE SERVICES  WILL  MEET  YOUR EXPECTATIONS; OR (IV) ANY ERRORS OR DEFECTS IN THE WEBSITE OR WEBSITE SERVICES WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR  WRITTEN,  OBTAINED  BY  YOU  FROM  THE  COMPANY  OR  THROUGH  USE  OF THE WEBSITE SERVICES   SHALL   CREATE   ANY   WARRANTY   NOT   EXPRESSLY STATED  IN  THE  TERMS  OF  USE.THE  WEB  SITE  DOES  NOT  FACILITATE  THE PURCHASE    OF    PHARMACEUTICAL    PRODUCTS,    AND    ONLY    ADVERTISES AND/OR SHOWCASES THE PHARMACEUTICAL PRODUCTS POSTED BY USERS(S). USER(S)  INVOLVED  IN  THE  PURCHASE  AND  SUPPLY  OF  PHARMACEUTICAL PRODUCTS   HEREBY   AGREE   TO   ABIDE   BY   AND   BE   COMPLIANT   OF   ANY APPLICABLE LAWS, RULES, REGULATIONS, NOTIFICATIONS OR ORDERS ISSUED
                                            10BY THE GOVERNMENT OF INDIA OR ANY OF ITS AGENCIES FROM TIME TO TIME IN  THIS  REGARD  INCLUDING  BUT  NOT  LIMITED  TO  DRUGS  AND  COSMETICS ACT,  1940,  DRUGS  AND  COSMETICS  RULES,  1945  ("DRUG  RULES"),  DRUGS  ANDMAGIC  REMEDIES  (OBJECTIONABLE  ADVERTISEMENTS)  ACT,  1954  ("DRUG  AND MAGIC REMEDIES ACT").
                                            <br /><br />
                                            9.2 THE  COMPANY  WILL  HAVE  NO  LIABILITY  RELATED  TO  ANY REGISTERED SERVICE PROVIDER/USERCONTENT ARISING UNDER INTELLECTUAL PROPERTY  RIGHTS,  LIBEL,  PRIVACY,  PUBLICITY,  OBSCENITY  OR  OTHER  LAWS. THE COMPANY ALSO DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS,   MODIFICATION   OR   UNAVAILABILITY   OF   ANY REGISTERED SERVICE PROVIDER/USERCONTENT.
                                            <br /><br />
                                            9.3 THE COMPANY WILL NOT BE LIABLE FOR ANY LOSS THAT YOU MAY INCUR AS A  CONSEQUENCE OF  UNAUTHORIZED  USE  OF  YOUR  ACCOUNT  OR  ACCOUNT INFORMATION   IN   CONNECTION   WITH   THE WEBSITE OR   ANY WEBSITE SERVICES, EITHER WITH OR WITHOUT YOUR KNOWLEDGE.
                                            <br /><br />
                                            9.4 THE  COMPANY HAS  ENDEAVORED  TO  ENSURE  THAT  ALL  THE  INFORMATION ON  THE WEBSITE IS  CORRECT,  BUT  THE  COMPANY NEITHER  WARRANTS  NOR MAKES  ANY  REPRESENTATIONS  REGARDING  THE  QUALITY,  ACCURACY  OR COMPLETENESS  OF  ANY  DATA,  INFORMATION,  OR WEBSITE SERVICE.  THE COMPANY SHALL NOT BE RESPONSIBLE FOR THE DELAY OR INABILITY TO USE THE WEBSITE OR RELATED FUNCTIONALITIES, THE PROVISION OF OR FAILURE TO   PROVIDE   FUNCTIONALITIES,   OR   FOR   ANY   INFORMATION,   SOFTWARE, FUNCTIONALITIES    AND    RELATED    GRAPHICS    OBTAINED    THROUGH    THE WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF THE WEBSITE, WHETHER BASED  ON  CONTRACT,  TORT,  NEGLIGENCE,  STRICT  LIABILITY  OR  OTHERWISE. FURTHER,   THE COMPANY SHALL   NOT   BE   HELD   RESPONSIBLE   FOR   NON-AVAILABILITY OF THE WEBSITE DURING PERIODIC MAINTENANCE OPERATIONS  OR  ANY  UNPLANNED  SUSPENSION  OF  ACCESS  TO  THE WEBSITE THAT   MAY   OCCUR   DUE   TO   TECHNICAL   REASONS OR   FOR   ANY   REASON BEYOND THE COMPANY'S  CONTROL. YOUUNDERSTAND  AND  AGREE  THAT ANY MATERIAL OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE WEBSITE IS  DONE  ENTIRELY  AT  YOUR  OWN  DISCRETION  AND  RISK,AND THAT   YOU   WILL   BE  SOLELY   RESPONSIBLE   FOR   ANY   DAMAGE   TO   YOUR COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA.
                                            <br /><br />
                                            <strong>10. Indemnification and Limitation of Liability</strong>
                                            <br /><br />
                                            10.1 You  agree  to  indemnify,  defend  and  hold  harmless the Company and  its  affiliates including  but  not  limited  to  its(and  its  affiliates’) officers,  directors,  consultants,agents and  employees(“Indemnitees”)from  and  against  any  and  all  losses,  liabilities,  claims, damages,  demands,  costs  and  expenses  (including  legal  fees  and  disbursements  in connection therewith and interest chargeable thereon) asserted against or incurred by the Indemnitees that arise out of, result from, or may be payable by virtue of, any breach or non-performance  of  any  representation,  warranty,  covenant  or  agreement  made  or obligation to be performed by You pursuant to these Terms of Use. Further, You agree to hold the Indemnitees harmless  against  any  claims  made by  any  third  party  due  to,  or arising out of, or in connection with, Your use of the Website or Website Services, Your violation  of  the  Terms  of  Use,  or  Your  violation  of  any  rights  of  another,  including  any intellectual property rights.
                                            <br /><br />
                                            10.2 In no event shall the Company, its officers, directors, consultants, agents and employees, be  liable  to  You or any  third  party  for  any  special,  incidental,  indirect,  consequential  or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether  or  not  foreseeable  or  whether  or  not the Company has  been  advised  of  the possibility of such damages, arising out of or in connection with (i) Your use of or access to the Website, Website Services or materials on the Website; or (ii) services provided by Registered Service Providers.
                                            <br /><br />
                                            10.3 The limitations and exclusions in this Section apply to the maximum extent permitted by applicable laws
                                            <br /><br />
                                            <strong>11. Violation of the Terms of Use</strong>
                                            <br /><br />
                                            11.1 You agree that the Company may, in its sole discretion and without prior notice, terminate Your  access  to  the Website for  cause,  delist  You and  block  Your  future  access  to  the Website if the Company determines that You have violated these Terms of Use. You also agree  that  any  violation  by  You  of  these  Terms  of  Use  will  constitute  an  unlawful  and unfair  business  practice,  and  will  cause  irreparable  harm  to the Company,  for  which monetary damages would be inadequate, and You consent to the Company obtaining any injunctive  or  equitable  relief  that the Company deems  necessary  or  appropriate  in  such circumstances.  These  remedies  are  in  addition  to  any  other  remedies that  the Company may have at law or in equity.
                                            <br /><br />
                                            11.2 If the Company does take any legal action against You as a result of Your violation of these Terms of Use, the Company will be entitled to recover from You, and You agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other relief granted to the Company.
                                            <br /><br />
                                            <strong>12. Termination</strong>
                                            <br /><br />
                                            12.1 The Terms of Use will continue to apply until terminated by either You or the Company as set forth below. If You want to terminate Your agreement with the Company, You may do so by (i)closing Your Account on the Website; and (ii) not accessing the Website.
                                            <br /><br />
                                            12.2 The Company may, at any time, with or without notice, terminate the Terms of Use with You if:
                                            <div className="listDots">
                                                <ul >
                                                    <li>
                                                        You breach any  of  the  provisions  of  the  Terms  of  Use,  the  Privacy  Policy  or  any other terms, conditions, or policies that may be applicable to You from time to time (or  have acted  in  a  manner  that  clearly  shows  that  You  do  not  intend  to,  or  are unable to, comply with the same);
                                                    </li>
                                                    <li>
                                                        The Companyis required to do so by law (for example, where the provision of the Website Services to  You  is,  or  becomes,  unlawful or  upon  receiving  request  for termination by law enforcement or other government agencies);
                                                    </li>
                                                    <li>
                                                        The provision of the Website Services to You by the Company is, in the opinion of the Company,  no  longer  commercially  viable  or in  any  way  detrimental  to  the Company, its business or the Website; or
                                                    </li>
                                                    <li>
                                                        The Company has  elected  to  discontinue,  with  or  without  reason,  access  to  the Website, Website Services or any part thereof.
                                                    </li>
                                                </ul>
                                            </div>
                                            <br /><br />
                                            12.3 The Company may also terminate or suspend all or a portion of Your Account or access to the Website Services with or without reason. Termination of Your Account may include:
                                            12(i)  removal  of  access  to  all  offerings  within  the Website or  with  respect  to  the Website Services; (ii) deletion of Your records and Account information, including Your personal information,  log-in  ID  and  password,  and  all  related  information,  files  and  materials associated with or inside Your Account (or any part thereof); and (iii) barring of further use of the Website and Website Services.
                                            <br /><br />
                                            12.4 The termination of Your Account shall not relieve You of any liability that You may have incurred or  may  incur in  relation  to  use  of Website Services  provided  prior  to  such termination.
                                            <br /><br />
                                            12.5 You agree that all terminations shall be made in the Company’s sole discretion and that the Company shall  not  be  liable  to  You  or  any  third  party  for  any  termination  of  Your Account, or Your access to the Website and Website Services.
                                            <br /><br />
                                            12.6 If You or the Company terminate Your use of the Website, the Company may delete any content or other materials relating to Your use of the Website or the Website Services and the Company will have no liability to You or any third party for doing so.
                                            <br /><br />
                                            <strong>13. Governing Law</strong>
                                            <br /><br />
                                            13.1 These Terms of Use and all transactions entered into on or through the Website and the relationship between You and the Company shall be governed in accordance with the laws of India without reference to conflict of laws principles.
                                            <br /><br />
                                            13.2 You agree that all claims, differences and disputes arising under or in connection with or in relation to the Website the Terms of Use or any transactions entered into on or through the Website or  the  relationship  between  You  and the Company shall  be  subject  to  the exclusive jurisdiction of the Courts at Mumbai, India and You hereby accede to and accept the jurisdiction of such Courts.
                                            <br /><br />
                                            <strong>14. Report Abuse</strong>
                                            <br /><br />
                                            In  the  event  You  come  across  any  abuse  or  violation  of  these  Terms  of  Use  or  if  You  become aware of any objectionable content on the Website, please reportthe same to the following e-mail id:<a href='javascript: void(0)'>support@medimny.com</a>
                                            <br /><br />
                                            <strong>15. Communications</strong>
                                            <br /><br />
                                            15.1 You  hereby  expressly  agree  to  receive  communications  by  way  of  SMS and/or e-mails from the Company relating to the Website Services provided through the Website.
                                            <br /><br />
                                            15.2 You can  unsubscribe/  opt-out  from  receiving  communications  from the Company through e-mail anytime by writing to us at <a href='javascript: void(0)'>support@medimny.com</a>
                                            <br /><br />
                                            <strong>16. General Provisions</strong>
                                            <br /><br />
                                            16.1 <strong>Notice: </strong>All  notices from the Company will  be  served  by  email to  Your  registered  email address or by general notification on the Website. Any notice provided to the Company pursuant to the Terms of Use should be sent to <a href='javascript: void(0)'>support@medimny.com</a> with subject line -Attention:TERMS OF USE.
                                            <br /><br />
                                            16.2 <strong>Assignment: </strong>You  cannot  assign  or  otherwise  transfer  the  Terms  of  Use,  or  any  rights granted here under to any third party. The Company’s rights under the Terms of Use are freely transferable by the Company to any third party without the requirement of seeking Your consent.
                                            <br /><br />
                                            16.3 <strong>Severability: </strong>If,  for  any  reason,  a  court  of  competent  jurisdiction  finds  any  provision  of the  Termsof  Use,  or any portion  thereof,  to  be  unenforceable,  that  provision  shall  be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect.
                                            <br /><br />
                                            16.4 <strong>Waiver: </strong>Any failure by the Company to enforce or exercise any provision of the Terms of Use, or any related right, shall not constitute a waiver by the Company of that provision or right.
                                            <br /><br />
                                            <strong>17. IP Infringement</strong>
                                            <br /><br />
                                            If You believe the Website or any of the Website Services violates Your intellectual property, You must  promptly  notify the  Company’s agent  in  writingat <a href='javascript: void(0)'>support@medimny.com</a>. These notifications  should  only  be  submitted  by  the  owner  of  the  intellectual  property  or  an  agent authorized  to  act  on  his/her  behalf.  However,  any  false  claim  by You may  result  in  the termination of Your access to the Website. You are required to provide the following details in Your notice:
                                            <br /><br />
                                            (a) the intellectual property that You believe is being infringed;
                                            <br /><br />
                                            (b) the item that You think is infringing and include sufficient information about where the material is located on the Website;
                                            <br /><br />
                                            (c) a statement that You believe in good faith that the item You have identified as infringing is  not  authorized  by  the  intellectual  property  owner,  its  agent,  or  the  law  tobe  used  in connection with the Website;
                                            <br /><br />
                                            (d) Your contact details, such as Your address, telephone number, and/or email;
                                            <br /><br />
                                            (e) a statement that the information You provided in Your notice is accurate, and that  You are  the  intellectual  property  owner  or  an agent authorized  to  act  on  behalf  of  the intellectual property owner whose intellectual property is being infringed; and
                                            <br /><br />
                                            (f) Your physical or electronic signature.
                                            <br /><br />
                                            <strong>18. Feedback and Information</strong>
                                            <br /><br />
                                            18.1 Any  feedback  You  provide on the Website shall  be  deemed  to  be  non-confidential. The Company shall  be  free  to  use  such  information  on  an  unrestricted  basis.  Further,  by submitting  the  feedback,  You  represent  and  warrant  that  (i)  Your  feedback  does  not contain confidential or proprietary information of You or of third parties; (ii) the Company is  not  under  any  obligation  of  confidentiality,  express  or  implied,  with  respect  to  the feedback; (iii) all information provided in the feedback is true and correct and relates to purchase  of  Items made by  You;  and  (iv)  You  are  not  entitled  to  any  compensation  or reimbursement of any kind from the Company for the feedback under any circumstances. The  Company  reserves  the  right  to  initiate  any  action  against  You  under  the  applicable laws for a breach of this representation, including appropriate actions for any defamation allegations. When  You  provide feedback on  the Website,  You  grant to the  Company,  a non-exclusive,    royalty-free,    worldwide,    transferable,    sub-licensable,    irrevocable, perpetual and unconditional license to (i) use or reproduce the feedback posted by You on the Website; (ii)delete and remove the entire or such part of any feedback posted by You that, in the opinion of the Company, is not in compliance with these Terms of Use; and (iii)communicate the feedback to other users, including Registered Service Providers.
                                            <br /><br />
                                            18.2 Any feedback posted by You on the Website regarding any Item/s purchased availed by You  may  be  required  to  be  substantiated  by  accompanying  evidence,  as  necessitated  or requested by the Company.
                                            <br /><br />
                                            Copyright © All rights reserved
                                        </div>
                                    </CustomScrollbars>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BuyerContainer>
    );
}

export default TermsOfUse;