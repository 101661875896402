import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import './index.css'
import { connect } from 'react-redux';
import { getShortbook } from 'actions/buyer';
import AddIcon from '@mui/icons-material/Add';

const EnhancedTableToolbarOpen = (props) => {
    const [month, setMonth] = useState(moment().format("MMMM"));
    const [year, setYear] = useState(moment().format("GGGG"));
    const [searchText, setSearchText] = useState('');
    const [hide, setHide] = useState(true);

    const handleTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const clearSearch = () => {
        setMonth(moment().format("MMMM"))
        setYear(moment().format("GGGG"))
        setSearchText('')
        setHide(true)
        let data = {
            page: 1,
            perPage: 50,
            searchText: '',
            tab: 'open',
            month: month,
            year: parseInt(year) ? parseInt(year) : ''
        }
        props.filter(data)
    }

    const applySearch = () => {
        let data = {
            page: 1,
            perPage: 50,
            tab: 'open',
            searchText: searchText,
            month: month,
            year: parseInt(year) ? parseInt(year) : ''
        }
        props.filter(data)
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            applySearch()
        }
    }

    const { identifier } = props;

    return (
        <Toolbar>
            {
                identifier === "shortbookList" ?
                    <React.Fragment>
                        {hide ? <h3 style={{ marginRight: "0px", marginLeft: "-8px" }}>Showing Shortbook
                        </h3> :
                            <div style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "0px", width: "80%" }}>
                                <TextField
                                    className='search'
                                    autoFocus={true}
                                    fullWidth={true}
                                    hidden={hide}
                                    value={searchText}
                                    onChange={handleTextChange}
                                    placeholder='Search Product'
                                    onKeyUp={handleKeyPress}
                                    variant="standard"
                                />
                            </div>
                        }
                        <Grow appear in={true} timeout={300}>
                            <div className='searchBox' style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "auto" }}>
                                <IconButton className='clear'>
                                    {hide ? <SearchIcon onClick={() => { setHide(false) }} /> : <ClearIcon onClick={clearSearch} />}
                                </IconButton>
                            </div>
                        </Grow>
                        <div style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "0px" }}>
                            <IconButton aria-label="Add Icon">
                                <AddIcon onClick={props.addClicked} />
                            </IconButton>
                        </div>
                    </React.Fragment>
                    :
                    null
            }

        </Toolbar>
    );
}

const mapStateToProps = ({ buyer }) => {
    const { shortbookList } = buyer;
    return { shortbookList }
};

export default connect(mapStateToProps, { getShortbook })(EnhancedTableToolbarOpen);