import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import UserProfileCard from 'components/userProfileCard/UserProfileCard';
import ListCard from 'components/userProfileCard/ListCard';
import { Button, Col, Row } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import './index.css'
import { connect } from 'react-redux';
import { getSearchProduct, getFilter } from 'actions/buyer';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import BuyerProductRequestPopOver from './BuyerProductRequestPopOver';
import { getUserDetail } from 'actions';
import ShortBookPopOver from './ShortBookPopOver';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const About = (props) => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  let decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
  const [perPage, setPerPage] = useState(12)
  const [sortBy, setSortBy] = useState(decodedURL.sortBy ? decodedURL.sortBy : 'price')
  const [popOverOpen, setPopOverOpen] = useState(false)
  const [list, setList] = useState(false)

  const seeMoreProducts = () => {
    setPerPage(perPage + 12)
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    const data = {
      key: decodedURL.search ? decodedURL.search : '',
      company_id: decodedURL.company_id ? decodedURL.company_id : '',
      category_id: decodedURL.category_id ? decodedURL.category_id.split(',') : [],
      seller_id: decodedURL.seller_id ? decodedURL.seller_id : '',
      mediType: decodedURL.medicineType ? decodedURL.medicineType : '',
      discount: decodedURL.discount && decodedURL.discount === '10 or more' ? 10 : decodedURL.discount === '20 or more' ? 20 : decodedURL.discount === '50 or more' ? 50 : '',
      page: 1,
      perPage: Number(perPage) + 12,
      sortBy: sortBy ? sortBy : 'price'
    }
    props.getSearchProduct({ data })
  }

  useEffect(() => {
    props.getFilter({})
  }, [])

  const toggle = () => {
    setPopOverOpen(!popOverOpen)
  }

  const handleSortChange = (e, key) => {
    setSortBy(e.target.value);
    let url2 = location.pathname;
    let strip = url2.split('/');
    let catN = strip ? strip[3] ? strip[3] : 'category' : 'category';
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    let obj = {}
    obj.search = decodedURL.search ? decodedURL.search : '';
    obj.sortBy = e.target.value;
    obj.company_id = decodedURL.company_id ? decodedURL.company_id : '';
    obj.seller_id = decodedURL.seller_id ? decodedURL.seller_id : '';
    obj.category_id = decodedURL.category_id ? decodedURL.category_id.split(',') : [];
    obj.medicineType = decodedURL.medicineType ? decodedURL.medicineType : '';
    obj.discount = decodedURL.discount ? decodedURL.discount : '';
    const data = {
      key: obj.search ? obj.search : '',
      company_id: obj.company_id ? obj.company_id : '',
      category_id: obj.category_id,
      seller_id: obj.seller_id ? obj.seller_id : '',
      mediType: obj.medicineType ? obj.medicineType : '',
      discount: obj.discount && obj.discount === '10 or more' ? 10 : obj.discount === '20 or more' ? 20 : obj.discount === '50 or more' ? 50 : '',
      page: 1,
      perPage: 12,
      sortBy: obj.sortBy
    }
    let catName = catN && ((catN).replace(/ /g, '-')).replace(/[\/,  @#%\^;?]/g, ' ').toLowerCase();
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    navigate(`/search-results/${catName && catName !== '' ? catName : 'category'}/${url}`, { state: 'searchPage' })
    props.getSearchProduct({ data })
  }

  const changeView = () => {
    setList(!list)
  };

  const { dataFromParent, filter, loader, searchProduct } = props;
  let searchedText = decodedURL.search
  const categoryName = filter && filter.detail && filter.detail.categories.map(function (e) { return e._id; }).indexOf(decodedURL.category_id);
  const companyName = filter && filter.detail && filter.detail.comapnies.map(function (e) { return e._id; }).indexOf(decodedURL.company_id);
  const SellerName = filter && filter.detail && filter.detail.sellers.map(function (e) { return e._id; }).indexOf(decodedURL.seller_id);

  return (
    <div className="w-100">
      <AppBar position="static" color="default">
        <Row>
          <Col sm={8} md={8} lg={8} xl={8} className='pr-0' style={{ paddingLeft: '50px', paddingRight: '50px', margin: 'auto' }}>
            {
              decodedURL.medicineType ?
                <span className='text-primary' style={{ marginTop: '20px', padding: 0 }} >
                  {`${decodedURL.medicineType} ( ${dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].metadata[0] && dataFromParent.products[0].metadata[0].total ? dataFromParent.products[0].metadata[0].total : 0} )`}
                </span>
                :
                <span className='text-primary' style={{ marginTop: '20px', padding: 0 }} >
                  {`${dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].metadata[0] && dataFromParent.products[0].metadata[0].total ? dataFromParent.products[0].metadata[0].total : 0} 
                    results  
                    ${decodedURL.search ? " for " + decodedURL.search : ''}`}
                </span>
            }
            <span className='text-primary' style={{ marginBottom: '20px', padding: 0 }} >
              {`
            ${decodedURL.discount && decodedURL.discount === '10 or more' ? " Of discount 10% off or more " : decodedURL.discount === '20 or more' ? " Of discount 20% off or more " : decodedURL.discount === '50 or more' ? " Of discount 50% off or more " : ''}     
            ${filter && filter.detail && filter.detail.categories[categoryName] && filter.detail.categories[categoryName].name ? " Of category " + [filter.detail.categories[categoryName].name] : ''}         
            ${filter && filter.detail && filter.detail.comapnies[companyName] && filter.detail.comapnies[companyName].name ? " By " + filter.detail.comapnies[companyName].name : ''}      
           ${filter && filter.detail && filter.detail.sellers[SellerName] && filter.detail.sellers[SellerName].company_name ? " Sold by " + filter.detail.sellers[SellerName].company_name : ''}`
              }
            </span></Col>
          {!isMobile && <Col sm={1} md={1} lg={1} xl={1} style={{ padding: '10px 0 0 40px' }}>
            {!list ?
              <IconButton className="icon-btn text-primary" onClick={changeView}>
                <i class="fas fa-list-alt text-primary" style={{ fontSize: '20px', cursor: 'pointer' }} ></i></IconButton> :
              <IconButton className="icon-btn text-primary" onClick={changeView}>
                <i class="fas fa-th text-primary" style={{ fontSize: '20px', cursor: 'pointer' }} ></i></IconButton>
            }
          </Col>}
          <Col sm={3} md={3} lg={3} xl={3} className='pt-2 pb-1 pr-4 m-auto'>
            <Select
              value={decodedURL.sortBy ? decodedURL.sortBy : 'price'}
              onChange={(e) => handleSortChange(e, 'sortBy')}
              input={<Input disableUnderline={true} className="form-control fullWidth" id="Filters" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 200
                  },
                },
              }}
            >
              <MenuItem value='name'>Name </MenuItem>
              <MenuItem value='price'>Best Price </MenuItem>
              <MenuItem value='discountHighToLow'>Discount (High To Low) </MenuItem>
              <MenuItem value='discountLowToHigh'>Discount (Low To High) </MenuItem>
            </Select>
          </Col>
        </Row>
      </AppBar>
      <div className="pt-3">
        {
          props.loading ?
            <div className="loader-view"
              style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
              <CircularProgress />
            </div>
            :
            dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data.length > 0 ?
              <div className="row">
                {dataFromParent && dataFromParent.products && dataFromParent.products[0] && dataFromParent.products[0].data.map((product, index) =>
                  <React.Fragment key={product._id}>
                    {
                      list ?
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-4 pl-4">
                          <ListCard styleName="pb-4" headerStyle="bg-gradient-primary" product={product} index={index} />
                        </div> :
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                          <UserProfileCard styleName="pb-4" headerStyle="bg-gradient-primary" product={product} index={index} />
                        </div>
                    }
                  </React.Fragment>
                )}
              </div>
              :
              dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data.length < 1 ?
                <React.Fragment>
                  <div style={{ textAlign: "center", marginTop: "100px", marginBottom: "100px" }}>
                    <span className="text-danger" >
                      <i class="zmdi zmdi-alert-circle animated wobble zmdi-hc-5x" style={{ marginBottom: "50px" }}></i>
                    </span>
                    {
                      decodedURL.medicineType && searchedText === '' && !decodedURL.company_id && !decodedURL.seller_id && !decodedURL.category_id && !decodedURL.discount ?
                        <h1>Medicines For Medicine Type {decodedURL.medicineType} Are Coming Soon!</h1>
                        :
                        <h1>Not Available!</h1>
                    }
                  </div>
                  {
                    dataFromParent && dataFromParent.inSystem === false && props.users && !decodedURL.medicineType && !decodedURL.company_id && !decodedURL.seller_id && !decodedURL.category_id && !decodedURL.discount ?
                      <React.Fragment>
                        <div style={{ textAlign: "center" }}>
                          <Button color='primary' onClick={toggle}>Create Product Request</Button>
                        </div>
                        <BuyerProductRequestPopOver
                          product={searchedText}
                          open={popOverOpen}
                          toggle={toggle}
                          companies={filter && filter.detail && filter.detail.comapnies ? filter.detail.comapnies : []}
                        />
                      </React.Fragment>
                      :
                      !loader && dataFromParent && searchProduct && dataFromParent.searchedProduct && Object.keys(searchProduct.searchedProduct).length > 0 && dataFromParent.inSystem === true && props.users && !decodedURL.medicineType && !decodedURL.company_id && !decodedURL.seller_id && !decodedURL.category_id && !decodedURL.discount ?
                        <React.Fragment>
                          <div style={{ textAlign: "center" }}>
                            <Button color='primary' onClick={toggle}>Add To Shortbook</Button>
                          </div>
                          <ShortBookPopOver
                            open={popOverOpen}
                            toggle={toggle}
                            product={dataFromParent.searchedProduct}
                          />
                        </React.Fragment>
                        : null
                  }
                </React.Fragment>
                :
                null
        }
      </div>
      {
        dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data ?
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            {(dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].metadata[0] && dataFromParent.products[0].metadata[0].total >= perPage) ?
              <Button color="primary" className="jr-btn jr-btn-lg btnPrimary" onClick={seeMoreProducts}>
                See More
              </Button>
              :
              dataFromParent.products[0].data.length > 1 ?
                <Button color="primary" className="jr-btn jr-btn-lg btnPrimary">No More Products</Button>
                :
                null
            }
          </div>
          :
          null
      }
    </div>
  );
}

const mapStateToProps = ({ buyer, seller, auth }) => {
  const { userDetails } = seller
  const { user_details } = auth;
  const { searchProduct, filter, loading, loader } = buyer;
  let users = userDetails ? userDetails : user_details
  return { searchProduct, filter, loading, loader, user_details, users }
};
export default connect(mapStateToProps, { getSearchProduct, getFilter, getUserDetail })(About);