import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './index.css'
import momemt from 'moment';
import { Link } from 'react-router-dom'

const TableComponent = (props) => {
    return (
        <React.Fragment>
            <TableRow
                hover
                className='cursor-pointer'
                key={props.row.productName}
                align={'left'}
                component={props.row.stock && props.row.stock === true ? Link : 'row'}
                to={`/search-results/category/search=${encodeURIComponent(props.row.productName)}`}
            >
                <TableCell align={'left'} >{props.row.productName}</TableCell>
                <TableCell align="left">{props.row.manufacturer}</TableCell>
                <TableCell align="left">{props.row.quantity}</TableCell>
                <TableCell align="left">
                    <span>{momemt(props.row.requestedDate).format('DD/MM/YYYY')}</span>
                    <p style={{ margin: 0, padding: 0 }}>{momemt(props.row.requestedDate).format('HH:mm A')}</p>
                </TableCell>
                <TableCell align="left">{props.row.status}</TableCell>
                {props.identifier === 'productRequestList' ? <TableCell align="left">{props.row.action}</TableCell> : <TableCell align="left">{props.row.stock && props.row.stock === true ? <div className="badge text-primary" style={{ margin: 0, padding: 0 }}>IN-STOCK</div> : <div className="badge text-danger" style={{ margin: 0, padding: 0 }}>NOT AVAILABLE</div>}</TableCell>}
            </TableRow>
        </React.Fragment>
    )
}

export default TableComponent;