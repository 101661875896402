import { FormatAlignJustify } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import { padding } from "@mui/system";
import { height } from "@mui/system";
import { margin } from "@mui/system";
import { borderRadius } from "@mui/system";
import { color } from "@mui/system";
import Widget from "components/Widget";
import { useState } from "react";
import { usePopper } from "react-popper";

export function Tooltip2({ element }) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(element, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }]
  });
  const customStyles = {
    ...styles.popper,
    backgroundColor: "rgb(109, 109, 109, 0.5)",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
    margin: "15px 0 0 0",
    zIndex: 120,
    width: "320px"
  };
  return (
    <div
      ref={setPopperElement}
      id="tooltip"
      style={customStyles}
      {...attributes.popper}
    >
      {element.innerText}
      <div ref={setArrowElement} id="arrow" style={styles.arrow} />
    </div>
  );
}
