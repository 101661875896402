import React, { useState, useEffect } from "react";
import './index.css';
import AxiosRequest from 'sagas/axiosRequest'
import { NotificationManager } from 'react-notifications';
import { useParams } from "react-router-dom";

const ProfileHeader = (props) => {
  const params = useParams()
  const [rate, setRate] = useState(0)

  const handleEmoji = async (e) => {
    setRate(e.target.id)
    let data = {
      seller_id: params.id,
      ratingValue: e.target.id,
    }
    let response = await AxiosRequest.axiosBuyerHelperFunc('post', 'users/addRating', '', data)
    if (response.data.error) {
      NotificationManager.error("Please Sign in to peform this action.")
    } else {
      NotificationManager.success(response.data.title)
    }
  }

  useEffect(() => {
    if (props.dataFromParent1 && props.dataFromParent1.userRating) {
      setRate(props.dataFromParent1.userRating)
    }
  }, [props.dataFromParent1])

  const { dataFromParent } = props;
  const login = localStorage.getItem("buyer_token");

  return (
    <div className="jr-profile-banner" style={{
      background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)),url(${require('assets/images/banners5.jpeg')})`, backgroundSize: 'cover',
      backgroundRepeat: 'none', backgroundPosition: 'center center'
    }}>
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top" style={{ marginBottom: '20px' }}>
            <div className="jr-profile-banner-avatar-info">
              <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light jr-profile-banner-top-mobile">
                {dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data[0] && dataFromParent.products[0].data[0].Seller && dataFromParent.products[0].data[0].Seller.company_name}
              </h2>
            </div>
          </div>
          <div className="jr-profile-banner-bottom-left" style={{ width: '100%' }}>
            {login ?
              <span>
                <i id={1} onClick={(e) => handleEmoji(e)}
                  className={`${rate === 1 ? 'fas' : 'far'} fa-angry warning`}
                  style={{ fontSize: '30px' }} ></i> &nbsp;
                <i id={2} onClick={(e) => handleEmoji(e)}
                  className={`${rate === 2 ? 'fas' : 'far'} fa-sad-tear `}
                  style={{ fontSize: '30px' }}></i> &nbsp;
                <i id={3} onClick={(e) => handleEmoji(e)}
                  className={`${rate === 3 ? 'fas' : 'far'} fa-meh `}
                  style={{ fontSize: '30px' }}></i> &nbsp;
                <i id={4} onClick={(e) => handleEmoji(e)}
                  className={`${rate === 4 ? 'fas' : 'far'} fa-smile-beam }`}
                  style={{ fontSize: '30px' }}></i> &nbsp;
                <i id={5} onClick={(e) => handleEmoji(e)}
                  className={`${rate === 5 ? 'fas' : 'far'} fa-grin-hearts }`}
                  style={{ fontSize: '30px' }}></i> &nbsp;
              </span> : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileHeader;