import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { getOrderDetails } from 'actions/order';
import { Input } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';

const styles = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
};

function StyledRadio(props) {
  return (
    <Radio
      sx={styles.root}
      disableRipple
      color="default"
      checkedIcon={<span style={{ ...styles.icon, ...styles.checkedIcon }} />}
      icon={<span style={{ ...styles.icon }} />}
      {...props}
    />
  );
}

const CancelOrder = (props) => {
  const params = useParams()
  const [open, setOpen] = useState(false)
  const [view, setView] = useState(false)
  const [reason, setReason] = useState('')
  const [selectedValue, setSelectedValue] = useState('Sorry, We do not have expected quantity.')
  const [removedItem, setRemovedItem] = useState([])
  const [disableButton, setDisableButton] = useState(false)

  const handleRequestClose = () => {
    setOpen(false)
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    if (removedItem.length > 0) {
      let prod = []
      for (const data of removedItem) {
        let inven = await props.orderDetails.products.filter(e => e._id === data);
        prod.push({
          productName: inven[0].productName,
          product_id: inven[0].inventory_id.product_id._id,
          inventory_id: inven[0].inventory_id._id,
          quantity: inven[0].quantity
        })
      }
      let data = {
        order_cancel_reason: selectedValue === 'Other' && reason.length > 0 ? reason : selectedValue,
        status: 'Cancelled',
        orderId: params.id,
        products: prod
      }
      await axios.post('/order/proccessCancelOrder', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }
      ).then(result => {
        if (result.data.error) {
          NotificationManager.error(result.data.title)
        } else {
          NotificationManager.success(result.data.title);
          props.getOrderDetails({ data: { orderId: params.id } })
        }
      }).catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
    } else {
      NotificationManager.error('Please select atleast 1 product.')
    }
    setOpen(false)
    setSelectedValue('Sorry, We do not have expected quantity.')
    setView(false)
    setReason('')
    setRemovedItem([])
  }

  const handleChange = (event) => {
    event.preventDefault();
    setSelectedValue(event.target.value)
    if (event.target.value === 'Other') {
      setView(true)
      setReason('')
    }
  }

  const handleChanged = (e) => {
    setReason(e.target.value)
  }

  const handleCheck = async (e) => {
    let temp = [...removedItem]
    if (e.target.checked) {
      temp.push(e.target.value)
      setRemovedItem(temp)
    } else {
      temp.splice(getChecked(e.target.value), 1)
      setRemovedItem(temp)
    }
  }

  const getChecked = (data) => {
    let index = removedItem.findIndex((val) => val === data)
    return index
  }

  let { status } = props;
  return (
    <div>
      <Button className={'jr-btn-xs ml-1'} disabled={(status === 'Processed' || status === 'Requested' || status === 'Cancelled' || status === 'Delivered' || status === 'LOST' || status === 'RTO' || status === 'RTO after Delivery') ? true : false} variant="contained" color="primary" onClick={() => setOpen(true)}> CANCEL ORDER </Button>
      <Dialog open={open} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle className="pb-0">
          Cancel Order
          <DialogContentText className="mt-0 mb-0">
            Are you sure you want to cancel the order ? {props.cancelText && props.cancelText}<br />
            <span className='text-danger'>Please select atleast 1 product!</span>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          {props.orderDetails && props.orderDetails.products.map((data, index) => {
            return <h4 className='text-muted mb-2'>
              <Checkbox
                onClick={handleCheck}
                value={data._id}
                color="primary"
                checked={getChecked(data._id) > -1 ? true : false}
                tabIndex="-1" /> {++index}. {data.productName ? data.productName : data.name} </h4>
          })
          }
          <DialogContentText className='mb-0 mt-3 text-dark'>
            Please select a reason for cancellation of this order:
          </DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup aria-label="reason" name="customized-radios" value={selectedValue} onChange={handleChange}>
              <FormControlLabel value="Sorry, We do not have expected quantity." control={<StyledRadio />} label="Sorry, We do not have expected quantity." />
              <FormControlLabel value="Other" control={<StyledRadio />} label="Other" />
            </RadioGroup>
          </FormControl>
          {view && <Input type="text" name="reason" id="reason" placeholder='* Optional' onChange={handleChanged} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color='secondary' >
            No
          </Button>
          <Button onClick={handleSubmit} color='primary' disabled={(removedItem.length === 0 || disableButton)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ order }) => {
  const { orderDetails } = order;
  return { orderDetails }
};


export default connect(mapStateToProps, { getOrderDetails })(CancelOrder)