import React, { useState } from 'react';
import './index.css'

const ComponentTable = (props) => {
    const [collapse, setCollapse] = useState(false)

    const OnCollapseProject = () => {
        setCollapse(!collapse)
        props.handleCollapse()
    }

    return (
        <React.Fragment>
            {collapse ?
                <span className='mr-1 cursor-pointer' onClick={() => OnCollapseProject()}>
                    <i className="zmdi zmdi-chevron-down"></i>
                </span>
                :
                <span className='mr-1 cursor-pointer' onClick={() => OnCollapseProject()}>
                    <i className="zmdi zmdi-chevron-right"></i>
                </span>
            }
        </React.Fragment>
    )
}

export default ComponentTable;