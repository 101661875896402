import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Settlement from 'components/settlement/Settlement';
import Report from 'components/settlement/SettlementReport';

function TabContainer({ children, tab, tabValue, dir }) {
  return (
    <div dir={dir}>
      {tab === tabValue && children}
    </div>
  );
}

const FullWidthTabsSettlement = (props) => {
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event, value) => {
    setTabValue(value)
  };

  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="All" />
          <Tab className="tab" label="Remittance advice" />
        </Tabs>
      </AppBar>
      <TabContainer dir="ltr" tab={0} tabValue={tabValue}>
        <Settlement />
      </TabContainer>
      <TabContainer dir="ltr" tab={1} tabValue={tabValue}>
        <Report />
      </TabContainer>
    </div>
  );
}

export default FullWidthTabsSettlement;