import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './index.css'

const Drop = (props) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: props.accept
  });

  useEffect(() => {
    props.handleFiles(acceptedFiles)
  }, [acceptedFiles])

  return (
    <div className="dropzone-card">
      <div className="dropzone" onClick={open}>
        <div {...getRootProps({ className: 'dropzone-file-btn drop' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here</p>
        </div>
      </div>
    </div>
  );
}

export default Drop;