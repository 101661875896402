import React from 'react';
import TicketTimeline from 'components/timeline/TicketTimeline'
import { EditOutlined, ErrorOutlineOutlined, AddCircleOutlineOutlined, CheckCircleOutlineOutlined, CheckOutlined } from '@mui/icons-material';

const TrackTicket = (props) => {
  const { activities } = props;
  return (
    <React.Fragment>
      <div className="timeline-section timeline-center clearfix animated slideInUpTiny animation-duration-3" >
        {
          activities && activities.length > 0 ?
            activities.map((value, key) => {
              return <TicketTimeline styleName={(key === 0 || key === 2 || key === 4 || key === 6 || key === 8 || key === 10) ? 'timeline-inverted' : ''} timeLine={value} color={(value.action === 'Seller open' || value.action === 'Buyer open' || value.action === 'RTO open') ? 'bg-primary' : value.action === 'Ticket created' ? 'bg-danger' : value.action === 'Ticket updated' ? 'bg-warning' : value.action === 'Ticket closed' ? 'bg-success' : 'bg-info'}>
                {
                  (value.action === 'Seller open' || value.action === 'Buyer open' || value.action === 'RTO open') ?
                    <ErrorOutlineOutlined /> :
                    value.action === 'Ticket created' ?
                      <AddCircleOutlineOutlined /> :
                      value.action === 'Cancelled' ?
                        <div><i style={{ fontSize: '1.5rem', paddingTop: 0 }} class="zmdi zmdi-close"></i></div> :
                        value.action === 'Ticket updated' ?
                          <EditOutlined /> :
                          value.action === 'Ticket closed' ?
                            <CheckOutlined /> :
                            (value.action === 'Seller close' || value.action === 'Buyer close' || value.action === 'RTO close') ?
                              <CheckCircleOutlineOutlined /> :
                              <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-truck"></i></div>
                }
              </TicketTimeline>
            }) : ''
        }
      </div>
    </React.Fragment>
  );
}

export default TrackTicket;
