import React, { useState, useEffect } from "react";
import FullWidthTabs from 'components/tabs/FullWidthTabs';
import CircularProgress from '@mui/material/CircularProgress';
import OrderHeader from './OrderHeader'
import SellerContainer from "components/sellerContainer"

const OrdersTab = (props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  return (
    <SellerContainer>
      <OrderHeader />
      {
        !loading ?
          <FullWidthTabs />
          :
          <div className="loader-view" style={{ textAlign: "center", marginTop: "300px" }}>
            <CircularProgress />
          </div>
      }
    </SellerContainer>
  );
}

export default OrdersTab;