import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCnxYi2WHrpwH-kqrhnRh907kEJqKnWTP8",
  authDomain: "medimny-46ccb.firebaseapp.com",
  projectId: "medimny-46ccb",
  storageBucket: "medimny-46ccb.appspot.com",
  messagingSenderId: "72371721101",
  appId: "1:72371721101:web:74f662f9e0d0345476a3c4",
  measurementId: "G-5W8EMR3WEY"
};

const app = initializeApp(firebaseConfig);
export default app;
