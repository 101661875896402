import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { getUserDetail } from 'actions';
import { getMediWallet } from 'actions/buyer';
import { connect } from 'react-redux';
import moment from 'moment';
import CustomFilter from 'components/Filter'
import CustomScrollbars from 'util/CustomScrollbars';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { DialogActions, DialogContent, DialogTitle, Dialog, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Badge } from 'reactstrap';
import AxiosRequest from 'sagas/axiosRequest';
import AppConfig from 'constants/config';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "constants/muiTableStyles"
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'components/PaginationCount';

const MediWallet = (props) => {
  const { users, mediWallet } = props;
  const perPage = 50
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('')
  const [month, setMonth] = useState(moment().format('MMMM'))
  const [year, setYear] = useState(moment().format('YYYY'))
  const [wallet, setWallet] = useState(0)
  const [openTopup, setOpenTopup] = useState(false)
  const [nanError, setNanError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [amount, setAmount] = useState('')
  const [initial, setInitial] = useState('0')
  const [isVisited, setIsVisited] = useState(false)
  const [tableData, setTableData] = useState([])

  const onFilterChange = (e, key) => {
    if (key === "month") {
      setMonth(e.target.value)
    } else {
      setYear(e.target.value)
    }
  }

  const clicked = () => {
    setInitial('0')
  }

  const handleResetFilter = (e) => {
    setMonth(moment().format("MMMM"))
    setYear(moment().format("GGGG"))
    setInitial('0')
    setFilter('')
    setPage(1)
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      month: moment().format('MMMM'),
      year: moment().format('YYYY')
    }
    props.getMediWallet({ data })
  }

  useEffect(() => {
    let data = {
      page: 1,
      perPage: 30,
      filter: '',
      month: month,
      year: year
    }
    props.getMediWallet({ data });
    setWallet(users && users.wallet_balance)
    setInitial('1')
  }, [])

  const changePage = (page) => {
    let pages = page + 1
    let data = {
      page: pages,
      perPage: perPage,
      filter: '',
      searchText: '',
      month: month,
      year: year
    }
    setPage(pages)
    setInitial('0')
    props.getMediWallet({ data })
  };

  const applyFilter = () => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      searchText: '',
      month: month,
      year: year
    }
    setPage(1)
    setInitial('0')
    props.getMediWallet({ data })
  }

  useEffect(() => {
    setWallet(users && users.wallet_balance)
  }, [users])

  useEffect(() => {
    const data = []
    mediWallet && mediWallet.details && mediWallet.details.length > 0 && mediWallet.details[0].data.map((dataOne, index) => {
      data.push({
        date: moment(dataOne.createdAt).format('DD/MM/YY HH:mm'),
        narration: dataOne.narration,
        type: dataOne.type,
        amount: (dataOne.settle_amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        closingBal: (dataOne.closing_bal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        status: <Badge pill className={dataOne.status.toLowerCase() === 'success' ? 'text-white bg-success' : dataOne.status.toLowerCase() === 'failed' ? 'text-white bg-danger' : 'text-white bg-primary'}>{dataOne.status}</Badge>
      })
    })
    setTableData(data)
  }, [mediWallet])

  const handleDownload = async () => {
    setPage(1)
    let data1 = {
      page: 1,
      perPage: perPage,
      filter: filter,
      month: month,
      year: year
    }
    let token = localStorage.getItem('buyer_token');
    await axios.request({
      method: 'POST',
      url: 'transactions/downloadTransactionReport',
      data: data1,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      responseType: 'Blob'
    }).then((result) => {
      if (result.data.size !== 0) {
        NotificationManager.success('CSV file downloaded successfully !');
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      else {
        NotificationManager.error('Something Went Wrong !')
      }
    })
      .catch(error => {
        NotificationManager.error('Something Went Wrong !')
      });
    props.getMediWallet({ data: data1 })
  }

  const closePopup = () => {
    setOpenTopup(false)
    setAmount('')
    setIsVisited(false)
    setNanError(false)
    setSubmitting('')
  }

  const handleChange = (e) => {
    setAmount(e.target.value)
    setIsVisited(true)
    let a = Number(e.target.value);
    if (isNaN(a)) {
      setNanError(true)
    } else {
      setNanError(false)
    }
  }

  const submit = () => {
    setSubmitting(true);
    let amt = Number(amount);
    let nanVal = isNaN(amt);
    if (nanVal === false) {
      if (amt >= 20000) {
        let data = { amount: amt }
        let data1 = AxiosRequest.axiosBuyerHelperFunc('post', 'users/walletToken', '', data)
        data1.then((result) => {
          if (result.data.error) {
            NotificationManager.error(result.data.title)
            closePopup()
          } else {
            if (result.data.token) {
              window.location.assign(`${AppConfig.baseUrl}payOnline/${result.data.token}?type=recharge`)
            }
          }
        })
      } else {
        NotificationManager.error('Amount should be greater than 20000')
        closePopup()
      }
    } else {
      NotificationManager.error('Only numbers allowed')
      closePopup()
    }
  }

  const options = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: true,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    page: page - 1,
    serverSide: true,
    count: initial === '1' ? (initial === '1' && mediWallet && mediWallet.details && mediWallet.details[0].data.length >= 50) ? 50 : mediWallet && mediWallet.details && mediWallet.details[0].data.length : mediWallet && mediWallet.details && mediWallet.details.length > 0 && mediWallet.details[0] &&
      mediWallet.details[0].metadata[0] && mediWallet.details[0].metadata[0].total,
    fixedHeader: false,
    sort: false,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <div style={{paddingTop:"23px"}}> 
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        </div>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "narration",
      label: "Narration",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "closingBal",
      label: "Closing Bal",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => {
            return (
              <CustomFilter
                onFilterChange={onFilterChange}
                applyFilter={applyFilter}
                click={clicked}
                handleResetFilter={handleResetFilter}
                month={month}
                year={year}
                handleDownload={handleDownload}
                filterFor='mediWallet'
              />
            )
          },
        }
      }
    }
  ]

  return (
    <React.Fragment>
<div style={{ backgroundColor: "white", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={3} sm={6} xs={12}>
          <h1 style={{
            padding: "11px",
            marginBottom: "inherit",
            fontWeight: 500,
            paddingLeft: "24px",
            paddingTop: "20px",
          }}>
            MediWallet
          </h1>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h3 style={{
            padding: "11px",
            marginBottom: "inherit",
            fontWeight: 500,
            paddingLeft: "24px",
            paddingTop: "22px"
          }}>
            Now recharge your MEDIWALLET and earn cashback
          </h3>
        </Grid>
        <Grid item md={3} sm={12} xs={12} container justifyContent="flex-end">
          <Button variant="outlined" className='text-white bg-primary mr-4 mt-3 mb-2' onClick={() => setOpenTopup(true)}>
            Wallet recharge
          </Button>
        </Grid>
      </Grid>
    </div>
      <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
        {props.loading ?
          <div className="loader-view" style={{ textAlign: "center", marginTop: "30px" }}>
            <CircularProgress />
          </div>
          :
          <ThemeProvider theme={createTheme({ components: styles })}>
            <MUIDataTable
              title={<h3>{props.users === undefined ? '' : 'Wallet Balance: MNY ' + (wallet).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '/-'}</h3>}
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        }
      </CustomScrollbars>
      <Dialog open={openTopup} onClose={closePopup} size='sm'>
        <DialogTitle id="alert-dialog-title">{"Wallet recharge"}</DialogTitle>
        <DialogContent>
          <TextField
            error={isVisited && (amount.length === 0 || nanError || Number(amount) < 0 || Number(amount) < 20000)}
            helperText={(isVisited && amount.length === 0) ? "This field is required" : (isVisited && Number(amount) < 20000) ? `Amount should be greater than 20000` : Number(amount) < 0 ? `Amount can't be negative` : nanError ? 'Only numbers allowed' : ""}
            value={(amount).toLocaleString('en-IN')}
            id="outlined-basic"
            variant="outlined"
            label={'Amount'}
            onChange={(e) => handleChange(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup} color="secondary" autoFocus> Cancel </Button>
          {(amount.length === 0 || nanError || Number(amount) < 20000) ?
            <Button disabled={true} autoFocus> Confirm </Button> :
            submitting ? <CircularProgress size={30} /> :
              <Button onClick={submit} color="primary" autoFocus> Confirm </Button>
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ seller, auth, buyer }) => {
  const { mediWallet, loading } = buyer;
  const { userDetails } = seller
  const { user_details } = auth;

  let users = userDetails ? userDetails : user_details
  return { mediWallet, user_details, users, loading };
}
export default connect(mapStateToProps, { getUserDetail, getMediWallet })(MediWallet);
