import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { connect } from 'react-redux';
import { getProductDetails } from 'actions/buyer';
import OtherSellerInfo from './OtherSellerInfo'

const OtherSellers = (props) => {
  const { otherSellers } = props;

  return (
    <CustomScrollbars className="jr-comments scrollbar" style={{ height: 660 }}>
      {
        otherSellers.length < 1 ? <div style={{ textAlign: 'center', marginTop: '100px', fontSize: 20 }}>Sorry, no other sellers found.</div> :
          otherSellers && otherSellers.map(data => {
            return (
              <OtherSellerInfo data={data} />
            );
          })
      }
    </CustomScrollbars>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { otherSellers, relatedProducts, productData } = buyer;
  return { otherSellers, relatedProducts, productData }
};

export default connect(mapStateToProps, { getProductDetails })(OtherSellers);

