import React, { useState, useEffect } from "react";
import Widget from "components/Widget";
import PropTypes from 'prop-types';
import SellerProductCard from 'components/viewSellerProductCard';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { Row, Col } from "reactstrap";
import { TextField } from "@mui/material";
import './index.css'
import { getAutoFill } from 'actions/buyer';
import { isBrowser } from "react-device-detect";
import { getProductsBySeller, getCategories } from 'actions/buyer';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const About = (props) => {
  const params = useParams()
  const [value, setValue] = useState('')
  const [perPage, setPerPage] = useState(12)
  const [hidden, setHidden] = useState(false)
  const [searchText, setSearchText] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)
    setPerPage(12)
    props.getProductsBySeller({
      seller_id: params.id,
      category_id: event.target.value !== '' ? [event.target.value] : [],
      page: 1,
      key: searchText,
      perPage: 12
    })
  };

  const seeMoreProducts = () => {
    props.getProductsBySeller({
      seller_id: params.id,
      category_id: value !== '' ? [value] : [],
      page: 1,
      perPage: Number(perPage) + 12
    })
    setHidden(true)
    setPerPage(Number(perPage) + 12)
    setTimeout(() => {
      setHidden(false)
    }, 2000)
  }

  const handleSigninOnEnter = (e) => {
    if (e.key === "Enter") {
      props.getProductsBySeller({ category_id: value ? [value] : [], seller_id: params.id, page: 1, perPage: perPage, key: searchText })
    }
  }

  useEffect(() => {
    props.getCategories({ seller_id: params._id })
  }, [])

  const { dataFromParent, categoriesFromParent, product } = props;

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <div position="static" color="default" >
        <Row style={{ margin: '15px', float: 'left', width: isBrowser ? '65%' : '57%' }}>
          <Col>
            <div className="search-bar right-side-icon bg-transparent">
              <div className="form-group search-container d-flex">
                <TextField
                  style={{ border: '2px solid #072791', borderRadius: "0px" }}
                  name="searchTextBoxField"
                  InputProps={{ sx: { height: 40 } }}
                  type="search"
                  className="inputRounded"
                  onKeyDown={handleSigninOnEnter}
                  onChange={(e) => setSearchText(e.target.value)}
                  fullWidth
                  size="small"
                  variant='outlined'
                  placeholder="Search Medicine"
                />
                <div style={{ textAlign: "right", color: "#072791", marginLeft: "-46px", zIndex: "100", marginTop: "8px" }}>
                  <i className="zmdi zmdi-search  zmdi-hc-2x"></i>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Col>
          <FormGroup style={{ marginRight: '15px', float: 'right', width: '28%' }} >
            <Label for="exampleSelect"></Label>
            <Input
              type="select"
              name="select"
              onChange={handleChange}
              value={value}
            >
              <option value={''}>All</option>
              {
                categoriesFromParent && categoriesFromParent.detail && categoriesFromParent.detail.map((val, i) =>
                  <option value={val._id} key={i}>{val.name}</option>
                )
              }
            </Input>
          </FormGroup>
        </Col>
      </div>
      <div className="jr-tabs-classic">
        <div className="jr-tabs-content jr-task-list">
          <TabContainer>
            <div className="row">
              {
                dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data ?
                  null
                  :
                  <div className="loader-view" style={{ margin: 'auto', marginBottom: '10px' }}>
                    <CircularProgress />
                  </div>
              }
              {dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data && dataFromParent.products[0].data.length > 0 ?
                dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data.map((value, index) =>
                  <React.Fragment>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <SellerProductCard styleName="pb-4" headerStyle="bg-gradient-primary" product={product} dataFromParentTwo={dataFromParent} value={value} />
                    </div>
                  </React.Fragment>
                )
                :
                dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].data < 1 ?
                  <div style={{ margin: 'auto', width: '50%', textAlign: ' center', fontSize: '25px' }}>
                    <span className="text-danger" >
                      <i class="zmdi zmdi-alert-circle animated wobble zmdi-hc-5x" ></i>
                    </span>
                    <h1>Not Available !</h1>
                  </div>
                  :
                  null
              }
            </div>
            {!hidden ?
              <div style={{ textAlign: "center" }}>
                {(dataFromParent && dataFromParent.products[0] && dataFromParent.products[0].metadata[0] && dataFromParent.products[0].metadata[0].total > perPage) ?
                  <Button color="primary" className="jr-btn jr-btn-lg btnPrimary" onClick={seeMoreProducts}>
                    See More
                  </Button>
                  : ''
                }
              </div>
              :
              <div className="loader-view">
                <CircularProgress />
              </div>
            }
          </TabContainer>
        </div>
      </div>
    </Widget>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { productsByCompany, categories, searchOptions } = buyer;
  return { productsByCompany, categories, searchOptions }
};

export default connect(mapStateToProps, { getAutoFill, getProductsBySeller, getCategories })(About)