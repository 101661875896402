import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_BUYER_NOTIFICATIONS,
    GET_BUYER_NOTIFICATIONS_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    unreadNotificationCount: 0,
    notificationData: [],
    unReadNotification : [],
    notiData:''
    
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_NOTIFICATIONS: {
            return {
                ...state,
                loader: false,
                tabValue: action.tabValue
            }
        }
        case GET_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                notiData: action.payload,
                unreadNotificationCount: action.payload.count,
                notificationData: action.payload.detail,
                unReadNotification: action.payload.unReadNotification,
            }
        }

        case GET_BUYER_NOTIFICATIONS: {
            return {
                ...state,
                loader: true,
                tabValue: action.tabValue
            }
        }
        case GET_BUYER_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                notiData: action.payload,
                unreadNotificationCount: action.payload.count,
                notificationData: action.payload.detail,
                unReadNotification: action.payload.unReadNotification,
            }
        }

        default:
            return state;
    }
}
