import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import { Col, Row } from 'reactstrap';
import axios from 'constants/axios';
import { required } from 'constants/validations';
import renderTextField from 'components/textBox';
import { Field, Form } from 'react-final-form'
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from 'actions/Auth';
import { useNavigate } from 'react-router';

const VerifyToken = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
    if (props.authUser !== null) {
      navigate('/');
    }
  }, [])

  const onSubmit = async (values) => {
    let data = {
      verificationOtp: values.otp
    }
    let api = localStorage.getItem('forgotPassword') === 'true' ? 'users/verifyOTP' : 'users/mobileVerification';
    let token = localStorage.getItem('forgotPassword') === 'true' ? localStorage.getItem('forgotPasswordToken') : localStorage.getItem('verifyToken')
    let users = localStorage.user
    await axios({
      method: 'post',
      url: `${api}`,
      data,
      headers: {
        'token': token
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      }
      else {
        if (api === 'users/verifyOTP') {
          if (result.data.token === null) {
            localStorage.setItem('forgotPassword', false)
            localStorage.setItem('forgotPasswordToken', null)
            NotificationManager.success('Otp verified successfully.');
            setTimeout(() => {
              NotificationManager.error('Your document is under verification, please try to login after sometime.');
              return navigate('/seller/signin')
            }, 2000)
          } else if (users === 'buyer') {
            localStorage.setItem('buyer_token', result.data.token)
            NotificationManager.success(result.data.title);
            setTimeout(() => {
              return navigate('/profile')
            }, 2000)
          }
          else if (users === 'seller') {
            localStorage.setItem('token', result.data.token)
            NotificationManager.success(result.data.title);
            setTimeout(() => {
              return navigate('/seller/change-Password')
            }, 2000)
          }
        }
        else {
          let buyer = localStorage.getItem('isBuyerVerification')
          if (buyer === 'true') {
            localStorage.removeItem('isBuyerVerification')
            navigate('/')
          } else {
            setTimeout(() => {
              return navigate('/')
            }, 2000)
          }
          NotificationManager.success(result.data.title);
        }
      }
    })
      .catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
  }

  const onResend = async () => {
    let token = localStorage.getItem('forgotPassword') === 'true' ? localStorage.getItem('forgotPasswordToken') : localStorage.getItem('verifyToken');
    await axios({
      method: 'post',
      url: 'users/resendOTP',
      headers: {
        'token': token
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      }
      else {
        NotificationManager.success(result.data.title);
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  return (
    <div className="col-xl-12 col-lg-12 verifyTokenContainer" >
      <div className="jr-card verifyTokenCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="mb-4">
            <h3>{localStorage.getItem('forgotPassword') === 'true' ? 'Verify OTP' : 'Mobile OTP verification'}</h3>
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} autocomplete="off">
                <Field name="otp" type="text"
                  component={renderTextField} label="OTP"
                  validate={required}
                />
                <div className="mb-2" style={{ display: 'inline', float: 'right' }}>
                  <Button color="primary" variant="contained" className="text-white " style={{ width: '120px' }}
                    type='submit'>
                    Verify
                  </Button>
                </div>
              </form>
            )}
          />
          <Row>
            <Col>
              <div className="mb-2" >
                <Button color="primary" onClick={onResend} variant="contained" className="text-white float-right" style={{ width: '128px' }}>
                  Resend OTP
                </Button>
              </div></Col>
          </Row>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, { userSignIn, hideMessage, showAuthLoader, })(VerifyToken);
