import React from 'react'
import { Col, Row } from 'reactstrap';
import ContainerHeader from 'components/ContainerHeader/index';
import { getUserDetail } from 'actions/seller'
import { connect } from 'react-redux'
import ComplianceFormComponent from './complianceFormComponent';
import BankDetails from './bankDetails'
import SellerContainer from "components/sellerContainer"

const ComplainceForm = (props) => {
    return (
        <SellerContainer>
            <div className="col-xl-12 col-lg-12">
                <ContainerHeader title="Compliance Forms" />
                <div className="jr-card">
                    <Row style={{ padding: 20 }}>
                        <Col sm={12} md={4} lg={4} xs={12} xl={4} className="mt-2">
                            <ComplianceFormComponent fileKey={'drugLic20B'} licKey='drugLic20BLicNo' label2='DrugLic20BLicNo' label="Drug Lic 20B" name="drugLic20BExpiry" />
                        </Col>
                        <Col sm={12} md={4} lg={4} xs={12} xl={4} className="mt-2">
                            <ComplianceFormComponent fileKey={'drugLic21B'} licKey='drugLic21BLicNo' label2='DrugLic21BLicNo' label="Drug Lic 21B" name="drugLic21BExpiry" />
                        </Col>
                        <Col sm={12} md={4} lg={4} xs={12} xl={4} className="mt-2">
                            <ComplianceFormComponent fileKey={'fassaiLic'} licKey='fassaiLicNo' label2='FssaiLicNo' label="FSSAI Lic (Optional)" name="fassaiLicExpiry" />
                        </Col>
                        <Col sm={12} md={4} lg={4} xs={12} xl={4} className="mt-5">
                            <ComplianceFormComponent fileKey={'gstLic'} licKey='gstLicNo' label2='GstLicNo' label="GSTN Lic (Optional)" name="gstLic" />
                        </Col>
                    </Row>
                </div>
                <div className="jr-card">
                    <div className='jr-card-header'>
                        <h3>Bank details</h3>
                    </div>
                    <BankDetails />
                </div>
            </div >
        </SellerContainer>
    )
}

const mapStateToProps = ({ seller }) => {
    const { userDetails } = seller;
    return { userDetails }
};

export default connect(mapStateToProps, { getUserDetail })(ComplainceForm)