import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getMarkRead } from 'actions/seller';
import AppNotification from 'components/AppNotification';
import CardHeader from 'components/CardHeader';
import { getNotification } from 'actions/notification';
import { Button, useMediaQuery, useTheme } from '@mui/material';

const Header = (props) => {
  const [appNotification, setAppNotification] = useState(false)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification)
  };

  useEffect(() => {
    props.getNotification({ data: {} })
  }, [])
  const onToggleCollapsedNav = () => {
    props.setOpen(!props.open);
  }
  const handleClose = (e) => {
    setAppNotification(false)
  }

  const handleChange = (e) => {
    props.getMarkRead({})
  }

  const { unreadNotificationCount, notificationData } = props;
  return (
    <AppBar className='app-main-header app-main-header-top'>
      <Toolbar className="app-toolbar" disableGutters={false}>
       { isMobile && <div className="d-block d-md-none pointer mr-3" 
        onClick={isMobile?onToggleCollapsedNav:""}
        >
          <span className="jr-menu-icon">
            <span className="menu-icon" />
          </span>
        </div>}
        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn" onClick={(e) => handleChange()}>
                  <i className={unreadNotificationCount > 0 ? "zmdi zmdi-notifications-none icon-alert animated infinite wobble" : "zmdi zmdi-notifications-none"} />
                </IconButton>
              </DropdownToggle>
              <DropdownMenu right>
                <CardHeader styleName="align-items-center" heading="Notifications" />
                <AppNotification
                  notificationData={notificationData}
                  type='seller'
                />
                <div style={{ padding: 10, borderTop: '1px solid #dee2e6', textAlign: 'center', zIndex: 10, margin: '0 -10px' }}>
                  <NavLink to="/seller/list-notification" className={'text-black NavLink'} onClick={(e) => handleClose(e)}>
                    <span>See all notifications</span>
                  </NavLink>
                </div>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
        <div className="ellipse-shape"></div>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = ({ settings, notification, seller }) => {
  const { unreadNotificationCount, notificationData } = notification;
  const { getMarkReadList } = seller;
  return { unreadNotificationCount, notificationData, getMarkReadList }
};

export default connect(mapStateToProps, { getNotification, getMarkRead })(Header);