import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';
import { getOrderList, getOrderHistoryList } from 'actions/buyer';
import Order from 'components/orderTable'
import CustomScrollbars from 'util/CustomScrollbars';

function TabContainer({ children, dir, index, value }) {
  return (
    <div dir={dir}>
      {value === index && children}
    </div>
  );
}

const FullWidthTabs = (props) => {
  const [tabValue, setTabValue] = useState(0)
  const [show, setShow] = useState(false)

  const handleChange = (event, value) => {
    setTabValue(value)
    setShow(true)
  };

  useEffect(() => {
    let data = { page: 1, perPage: 50, filter: '' };
    props.getOrderList({ data });
    props.getOrderHistoryList({ data });
  }, [])

  const { orderList, orderHistoryList } = props;

  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="History" />
        </Tabs>
      </AppBar>
      <TabContainer dir="ltr" value={tabValue} index={0}>
        <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
          <Order
            orderFromParent={orderList}
            identifier={'orderList'}
            show={show}
          />
        </CustomScrollbars>
      </TabContainer>
      <TabContainer dir="ltr" value={tabValue} index={1}>
        <CustomScrollbars className="messages-list scrollbar" style={{ height: 650 }}>
          <Order
            orderFromParent={orderHistoryList}
            identifier={'orderHistoryList'}
            show={show}
          />
        </CustomScrollbars>
      </TabContainer>
    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { orderList, orderHistoryList } = buyer;
  return { orderList, orderHistoryList }
};

export default connect(mapStateToProps, { getOrderList, getOrderHistoryList })(FullWidthTabs);