import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    GET_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    GET_ORDER_HISTORY,
    GET_ORDER_HISTORY_SUCCESS,
    GET_DASHBOARD_SALES_STATS,
    GET_DASHBOARD_SALES_STATS_SUCCESS,
} from "constants/ActionTypes";

import {
    getOrder,
    getOrderSuccess,
    getOrderDetails,
    getOrderDetailsSuccess,
    updateOrder,
    updateOrderSuccess,
    getOrderHistory,
    getOrderHistorySuccess,
    getDashboardSalesStatsSuccess
} from "actions/order";

import { showAuthMessage } from "actions/Auth";

import Axios from './axiosRequest'

function* getOrderFunction({ payload }) {
    try {
        const getOrderResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/allOrderListing', '', payload.data);
        if (getOrderResponse && getOrderResponse.data && getOrderResponse.data.error) {
            yield put(showAuthMessage(getOrderResponse.data.title));
        } else if (getOrderResponse && getOrderResponse.data) {
            yield put(getOrderSuccess(getOrderResponse.data))
        }
    } catch (error) {
        yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
    }
}
export function* getOrderDispatcher() {
    yield takeLatest(GET_ORDER, getOrderFunction);
}

function* getOrderDetailsFunction({ payload }) {
    try {
        const getOrderDetailsResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/getOrderDetails', '', payload.data);
        if (getOrderDetailsResponse && getOrderDetailsResponse.data && getOrderDetailsResponse.data.error) {
            yield put(showAuthMessage(getOrderDetailsResponse.data.title));
        } else if (getOrderDetailsResponse && getOrderDetailsResponse.data) {
            yield put(getOrderDetailsSuccess(getOrderDetailsResponse.data))
        }
    } catch (error) {
        yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
    }
}
export function* getOrderDetailsDispatcher() {
    yield takeLatest(GET_ORDER_DETAILS, getOrderDetailsFunction);
}

function* updateOrderFunction({ payload }) {
    const { history } = payload
    try {
        const updateOrderResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/updateOrder', '', payload.data);
        if (updateOrderResponse && updateOrderResponse.data && updateOrderResponse.data.error) {
            yield put(showAuthMessage(updateOrderResponse.data.title));
            yield put(getOrderDetails({ history: history, data: { orderId: payload.data.orderId } }))
        } else if (updateOrderResponse && updateOrderResponse.data) {
            yield put(updateOrderSuccess(updateOrderResponse.data))
            yield put(getOrderDetails({ history: history, data: { orderId: payload.data.orderId } }))
        }
    } catch (error) {
        yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
    }
}
export function* updateOrderDispatcher() {
    yield takeLatest(UPDATE_ORDER, updateOrderFunction);
}

function* getOrderHistoryFunction({ payload }) {
    try {
        const getOrderHistoryResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/allOrderListing', '', payload.data);
        if (getOrderHistoryResponse && getOrderHistoryResponse.data && getOrderHistoryResponse.data.error) {
            yield put(showAuthMessage(getOrderHistoryResponse.data.title));
        } else if (getOrderHistoryResponse && getOrderHistoryResponse.data) {
            yield put(getOrderHistorySuccess(getOrderHistoryResponse.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
    }
}
export function* getOrderHistoryDispatcher() {
    yield takeLatest(GET_ORDER_HISTORY, getOrderHistoryFunction);
}

function* getDashboardSalesStatsFunction({ payload }) {
    try {
        const getDashboardSalesStatsResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/dashboardSalesStats', '', payload.data);
        if (getDashboardSalesStatsResponse && getDashboardSalesStatsResponse.data && getDashboardSalesStatsResponse.data.error) {
            yield put(showAuthMessage(getDashboardSalesStatsResponse.data.title));
        } else if (getDashboardSalesStatsResponse && getDashboardSalesStatsResponse.data) {
            yield put(getDashboardSalesStatsSuccess(getDashboardSalesStatsResponse.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error && error.message ? error.message : 'Something went wrong, please try again!'));
    }
}
export function* getDashboardSalesStatsDispatcher() {
    yield takeLatest(GET_DASHBOARD_SALES_STATS, getDashboardSalesStatsFunction);
}

export default function* rootSaga() {
    yield all([
        fork(getOrderDispatcher),
        fork(getOrderDetailsDispatcher),
        fork(updateOrderDispatcher),
        fork(getOrderHistoryDispatcher),
        fork(getDashboardSalesStatsDispatcher),
    ]);
}