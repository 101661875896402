import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './index.css'
import momemt from 'moment';
import { Link } from 'react-router-dom'

const TableComponent = (props) => {
    return (
        <React.Fragment>
            <TableRow
                hover
                className='cursor-pointer'
                key={props.row.productName}
                align={'left'}
            >
                <TableCell align={'left'} component={Link} to={`/search-results/category/search=${encodeURIComponent(props.row.productName)}`}>
                    {props.row.productName}
                </TableCell>
                <TableCell align={'left'} component={Link} to={`/search-results/category/search=${encodeURIComponent(props.row.productName)}`}>
                    {props.row.manufacturer}
                </TableCell>
                <TableCell align={'left'} component={Link} to={`/search-results/category/search=${encodeURIComponent(props.row.productName)}`}>
                    {props.row.quantity}
                </TableCell>
                <TableCell align={'left'} component={Link} to={`/search-results/category/search=${encodeURIComponent(props.row.productName)}`}>
                    <span>{momemt(props.row.requestedDate).format('DD/MM/YYYY')}</span>
                    <p style={{ margin: 0, padding: 0 }}>{momemt(props.row.requestedDate).format('HH:mm A')}</p>
                </TableCell>
                <TableCell align={'left'} component={Link} to={`/search-results/category/search=${encodeURIComponent(props.row.productName)}`}>
                    {props.row.status}
                </TableCell>
                {props.identifier === 'shortbookList' ? <TableCell align="left">{props.row.action}</TableCell> : null}
            </TableRow>
        </React.Fragment>
    )
}

export default TableComponent;