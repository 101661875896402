import {
    API_FAILED,
    VACCATION_MODE,
    VACCATION_MODE_SUCCESS,
    ADD_GROUP,
    ADD_GROUP_SUCCESS,
    DELETE_GROUP,
    DELETE_GROUP_SUCCESS,
    GET_INVENTORY_POPUP,
    GET_INVENTORY_POPUP_SUCCESS,
    LIST_GROUP,
    LIST_GROUP_SUCCESS,
    LIST_PERMISSION_MODULES,
    LIST_PERMISSION_MODULES_SUCCESS,
    GET_SIDEBAR,
    GET_SIDEBAR_SUCCESS,
    ADD_STAFF,
    ADD_STAFF_SUCCESS,
    DELETE_STAFF,
    DELETE_STAFF_SUCCESS,
    LIST_STAFF,
    LIST_STAFF_SUCCESS,
    GET_REQUEST_PRODUCT,
    GET_REQUEST_PRODUCT_SUCCESS,
    REQUEST_PRODUCT,
    REQUEST_PRODUCT_SUCCESS,
    GET_USERDETAILS,
    GET_USERDETAILS_SUCCESS,
    GET_INVENTORY,
    GET_INVENTORY_SUCCESS,
    GET_DASHBOARD_CARD,
    GET_DASHBOARD_CARD_SUCCESS,
    GET_DASHBOARD_TOP_SELLING_PRODUCTS,
    GET_DASHBOARD_TOP_SELLING_PRODUCTS_SUCCESS,
    GET_SETTLEMENT,
    GET_SETTLEMENT_SUCCESS,
    GET_DASHBOARD_LEAST_SELLING_PRODUCTS,
    GET_DASHBOARD_LEAST_SELLING_PRODUCTS_SUCCESS,
    GET_MARK_READ_LIST,
    GET_MARK_READ_LIST_SUCCESS,
        // ------------- SETTLEMENTS-----------------

    GET_SETTLEMENTS,
    GET_SETTLEMENTS_SUCCESS,
    REMOVE_USER,
    GET_LIST_GROUP_SETTLEMENT,
    GET_LIST_GROUP_SETTLEMENT_SUCCESS,

    GET_TICKET_LIST_SELLER,
    GET_TICKET_LIST_SELLER_SUCCESS,
    GET_ADD_TICKET,
    GET_ADD_TICKET_SUCCESS,
    GET_BULK_LIST_SELLER,
    GET_BULK_LIST_SELLER_SUCCESS
} from 'constants/ActionTypes'
import { NotificationManager } from 'react-notifications';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    userDetails: '',
    listGroupData: [],
    listpermissionModulesdata: [],
    sidebar: [],
    Detail:{},
    staffList: [],
    requestedProducts: [],
    listInventory:[],
    productCategories:[],
    topSellingProducts:[],
    settlements : [],
    totalEarning : 0,
    leastSellingProducts : [],
    isVaccatioMode:false,

    sellerStats:'',
    // activeSelling:0,
    // shortExpire:0,
    // expiredProducts: 0,
    // outOfStock:0,
    getMarkReadList:'',

    sellerSettlements:'',
    listGroupSettlement: '',
    ticketListSeller: '',
    addTicket: '',
    bulkListSeller: '',
    sellerId: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_FAILED:                       
        NotificationManager.error(action.payload)
            return {
                ...state,
                loading: false,

            }
        case VACCATION_MODE:
            return {
                ...state,
                loading: true
            }
        case VACCATION_MODE_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false,
                userDetails: action.payload.userDetails,
            }
        case ADD_GROUP:
            return {
                ...state,

                loading: true
            }
        case ADD_GROUP_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case DELETE_GROUP:
            return {
                ...state,
                loading: true
            }
        case DELETE_GROUP_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case LIST_GROUP:
            return {
                ...state,
                loading: true
            }
        case LIST_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                listGroupData: action.payload.detail
            }
        case LIST_PERMISSION_MODULES:
            return {
                ...state,
                loading: true
            }
        case LIST_PERMISSION_MODULES_SUCCESS:
            return {
                ...state,
                listpermissionModulesdata: action.payload.detail,
                loading: false
            }

        case GET_SIDEBAR:
            return {
                ...state,
                loading: true
            }
        case GET_SIDEBAR_SUCCESS:
        if(action.payload.message){
            NotificationManager.error(action.payload.message)
        }
            return {
                ...state,
                sidebar: action.payload.detail,
                userDetails: action.payload.user_details,
                isVaccatioMode:action.payload.isVaccatioMode,
                loading: false
            }
        case ADD_STAFF:
            return {
                ...state,
                loading: true
            }
        case ADD_STAFF_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case DELETE_STAFF:
            return {
                ...state,
                loading: true
            }
        case DELETE_STAFF_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case LIST_STAFF:
            return {
                ...state,
                loading: true
            }
        case LIST_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                staffList: action.payload.detail
            }
        case REQUEST_PRODUCT:
            return {
                ...state,
                loading: true
            }
        case REQUEST_PRODUCT_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case GET_REQUEST_PRODUCT:
            return {
                ...state,
                loading: true
            }
        case GET_REQUEST_PRODUCT_SUCCESS:
            return {
                ...state,
                requestedProducts: action.payload.detail,
                loading: false
            }

        case GET_USERDETAILS:
            return {
                ...state,
                loading: true
            }
            
        case GET_USERDETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.payload.detail,
                loading: false
            }

        case GET_INVENTORY:
            return {
                ...state,
                loading: true
            }
        case GET_INVENTORY_SUCCESS:
            return {
                ...state,
                listInventory: action.payload.detail,
                productCategories:action.payload.productCategories,
                loading: false
            }


            case GET_INVENTORY_POPUP:
                return {
                    ...state,
                    loading: true,
                   
                }
            case GET_INVENTORY_POPUP_SUCCESS:
                return {
                    ...state,
                    Detail: action.payload,
                    loading: false
                }

        case GET_DASHBOARD_CARD:
        return{
            ...state,
            loading:true
        }
        case GET_DASHBOARD_CARD_SUCCESS:
        return{
            ...state,
            sellerStats:action.payload,
            // shortExpire:action.payload.shortExpireProduct,
            // outOfStock:action.payload.outOfStock,         
            // expiredProducts:action.payload.expired, 
        }
        case GET_DASHBOARD_TOP_SELLING_PRODUCTS:
        return{
            ...state,
            loading:true
        }
        case GET_DASHBOARD_TOP_SELLING_PRODUCTS_SUCCESS:
        return{
            ...state,
            topSellingProducts:action.payload.detail,
            loading:false
        }

        case GET_SETTLEMENT:
        return{
            ...state,
            loading:true
        }
        case GET_SETTLEMENT_SUCCESS:
        return{
            ...state,
            settlements:action.payload.detail,
            totalEarning : action.payload.totalEarning,
            loading:false
        }
        case GET_DASHBOARD_LEAST_SELLING_PRODUCTS:
        return{
            ...state,
            loading:true
        }
        case GET_DASHBOARD_LEAST_SELLING_PRODUCTS_SUCCESS:
        return{
            ...state,
            leastSellingProducts:action.payload.detail,
            loading:false
        }
        // ----------------------------------------------------

        case GET_MARK_READ_LIST: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_MARK_READ_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                getMarkReadList: action.payload,
            }
        }

        case GET_SETTLEMENTS:
            return{
                ...state,
                loading:true
            }

        case GET_SETTLEMENTS_SUCCESS:
            return{
                ...state,
                sellerSettlements: action.payload,
                loading:false,
            } 
        case REMOVE_USER:
            return{
                ...state,
                userDetails:''
            }
        case GET_LIST_GROUP_SETTLEMENT: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_LIST_GROUP_SETTLEMENT_SUCCESS: {

            return {
                ...state,
                loading: false,
                listGroupSettlement: action.payload,
            }
        }

        case GET_TICKET_LIST_SELLER: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_TICKET_LIST_SELLER_SUCCESS: {
            return {
                ...state,
                loading: false,
                ticketListSeller: action.payload.data,
            }
        }

        case GET_ADD_TICKET: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_ADD_TICKET_SUCCESS: {
            return {
                ...state,
                loading: false,
                addTicket: action.payload,
            }
        }

        case GET_BULK_LIST_SELLER: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_BULK_LIST_SELLER_SUCCESS: {
            return {
                ...state,
                loading: false,
                sellerId: action.payload.sellerId,
                bulkListSeller: action.payload.data,
            }
        }
        default:
            return state;
    }
}