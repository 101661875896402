import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import './index.css'
import { getTicketList } from 'actions/buyer';
import { connect } from 'react-redux';
import AddTicket from './AddTicket';
import AddIcon from '@mui/icons-material/Add';

const EnhancedTableToolbar = (props) => {
    const [searchText, setSearchText] = useState('')
    const [hide, setHide] = useState(true)
    const [openTicket, setOpenTicket] = useState(false)

    useEffect(() => {
        let data = {
            page: 1,
            perPage: 50,
        }
        props.getTicketList({ data })
    }, [])

    const handleTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const clearSearch = () => {
        setSearchText('')
        setHide(true)
        let data = {
            page: 1,
            perPage: 50,
            filter: '',
            searchText: ''
        }
        props.getTicketList({ data })
    }

    const applySearch = () => {
        let data = {
            page: 1,
            perPage: 50,
            filter: '',
            searchText: searchText
        }
        props.getTicketList({ data })
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            applySearch()
        }
    }

    const handleClick = (data) => {
        setOpenTicket(false)
    }

    const hitApi = () => {
        let data = { page: 1, perPage: 50 };
        props.getTicketList({ data })
    }

    const { identifier } = props;

    return (
        <Toolbar>
            {
                identifier === "ticketList" ?
                    <React.Fragment>
                        <AddTicket open={openTicket} handleClick={handleClick} hitApi={hitApi} />
                        <div style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "0px", width: "80%" }}>
                            <TextField
                                className='search'
                                autoFocus={true}
                                fullWidth={true}
                                hidden={hide}
                                value={searchText}
                                onChange={handleTextChange}
                                placeholder='Type Ticket number OR Order id'
                                onKeyUp={handleKeyPress}
                                variant="standard"
                            />
                        </div>
                        <Grow appear in={true} timeout={300}>
                            <div className='searchBox' style={{ marginTop: "-10px", marginRight: "0px", marginLeft: "auto" }}>
                                <IconButton className='clear'>
                                    {hide ? <SearchIcon onClick={() => { setHide(false) }} /> : <ClearIcon onClick={clearSearch} />}
                                </IconButton>
                            </div>
                        </Grow>
                        <div style={{ marginTop: "-10px", marginRight: "0px" }}>
                            <IconButton >
                                <AddIcon onClick={(e) => setOpenTicket(true)} />
                            </IconButton>
                        </div>
                    </React.Fragment>
                    :
                    null
            }
        </Toolbar>
    );
}

const mapStateToProps = ({ buyer }) => {
    const { getTicketList } = buyer;
    return { getTicketList }
};


export default connect(mapStateToProps, { getTicketList })(EnhancedTableToolbar);