import React from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const renderReactStrapField = ({ classNameField = '', input, hide, hidden, disabled, label, type, rows, props, meta: { asyncValidate, touched, error, warning } }) => {
    return <FormGroup style={{ marginTop: hidden ? '-37px' : '0px', display: hide === true ? 'none' : '' }} className={asyncValidate ? `async-validating ${classNameField}` : classNameField}>
        <Label for={label}>{label}</Label>
        <Input disabled={disabled} hidden={hidden} invalid={(touched && error) || (warning)} type={type} {...props} {...input} placeholder={label} rows={rows} />
        <FormFeedback>{error}</FormFeedback>
    </FormGroup>
}
export default renderReactStrapField