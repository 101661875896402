import React, { useState, useEffect } from 'react';
import './index.css'
import Radio from '@mui/material/Radio';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSearchProduct } from 'actions/buyer';

const ManufacturersPopOver = (props) => {
  const params = useParams()
  const [manufacturers, setManufacturers] = useState([])
  const [obj, setObj] = useState({ search: '', company_id: '', category_id: '', seller_id: '' })

  useEffect(() => {
    const { dataFromParent } = props;
    let sorted = dataFromParent && dataFromParent.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
      if (nameA < nameB)
        return -1
      if (nameA > nameB)
        return 1
      return 0
    })
    setManufacturers(sorted)
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    let newObj = { ...obj }
    newObj.search = decodedURL.search ? decodedURL.search : ''
    newObj.company_id = decodedURL.company_id ? decodedURL.company_id : ''
    newObj.category_id = decodedURL.category_id ? decodedURL.category_id : ''
    newObj.seller_id = decodedURL.seller_id ? decodedURL.seller_id : ''
    setObj(newObj)
  }, [])

  const handleClick = (key, e) => {
    if (e !== '') {
      props.handleClick('company_id', e);
    }
  }

  return (
    <div>
      <div className=''
        style={{
          width: "90%",
          position: "sticky", top: 0,
          marginBottom: 0,
          marginLeft: -20,
          marginRight: -20,
          marginTop: -22,
        }}
      >
      </div>
      <hr style={{ marginTop: 58, marginLeft: -20, width: "105%" }} />
      <ul className="horizontal" style={{ fontSize: 12, marginLeft: -20, marginBottom: 0, }}>
        {manufacturers && manufacturers.length > 0 && manufacturers.map(companies => {
          let cls = companies.name.length > 32 ? '' : 'liCompanies'
          return <li className={cls}>
            <Radio color="primary"
              checked={props.company_id === companies._id}
              onClick={(e) => handleClick('company_id', companies._id)}
              value="a" name="radio button demo"
              aria-label="A" />
            {companies.name ? companies.name : companies.first_name + " " + companies.last_name}
          </li>
        })}
      </ul>
    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { searchProduct } = buyer;
  return { searchProduct }
};

export default connect(mapStateToProps, { getSearchProduct })(ManufacturersPopOver);
