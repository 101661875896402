import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Widget from "components/Widget/index";
import EditProfileForm from 'components/BEditProfile'
import Password from 'components/BChangePassword'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EditProfile = (props) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleChange = (event, value) => {
    setActiveStep(value);
  };

  const { userDataFromParent } = props;

  return (
    <div className="jr-profile-content w-100">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile" >
            <AppBar position="static" color="default" indicatorColor='primary' >
              <Tabs
                textColor='primary'
                indicatorColor='secondary'
                className="jr-tabs-center"
                value={activeStep}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab textColor='primary' className="profileTab" label="Profile" />
                <Tab textColor='primary' className="profileTab" label="Password" />
              </Tabs>
            </AppBar>
            <div className="jr-tabs-classic">
              <div className="jr-tabs-content jr-task-list" style={{ maxHeight: '865px' }}>
                <CustomTabPanel dir="ltr" value={activeStep} index={0}>
                  <EditProfileForm
                    className='mt-2 pb-0'
                    userDataFromParentTwo={userDataFromParent}
                  />
                </CustomTabPanel>
                <CustomTabPanel dir="ltr" value={activeStep} index={1}>
                  <Password className='mt-2' />
                </CustomTabPanel>
              </div>
            </div>
          </Widget>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;