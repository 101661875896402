import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { connect } from 'react-redux';
import { getSearchProduct } from 'actions/buyer';
import './index.css'
import { useParams } from 'react-router-dom';

const CategoryPopOver = (props) => {
  const params = useParams()
  const [obj, setObj] = useState({ search: '', company_id: '', category_id: '', seller_id: '' })
  const [manufacturers, setManufacturers] = useState([])

  useEffect(() => {
    const { dataFromParent } = props;
    let sorted = dataFromParent && dataFromParent.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
      if (nameA < nameB)
        return -1
      if (nameA > nameB)
        return 1
      return 0
    })
    setManufacturers(sorted)
    const decodedURL = JSON.parse('{"' + decodeURIComponent(params.search.replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}')
    let newObj = { ...obj }
    newObj.search = decodedURL.search ? decodedURL.search : ''
    newObj.company_id = decodedURL.company_id ? decodedURL.company_id : ''
    newObj.category_id = decodedURL.category_id ? decodedURL.category_id : ''
    newObj.seller_id = decodedURL.seller_id ? decodedURL.seller_id : ''
    setObj(newObj)
  }, [])

  const handleClick = (key, e) => {
    if (e !== '') {
      props.handleCategories('category_id', e);
    }
  }

  return (
    <div>
      <div className=''
        style={{
          width: "90%",
          position: "sticky", top: 0,
          marginBottom: 0,
          marginLeft: -20,
          marginRight: -20,
          marginTop: -22,
        }}>
      </div>
      <hr style={{ marginTop: 58, marginLeft: -20, width: "106%" }} />
      <ul className="horizontal" style={{ fontSize: 12, marginLeft: -20, marginBottom: 0, }}>
        {manufacturers && manufacturers.length > 0 && manufacturers.map(category => <li className="liCompanies">
          <Checkbox
            onClick={(e) => handleClick('category_id', e)}
            color="primary"
            checked={props.getChecked(category._id) > -1 ? true : false}
            tabIndex="-1"
            value={category._id} />
          {category.name ? category.name : category.first_name + " " + category.last_name}
        </li>
        )}
      </ul>
    </div>
  );
}

const mapStateToProps = ({ buyer }) => {
  const { searchProduct } = buyer;
  return { searchProduct }
};

export default connect(mapStateToProps, { getSearchProduct })(CategoryPopOver);