import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import app from '../firebase-conf';
import TextBox from 'components/textBox'
import { Field, Form } from 'react-final-form'
import { required, emailField } from 'constants/validations'
import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMessaging, getToken } from '@firebase/messaging';

const SignIn = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showNotification, setShowNotification] = useState('seller')

  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
    if (props.authUser !== null) {
      navigate('/');
    }
  }, [])

  const gotoForgotPassword = () => {
    localStorage.setItem('user', 'seller')
  }

  const onSubmit = async (values) => {
    let web_token = ""
    try {
      const msg = await getMessaging(app);
      const Wtoken = await getToken(msg, { vapidKey: "BOBJN6BcqltSJVpB2FZi8-s6q2Glta94R4UEx7JcWLn8upFx3MJ8lhLnpOaTi5Fvwoil_Bbb14yaWq2tqzTuLtk" })
      if (Wtoken) {
        web_token = Wtoken
      } else {
        web_token = ""
      }
    } catch (error) {
      console.log(error, "@@@@@ERROR OF NOTIFICATION signin");
    }
    if (showNotification) {
      localStorage.clear();
      props.showAuthLoader();
      props.userSignIn({ email: values.email, password: values.password, user_type: "seller", web_token, navigate, location });
      setShowNotification({ showNotification: false });
    }
  }

  return (
    <div className="col-xl-8 col-lg-8 signinContainer" >
      <div className="jr-card signinCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="login-header mb-0">
            <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
          </div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                    <Field
                      name="email"
                      type="text"
                      component={TextBox}
                      label="Email"
                      validate={emailField}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={12} xs={12} xl={12}>
                    <Field
                      name="password"
                      type="password"
                      component={TextBox}
                      label="Password"
                      validate={required}
                    />
                  </Col>
                  <Col sm={6} md={6} lg={6} xs={6} xl={6} >
                    <Button type='submit' variant='contained' color='primary' className='m-2 signin' size='medium' >LOGIN</Button>
                  </Col>
                  <Col sm={6} md={6} lg={6} xs={6} xl={6} className='d-flex justify-content-end align-items-center'>
                    <a href='/forgotPassword' onClick={gotoForgotPassword}><span color='primary'>Forgot your password?</span></a>
                  </Col>
                </Row>
                <div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, { userSignIn, hideMessage, showAuthLoader })(SignIn)