import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { listPermissionModules, addGroup } from 'actions/seller';
import { connect } from 'react-redux';
import ReactStrapTextField from 'components/ReactStrapTextField';
import { Field, Form } from 'react-final-form'
import { required } from 'constants/validations';
import { NotificationManager } from 'react-notifications';

const EditGroup = (props) => {
    const [permission, setPermission] = useState([])
    const [admin_access, setAdminAccess] = useState(false)

    useEffect(() => {
        props.listPermissionModules({})
    }, [])

    useEffect(() => {
        let tempState = props.selectedData && props.selectedData.Permissions ? props.selectedData.Permissions : [];
        if (props.selectedData && props.selectedData.Permissions && props.listpermissionModulesdata && props.selectedData.Permissions.length === props.listpermissionModulesdata.length) {
            setPermission(tempState)
            setAdminAccess(true)
        } else {
            setPermission(tempState)
            setAdminAccess(false)
        }
    }, [props.selectedData])

    const handleRequestClose = (e) => {
        e.preventDefault();
        props.handleClick('editGroup');
    };

    const onSubmit = (values) => {
        let data = {
            permissions: permission,
            admin_access: admin_access,
            name: values.name,
            groupId: values.groupId
        }
        if (data.permissions.length > 0) {
            props.handleClick('editGroup');
            props.addGroup({ data, listGroup: { searchText: '', page: 1, perPage: 10 } })
            setPermission([])
            setAdminAccess(false)
        } else {
            NotificationManager.error("Please select at least one permission.")
        }
    }

    const handleAdminCheck = (e) => {
        e.preventDefault();
        if (admin_access === false) {
            setPermission(props.listpermissionModulesdata)
            setAdminAccess(true)
        } else {
            setPermission([])
            setAdminAccess(false)
        }
    }

    const handleCheck = (e, index) => {
        e.preventDefault();
        if (!admin_access) {
            let tempPermission = [...permission];
            let permissionIndex = tempPermission.findIndex((e) => e._id === props.listpermissionModulesdata[index]._id);
            if (permissionIndex > -1) {
                tempPermission.splice(permissionIndex, 1);
                setPermission(tempPermission);
            } else {
                tempPermission.push(props.listpermissionModulesdata[index]);
                setPermission(tempPermission);
            }
            if (tempPermission.length === props.listpermissionModulesdata.length) {
                setPermission(tempPermission)
                setAdminAccess(true)
            }
        }
    }

    const { editGroup, title, listpermissionModulesdata, selectedData } = props;

    return (
        <React.Fragment>
            <Dialog open={editGroup} onClose={(e) => handleRequestClose(e)}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        name: selectedData ? selectedData.name : '',
                        groupId: selectedData ? selectedData._id : ''
                    }}
                    render={({ handleSubmit }) =>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <DialogTitle>
                                {title}
                            </DialogTitle>
                            <DialogContent>
                                <Row>
                                    <Col >
                                        <Field id="name" name="name" type="text"
                                            component={ReactStrapTextField} label={"Name"}
                                            validate={required}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                                        <Row>
                                            <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length}
                                                            onClick={(e) => handleAdminCheck(e)}
                                                            value={'admin_access'}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Admin Access'}
                                                />
                                            </Col>
                                            {
                                                (listpermissionModulesdata !== undefined && listpermissionModulesdata.length > 0) ? listpermissionModulesdata.map((value, key) => {
                                                    let index = permission.findIndex((e) => e._id === value._id)
                                                    return <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={index > -1 ? true : false}
                                                                    onClick={(e) => handleCheck(e, key)}
                                                                    value={value.value}
                                                                    color="primary"
                                                                    disabled={listpermissionModulesdata.length === permission.length ? true : false}
                                                                />
                                                            }
                                                            label={value.name === 'Ticketing View' ? 'Ticket Maker' : value.name === 'Ticketing' ? 'Ticket Checker' : value.name}
                                                        />
                                                    </Col>
                                                }) : ''
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </DialogContent>
                            <DialogActions className="pr-4">
                                <Button onClick={(e) => handleRequestClose(e)} color='secondary' >	Cancel </Button>
                                <Button type="submit" color='primary'> Edit </Button>
                            </DialogActions>
                        </form>
                    }
                />
            </Dialog>
        </React.Fragment >
    );
}

const mapStateToProps = ({ seller }) => {
    const { listpermissionModulesdata } = seller;
    return { listpermissionModulesdata }
};

export default connect(mapStateToProps, { listPermissionModules, addGroup })(EditGroup)