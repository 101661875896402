import {
    GET_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    GET_ORDER_HISTORY,
    GET_ORDER_HISTORY_SUCCESS,
    GET_DASHBOARD_SALES_STATS,
    GET_DASHBOARD_SALES_STATS_SUCCESS,
} from 'constants/ActionTypes';

export function getOrder(order) {
    return {
        type: GET_ORDER,
        payload: order
    }
}

export function getOrderSuccess(order) {
    return {
        type: GET_ORDER_SUCCESS,
        payload: order
    }
}

export function getOrderDetails(orderDetails) {
    return {
        type: GET_ORDER_DETAILS,
        payload: orderDetails
    }
}

export function getOrderDetailsSuccess(orderDetails) {
    return {
        type: GET_ORDER_DETAILS_SUCCESS,
        payload: orderDetails
    }
}

export function updateOrder(updatedOrder) {
    return {
        type: UPDATE_ORDER,
        payload: updatedOrder
    }
}

export function updateOrderSuccess(updatedOrder) {
    return {
        type: UPDATE_ORDER_SUCCESS,
        payload: updatedOrder
    }
}

export function getOrderHistory(getOrderHistory) {
    return {
        type: GET_ORDER_HISTORY,
        payload: getOrderHistory
    }
}

export function getOrderHistorySuccess(getOrderHistory) {
    return {
        type: GET_ORDER_HISTORY_SUCCESS,
        payload: getOrderHistory
    }
}

export function getDashboardSalesStats(data) {
    return {
        type: GET_DASHBOARD_SALES_STATS,
        payload: data
    }
}

export function getDashboardSalesStatsSuccess(data) {
    return {
        type: GET_DASHBOARD_SALES_STATS_SUCCESS,
        payload: data
    }
}
