import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getDashboardSalesStats } from 'actions/order'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SalesStatistic = (props) => {
  let { statsData } = props;
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    props.getDashboardSalesStats({});
  }, [])

  useEffect(() => {
    setGraphData(statsData.montlyRevenueOrders);
  }, [statsData])

  let data = [
    { Month: 'Apr', Orders: 0, },
    { Month: 'May', Orders: 0, },
    { Month: 'Jun', Orders: 0, },
    { Month: 'Jul', Orders: 0, },
    { Month: 'Aug', Orders: 0, },
    { Month: 'Sep', Orders: 0, },
    { Month: 'Oct', Orders: 0, },
    { Month: 'Nov', Orders: 0, },
    { Month: 'Dec', Orders: 0, },
    { Month: 'Jan', Orders: 0, },
    { Month: 'Feb', Orders: 0, },
    { Month: 'Mar', Orders: 0, },
  ];

  graphData && graphData.sort(function (a, b) {
    return parseFloat(a._id) - parseFloat(b._id);
  }).map((dataOne, index) => {
    if (Number(dataOne._id) === 4) {
      data[0] = { Month: 'Apr', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 5) {
      data[1] = { Month: 'May', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 6) {
      data[2] = { Month: 'Jun', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 7) {
      data[3] = { Month: 'Jul', Orders: dataOne.orders, Amount: ((dataOne.amount).toFixed(2)) }
    }
    if (Number(dataOne._id) === 8) {
      data[4] = { Month: 'Aug', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 9) {
      data[5] = { Month: 'Sep', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 10) {
      data[6] = { Month: 'Oct', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 11) {
      data[7] = { Month: 'Nov', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 12) {
      data[8] = { Month: 'Dec', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 1) {
      data[9] = { Month: 'Jan', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 2) {
      data[10] = { Month: 'Feb', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
    if (Number(dataOne._id) === 3) {
      data[11] = { Month: 'Mar', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
    }
  })

  let n1;
  let maxValue;
  graphData && graphData.sort(function (a, b) {
    return parseFloat(a.amount) - parseFloat(b.amount);
  }).map((dataOne, index) => {
    n1 = dataOne.amount / 2;
    maxValue = parseInt(dataOne.amount + n1);
  })

  return (
    <div className="jr-card">
      <div className="row">
        <div className="jr-card-header d-flex align-items-center col-6 col-sm-6 col-md-6 col-lg-6">
          <h3 className="mb-0">Sales Statistic</h3>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 col-sm-4 col-md-3 col-lg-2">
          <span className="d-flex align-items-center mb-2">
            <i className="zmdi zmdi-alert-triangle text-muted chart-f20" />
            <span className="ml-3 text-dark">{statsData ? statsData.pendingOrders : 0}</span>
          </span>
          <p className="text-muted">Pending Orders</p>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2">
          <span className="d-flex align-items-center mb-2">
            <i className="zmdi zmdi-calendar-note text-muted chart-f20" />
            <span className="ml-3 text-dark">{statsData ? statsData.monthlyOrders : 0}</span>
          </span>
          <p className="text-muted">Monthly Orders</p>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2">
          <span className="d-flex align-items-center mb-2">
            <i className="zmdi zmdi-shopping-cart text-muted chart-f20" />
            <span className="ml-3 text-dark">₹{statsData ? parseInt(statsData.monthlySales).toLocaleString('en-IN') : 0}</span>
          </span>
          <p className="text-muted">Monthly Sales</p>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2">
          <span className="d-flex align-items-center mb-2">
            <i className="zmdi zmdi-truck text-muted chart-f20" />
            <span className="ml-3 text-dark">{statsData ? statsData.totalOrders : 0}</span>
          </span>
          <p className="text-muted">Total Orders</p>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2">
          <span className="d-flex align-items-center mb-2">
            <i className="zmdi zmdi-chart text-muted chart-f20" />
            <span className="ml-3 text-dark">₹{statsData ? parseInt(statsData.totalSales).toLocaleString('en-IN') : 0}</span>
          </span>
          <p className="text-muted">Total Sales</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-12 mb-12 mb-lg-12">
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis yAxisId="left" domain={[0, 1000]} orientation="left" stroke="#193298" />
              <YAxis yAxisId="right" domain={[0, maxValue]} orientation="right" stroke="#f0a500" />
              <Tooltip formatter={(value) => new Intl.NumberFormat('en-IN').format(value)} />
              <Legend />
              <Bar yAxisId="left" dataKey="Orders" fill="#193298" />
              <Bar yAxisId="right" dataKey="Amount" fill="#f0a500" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ order }) => {
  let { statsData, } = order;
  return { statsData }
}

export default connect(mapStateToProps, { getDashboardSalesStats })(SalesStatistic);
